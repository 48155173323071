/**
 * A single row in the po deep dive table
 */

import React from "react"
import PropType from "prop-types"
import { TableRow, TableCell } from "@amzn/meridian/table"
import Text from "@amzn/meridian/text"
import PropTypes from "prop-types";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import EventIcon from "../../event/event-icon";


const NyrTableRow = props => {
  const { nyrItem, criticalAsinEvents, region_id } = props
  const nyrQuantity = nyrItem.quantityConfirmed - nyrItem.quantityReceived

  const criticalEvents = nyrItem.criticalEvent ? criticalAsinEvents ?
      criticalAsinEvents.filter((criticalAsinEvent) => nyrItem.criticalEvent.includes(criticalAsinEvent.eventName))
      : [] : [];
  return (
    <TableRow key={nyrItem.asin}>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={nyrItem.tempZone ? nyrItem.tempZone : ""}>
          {nyrItem.tempZone ? nyrItem.tempZone : ""}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
          <Column>
            <Text tag="p" children={nyrItem.asin ? nyrItem.asin : ""}>
              {nyrItem.asin ? nyrItem.asin : ""}
            </Text>
              { criticalEvents.length > 0 &&
                  <Row alignmentHorizontal="center">
                      {criticalEvents.map(criticalEvent => <EventIcon event={criticalEvent.eventName} regionId={region_id}/>)}
                  </Row>
              }
          </Column>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={nyrItem.asinTitle ? nyrItem.asinTitle : "Unknown Title"}>
          {nyrItem.asinTitle ? nyrItem.asinTitle : "Unknown Title"}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={nyrItem.quantityConfirmed ? nyrItem.quantityConfirmed : 0}>
          {nyrItem.quantityConfirmed ? nyrItem.quantityConfirmed.toLocaleString() : 0}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={nyrItem.quantityReceived ? nyrItem.quantityReceived : 0}>
          {nyrItem.quantityReceived ? nyrItem.quantityReceived.toLocaleString() : 0}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={nyrQuantity}>
          {nyrQuantity.toLocaleString()}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={nyrItem.isCriticalAsin}>
          {nyrItem.isCriticalAsin}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={nyrItem.isAsrsAsin}>
          {nyrItem.asrsEligible!=null && nyrItem.asrsEligible===1 ? "Y": "N"}
        </Text>
      </TableCell>
    </TableRow>
  )
}

NyrTableRow.propTypes = {
  nyrItem: PropType.object.isRequired,
  rowComponents: TableRow.propTypes.rowComponents,
  criticalAsinEvents: PropTypes.object.isRequired,
  region_id: PropTypes.number.isRequired,
}

export default NyrTableRow
