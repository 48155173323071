/**
 * A context-based hook that allows components to consume stage data we'll mainly use to capture user actions
 */

import React, { useContext, createContext } from "react";

import PropTypes from "prop-types";

const StageContext = createContext();

const StageContextProvider = props => {
  let stage = 'prod';

  if (window.location.hostname.includes('beta')) {
    stage = 'beta';
  } else if (window.location.hostname.includes('dev-dsk')) {
    stage = 'dev';
  }
  return (
    <StageContext.Provider value={stage}>
      {props.children}
    </StageContext.Provider>
  );
};

StageContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStage = () => useContext(StageContext);

export { StageContextProvider, useStage };
export default useStage;