
import { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

import Alert from "@amzn/meridian/alert";
import Button from "@amzn/meridian/button";
import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Toaster from "@amzn/meridian/toaster";
import Select, { SelectOption } from "@amzn/meridian/select";
import Modal, { ModalFooter } from "@amzn/meridian/modal";

import { MainAPI } from "../../utilities/mainAPI";
import useAuth from "../../hooks/use-auth";
import Textarea from "@amzn/meridian/textarea";



const AsinEscalationsActionModal = props => {
  const { asinEscalationRequestId, getASINEscalations } = props;
  const userData = useAuth();
  const [open, setOpen] = useState(false);
  const [actionComment, setActionComment] = useState();
  const [actionStatus, setActionStatus] = useState();
  const [rootCause, setRootCause] = useState();
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [toasts, setToasts] = useState([]);
  const api = new MainAPI();

  const updateSuccessMessage = "Escalation Updated";
  const updateFailureMessage = "Escalation Update Failed!";

  const onCloseToast = id => setToasts(toasts.filter(t => t.id !== id))
  const onOpenToast = (message) => setToasts(toasts.concat({ id: message, timeout: 3000 }))

  const onClickButton = useCallback(() => setOpen(true), []);
  const onCloseButton = () => {
    setOpen(false);
  };


  const onConfirm = async () => {
    setSubmitButtonDisabled(true)
    api.updateDCEscalationAsin(asinEscalationRequestId, actionStatus, actionComment ? actionComment.substring(0, 250) : undefined, rootCause ? rootCause.substring(0, 75) : undefined, userData.username)
      .then((res) => {
        if (res.message === "Success") {
          onOpenToast(updateSuccessMessage);
          getASINEscalations(asinEscalationRequestId);
          setOpen(false);
        } else {
          onOpenToast(updateFailureMessage);
          setSubmitButtonDisabled(false);
        }
      })
  };

  useEffect(() => {
    setSubmitButtonDisabled(!(asinEscalationRequestId && actionStatus))
  }, [asinEscalationRequestId, actionStatus])

  return (
    <>
      <Button
        size="medium"
        minWidth="8%"
        onClick={onClickButton}
        data-testid="modalButton"
        type="icon"
      >
        <Box
          height="100%"
          width="100%"
          type="fill"
          spacingInset="300"
        >
          <Row>
            <Text
              alignment={"center"}
            >
              Update Escalation
            </Text>
          </Row>
        </Box>

      </Button>
      <Modal
        title="Update ASIN Escalation"
        open={open}
        scrollContainer="viewport"
        describedById="asin-escalsations-action-modal-description"
        onClose={onCloseButton}
        closeLabel="Close"
      >
        <Toaster toasts={toasts} onCloseToast={onCloseToast}>
          {toast => <Alert toast={true} type={[updateSuccessMessage, updateFailureMessage].includes(toast.id) ? "success" : "error"}>{toast.id}</Alert>}
        </Toaster>
        <Row spacing={"600"} alignmentVertical={"top"}>
          <Column>
            <Text>ASIN Escalation</Text>
            <Row>
              {asinEscalationRequestId}
            </Row>
            <Row>
              <Select
                value={actionStatus}
                onChange={setActionStatus}
                id="action-status-select"
                placeholder="Select Escalation Status"
              >
                <SelectOption key={"ASIN Not Found"} label={"ASIN Not Found"} value={"ASIN Not Found"} />
                <SelectOption key={"ASIN Found"} label={"ASIN Found"} value={"ASIN Found"} />
                <SelectOption key={"ASIN Virtually Manifested"} label={"ASIN Virtually Manifested"} value={"ASIN Virtually Manifested"} />
              </Select>
            </Row>
            <Row>
              <Textarea
                label={"Enter Comments"}
                size={"medium"}
                value={actionComment}
                onChange={setActionComment}
                rows={4}
                resize={"none"}
                width={400}
              />
            </Row>
            <Row>
              <Textarea
                label={"Enter Root Cause"}
                size={"medium"}
                value={rootCause}
                onChange={setRootCause}
                rows={4}
                resize={"none"}
                width={400}
              />
            </Row>
            <Button
              type="primary"
              onClick={onConfirm}
              disabled={submitButtonDisabled}
            >
              Update Escalation
            </Button>
          </Column>
        </Row>
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button type="secondary" onClick={onCloseButton}>
              Cancel
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  )
};

AsinEscalationsActionModal.propTypes = {
  asinEscalationRequestId: PropTypes.string.isRequired,
  getASINEscalations: PropTypes.func
}

export default AsinEscalationsActionModal;
