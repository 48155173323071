import { API } from 'aws-amplify';
const apiName: string = 'IBMaraudersMapRest';

class MainAPI {

  dcPalletEscalation(warehouseId: string, dc: string, tempZone: string, palletId: string, regionId: number, requester: string, escalationType: string): Promise<any> {
    return API.get(apiName, '/dcEscalations', {
      'queryStringParameters': {
        'warehouseId': warehouseId,
        "dc": dc,
        "tempZone": tempZone,
        "palletId": palletId,
        "escalationType": escalationType,
        "regionId": regionId,
        "requester": requester
      }
    });
  }

  dcCasesEscalation(warehouseId: string, dc: string, tempZone: string, cases: Array<string>, regionId: number, requester: string): Promise<any> {
    return API.get(apiName, '/dcEscalations', {
      'queryStringParameters': {
        'warehouseId': warehouseId,
        "dc": dc,
        "tempZone": tempZone,
        "cases": cases,
        "escalationType": "case",
        "regionId": regionId,
        "requester": requester
      }
    });
  }

  dcAsinsEscalation(warehouseId: string, dc: string, tempZone: string, asins: Array<Map<string, string>>, actionIssue: string, regionId: number, requester: string): Promise<any> {
    return API.get(apiName, '/dcEscalations', {
      'queryStringParameters': {
        'warehouseId': warehouseId,
        "dc": dc,
        "tempZone": tempZone,
        "asins": asins,
        "escalationType": "asin",
        "actionIssue": actionIssue,
        "regionId": regionId,
        "requester": requester
      }
    });
  }

  getDCEscalations(warehouseId: string, regionId: number): Promise<any> {
    return API.get(apiName, '/getDCEscalations', {
      'queryStringParameters': {
        'warehouseId': warehouseId,
        "regionId": regionId
      }
    });
  }

  closeDCEscalation(actionId: string | number, escalationType: string, requester: string): Promise<any> {
    return API.get(apiName, '/closeDCEscalation', {
      'queryStringParameters': {
        'actionId': actionId,
        'escalationType': escalationType,
        "requester": requester
      }
    });
  }

  getDCEscalationCases(requestId: string): Promise<any> {
    return API.get(apiName, '/getDCEscalationCases', {
      'queryStringParameters': {
        'requestId': requestId,
      }
    });
  }

  getDCEscalationAsin(asinEscalationRequestId: string): Promise<any> {
    return API.get(apiName, '/getDCEscalationAsin', {
      'queryStringParameters': {
        'asinEscalationRequestId': asinEscalationRequestId,
      }
    });
  }

  updateDCEscalationAsin(asinEscalationRequestId: string, actionStatus: string, actionComment: string, rootCause: string, updatedBy: string): Promise<any> {
    return API.get(apiName, '/updateDCEscalationAsin', {
      'queryStringParameters': {
        'asinEscalationRequestId': asinEscalationRequestId,
        'actionStatus': actionStatus,
        'actionComment': actionComment,
        'rootCause': rootCause,
        'updatedBy': updatedBy,
      }
    });
  }

  barcodeSearch(warehouseId: string, searchInput: string): Promise<any> {
    return API.get(apiName, '/barcodeSearch', {
      'queryStringParameters': {
        'warehouseId': warehouseId,
        'searchInput': searchInput,
      }
    });
  }
}

export { MainAPI };