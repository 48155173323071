import PropTypes from "prop-types";
import { AiFillAmazonCircle, AiFillStar } from "react-icons/ai";
import { FaAmazon, FaBaby, FaBabyCarriage, FaBroom, FaCity, FaEgg, FaFootballBall, FaHeart, FaLeaf, FaMenorah, FaSchool, FaSnowflake, FaSun } from "react-icons/fa";
import { FaBasketball, FaClover, FaEarthAmericas, FaEarthEurope, FaEarthAsia } from "react-icons/fa6";
import { GiChickenOven, GiFireworkRocket, GiAmericanFootballHelmet, GiMexico, GiPumpkinMask } from "react-icons/gi";
import { IoIosHappy,IoMdFlower } from "react-icons/io";
import { IoDiamond } from "react-icons/io5";
import { MdOutdoorGrill, MdSick } from "react-icons/md";
import { PiFlowerTulipFill } from "react-icons/pi";
import { SlMustache } from "react-icons/sl";
import { TbJewishStarFilled } from "react-icons/tb";

import { IconContext } from "react-icons";
import Tooltip from "@amzn/meridian/tooltip";


const EventIcon = props => {
    const { regionId,  event } = props;
    const specialPrimeDayTitle = 'PRIME_DAY_HOT';
    const normalSize = '1.5em';
    const largeSize = '1.75em';

    const iconMap = {
        default: <AiFillStar title={event} />,
        'PRIME_DAY': <AiFillAmazonCircle title={event} />,
        'SUMMER_STORE': <FaSun title={event} />,
        'FATHERS_DAY': <SlMustache title={event} />,
        'VALENTINES_DAY': <FaHeart title={event} />,
        'SUPER_BOWL': <FaFootballBall title={event} />,
        'ST_PATRICKS_DAY': <FaClover title={event} />,
        'BABY_SALE_MARCH': <FaBabyCarriage title={event} />,
        'MARCH_MADNESS': <FaBasketball title={event} />,
        'EASTER': <FaEgg title={event} />,
        'PASSOVER': <TbJewishStarFilled title={event} />,
        'SPRING_CLEANING': <FaBroom title={event} />,
        'SPRING_STORE': <IoMdFlower title={event} />,
        'EARTH_DAY': regionId === 1 ? <FaEarthAmericas title={event} /> : regionId === 2  ? <FaEarthEurope title={event} /> : <FaEarthAsia title={event} />,
        'MOTHERS_DAY': <PiFlowerTulipFill title={event} />,
        'CINCO_DE_MAYO': <GiMexico title={event} />,
        'SUMMER_GRILLING': <MdOutdoorGrill title={event} />,
        'HAPPY_SCHOOL_YEAR': <FaSchool title={event} />,
        'WELLNESS_SALE_AUGUST': <IoIosHappy title={event} />,
        'BABY_SALE_SEPTEMBER': <FaBaby title={event} />,
        'TAILGATING': <GiAmericanFootballHelmet title={event} />,
        'FALL_FLAVORS': <FaLeaf title={event} />,
        'HALLOWEEN': <GiPumpkinMask title={event} />,
        'COLD_FLU': <MdSick title={event} />,
        'THANKSGIVING': <GiChickenOven title={event} />,
        'HANUKKAH': <FaMenorah title={event} />,
        'HOLIDAY': <FaSnowflake title={event} />,
        'NYNY': <FaCity title={event} />,
        'NYE': <GiFireworkRocket title={event} />,
        'DIAMOND': regionId !== 1 ? <IoDiamond title={event} /> : <FaAmazon title={'PRIME_DAY_HOT'} size={largeSize} color={'red'} />,
    }

    return  (
        <Tooltip position={'end'} title={regionId === 1 && event === 'DIAMOND' ? specialPrimeDayTitle : event}>
            <span>
                <IconContext.Provider value={{ size: normalSize }}>
                    {iconMap[event] ? iconMap[event] : iconMap.default}
                </IconContext.Provider>
            </span>
        </Tooltip>
    );
};

EventIcon.propTypes = {
    regionId: PropTypes.number.isRequired,
    event: PropTypes.string.isRequired,

}

export default EventIcon;