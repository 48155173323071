/**
 * The action bar shown above the iol table. Includes a search field and
 * filters to help find shipments.
 */


import { TableActionBar } from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import HorizontalStickyContainer from "../../app/horizontal-sticky-container";

const HorizontalStickyRow = props => (
  <HorizontalStickyContainer Component={Row} {...props} />
)
const InstockDetailsActionBar = props => {
  const Wrapper = props.stick ? HorizontalStickyRow : Row
  const instockSnapshot = props.instockSnapshot;
  return (
    <Wrapper widths="fill" >
      <TableActionBar alignmentHorizontal="end">
        {`Inventory Snapshot Time (Local): ${new Date(instockSnapshot).toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        })}`}
      </TableActionBar>
    </Wrapper>
  )
}
export default InstockDetailsActionBar
