/**
 * The action bar shown above the shipments table. Includes a search field and
 * filters to help find shipments. Also includes a create shipment button and
 * actions for bulk-editing shipments.
 */

import React, { useRef } from "react";
import PropTypes from "prop-types";
import { TableActionBar } from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Heading from "@amzn/meridian/heading";
import Icon from "@amzn/meridian/icon";
import Text from "@amzn/meridian/text";
import Tooltip from "@amzn/meridian/tooltip";
import filterIconTokens from "@amzn/meridian-tokens/base/icon/filter";
import IncomingShipmentsSearchField from "./incoming-shipments-search-field";
import IncomingShipmentsViewPreferences from "./incoming-shipments-view-preferences";
import HorizontalStickyContainer from "../app/horizontal-sticky-container";
import useIncomingShipmentsFilters from "../../hooks/use-incoming-shipments-filters";
import usePageLayoutContentRectangle from "../../hooks/use-page-layout-content-rectangle";
import incomingShipmentPropType from "../../prop-types/incoming-shipment";
import CsvDownloader from 'react-csv-downloader';
import IncomingShipmentsDateFilter from "./incoming-shipments-date-filter";

const HorizontalStickyRow = props => (
  <HorizontalStickyContainer Component={Row} {...props} />
)

const FilterButton = () => {
  const {
    sheet: [sheetOpen, setSheetOpen],
  } = useIncomingShipmentsFilters()
  const buttonRef = useRef()
  return (
    <Tooltip title="Filters" position="top">
      <Button
        ref={buttonRef}
        onClick={() => setSheetOpen(!sheetOpen)}
        type="icon"
      >
        <Icon tokens={filterIconTokens}>Filters</Icon>
      </Button>
    </Tooltip>
  )
}

const IncomingShipmentsActionBar = props => {
  const { incomingShipments, totalIncomingUnits, totalIncomingPallets } = props
  const Wrapper = props.stick ? HorizontalStickyRow : Row
  const { width: contentWidth = 1200 } = usePageLayoutContentRectangle()
  const mobile = contentWidth < 600

  const csvColumns = ["loadType", "source", "loadId", "tempZone", "scheduledArrivalsParsed",
      "expectedArrivalTimeParsed", "arrivalTimeParsed", "expectedUnits", "expectedAsrsUnitsPercent", "expectedAsrsUnitsPercent", "expectedPallets", "shipmentId", "ocrVendor",
      "scac"
  ]
 
  const mapDataForCsv = () => {
    return incomingShipments.map(data => {
      const selectedData = {};
  
      csvColumns.forEach(key => {
        if (Array.isArray(data[key])) {
          selectedData[key] = data[key].join(' && ');
        }
        else {
          selectedData[key] = data[key];
        }
      });
      return selectedData;
    });
  };

  return (
    <Wrapper widths="fill">
      <TableActionBar alignmentHorizontal="justify">
        <Row spacing="200">
          <IncomingShipmentsDateFilter />
          <IncomingShipmentsSearchField mobile={mobile} />
          <FilterButton />
          <Row>
            <Heading level={6}>Total Incoming Units:</Heading>
            <Text>{totalIncomingUnits.toLocaleString()}</Text>
            <Heading level={6}>Total Incoming Pallets:</Heading>
            <Text>{totalIncomingPallets.toLocaleString()}</Text>
          </Row>
        </Row>
        <Row spacing="200">
          <IncomingShipmentsViewPreferences />
          <CsvDownloader
              datas={ mapDataForCsv()}
              filename="incoming_shipments"
              extension=".csv"
          >
              <Button>CSV</Button>
          </CsvDownloader>

        </Row>
      </TableActionBar>
    </Wrapper>
  )
}

IncomingShipmentsActionBar.propTypes = {
  stick: PropTypes.bool.isRequired,
  incomingShipments: PropTypes.arrayOf(incomingShipmentPropType),
  totalIncomingUnits: PropTypes.number,
  totalIncomingPallets: PropTypes.number,
}

export default IncomingShipmentsActionBar
