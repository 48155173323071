/**
 * A table of shipments. Rendered when the "My shipments" page view is set to
 * "table".
 */

import React, { useState } from "react"
import PropTypes from "prop-types"

import Text from "@amzn/meridian/text"
import Table, { TableRow, TableCell } from "@amzn/meridian/table"
import IncomingShipmentsTableRow from "./incoming-shipments-table-row"
import incomingShipmentPropType from "../../prop-types/incoming-shipment"
import incomingShipmentsTableColumns from "../../data/incoming-shipments-table-columns"
import useIncomingShipmentsViewPreferences from "../../hooks/use-incoming-shipments-view-preferences"

const IncomingShipmentsTable = props => {
  const { incomingShipments, warehouseId } = props
  const [sortColumn, setSortColumn] = useState("")
  const [viewPreferences] = useIncomingShipmentsViewPreferences()
  const [sortDirection, setSortDirection] = useState("ascending")

  const visibleColumns = viewPreferences.get("columns")

  const onSort = ({ sortColumn, sortDirection }) => {
    setSortDirection(sortDirection)
    setSortColumn(sortColumn)
  }

  // Make a copy of incoming shipments and sort if `sortColumn` exists
  const sortedIncomingShipments = [...incomingShipments]
  if (sortColumn) {
    if (sortColumn === "expectedAsrsUnits") {
      sortedIncomingShipments.sort((a, b) => {
        let aValue = isNaN(a.expectedAsrsUnits)  ? 0 : a.expectedAsrsUnits;
        let bValue = isNaN(b.expectedAsrsUnits)  ? 0 : b.expectedAsrsUnits;
        return sortDirection === "ascending" ? aValue - bValue : bValue - aValue;
      })
    }
    else if (sortColumn === "expectedAsrsUnitsPercent") {
      sortedIncomingShipments.sort((a, b) => {
        let aValue = isNaN(a.expectedAsrsUnitsPercent)  ? 0 : a.expectedAsrsUnitsPercent;
        let bValue = isNaN(b.expectedAsrsUnitsPercent)  ? 0 : b.expectedAsrsUnitsPercent;
        return sortDirection === "ascending" ? aValue - bValue : bValue - aValue;
      })
    }
    else{
      sortedIncomingShipments.sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) {
          return sortDirection === "ascending" ? -1 : 1
        }
        else if (a[sortColumn] > b[sortColumn]) {
          return sortDirection === "ascending" ? 1 : -1
        }
        return 0
      })
    }
  }

  const visibleIncomingShipments = sortedIncomingShipments.slice(
    props.visibleIndices[0],
    props.visibleIndices[1]
  )

  return (
    <React.Fragment>
      <Table
        headerRows={1}
        showDividers={true}
        rowComponents={[IncomingShipmentsTableRow, TableRow]}
        stickyHeaderRow={true}
        stickyHeaderColumn={false}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={onSort}
        showStripes={viewPreferences.get("stripes")}
        spacing={viewPreferences.get("spacing")}
      >
        <TableRow>
          {incomingShipmentsTableColumns
            // Only include columns that have been set to "visible"
            .filter(({ id }) => visibleColumns[id])
            .map(header => (
              <TableCell
                key={header.id}
                sortColumn={header.id}
                width={header.width}
                alignmentHorizontal={header.alignment}
              >
                {header.label}
              </TableCell>
            ))}
          <TableCell><Text><strong>Action</strong></Text></TableCell>
        </TableRow>
        {visibleIncomingShipments.map((incomingShipment, index) => (
          <IncomingShipmentsTableRow
            key={incomingShipment.id}
            incomingShipment={incomingShipment}
            warehouseId={warehouseId}
          />
        ))}
      </Table>
    </React.Fragment>
  )
}

IncomingShipmentsTable.propTypes = {
  visibleIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
  incomingShipments: PropTypes.arrayOf(incomingShipmentPropType),
  warehouseId: PropTypes.string.isRequired,
}

IncomingShipmentsTable.defaultProps = {
  areAllSelected: false,
}

export default IncomingShipmentsTable
