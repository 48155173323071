import React, { useState } from "react";
import PropTypes from "prop-types";

import Button from "@amzn/meridian/button";
import Select, { SelectOption } from "@amzn/meridian/select";
import { TableRow, TableCell } from "@amzn/meridian/table";
import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";
import { MainAPI } from "../../../utilities/mainAPI";
import useAuth from "../../../hooks/use-auth";
import Alert from "@amzn/meridian/alert";
import Toaster from "@amzn/meridian/toaster";

const ReviewPalletsTableRow = props => {
  const { transshipmentPallet, dcEscalations, getDCEscalations, warehouseId, sourceDC, regionId, wms } = props;
  const [selectedAction, setSelectedAction] = useState();
  const userData = useAuth();
  const [toasts, setToasts] = useState([]);
  const dcEscalation = dcEscalations ?
      dcEscalations.filter(escalation => escalation.pallet_id === transshipmentPallet.palletId)[0]
      : undefined;

  const submissionSuccessMessage = "Escalation Submitted!";
  const submissionFailureMessage = "Escalation Submission Failed!";

  const onOpenToast = (message) => setToasts(toasts.concat({ id: message, timeout: 3000 }))
    const onCloseToast = id => setToasts(toasts.filter(t => t.id !== id))
  const onSubmit = async () => {
    // Show a success toast that the shipment has been updated.
    const api = new MainAPI()
    api.dcPalletEscalation(
        warehouseId,
        sourceDC,
        transshipmentPallet.tempZone ? transshipmentPallet.tempZone : "UNKNOWN",
        transshipmentPallet.palletId,
        regionId,
        userData.username,
        selectedAction
    )
          .then((res) => {
              if (res.message === "Success") {
                onOpenToast(submissionSuccessMessage)
                getDCEscalations(warehouseId, regionId)
                setSelectedAction()
              } else {
                onOpenToast(submissionFailureMessage)
              }
          })
  }

  return (
    <TableRow>
        <Toaster toasts={toasts} onCloseToast={onCloseToast}>
          {toast => <Alert toast={true} type={[submissionSuccessMessage].includes(toast.id) ? "success" : "error"}>{toast.id}</Alert>}
        </Toaster>
      <TableCell alignmentHorizontal="center">{transshipmentPallet.palletId}</TableCell>
      <TableCell alignmentHorizontal="center">
          <Column>
          {transshipmentPallet.tempZone ?
              transshipmentPallet.tempZone.split(",").sort().map(tempZone => <Text>{tempZone}</Text>) :
              <Text>{"UNKNOWN"}</Text>
          }
          </Column>
      </TableCell>
      {wms === 'AFTLite' &&
        <TableCell alignmentHorizontal="center">{transshipmentPallet.received ? "Y" : "N"}</TableCell>
      }
      <TableCell alignmentHorizontal="center">
          {transshipmentPallet.received && wms === 'AFTLite' ? "SPOKES" : dcEscalation ? dcEscalation.action_owner : "SPOKES"}
      </TableCell>
      <TableCell alignmentHorizontal="center">
          {
              transshipmentPallet.received && wms === 'AFTLite' ?
                  "Pallet has been received"
                  : dcEscalation ?
                      "Escalation submitted"
                      : <Select
                          value={selectedAction}
                          onChange={setSelectedAction}
                          placeholder="Select Action"
                          width={300}
                        >
                          <SelectOption value="missing-pallet" label="Pallet did not arrive at site" />
                        </Select>
          }
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Button
          type="primary"
          onClick={onSubmit}
          disabled={transshipmentPallet.received && wms === 'AFTLite' ?
              true
              : selectedAction === undefined
        }
        >
          Submit
        </Button>
      </TableCell>
    </TableRow>
  );
};

ReviewPalletsTableRow.propTypes = {
  transshipmentPallet: PropTypes.object,
  dcEscalations: PropTypes.arrayOf(PropTypes.object),
  getDCEscalations: PropTypes.func,
  warehouseId: PropTypes.string,
  sourceDC: PropTypes.string,
  regionId: PropTypes.number,
  wms: PropTypes.string,
};

export default ReviewPalletsTableRow;