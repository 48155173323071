/**
 * "Instock" page - view instock Risk POs and Transshipments
 */
import React, {
  useRef,
  useState,
  useEffect,
} from "react"
import {
  VictoryChart,
  VictoryVoronoiContainer,
  VictoryAxis,
  VictoryLine,
} from "victory"
import Legend, { LegendProvider } from "@amzn/meridian/legend"
import victoryLinePlugin from "@amzn/meridian/legend/plugin/victory-line"
import victoryTooltipPlugin from "@amzn/meridian/legend/plugin/victory-tooltip"
import useVictoryTheme from "@amzn/meridian/use-victory-theme"
import Column from "@amzn/meridian/column"
import Text from "@amzn/meridian/text"
import Row from "@amzn/meridian/row"
import PageLayout from "../components/app/page-layout"
import TableFooter from "../components/app/table-footer"
import usePagination from "../hooks/use-pagination"
import usePageLayoutContentRectangle from "../hooks/use-page-layout-content-rectangle"
import InstockTable from "../components/instock/instock-table";
import {  resultsPerPageOptions } from "../components/Constants";

import InstockEmpty from "../components/instock/instock-empty"
import InstockActionBar from "../components/instock/instock-action-bar"
import useInstockFilters from "../hooks/use-instock-filters"
import FilterTags from "../components/instock/filter-tags"
import useFilters from "../hooks/use-filters";
import filterInstock from "../filters/filter-instock";


let vbi_data = []
const getTableWidth = wrapperElement => {
  try {
    return wrapperElement.querySelector("table").clientWidth
  } catch (_) {
    return undefined
  }
}
const Instock = props => {
  
 
  const theme = useVictoryTheme({ showIndependentGrid: false })
  const { warehouseId, instock, instockSnapshot, vbi, oosAsinCount, oosRiskAsinCount, vbiCtm, noosCount} = props
  vbi_data = vbi
  const { filters } = useInstockFilters()
  console.log(filters)
  const instock_f = useFilters(instock, filterInstock, filters);
  console.log(instock_f)
  const { width: contentWidth = 1200 } = usePageLayoutContentRectangle();
  const tableWrapperRef = useRef();
  const [resultsPerPage, setResultsPerPage] = useState(10);
  
  const [selectedResultsPerPage, setSelectedResultsPerPage] = useState(10);
  // const shipments = useFilters(incomingIncomingShipments, filterIncomingShipments, filters)
  // const instock = useInstock(instock1, filters)
  const pagination = usePagination({
    numberOfItemsPerPage: resultsPerPage,
    numberOfResults: instock_f?.length || 0
  })
  const { visibleIndices } = pagination
  const tableWidth = getTableWidth(tableWrapperRef.current)
  const isSticky = contentWidth < tableWidth
  const datasets = [
    {
      key: "vbi",
      label: `VBI % (Weighted)`,
      data: vbi_data
    }]
  

    const onChangeResultsPerPage = resultsPerPage => {
      // Update local state variable
      setSelectedResultsPerPage(resultsPerPage)
      if (resultsPerPage === "All") {
        setResultsPerPage(instock_f?.length || 0)
      } else {
        setResultsPerPage(resultsPerPage)
      }
      // Change pagination back to page 1. This avoids the weird state users can
      // find themselves in where they're on a page that no longer exists if the
      // number of pages decreases.
      pagination.onChange(1)
    }
  
    // Set pagination back to page 1 when filters change
    useEffect(() => {
      // Change pagination back to page 1. This avoids the weird state users can
      // find themselves in where they're on a page that no longer exists if the
      // number of pages decreases.
      onChangeResultsPerPage(selectedResultsPerPage)
      // We disable the exhaustive-deps hooks rule here because including `pagination`
      // will cause the effect to be called over-and-over.
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [filters, instock])
  

  return (
    
      <PageLayout
      loading={!instock_f }
      width={instock_f ? "inherit" : undefined}
      horizontallySticky={isSticky}
      maxWidth={isSticky ? "none" : undefined}
      alignmentHorizontal={isSticky ? "start" : "center"}
      >
      
      {vbi && vbi.length > 0 ? (<LegendProvider
      data={datasets}
      plugins={[
        victoryLinePlugin({ theme }),
        victoryTooltipPlugin({ showCrosshair: true }),
      ]}
      aria-label="Vbi intraday"
      >
      {({ getLineProps, voronoiContainerProps, Tooltip }) => (
        <Column spacing="none">
          <VictoryChart
            theme={theme}
            width={1500}
            height={460}
            name="vbi intraday"
            containerComponent={
              <VictoryVoronoiContainer
                labels={formatLabel}
                {...voronoiContainerProps}
              />
            }
          >
          <VictoryAxis />
          <VictoryAxis
            dependentAxis
            tickFormat={(value, index) => (index > 0 ? value : "")}
          />
          {datasets.map(({ data, key }) => (
            <VictoryLine
              key={key}
              data={data}
              x="snapshot_hour"
              y="vbi_weight_pcnt"
              labels={formatLabel}
              labelComponent={<Tooltip legendKey={key} />}
              {...getLineProps(key)}
            />
          ))}
          </VictoryChart>
          <Legend direction="horizontal" />
        </Column>
      )}
  </LegendProvider>) : null}
  <Column>
  <InstockActionBar oosAsinCount={oosAsinCount} oosRiskAsinCount={oosRiskAsinCount} vbiCtm={vbiCtm} noosCount={noosCount} ></InstockActionBar>
  <FilterTags stick={isSticky} />
    <Row>

      <InstockTable visibleIndices={visibleIndices} stick={true} warehouseId={warehouseId} instock={instock_f} instockSnapshot={instockSnapshot}/></Row>
    {instock && instock.length === 0 ? (
      <Row><InstockEmpty searching={Boolean(0)} /></Row>
    ) : null}
    <Row>
      <Text ><em>** VBI CTM is calculated using only OOS ASINs</em></Text>
      </Row>
    {instock && instock.length > 0 ? (
    <Row>
      
      <TableFooter
        pagination={pagination}
        stick={isSticky}
        resultsPerPage={resultsPerPage}
        onChangeResultsPerPage={onChangeResultsPerPage}
        resultsPerPageOptions={resultsPerPageOptions}
        selectedResultsPerPage={selectedResultsPerPage}
      />
    </Row>
    ) : null}
  </Column>
  </PageLayout>
  )    
}
const formatLabel = ({ datum }) => { return datum.vbi_weight_pcnt.toFixed(3)}

export default Instock
