/**
 * A modal that allows user to reconcile a dock received shipment.
 */

import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

import Button from "@amzn/meridian/button";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";

import Modal, { ModalFooter } from "@amzn/meridian/modal";

import { MainAPI } from "../../utilities/mainAPI";
import Table, {TableCell, TableRow} from "@amzn/meridian/table";



const DCEscalationsCasesModal = props => {
  const { escalationId } = props;
  const [cases, setCases] = useState();
  const [open, setOpen] = useState(false);


  useEffect(() => {
    const getCaseData= async () => {
      const api = new MainAPI();
      const data = await api.getDCEscalationCases(escalationId)
        if (data) {
          setCases(data);
        }
    }
    getCaseData()
  }, [setCases, escalationId]);

  const onClickButton = useCallback(() => setOpen(true), []);
  const onCloseButton = () => {
    setOpen(false);
    };


  return (
    <>
      <Button
        size="medium"
        minWidth="8%"
        onClick={onClickButton}
        data-testid="modalButton"
        type="link"
      >
          {escalationId}
      </Button>
      <Modal
        title="DC Escalation Cases"
        open={open}
        scrollContainer="viewport"
        describedById="dc-escalsations-cases-modal-description"
      >
        {cases ?
          <Table headerRows={1}>
            <TableRow>
              <TableCell>Container ID</TableCell>
            </TableRow>
            {cases.map(containerId =>
                <TableRow>
                  <TableCell>{containerId}</TableCell>
                </TableRow>
                )}
          </Table>
            : <Loader/>
        }
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button type="secondary" onClick={onCloseButton}>
              Cancel
            </Button>

          </Row>
        </ModalFooter>
      </Modal>
    </>
  )
};

DCEscalationsCasesModal.propTypes = {
  warehouseId: PropTypes.string.isRequired,
  escalationId: PropTypes.string.isRequired
}

export default DCEscalationsCasesModal;
