import Countdown from "react-countdown";
import PropTypes from "prop-types";
import Text from "@amzn/meridian/text";

const DockReceivedSlaCounter = props => {
  const { countdownDate, onTick } = props;
  return (
    <Countdown
      date={countdownDate}
      daysInHours={true}
      overtime={true}
      onTick={onTick}
      // How often it ticks in ms
      intervalDelay={15000}
      renderer={({ days, hours, minutes }) => {
        const renderedHours = (days * 24) + hours;
        return (
            <Text tag="p">
                {Date.now() > countdownDate ? "-" : ""}{renderedHours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}
            </Text>)
      }}
    />
  )
};

DockReceivedSlaCounter.propTypes = {
  countdownDate: PropTypes.number.isRequired,
  onTick: PropTypes.func,
};

export default DockReceivedSlaCounter;