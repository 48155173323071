import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Heading from "@amzn/meridian/heading";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";

import usePagination from "../../hooks/use-pagination";
import useFilters from "../../hooks/use-filters";
import useNetworkPODefectsFilters from "../../hooks/use-network-po-defects-filters";
import NetworkPODefectsActionBar from "../../components/network-po-defects/network-po-defects-action-bar";
import NetworkPODefectsTable from "../../components/network-po-defects/network-po-defects-table";
import TableFooter from "../../components/app/table-footer";
import EmptyTable from "../../components/app/empty-table";
import filterNetworkPODefects from "../../filters/filter-network-po-defects";
import FilterTags from "../../components/app/filter-tags";

import { defaultResultsPerPage, resultsPerPageOptions } from "../../components/Constants";


const NetworkPODefects = props => {
  const { incomingNetworkPODefects } = props;
  const [resultsPerPage, setResultsPerPage] = useState(defaultResultsPerPage);
  const [selectedResultsPerPage, setSelectedResultsPerPage] = useState(defaultResultsPerPage);
  const { filters } = useNetworkPODefectsFilters();
  const networkPODefects = useFilters(incomingNetworkPODefects, filterNetworkPODefects, filters);

  const pagination = usePagination({
    numberOfItemsPerPage: resultsPerPage,
    numberOfResults: networkPODefects?.length || 0,
  });

  const onChangeResultsPerPage = resultsPerPage => {
    // Update local state variable
    setSelectedResultsPerPage(resultsPerPage)
    if (resultsPerPage === "All") {
      setResultsPerPage(networkPODefects?.length || 0)
    } else {
      setResultsPerPage(resultsPerPage)
    }
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    pagination.onChange(1)
  }

  // Set pagination back to page 1 when filters change
  useEffect(() => {
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    onChangeResultsPerPage(selectedResultsPerPage)
    // We disable the exhaustive-deps hooks rule here because including `pagination`
    // will cause the effect to be called over-and-over.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filters, incomingNetworkPODefects])

  const { visibleIndices } = pagination;

  return (
    <Column>
      <Row><Heading level={2}>{"PO Defects: Pos checked in/received incorrectly against future POs"}</Heading></Row>
      <Row><NetworkPODefectsActionBar /></Row>
      <Row><FilterTags stick={true} filtersHook={useNetworkPODefectsFilters}/></Row>
      <Row><NetworkPODefectsTable networkPODefects={networkPODefects} visibleIndices={visibleIndices}/></Row>
      {networkPODefects.length === 0 ? (
        <Row><EmptyTable searching={Boolean(filters.size)} /></Row>
        ) : null
      }
      {networkPODefects.length > 0 ? (
        <Row>
          <TableFooter
            pagination={pagination}
            stick={false}
            resultsPerPage={resultsPerPage}
            onChangeResultsPerPage={onChangeResultsPerPage}
            resultsPerPageOptions={resultsPerPageOptions}
            selectedResultsPerPage={selectedResultsPerPage}
          />
        </Row>
        ) : null
      }
    </Column>
  );
};

NetworkPODefects.propTypes = {
  dwellingShipments: PropTypes.arrayOf(PropTypes.object).isRequired,
  shipmentsType: PropTypes.string.isRequired
};

export default NetworkPODefects;