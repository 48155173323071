/**
 * A single row in the iol table. Shows details for a item.
 */

import React from "react"
import PropTypes from "prop-types"
import { TableRow, TableCell } from "@amzn/meridian/table"
import Text from "@amzn/meridian/text"
import iolPropType from "../../prop-types/iol"
import IOLDetailsModal from "./iol-details-modal"

const rowShouldNotUpdate = (prevProps, nextProps) =>
  !(
    prevProps.selected !== nextProps.selected ||
    prevProps.iol !== nextProps.iol
  )

const IOLTableRow = React.memo(props => {
  const { iol, warehouseId } = props

  return (
    <TableRow key={iol.id} rowComponents={props.rowComponents}>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {iol.location}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {iol.tempZone}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        {iol.quantity ? iol.quantity.toLocaleString() : 0}
      </TableCell>
      <TableCell alignmentHorizontal="center">
        {iol.dwellTimeHours ? iol.dwellTimeHours.toLocaleString() : null}
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <IOLDetailsModal incomingIOLDetails={iol.details} location={iol.location} warehouseId={warehouseId}/>
      </TableCell>
    </TableRow>
  )
}, rowShouldNotUpdate)

IOLTableRow.propTypes = {
  iol: iolPropType.isRequired,
  rowComponents: TableRow.propTypes.rowComponents,
  warehouseId: PropTypes.string.isRequired
}

export default IOLTableRow
