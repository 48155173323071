// React
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

// Amplify
import { Amplify, Analytics, Auth } from "aws-amplify";
import awsExportsDev from './awsExports/dev/aws-exports';
import awsExportsBeta from './awsExports/beta/aws-exports';
import awsExportsProd from './awsExports/prod/aws-exports';
import { authenticateAmplify } from './components/auth/midwayAuth';
import type { AuthDetails } from './components/auth/midwayAuth';

import { baseUrl } from "./components/Constants";

// Assets
import '@amzn/meridian-tokens/base/font/amazon-ember.css';
import './App.css';

// Meridian
import AppLayout from '@amzn/meridian/app-layout';
import Loader from "@amzn/meridian/loader";


// Layout Components
import Masthead from './components/app/masthead';
import SideMenu from './components/app/side-menu';
import Footer from './components/app/footer';

// Context Providers
import { AuthContextProvider } from "./hooks/use-auth";
import { StageContextProvider } from "./hooks/use-stage";
import { WarehouseDataContextProvider } from "./hooks/use-warehouse-data";
import { SideMenuOpenContextProvider } from "./hooks/use-side-menu-open";
import { SelectedOptionsContextProvider } from "./hooks/use-selected-options";
import { IncomingShipmentsFiltersContextProvider } from "./hooks/use-incoming-shipments-filters";
import { DockReceivedFiltersContextProvider } from "./hooks/use-dock-received-filters";
import { NYRFiltersContextProvider } from "./hooks/use-nyr-filters";
import { NYSFiltersContextProvider } from "./hooks/use-nys-filters";
import { IOLFiltersContextProvider } from "./hooks/use-iol-filters";
import { IOLDetailsFiltersContextProvider } from "./hooks/use-iol-details-filters";
import { NetworkDwellingPosFiltersContextProvider } from "./hooks/use-network-dwelling-pos-filters";
import { NetworkDwellingTransshipmentsFiltersContextProvider } from "./hooks/use-network-dwelling-transshipments-filters";
import { NetworkPODefectsFiltersContextProvider } from "./hooks/use-network-po-defects-filters";
import { NetworkPODeepDiveFiltersContextProvider } from "./hooks/use-network-po-deep-dive-filters";
import MeridianMDXProvider from "./components/app/meridian-mdx-provider";

// Routes
import Router from './routing/router';
import CurrentScreen from './routing/current-screen';
import CurrentSheet from './routing/current-sheet';
import ErrorRecoveryScreen from './screens/error-recovery-screen';

// Utilities
import ResetScroll from './components/app/reset-scroll';
import { NotificationsFiltersContextProvider } from "./hooks/use-notifications-filters";
import { InstockFiltersContextProvider } from './hooks/use-instock-filters';


let stage = 'prod';

if (window.location.hostname.includes('beta')) {
  stage = 'beta';
} else if (window.location.hostname.includes('dev-dsk')) {
  stage = 'dev';
}

// We're mapping our backend stages to match our frontend
const awsMap = { dev: awsExportsDev, beta: awsExportsBeta, prod: awsExportsProd };

const awsExports = awsMap[stage as keyof typeof awsMap];

const redirectURLs = {
  dev: 'https://' + window.location.hostname + ':3000/',
  beta: 'https://beta.' + baseUrl,
  prod: 'https://' + baseUrl
};

const redirectURL = redirectURLs[stage as keyof typeof redirectURLs];

awsExports.oauth.redirectSignIn = redirectURL;
awsExports.oauth.redirectSignOut = redirectURL;

Amplify.configure(awsExports);
Auth.configure(awsExports);
Analytics.configure(awsExports);

Analytics.autoTrack('session', {
  enable: true,
  immediate: true,
  provider: "AWSPinpoint"
});

Analytics.autoTrack('pageView', {
  enable: true,
  type: 'SPA',
  immediate: true,
  provider: "AWSPinpoint"
});

Analytics.autoTrack('event', {
  enable: true,
  immediate: true,
  provider: "AWSPinpoint"
});



function App() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(undefined);
  const [user, setUser] = useState<{ name: string } | null>(null);

  useEffect(() => {
    const authUser = async () => {
      const loggedInUser: AuthDetails = await authenticateAmplify();

      if (loggedInUser['username'] === '') {
        setIsLoggedIn(false);
      } else {
        setIsLoggedIn(true);
        const newUser = { name: loggedInUser['username'] };
        setUser(newUser);
      }
    }
    if (!isLoggedIn) {
      authUser();
    }

  }, [isLoggedIn, setIsLoggedIn])
  const LoginMessage = () => isLoggedIn === undefined ? <Loader /> : <div>unauthorized</div>;
  return (
    <>

      {!isLoggedIn ?
        <LoginMessage /> :

        <AuthContextProvider>
        <StageContextProvider>
        <WarehouseDataContextProvider>
        <SideMenuOpenContextProvider>
        <SelectedOptionsContextProvider>
          <Router>
            <ResetScroll />
            <IncomingShipmentsFiltersContextProvider>
            <DockReceivedFiltersContextProvider>
            <NYRFiltersContextProvider>
            <NYSFiltersContextProvider>
            <IOLFiltersContextProvider>
            <IOLDetailsFiltersContextProvider>
            <InstockFiltersContextProvider>
            <NetworkDwellingPosFiltersContextProvider>
            <NetworkDwellingTransshipmentsFiltersContextProvider>
            <NetworkPODefectsFiltersContextProvider>
            <NetworkPODeepDiveFiltersContextProvider>
            <NotificationsFiltersContextProvider>
              <AppLayout
                headerComponent={Masthead}
                sidebarComponent={SideMenu}
                footerComponent={Footer}
                sheetComponent={CurrentSheet}
                alwaysShowScrollbar={true}
              >
                <Masthead />
                <SideMenu />
                <Footer />
                <ErrorBoundary FallbackComponent={ErrorRecoveryScreen}>
                  <MeridianMDXProvider>
                    <CurrentScreen user = {user}/>
                  </MeridianMDXProvider>
                </ErrorBoundary>
                <CurrentSheet />
              </AppLayout>
            </NotificationsFiltersContextProvider>
            </NetworkPODeepDiveFiltersContextProvider>
            </NetworkPODefectsFiltersContextProvider>
            </NetworkDwellingTransshipmentsFiltersContextProvider>
            </NetworkDwellingPosFiltersContextProvider>
            </InstockFiltersContextProvider>
            </IOLDetailsFiltersContextProvider>
            </IOLFiltersContextProvider>
            </NYSFiltersContextProvider>
            </NYRFiltersContextProvider>
            </DockReceivedFiltersContextProvider>
            </IncomingShipmentsFiltersContextProvider>
          </Router>
        </SelectedOptionsContextProvider>
        </SideMenuOpenContextProvider>
        </WarehouseDataContextProvider>
        </StageContextProvider>
        </AuthContextProvider>
      }
    </>
  )

}

export default App;
