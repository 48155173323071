/**
 * A hook that simplifies state management for the Pagination component.
 */

import React, { useState, useMemo, useCallback } from "react"
import ScreenReaderAnnouncer from "../components/app/screen-reader-announcer"

const usePagination = ({
  numberOfItemsPerPage,
  numberOfResults,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const numberOfPages = useMemo(
    () => Math.ceil(numberOfResults / numberOfItemsPerPage),
    [numberOfResults, numberOfItemsPerPage]
  )

  const visibleIndices = useMemo(() => {
    const firstIndex = (currentPage - 1) * numberOfItemsPerPage
    return [firstIndex, firstIndex + numberOfItemsPerPage]
  }, [currentPage, numberOfItemsPerPage])

  const onChange = useCallback(newValue => setCurrentPage(newValue), [setCurrentPage])
  return {
    numberOfPages,
    currentPage,
    onChange,
    visibleIndices,
    PaginationAnnouncer: () => (
      <ScreenReaderAnnouncer aria-live="assertive">{`Page ${currentPage}`}</ScreenReaderAnnouncer>
    ),
  }
}

export default usePagination