/**
 * All "IOL" table columns. Each column has an `id` (used as a key and for
 * sorting), `label` (the header column text), `width` (how wide the column should
 * be in pixels), and `alignment` (which side the column's content "sticks" to).
 */

const InstockDetailsTableColumns = [
    {
      id: "alarm",
      label: "Alarm",
      width: 100,
      alignment: "center",
    },
    {
      id: "asin",
      label: "ASIN",
      width: 100,
      alignment: "center",
    },
    {
      id: "asinTitle",
      label: "Product Name",
      width: 400,
      alignment: "center",
    },
    {
      id: "noos",
      label: "NOOS",
      width: 100,
      alignment: "center",
    },
    {
      id: "tempZoneDetailed",
      label: "Temp Zone",
      width: 100,
      alignment: "center",
    },
    {
      id: "quantityConfirmed",
      label: "Quantity Confirmed",
      width: 100,
      alignment: "center",
    },
    {
      id: "onhand_inventory",
      label: "Onhand Inventory",
      width: 100,
      alignment: "center",
    },
    {
      id: "demand",
      label: "Demand",
      width: 80,
      alignment: "center",
    },
    {
      id: "weight",
      label: "AVIF Weight",
      width: 80,
      alignment: "center",
    },
    
  ]
  
  export const defaultInstockDetailsTableColumns = InstockDetailsTableColumns.reduce(
    (columns, column) => ({
      ...columns,
      [column.id]: true,
    }),
    {}
  )
  
  export const getInstockDetailsTableColumnById = id =>
    InstockDetailsTableColumns.find(column => column.id === id)
  
  export default InstockDetailsTableColumns
  