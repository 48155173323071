import React from "react";
import PropTypes from "prop-types";
import Pie from "@amzn/meridian/pie";
import Legend, { LegendProvider } from "@amzn/meridian/legend";
import Heading from "@amzn/meridian/heading";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";

const NetworkNYRBreakdownPie = props => {
  const { chartData, chartTitle } = props;
  return (
    <LegendProvider data={chartData} aria-labelledby={chartTitle}>
      <Row spacing="500" alignmentHorizontal="center" alignmentVertical="top">
        <Pie width={150} />
        <Column>
          <Heading id={chartTitle} level={4}>
            {chartTitle}
          </Heading>
          <Legend
            direction="vertical"
            values={item => item.value.toLocaleString()}
          />
        </Column>
      </Row>
    </LegendProvider>
  );
};

NetworkNYRBreakdownPie.propTypes = {
  chartData: PropTypes.object,
  chartTitle: PropTypes.string.isRequired
}

export default NetworkNYRBreakdownPie;