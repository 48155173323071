import PropTypes from "prop-types";
import usePagination from "../../hooks/use-pagination";
import React, {useEffect, useState} from "react";
import { ShipmentSearchResultTable } from "./shipment-search-result-table";
import Column from "@amzn/meridian/column";
import ShipmentSearchEmpty from "./shipment-search-empty";
import Row from "@amzn/meridian/row";
import TableFooter from "../app/table-footer";
import { resultsPerPageOptions } from "../Constants";

export const ShipmentSearchResult = props => {
    const { result } = props;
    const [resultsPerPage, setResultsPerPage] = useState(result?.length || 0)
    const [selectedResultsPerPage, setSelectedResultsPerPage] = useState('All')

    const pagination = usePagination({
        numberOfItemsPerPage: resultsPerPage,
        numberOfResults: result?.length || 0,
    })

    const { visibleIndices } = pagination

    useEffect(() => {
        setResultsPerPage(result?.length || 0);
        setSelectedResultsPerPage('All');
    }, [result]);

    const onChangeResultsPerPage = resultsPerPage => {
        // Update local state variable
        setSelectedResultsPerPage(resultsPerPage)
        if (resultsPerPage === "All") {
            setResultsPerPage(result?.length || 0)
        } else {
            setResultsPerPage(resultsPerPage)
        }
        // Change pagination back to page 1. This avoids the weird state users can
        // find themselves in where they're on a page that no longer exists if the
        // number of pages decreases.
        pagination.onChange(1)
    };

    return (
            <Column>
                {result?.length > 0 &&
                    <ShipmentSearchResultTable result={result ? result : []} visibleIndices={visibleIndices}/>}
                {!result || result?.length === 0 ? (
                    <ShipmentSearchEmpty searching={result?.length === 0} />
                ) : null}
                {result?.length > 0 ? (
                    <Row>
                        <TableFooter
                            pagination={pagination}
                            stick={true}
                            resultsPerPage={resultsPerPage}
                            onChangeResultsPerPage={onChangeResultsPerPage}
                            resultsPerPageOptions={resultsPerPageOptions}
                            selectedResultsPerPage={selectedResultsPerPage}
                        />
                    </Row>
                ) : null}
            </Column>
    )
};

ShipmentSearchResult.propTypes = {
    result: PropTypes.arrayOf(
        PropTypes.shape(
            {
                asin: PropTypes.string,
                orderId: PropTypes.string.isRequired,
                palletId: PropTypes.string,
                source: PropTypes.string,
                destination: PropTypes.string,
                manifestTime: PropTypes.number,
                appointmentTime: PropTypes.number,
                actualArrival: PropTypes.number,
                dockReceivedTime: PropTypes.number,
                status: PropTypes.string,
                nyr_nys: PropTypes.number,
                searchInput: PropTypes.string,
                searchId: PropTypes.string,
            }
            )
    ).isRequired,
};
