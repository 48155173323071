function csvParser(csvString: string, lineDelimiter: string = ""): string[] {
    const lineDelimiters: Array<string> = ["\r\n", "\n"];
    let finalLineDelimiter = lineDelimiter;

    if (finalLineDelimiter === "") {
        for (const delimiter of lineDelimiters) {
            if (csvString.includes(delimiter)) {
                finalLineDelimiter = delimiter
                break
            }
        }
    }

    return csvString.split(finalLineDelimiter);
}

export { csvParser };