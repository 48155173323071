function getStartOfDay(timeZone: string, dayDiff:number = 0) {
    const now = Date.now();
    const [hour, minute, second] = Intl.DateTimeFormat("sv-SE", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
      timeZone: timeZone
    }).format(now).split(":")
    return  (
        // zero out ms
        (Math.floor(now / 1000) * 1000)
        // Subtract current hour
        - (parseInt(hour) * 3600000)
        // Subtract current minute
        - (parseInt(minute) * 60000)
        // Subtract current second
        - (parseInt(second) * 1000)
        // Add in day diff
        + (dayDiff * 86400000)
    )
};

export { getStartOfDay };