// /**
//  * This module exports a function that filters an array of networkDwellings according
//  * to a Map of filters.
//  */
//

/**
 * Returns a function that checks if an object's value is in an array of options.
 * @example
 * const checkFoo = isInOptions("foo")
 * const object = { foo: "bar" }
 * checkFoo(object, ["bar", "baz"])  // returns true
 * checkFoo(object, ["baz"]) // returns false
 */

import { searchBarFilter } from "../utilities/search-bar-filter";
import defaultNetworkPODefectsTableColumns from "../data/network-po-defects-table-columns";


// An object of filter applications where each key points to a function
// that expects `networkDwelling` and `value` to consider
const applyFilter = {
  allFields: (networkDwelling, value) => {
    // If any field passes, return true
    const searchableFields = defaultNetworkPODefectsTableColumns.map(column => column.id);
    const dateKeys = [
      "firstAppointmentTime",
      "firstDockReceiveTime",
      "handoffWindowStartDateUTC",
      "handoffWindowEndDateUTC",
      "firstReceiveTime"
    ];
    return searchBarFilter(value, networkDwelling, dateKeys, searchableFields);
  },
}

/**
 * This function filters an array of networkDwellings according to a Map of filters.
 *
 * @param  networkDwelling          Array of networkDwelling objects (see `src/prop-types/incoming-iol` to see shape)
 * @param  filters            Map of filter objects
 * @return filteredNetworkPODefects Array of filtered networkDwellings
 */
const filterNetworkPODefects = (networkDwelling, filters) => {
  if (filters.size === 0) return networkDwelling
  let filteredNetworkPODefects = [...networkDwelling]
  // Loop through and apply filter in the Map to the networkDwelling array
  for (const filter of filters) {
    const [key, { value }] = filter
    filteredNetworkPODefects = filteredNetworkPODefects.filter(networkDwelling =>
      applyFilter[key](networkDwelling, value)
    )
  }
  return filteredNetworkPODefects
}

export default filterNetworkPODefects
