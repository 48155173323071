import { TableRow, TableCell } from "@amzn/meridian/table";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Link from "@amzn/meridian/link";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import Button from "@amzn/meridian/button";
import { convertUnix } from "../../utilities/convert-unix";
import InstockDetailsModal from "./instock-details-modal"
import useWarehouseData from "../../hooks/use-warehouse-data";
import { getWarehouseAttributes } from "../../utilities/get-warehouse-attributes";
import copyTokens from "@amzn/meridian-tokens/base/icon/copy";
import Tag from "@amzn/meridian/tag";
const InstockTableRow = props => {
    const { instock, warehouseId, instockSnapshot} = props
    const { time_zone } = getWarehouseAttributes(
      {warehouseId: warehouseId, warehouseParams: useWarehouseData()}
  );

    return (
        <TableRow width={900}>
          { (
            <TableCell alignmentHorizontal="center" width={10}>
              <Text tag="p">
                {instock.alarm==="OUT_OF_STOCK" ? "OOS" : "OOS Risk" }
              </Text>
              {instock.noos ?
              <Tag type="error" key="noos">
              {`NOOS: ${instock.noos}`}
              </Tag>
              : <Tag type="success" key="noos">
              {`NOOS: ${instock.noos}`}
              </Tag>
              }
            </TableCell>
          )}
          {<TableCell alignmentHorizontal="center" width={100}>
              <Column>
              <Row alignmentHorizontal="center">
              <Link
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
              <Text tag="p">
                {instock.orderId && instock.orderId.startsWith('PO_') ? (instock.orderId.substring(instock.orderId.indexOf('_') + 1)) : instock.orderId
                }
                </Text>
                </Link>
              <Button type="icon" size="small" onClick={() => {
                navigator.clipboard.writeText(instock.orderId.substring(instock.orderId.indexOf('_') + 1))
              }}>
                <Icon tokens={copyTokens} />
              </Button>
              </Row>
              </Column>
              
            </TableCell>
          }
          {(
            <TableCell alignmentHorizontal="center">
              <Text tag="p">
                {convertUnix(instock.firstDockReceiveTime, time_zone)}
              </Text>
            </TableCell>
          )}
          {(
            <TableCell alignmentHorizontal="center">
              <Text tag="p">
                {instock.oos_asin_category}
              </Text>
            </TableCell>
          )}
          {(
            <TableCell alignmentHorizontal="center">
              <Text tag="p">
                {instock.oos_quantity}
              </Text>
            </TableCell>
          )}
          {(
            <TableCell alignmentHorizontal="center">
              <Text tag="p">
                {instock.oos_risk_quantity}
              </Text>
            </TableCell>
          )}
          {(
            <TableCell alignmentHorizontal="center">
              <Text tag="p">
                {instock.vbi ? Math.round(instock.vbi) : "0"}
              </Text>
            </TableCell>
          )}
          {(
            <TableCell alignmentHorizontal="center">
               <InstockDetailsModal warehouseId={warehouseId} instock={instock.details} instockSnapshot={instockSnapshot}/>
            </TableCell>
          )}
          </TableRow>
    ) 
}
export default InstockTableRow