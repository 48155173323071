/**
 * A single row in the incoming network shipments table. Shows summary of incoming shipments.
 */

import React from "react";
import PropTypes from "prop-types";
import { TableRow, TableCell } from "@amzn/meridian/table";
import Text from "@amzn/meridian/text";


const IncomingNetworkShipmentsTableRow = props => {
  const { incomingNetworkShipment, incomingNetworkShipmentsTableColumns } = props
  return (
      <>
    {Object.keys(incomingNetworkShipment.tempZones).map(tempZone =>
      <TableRow>
        {Object.keys(incomingNetworkShipment.tempZones).indexOf(tempZone) === 0 ?
          <TableCell  header={true} alignmentHorizontal={"center"} rowSpan={Object.keys(incomingNetworkShipment.tempZones).length} >
            <Text><strong>{incomingNetworkShipment.warehouseId}</strong></Text>
          </TableCell>
           : null
        }
        <TableCell><Text>{tempZone}</Text></TableCell>
        {incomingNetworkShipmentsTableColumns.map(date =>
          <TableCell>
            <Text>
                {incomingNetworkShipment.tempZones[tempZone][date] ?
                    incomingNetworkShipment.tempZones[tempZone][date].toLocaleString() : 0
                }
            </Text>
          </TableCell>
        )}
      </TableRow>
    )}
          </>
  )
}

IncomingNetworkShipmentsTableRow.propTypes = {
  incomingNetorkShipment: PropTypes.object.isRequired,
  incomingNetworkShipmentsTableColumns: PropTypes.object.isRequired,
  rowComponents: TableRow.propTypes.rowComponents,
}

export default IncomingNetworkShipmentsTableRow
