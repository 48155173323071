/**
 * A single row in the dock received shipments table. Shows details for a single shipment.
 */

import React, {useState} from "react";
import PropTypes from "prop-types";

import { TableRow, TableCell } from "@amzn/meridian/table";
import Link from "@amzn/meridian/link";
import Text from "@amzn/meridian/text";

import ExceptionTag from "../../app/exception-tag";
import useWarehouseData from "../../../hooks/use-warehouse-data";
import { getWarehouseAttributes } from "../../../utilities/get-warehouse-attributes";
import NYRModal from "../nyr/nyr-modal";
import NYSModal from "../nys/nys-modal";
import DockReceivedSlaCounter from "../dock-received-sla-counter";
import Icon from "@amzn/meridian/icon";
import copyTokens from "@amzn/meridian-tokens/base/icon/copy";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import Alert from "@amzn/meridian/alert";
import Toaster from "@amzn/meridian/toaster";


const NotificationsTableRow = props => {
  const { notification, warehouseId } = props
  const { wms } = getWarehouseAttributes(
      {warehouseId: warehouseId, warehouseParams: useWarehouseData()}
  );

  const [toasts, setToasts] = useState([]);
  const onCloseToast = id => setToasts(toasts.filter(t => t.id !== id));
  const onOpenToast = loadId => setToasts(toasts.concat({ id: loadId, timeout: 1500 }));

   const transshipmentLinkMap = {
    "AFTLite": `https://aftlite-portal.amazon.com/receive_inventory/show?transferId=10369`,
    "PRIMS": "https://dashboard.prod.whitetree.ihm.a2z.com/IhmTransshipDashboards/index.html#/inbound"
  };

  const vendorLinkMap = {
    "AFTLite": `https://procurementportal-na.corp.amazon.com/bp/po?poId=${notification.loadId}&tabId=summary`,
    "PRIMS": `https://dashboard.prod.whitetree.ihm.a2z.com/IhmVendorPODashboard/index.html#/vendor-pos-detail/${notification.loadId}`
  }


  return (
    <TableRow key={notification.id} rowComponents={props.rowComponents}>
        <Toaster toasts={toasts} onCloseToast={onCloseToast}>
            {toast => (
              <Alert toast={true} onClose={toast.onClose}>
                {`${toast.id} copied to clipboard`}
              </Alert>
            )}
        </Toaster>
        <TableCell alignmentHorizontal="center">
            <ExceptionTag exception={notification.alarmCategory} />
        </TableCell>
        <TableCell alignmentHorizontal="center" width={"10%"}>
            <Row>
              <Link
                  href={notification.loadType === "Vendor" ?
                      vendorLinkMap[wms]
                      : transshipmentLinkMap[wms]
                  }
                  target={"_blank"}
                  rel={"noopener noreferrer"}
              >
                <Text truncate={true}>{notification.loadId}</Text>
              </Link>
            <Button type="icon" size="small" onClick={() => {
              navigator.clipboard.writeText(notification.loadId)
              onOpenToast(notification.loadId)
            }
            }>
                <Icon tokens={copyTokens} />
            </Button>
            </Row>
        </TableCell>
      
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            {notification.tempZone}
          </Text>
        </TableCell>
      

        <TableCell alignmentHorizontal="center">
            {notification.nyrnys > 0 ?
                notification.loadType === "Vendor" ?
                  <NYRModal
                    nyr={notification.nyrnys}
                    po={notification.loadId}
                    tempZone={notification.tempZone}
                  />
                  : <NYSModal
                      nys={notification.nyrnys}
                      manifestId={notification.loadId}
                      tempZone={notification.tempZone}
                    />

                : <Text tag="p">{notification.nyrnys.toLocaleString()}</Text>
            }
        </TableCell>
      
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            {`${notification.progress}%`}
          </Text>
        </TableCell>

      
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            <DockReceivedSlaCounter
                countdownDate={notification.lastDockReceiveTime + (notification.sla ? Math.min(notification.sla, 24) : 24) * 60 * 60 * 1000}
            />
          </Text>
        </TableCell>
      
    </TableRow>
  )
}

NotificationsTableRow.propTypes = {
    notification: PropTypes.object,
    warehouseId: PropTypes.string.isRequired
}

export default NotificationsTableRow
