/**
 * A helper for laying out page contents including a title, breadcrumbs, content,
 * and a loader.
 */

import React from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import Column from "@amzn/meridian/column"
import Responsive from "@amzn/meridian/responsive"
import Row from "@amzn/meridian/row"
import Heading from "@amzn/meridian/heading"
import Breadcrumb, { BreadcrumbGroup } from "@amzn/meridian/breadcrumb"
import Loader from "@amzn/meridian/loader"
import HorizontalStickyContainer from "./horizontal-sticky-container"
import RowOrColumn from "../app/row-or-column"

export const contentId = "page-layout-content"

// If sticky is true, ConditionalStickyRow will be HorizontalStickyContainer.
// Otherwise it will be React.Fragment which essentially means there will be
// no wrapper (https://reactjs.org/docs/fragments.html)
const ConditionalStickyRow = props =>
  props.sticky ? (
    <HorizontalStickyContainer Component={Row} {...props} />
  ) : (
    <React.Fragment>{props.children}</React.Fragment>
  )

ConditionalStickyRow.propTypes = {
  children: PropTypes.node,
  sticky: PropTypes.bool,
}

const PageLayout = props => {
  const navigate = useNavigate()

  return (
    <Column
      alignmentHorizontal={props.alignmentHorizontal}
      spacingInset={props.spacingInset}
      id={contentId}
    >
      <Column
        width={props.width}
        maxWidth={props.maxWidth}
        spacing={props.spacing}
      >
        {props.breadcrumbs?.length > 0 ? (
          <ConditionalStickyRow sticky={props.horizontallySticky}>
            <BreadcrumbGroup>
              {props.breadcrumbs.map(breadcrumb => (
                <Breadcrumb
                  key={breadcrumb.path}
                  href={breadcrumb.path}
                  onClick={navigate}
                >
                  {breadcrumb.title}
                </Breadcrumb>
              ))}
              {props.title && <Breadcrumb>{props.title}</Breadcrumb>}
            </BreadcrumbGroup>
              Stick me
          </ConditionalStickyRow>
        ) : null}
        {props.title ? (
          <ConditionalStickyRow sticky={props.horizontallySticky}>
            <Responsive
              query="min-width"
              props={{
                direction: {
                  default: "column",
                  "900px": "row",
                },
              }}
            >
              {({ direction }) => (
                <RowOrColumn
                  columnProps={{ alignmentHorizontal: "start" }}
                  rowProps={{ widths: ["fill", "fit"] }}
                  direction={direction}
                >
                  <Heading level={1} type="h600">
                    {props.title}
                  </Heading>
                  {props.actions}
                </RowOrColumn>
              )}
            </Responsive>
          </ConditionalStickyRow>
        ) : null}
        {props.loading ? (
          <ConditionalStickyRow sticky={props.horizontallySticky} widths="fill">
            <Column alignmentHorizontal="center" spacingInset="600 none">
              <Loader type="circular" label="Loading shipments" />
            </Column>
          </ConditionalStickyRow>
        ) : (
          props.children
        )}
      </Column>
    </Column>
  )
}

PageLayout.propTypes = {
  actions: PropTypes.node,
  alignmentHorizontal: Column.propTypes.alignmentHorizontal,
  backgroundColor: Column.propTypes.backgroundColor,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    })
  ),
  children: PropTypes.node,
  horizontallySticky: PropTypes.bool,
  loading: PropTypes.bool,
  maxWidth: PropTypes.string,
  spacing: Column.propTypes.spacing,
  spacingInset: Column.propTypes.spacingInset,
  title: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

PageLayout.defaultProps = {
  alignmentHorizontal: "center",
  loading: false,
  spacing: "500",
  spacingInset: "500 500 600",
  horizontallySticky: false,
  maxWidth: "100%",
  width: 950,
}

export default PageLayout