/**
 * A component that uses react-router to render the current application sheet
 * based on the URL path.
 */

import React from "react"
import { Routes, Route } from "react-router-dom"
// Sheets
import IncomingShipmentsFilterSheet from "../components/incoming-shipments/incoming-shipments-filter-sheet"
import DockReceivedFilterSheet from "../components/dock-received/dock-received-filter-sheet"
import IOLDetailsFilterSheet from "../components/iol/iol-details/iol-details-filter-sheet"
import InstockFilterSheet from "../components/instock/instock-filter-sheet"

const CurrentSheet = () => {
  return (
    <Routes>
      <Route path="/" element={<></>} />
      <Route path="/summary" element={<></>} />
        <Route path="/summary/building" element={<></>} />
          <Route path="/summary/building/:warehouseId" element={<></>} />
        <Route path="/summary/network" element={<></>} />
          <Route path="/summary/network/:group" element={<></>} />
      <Route path="/building" element={<></>} />
        <Route path="/building/:warehouseId" element={<></>} />
          <Route path="/building/:warehouseId/incomingShipments" element={<IncomingShipmentsFilterSheet />} />
          <Route path="/building/:warehouseId/dockReceived" element={<DockReceivedFilterSheet />} />
          <Route path="/building/:warehouseId/iol" element={<IOLDetailsFilterSheet />} />
          <Route path="/building/:warehouseId/instock" element={<InstockFilterSheet />} />
    </Routes>
  )
}

export default CurrentSheet