/**
 * A container that will horizontally stick on scroll. See `hooks/use-page-layout-content-rectangle`
 * to learn how the "sticky" styles are calculated.
 */

import React from "react"
import PropTypes from "prop-types"
import { cx, css } from "emotion"
import usePageLayoutContentRectangle from "../../hooks/use-page-layout-content-rectangle"

const stickyStyles = ({ width, left, right }) =>
  css({
    width,
    left,
    right,
    position: "sticky !important",
  })

const HorizontalStickyContainer = props => {
  const contentRectangle = usePageLayoutContentRectangle()
  return (
    <props.Component
      {...props}
      className={cx(props.className, stickyStyles(contentRectangle))}
    />
  )
}

HorizontalStickyContainer.propTypes = {
  Component: PropTypes.elementType.isRequired,
  className: PropTypes.string,
}

export default HorizontalStickyContainer