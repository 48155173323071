/**
 * All "My shipment" table columns. Each column has an `id` (used as a key and for
 * sorting), `label` (the header column text), `width` (how wide the column should
 * be in pixels), and `alignment` (which side the column's content "sticks" to).
 */

const dockReceivedTableColumns = [
  {
    id: "loadType",
    label: "Load Type",
    width: 95,
    alignment: "center",
  },
  {
    id: "source",
    label: "Source",
    width: 95,
    alignment: "center",
  },
  {
    id: "loadId",
    label: "Load ID",
    width: 95,
    alignment: "center",
  },
  {
    id: "tempZone",
    label: "Temp Profile",
    width: 95,
    alignment: "center",
  },
  {
    id: "criticalAsinEvents",
    label: "Events",
    width: 95,
    alignment: "center"
  },
  {
    id: "actualArrival",
    label: "Actual Arrival",
    width: 175,
    alignment: "center",
  },
  {
    id: "dockReceived",
    label: "Dock Received",
    width: 175,
    alignment: "center",
  },
  {
    id: "lastDockReceiveBy",
    label: "Dock Received By",
    width: 175,
    alignment: "center",
  },
  {
    id: "firstReceiveTime",
    label: "First Receive",
    width: 175,
    alignment: "center",
  },
  {
    id: "confirmedUnits",
    label: "Confirmed Units",
    width: 95,
    alignment: "center",
  },
  {
    id: "asrsConfirmedUnits",
    label: "Confirmed Automation Units",
    width: 95,
    alignment: "center",
  },
  {
    id: "asrsConfirmedUnitsPercent",
    label: "Confirmed Automation Units %",
    width: 95,
    alignment: "center",
  },
  {
    id: "receivedUnits",
    label: "Received Units",
    width: 95,
    alignment: "center",
  },
  {
    id: "asrsReceivedUnits",
    label: "Received Automation Units",
    width: 95,
    alignment: "center",
  },
  {
    id: "nyrnys",
    label: "NYR/NYS",
    width: 95,
    alignment: "center",
  },
  {
    id: "progress",
    label: "Progress",
    width: 95,
    alignment: "center",
  },
  {
    id: "dwellDays",
    label: "Dwell (Days)",
    width: 95,
    alignment: "center",
  },
  {
    id: "timeToSLA",
    label: "Time to SLA (hh:mm)",
    width: 95,
    alignment: "center",
  },
  {
    id: "action",
    label: "Action",
    width: 95,
    alignment: "center",
  },

]

// By default all visible table columns are set to visible (`true`)
export const defaultDockReceivedTableColumns = dockReceivedTableColumns.reduce(
  (columns, column) => ({
    ...columns,
    [column.id]: true,
  }),
  {}
)

export const getDockReceivedTableColumnById = id =>
  dockReceivedTableColumns.find(column => column.id === id)

export default dockReceivedTableColumns
