/**
 * The date range selector for incoming shipments
 */

import React from "react";
import useNotificationsFilters from "../../../hooks/use-notifications-filters";
import Select, { SelectOption } from "@amzn/meridian/select";
import notifications, { getNotificationById } from "../../../data/notifications";



const NotificationsDropFilterFilter = () => {
  const { filters, dispatch } = useNotificationsFilters()
  const onChangeFilter = (filter, makeLabel) => value => {
    if (!value || value.length === 0) {
      dispatch({ type: "REMOVE_FILTER", filter })
    } else {
      dispatch({
        type: "UPDATE_FILTER",
        filter,
        value,
        tagLabel: makeLabel(value),
      })
    }
  }

  return (
    <Select
      value={filters.get("alarmCategory")?.value || []}
      onChange={onChangeFilter("alarmCategory", value =>
        value.map(
          notification => `Alarm: ${getNotificationById(notification).label}`
        )
      )}
      id="notification-select"
      placeholder="Select an Alarm"
    >
      {notifications.map(({ id, label }) => (
        <SelectOption key={id} label={label} value={id} />
      ))}
    </Select>
  )
}

export default NotificationsDropFilterFilter
