import React from "react";
import PropTypes from "prop-types";


import Table, { TableRow, TableCell } from "@amzn/meridian/table";

import ReviewPalletsTableRow from "./review-pallets-table-row";

const ReviewPalletsTable = props => {
  const { transshipmentPallets, dcEscalations, getDCEscalations, warehouseId, sourceDC, regionId, wms } = props;
  return (
    <Table
        headerRows={1}
        showDividers={true}
        rowComponents={[ReviewPalletsTableRow, TableRow]}
        stickyHeaderRow={true}
      >
      <TableRow>
        <TableCell header={true}>Pallet Id</TableCell>
        <TableCell header={true}>Temp Zone</TableCell>
        {wms === 'AFTLite' && <TableCell header={true}>Receive Initiated</TableCell>}
        <TableCell header={true}>Action Owner</TableCell>
        <TableCell header={true}>Action</TableCell>
        <TableCell header={true}>Escalate</TableCell>
      </TableRow>
      {transshipmentPallets.map(transshipmentPallet =>
          <ReviewPalletsTableRow
              transshipmentPallet={transshipmentPallet}
              dcEscalations={dcEscalations}
              getDCEscalations={getDCEscalations}
              warehouseId={warehouseId}
              sourceDC={sourceDC}
              regionId={regionId}
              wms={wms}
          />
      )}
    </Table>
  );
};

ReviewPalletsTable.propTypes = {
  transshipmentPallets: PropTypes.arrayOf(PropTypes.object).isRequired,
  dcEscalations: PropTypes.arrayOf(PropTypes.object),
  getDCEscalations: PropTypes.func,
  warehouseId: PropTypes.string,
  sourceDC: PropTypes.string,
  regionId: PropTypes.number,
  wms: PropTypes.string,
};

export default ReviewPalletsTable;
