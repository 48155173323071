/**
 * A hook that that gives components access to the current array of shipments.
 * The component will be re-rendered with the latest iol any time the
 * data changes.
 */

import { useMemo } from "react"
import filterIOLDetails from "../filters/filter-iol-details"

const useIOLDetails = (incomingIOLDetails, filters = new Map()) => {

  const filteredIOLDetails = useMemo(() => {
    if (incomingIOLDetails) {
      return filterIOLDetails(incomingIOLDetails, filters)
    } else {
      return null
    }
  }, [filters, incomingIOLDetails])

  return filteredIOLDetails
}

export default useIOLDetails
