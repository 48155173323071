import React from "react";
import useNYSFilters from "../../../hooks/use-nys-filters";
import Select, { SelectOption } from "@amzn/meridian/select";
import asrsEligible, { getAsrsEligible } from "../../../data/asrs-eligible"



const NYSAsrsAsinFilter = () => {
  const { filters, dispatch } = useNYSFilters()
  const onChangeFilter = (filter, makeLabel) => value => {
    if (!value || value.length === 0) {
      dispatch({ type: "REMOVE_FILTER", filter })
    } else {
      dispatch({
        type: "UPDATE_FILTER",
        filter,
        value,
        tagLabel: makeLabel(value),
      })
    }
  }

  return (
    <Select
        value={filters.get("asrsEligible")?.value || []}
        onChange={onChangeFilter("asrsEligible", value =>
        value.map(
            asrsEligible => `asrsEligible: ${getAsrsEligible(asrsEligible).label}`
        )
        )}
        id="asrsEligible"
        placeholder="Is asrs eligible?"
    >
        {asrsEligible.map(({ id, label }) => (
        <SelectOption key={id} label={label} value={id} />
        ))}
    </Select>
  )
}

export default NYSAsrsAsinFilter