/**
 * The action bar shown above the dock received table. Includes a search field and
 * filters to help find shipments.
 */

import React from "react";
import PropTypes from "prop-types";
import { TableActionBar } from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import CsvDownloader from "react-csv-downloader";
import NotificationsSearchField from "./notifications-search-field";
import NotificationsDropFilterFilter from "./notifications-drop-filter";

const NotificationsActionBar = props => {
  const { notifications } = props
  const Wrapper = Row;

  const csvColumns = ["loadType", "source", "loadId", "tempZone", "actualArrivalParsed", "notificationsParsed",
          "lastDockReceiveBy", "firstReceiveTimeParsed", "confirmedUnits", "receivedUnits", "nyrnys", "progress",
          "dwellDays", "sla", "suppression", "alarmCategory"
        ]

  return (
    <Wrapper widths="fill">
      <TableActionBar alignmentHorizontal="justify">
        <Row spacing="200">
          <NotificationsSearchField />
          <NotificationsDropFilterFilter />
          <CsvDownloader
              datas={notifications.map(data => Object.fromEntries(csvColumns.map(key => [key, data[key]])))}
              filename="notifications"
              extension=".csv"
          >
              <Button>CSV</Button>
          </CsvDownloader>
        </Row>
      </TableActionBar>
    </Wrapper>
  )
}

NotificationsActionBar.propTypes = {
  notifications: PropTypes.object.isRequired,
}

export default NotificationsActionBar
