/**
 * Renders a warning/error tag for shipment exceptions (e.g. late, redirected).
 */

import React from "react";
import PropTypes from "prop-types";
import Tag from "@amzn/meridian/tag";
import { getExceptionById } from "../../data/exceptions";

const ExceptionTag = props => {
  const { exception } = props;
  const exceptionTag = getExceptionById(exception);
  if (!exceptionTag) return null;
  return (
    <>
      {props.children}
      <Tag type={exceptionTag.type} key={exceptionTag.id}>
        {exceptionTag.label}
      </Tag>
    </>
  );
};

ExceptionTag.propTypes = {
  exception: PropTypes.string,
  children: PropTypes.node,
};

export default ExceptionTag;
