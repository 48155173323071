/**
 * A modal that allows user to reconcile a dock received shipment.
 */

import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

import Alert from "@amzn/meridian/alert";
import Badge from "@amzn/meridian/badge";
import Button from "@amzn/meridian/button";
import Box from "@amzn/meridian/box";
import ButtonGroup, { ButtonOption } from "@amzn/meridian/button-group";
import Column from "@amzn/meridian/column";
import FileInput, { FileDetails } from "@amzn/meridian/file-input";
import helpTokens from "@amzn/meridian-tokens/base/icon/help";
import Icon from "@amzn/meridian/icon"
import Input from "@amzn/meridian/input"
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import Text from "@amzn/meridian/text";
import Toaster from "@amzn/meridian/toaster";
import Tooltip from "@amzn/meridian/tooltip";
import Select, { SelectOption } from "@amzn/meridian/select";
import Modal, { ModalFooter } from "@amzn/meridian/modal";

import { basicTempZones } from "../Constants";

import { getPaginatedAPICall } from "../../utilities/get-paginated-api-call";
import { getDCs } from "../../graphql/queries";
import useWarehouseData from "../../hooks/use-warehouse-data";
import { getWarehouseAttributes } from "../../utilities/get-warehouse-attributes";
import { csvParser } from "../../utilities/csv-parser";
import { MainAPI } from "../../utilities/mainAPI";
import useAuth from "../../hooks/use-auth";
import DCEscalationsCasesModal from "./dc-escalations-cases-modal";
import Link from "@amzn/meridian/link";



const DCEscalationsModal = props => {
  const { warehouseId, dcEscalations, getDCEscalations } = props;
  const warehouseParam = getWarehouseAttributes({ warehouseId: warehouseId, warehouseParams: useWarehouseData() })
  const userData = useAuth();
  const [open, setOpen] = useState(false);
  const [escalationType, setEscalationType] = useState("pallet");
  const [palletId, setPalletId] = useState("");
  const [percentage, setPercentage] = useState(undefined);
  const [files, setFiles] = useState([]);
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(files.length > 0);
  const [selectedDC, setSelectedDC] = useState();
  const [dcs, setDCs] = useState();
  const [selectedTempZone, setSelectedTempZone] = useState();
  const [selectedActionIssue, setSelectedActionIssue] = useState();
  const [uploadedFile, setUploadedFile] = useState();
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [toasts, setToasts] = useState([]);
  const [escalationCloseButtonDisabled, setEscalationCloseButtonDisabled] = useState(false);
  const api = new MainAPI();

  const submissionSuccessMessage = "Escalation Submitted!";
  const submissionFailureMessage = "Escalation Submission Failed!";
  const closeSuccessMessage = "Escalation Closed!";
  const closeFailureMessage = "Escalation Closure Failed";

  const maxPalletIdCharacterLength = 20;

  const spokeOwnedEscalations = dcEscalations ? dcEscalations.filter(dcEscalation => dcEscalation.action_owner === "SPOKES").length : 0;

  const onCloseToast = id => setToasts(toasts.filter(t => t.id !== id))
  const onOpenToast = (message) => setToasts(toasts.concat({ id: message, timeout: 3000 }))

  useEffect(() => {
    const getDCData = async () => {
      const params = {
        warehouse: {
          regionId: warehouseParam.region_id,
        }
      };
      const incomingData = await getPaginatedAPICall(params, getDCs, "getDCs")
      if (incomingData) {
        setDCs(incomingData.sort((a, b) => a.warehouse_id.localeCompare(b.warehouse_id)))
      }
    }
    getDCData()
  }, [setDCs, warehouseParam]);

  const onClickButton = useCallback(() => setOpen(true), []);
  const onCloseButton = () => {
    setOpen(false);
    setPalletId("");
    setFiles([]);
    setSelectedTempZone(undefined);
    setSelectedActionIssue(undefined);
    setSelectedDC(undefined);
  };


  const onConfirm = async () => {
    setSubmitButtonDisabled(true)
    if (escalationType === "pallet") {
      api.dcPalletEscalation(warehouseId, selectedDC, selectedTempZone, palletId, warehouseParam.region_id, userData.username, "untracked-pallet")
        .then((res) => {
          if (res.message === "Success") {
            onOpenToast(submissionSuccessMessage)
            getDCEscalations(warehouseId, warehouseParam.region_id)
            setSelectedDC()
            setSelectedTempZone()
            setSelectedActionIssue()
            setFiles([])
            setPalletId("")
          } else {
            onOpenToast(submissionFailureMessage)
            setSubmitButtonDisabled(false)
          }
        })
    } else if (escalationType === "case") {
      const cases = csvParser(uploadedFile.result).map(row => (
        row.split(",").map(container => {
          if (container.startsWith("ts") || container.startsWith("cs")) {
            return container
          } else {
            return undefined
          }
        })
      )).flat().filter(item => item !== undefined);
      api.dcCasesEscalation(warehouseId, selectedDC, selectedTempZone, cases, warehouseParam.region_id, userData.username)
        .then((res) => {
          if (res.message === "Success") {
            onOpenToast(submissionSuccessMessage)
            getDCEscalations(warehouseId, warehouseParam.region_id)
            setSelectedDC()
            setSelectedTempZone()
            setSelectedActionIssue()
            setFiles([])
            setPalletId("")
          } else {
            onOpenToast(submissionFailureMessage)
            setSubmitButtonDisabled(false)
          }
        })
    } else if (escalationType === "asin") {
      const asins = csvParser(uploadedFile.result).map(row => {
        const attributes = row.split(",");
        return (Object.values(attributes).every(value => value)) ? JSON.stringify({ "asin": attributes[0], "cpt": attributes[1] }) : undefined;
      }).flat().filter(item => (item !== undefined));
      console.log(asins)
      api.dcAsinsEscalation(warehouseId, selectedDC, selectedTempZone, asins, selectedActionIssue, warehouseParam.region_id, userData.username)
        .then((res) => {
          console.log(res)
          if (res.message === "Success") {
            onOpenToast(submissionSuccessMessage)
            getDCEscalations(warehouseId, warehouseParam.region_id)
            setSelectedDC()
            setSelectedTempZone()
            setSelectedActionIssue()
            setFiles([])
            setPalletId("")
          } else {
            onOpenToast(submissionFailureMessage)
            setSubmitButtonDisabled(false)
          }
        })
    }
  };

  const onCloseEscalation = async (dcEscalation) => {
    setEscalationCloseButtonDisabled(true)
    api.closeDCEscalation(dcEscalation.action_id, dcEscalation.escalation_type, userData.username)
      .then((res) => {
        if (res.message === "Success") {
          onOpenToast(closeSuccessMessage)
          getDCEscalations(warehouseId, warehouseParam.region_id)
        } else {
          onOpenToast(closeFailureMessage)

        }
      })
    setEscalationCloseButtonDisabled(false)
  };

  useEffect(() => {
    setUploadButtonDisabled(files.length > 0)
  }, [setUploadButtonDisabled, files])

  useEffect(() => {
    if (escalationType === "pallet") {
      setSubmitButtonDisabled(!(selectedDC && selectedTempZone && palletId.length > 0))
    } else if (escalationType === "case") {
      setSubmitButtonDisabled(!(selectedDC && selectedTempZone && files.length > 0))
    } else if (escalationType === "asin") {
      setSubmitButtonDisabled(!(selectedDC && selectedTempZone && selectedActionIssue && files.length > 0))
    } else {
      setSubmitButtonDisabled(false)
    }
  }, [selectedDC, selectedTempZone, selectedActionIssue, palletId, files, escalationType])

  useEffect(() => {
    if (palletId.length > maxPalletIdCharacterLength) {
      setPalletId(palletId.slice(0, maxPalletIdCharacterLength))
    }
  }, [palletId, setPalletId])

  return (
    <>
      <Button
        size="medium"
        minWidth="8%"
        onClick={onClickButton}
        data-testid="modalButton"
        type="icon"
      >
        <Box
          backgroundColor={spokeOwnedEscalations <= 0 ? "#077398" : "#CC0C39"}
          height="100%"
          width="100%"
          type="fill"
          spacingInset="300"
        >
          <Row>
            <Text
              alignment={"center"}
              color={"inverted"}
            >
              DC Escalations
            </Text>
            <Badge
              value={spokeOwnedEscalations}
              type={spokeOwnedEscalations <= 0 ? "neutral" : "error"}
            />
          </Row>
        </Box>

      </Button>
      <Modal
        title="Report DC Escalations"
        open={open}
        scrollContainer="viewport"
        describedById="dc-escalsations-modal-description"
        onClose={onCloseButton}
        closeLabel="Close"
      >
        <Toaster toasts={toasts} onCloseToast={onCloseToast}>
          {toast => <Alert toast={true} type={[submissionSuccessMessage, closeSuccessMessage].includes(toast.id) ? "success" : "error"}>{toast.id}</Alert>}
        </Toaster>
        <Row spacing={"600"} alignmentVertical={"top"}>
          <Column>
            <ButtonGroup value={escalationType} onChange={setEscalationType}>
              <ButtonOption value="pallet">Pallet Escalation</ButtonOption>
              <ButtonOption value="case">Tote/Case Escalation</ButtonOption>
              <ButtonOption value="asin">ASIN Escalation</ButtonOption>
            </ButtonGroup>
            <Row>
              {dcs ?
                <Select
                  value={selectedDC}
                  onChange={setSelectedDC}
                  id="dc-select"
                  placeholder="Select a source DC"
                >
                  {dcs.map((dc) => (
                    <SelectOption key={dc.warehouse_id} label={dc.warehouse_id} value={dc.warehouse_id} />
                  ))}
                </Select>
                : <Loader />
              }
            </Row>
            <Row>
              <Select
                value={selectedTempZone}
                onChange={setSelectedTempZone}
                id="temp-zone-select"
                placeholder="Select Temp Zone"
              >
                {basicTempZones.map((tempZone) => (
                  <SelectOption key={tempZone} label={tempZone} value={tempZone} />
                ))}
              </Select>
            </Row>
            {escalationType === "pallet" && (
              <Row>
                <Input
                  label={"Pallet ID"}
                  size={"small"}
                  value={palletId}
                  onChange={setPalletId}
                />
              </Row>
            )}
            {escalationType === "case" && (
              <Row>
                <FileInput
                  onFileAttached={(acceptedFiles) => {
                    acceptedFiles.forEach((file) => {
                      file.uploadedFileHref = "https://www.google.com";
                      let fileData = new FileReader();
                      fileData.readAsText(file);
                      setPercentage(100);
                      setUploadedFile(fileData);
                    });
                    setFiles([...files, ...acceptedFiles]);
                  }}
                  accept=".csv"
                  uploadButtonDisabled={uploadButtonDisabled}
                  uploadButtonLabel={"Upload Case/Tote List (CSV)"}
                  uploadButtonType={"tertiary"}
                >
                  {files.map((file) => {
                    return (
                      <FileDetails
                        error={file.error}
                        errorMessage={file.errorMessage}
                        file={file}
                        connectionSpeed="1MB/s"
                        uploadPercentage={percentage}
                        key={file.name}
                        onClickCancel={() => {
                          setFiles(files.filter((fl) => fl.name !== file.name));
                        }}
                        onClickRemoveFile={() => {
                          const updatedFileArr = files.filter((fl) => fl.name !== file.name);
                          setFiles(updatedFileArr);
                          setPercentage(0);
                          setUploadedFile();
                        }}
                        uploadComplete={percentage === 100}
                      />
                    );
                  })}
                </FileInput>
                <Tooltip position="end" title="File should be formatted as a csv with no headers" id="fileTip">
                  <Icon tokens={helpTokens} />
                </Tooltip>
              </Row>
            )}
            {escalationType === "asin" && (
              <Row>
                <Select
                  value={selectedActionIssue}
                  onChange={setSelectedActionIssue}
                  id="action-issue-select"
                  placeholder="Select Action Issue"
                >
                  <SelectOption key={"untracked-asin"} label={"Untracked"} value={"untracked-asin"} />
                  <SelectOption key={"missing-asin"} label={"Missing"} value={"missing-asin"} />
                </Select>
              </Row>
            )}
            {escalationType === "asin" && (
              <Row>
                <FileInput
                  onFileAttached={(acceptedFiles) => {
                    acceptedFiles.forEach((file) => {
                      file.uploadedFileHref = "https://www.google.com";
                      let fileData = new FileReader();
                      fileData.readAsText(file);
                      setPercentage(100);
                      setUploadedFile(fileData);
                    });
                    setFiles([...files, ...acceptedFiles]);
                  }}
                  accept=".csv"
                  uploadButtonDisabled={uploadButtonDisabled}
                  uploadButtonLabel={"Upload ASIN List (CSV)"}
                  uploadButtonType={"tertiary"}
                >
                  {files.map((file) => {
                    return (
                      <FileDetails
                        error={file.error}
                        errorMessage={file.errorMessage}
                        file={file}
                        connectionSpeed="1MB/s"
                        uploadPercentage={percentage}
                        key={file.name}
                        onClickCancel={() => {
                          setFiles(files.filter((fl) => fl.name !== file.name));
                        }}
                        onClickRemoveFile={() => {
                          const updatedFileArr = files.filter((fl) => fl.name !== file.name);
                          setFiles(updatedFileArr);
                          setPercentage(0);
                          setUploadedFile();
                        }}
                        uploadComplete={percentage === 100}
                      />
                    );
                  })}
                </FileInput>
                <Tooltip position="end" title="File should be formatted as a csv with no headers" id="fileTip">
                  <Icon tokens={helpTokens} />
                </Tooltip>
              </Row>
            )}
            <Button
              type="primary"
              onClick={onConfirm}
              disabled={submitButtonDisabled}
            >
              Submit Escalation
            </Button>
          </Column>
          <Column>
            <Table headerRows={1}>
              <TableRow>
                <TableCell>Escalation ID</TableCell>
                <TableCell>Source DC</TableCell>
                <TableCell>Temp Zone</TableCell>
                <TableCell>Escalation</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action Owner</TableCell>
                <TableCell>Comment</TableCell>
                <TableCell>Dwell Time(hours)</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
              {dcEscalations ?
                dcEscalations.map(dcEscalation =>
                  <TableRow>
                    <TableCell alignmentHorizontal={"center"}>
                      {dcEscalation.escalation_type === "PALLET" && (
                        dcEscalation.pallet_id.toLocaleString()
                      )}
                      {dcEscalation.escalation_type === "CASE" && (
                        <DCEscalationsCasesModal escalationId={dcEscalation.action_id} />
                      )}
                      {dcEscalation.escalation_type === "ASIN" && (
                        dcEscalation.pallet_id.toLocaleString()
                      )}
                    </TableCell>
                    <TableCell alignmentHorizontal={"center"}>{dcEscalation.source_fc}</TableCell>
                    <TableCell alignmentHorizontal={"center"}>{dcEscalation.tempzone}</TableCell>
                    <TableCell alignmentHorizontal={"center"}>{dcEscalation.escalation_type}</TableCell>
                    <TableCell alignmentHorizontal={"center"}>{dcEscalation.action_status}</TableCell>
                    <TableCell alignmentHorizontal={"center"}>{dcEscalation.action_owner}</TableCell>
                    <TableCell alignmentHorizontal={"center"}>{dcEscalation.action_comment}</TableCell>
                    <TableCell alignmentHorizontal={"center"}>{dcEscalation.dwell_time.toLocaleString()}</TableCell>
                    <TableCell alignmentHorizontal={"center"}>
                      <Button
                        type={"tertiary"}
                        onClick={() => onCloseEscalation(dcEscalation)}
                        disabled={escalationCloseButtonDisabled}
                      >
                        Close
                      </Button>
                    </TableCell>
                  </TableRow>
                )
                : null
              }
            </Table>
          </Column>
        </Row>
        <ModalFooter>
          <Row alignmentHorizontal="start" widths="fit">
            <Link
              href="https://w.amazon.com/bin/view/GSF/SupplyChain/IRIS/Projects/MM_DC_Escalations/"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              <Text>Wiki Documentation</Text>
            </Link>
          </Row>
          <Row alignmentHorizontal="end" widths="fit">
            <Button type="secondary" onClick={onCloseButton}>
              Cancel
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  )
};

DCEscalationsModal.propTypes = {
  warehouseId: PropTypes.string.isRequired,
  dcEscalations: PropTypes.arrayOf(PropTypes.object)
}

export default DCEscalationsModal;
