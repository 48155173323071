/**
 * All "IOL" table columns. Each column has an `id` (used as a key and for
 * sorting), `label` (the header column text), `width` (how wide the column should
 * be in pixels), and `alignment` (which side the column's content "sticks" to).
 */

const iolDetailsTableColumns = [
  {
    id: "receivedDate",
    label: "Received Date",
    width: 200,
    alignment: "center",
  },
  {
    id: "asin",
    label: "ASIN",
    width: 100,
    alignment: "center",
  },
  {
    id: "asinTitle",
    label: "ASIN Title",
    width: 400,
    alignment: "center",
  },
  {
    id: "cost",
    label: "Cost",
    width: 100,
    alignment: "center",
  },
  {
    id: "expired",
    label: "Expired",
    width: 80,
    alignment: "center",
  },
  {
    id: "units",
    label: "Units",
    width: 80,
    alignment: "center",
  },
  {
    id: "dwellHours",
    label: "Dwell (Hours)",
    width: 100,
    alignment: "center",
  }
]

export const defaultIOLDetailsTableColumns = iolDetailsTableColumns.reduce(
  (columns, column) => ({
    ...columns,
    [column.id]: true,
  }),
  {}
)

export const getIOLDetailsTableColumnById = id =>
  iolDetailsTableColumns.find(column => column.id === id)

export default iolDetailsTableColumns
