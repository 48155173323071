const notificationsTableColumns = [
  {
    id: "slaCategory",
    label: "Alarm",
    width: 95,
    alignment: "center",
  },
  {
    id: "loadId",
    label: "Load ID",
    width: 95,
    alignment: "center",
  },
  {
    id: "tempZone",
    label: "Temp Profile",
    width: 95,
    alignment: "center",
  },
  {
    id: "nyrnys",
    label: "Remaining Units",
    width: 95,
    alignment: "center",
  },
  {
    id: "progress",
    label: "Progress",
    width: 95,
    alignment: "center",
  },
  {
    id: "timeToSLA",
    label: "Time to SLA (hh:mm)",
    width: 95,
    alignment: "center",
  }
]

// By default all visible table columns are set to visible (`true`)
export const defaultNotificationTableColumns = notificationsTableColumns.reduce(
  (columns, column) => ({
    ...columns,
    [column.id]: true,
  }),
  {}
)

export const getNotificationsTableColumnById = id =>
  notificationsTableColumns.find(column => column.id === id)

export default notificationsTableColumns
