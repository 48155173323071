import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import Column from "@amzn/meridian/column";

import escalationTableColumns from "../data/escalation-table-columns";
import PageLayout from "../components/app/page-layout";
import Table, { TableRow, TableCell } from "@amzn/meridian/table"
import Text from "@amzn/meridian/text";
import { MainAPI } from "../utilities/mainAPI";
import AsinEscalationsActionModal from "../components/dc-escalation/asin-escalation-action-modal"


const EscalationScreen = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const [asinData, setAsinData] = useState();
  const [AsinEscalationRequestId] = useState(params.get("asin_escalation_request_id"));

  const getASINEscalations = useCallback(async (asin_escalation_request_id) => {
    const api = new MainAPI();
    const data = await api.getDCEscalationAsin(asin_escalation_request_id)
    if (data) {
      setAsinData(data);
    }
  }, [setAsinData])

  useEffect(() => {
    getASINEscalations(AsinEscalationRequestId);
  }, [setAsinData, AsinEscalationRequestId, getASINEscalations]);

  return (
    <PageLayout
      loading={false}
      width={"inherit"}
      horizontallySticky={false}
      maxWidth={undefined}
      alignmentHorizontal={"start"}
    >
      <Column>
        <Table
          headerRows={1}
          // layout={"fixed"}
          showDividers={true}
          rowComponents={[TableRow]}
          stickyHeaderRow={true}
          stickyHeaderColumn={false}
        >
          <TableRow>
            {escalationTableColumns
              .map(header => (
                <TableCell
                  key={header.id}
                  sortColumn={header.id}
                  width={header.width}
                  alignmentHorizontal={header.alignment}
                >
                  {header.label}
                </TableCell>
              ))}
          </TableRow>
          {asinData &&
            asinData.map(row =>
              <TableRow key={row["asin_escalation_request_id"]}>
                <TableCell alignmentHorizontal="center">
                  <Text tag="p">
                    {row["region_id"]}
                  </Text>
                </TableCell>
                <TableCell alignmentHorizontal="center">
                  <Text tag="p">
                    {row["source_fc"]}
                  </Text>
                </TableCell>
                <TableCell alignmentHorizontal="center">
                  <Text tag="p">
                    {row["destination_fc"]}
                  </Text>
                </TableCell>
                <TableCell alignmentHorizontal="center">
                  <Text tag="p">
                    {row["tempzone"]}
                  </Text>
                </TableCell>
                <TableCell alignmentHorizontal="center">
                  <Text tag="p">
                    {row["action_issue"]}
                  </Text>
                </TableCell>
                <TableCell alignmentHorizontal="center">
                  <Text tag="p">
                    {row["action_status"]}
                  </Text>
                </TableCell>
                <TableCell alignmentHorizontal="center">
                  <Text tag="p">
                    {row["action_owner"]}
                  </Text>
                </TableCell>
                <TableCell alignmentHorizontal="center">
                  <Text tag="p">
                    {row["requested_by"]}
                  </Text>
                </TableCell>
                <TableCell alignmentHorizontal="center">
                  <Text tag="p">
                    {row["asin"]}
                  </Text>
                </TableCell>
                <TableCell alignmentHorizontal="center">
                  <Text tag="p">
                    {row["critical_pull_time"]}
                  </Text>
                </TableCell>
                <TableCell alignmentHorizontal="center">
                  <Text tag="p">
                    {row["action_comment"]}
                  </Text>
                </TableCell>
                <TableCell alignmentHorizontal="center">
                  <Text tag="p">
                    {row["root_cause"]}
                  </Text>
                </TableCell>
                <TableCell alignmentHorizontal="center">
                  <AsinEscalationsActionModal
                    asinEscalationRequestId={row["asin_escalation_request_id"]}
                    getASINEscalations={getASINEscalations}
                  />
                </TableCell>
              </TableRow>
            )}
        </Table>
      </Column>
    </PageLayout>
  )
}

export default EscalationScreen;