/**
 * All "My shipment" table columns. Each column has an `id` (used as a key and for
 * sorting), `label` (the header column text), `width` (how wide the column should
 * be in pixels), and `alignment` (which side the column's content "sticks" to).
 */

import { shipmentQuantityKeys } from "../components/Constants";

const { transConfirmedUnits, transReceivedUnits } = shipmentQuantityKeys;

const nysTableColumns = [
  {
    id: "tempZone",
    label: "Temp Zone",
    width: 110,
    alignment: "start",
  },
    {
    id: "toteId",
    label: "Location",
    width: 110,
    alignment: "start",
  },
    {
    id: "asin",
    label: "ASIN",
    width: 110,
    alignment: "start",
  },
  {
    id: "asinTitle",
    label: "Product Name",
    width: 220,
    alignment: "start",
  },
  {
    id: transConfirmedUnits,
    label: "Expected Qty.",
    width: 150,
    alignment: "start",
  },
  {
    id: transReceivedUnits,
    label: "Stowed Qty.",
    width: 150,
    alignment: "start",
  },
  {
    id: "backlog",
    label: "NYS Qty.",
    width: 150,
    alignment: "start",
  },
  {
    id: "isCriticalAsin",
    label: "Critical ASIN",
    width: 150,
    alignment: "start",
  },
  {
    id: "isAsrsAsin",
    label: "ASRS ASIN",
    width: 150,
    alignment: "start",
  },
]

export const defaultNYSTableColumns = nysTableColumns.reduce(
  (columns, column) => ({
    ...columns,
    [column.id]: true,
  }),
  {}
)

export const getNYSTableColumnById = id =>
  nysTableColumns.find(column => column.id === id)

export default nysTableColumns
