/**
 * A table of nys
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import NYSTableRow from "./nys-table-row";
import nysTableColumns from "../../../data/nys-table-columns";

import { shipmentQuantityKeys } from "../../Constants";

const { transConfirmedUnits, transReceivedUnits } = shipmentQuantityKeys;
const NYSTable = props => {
  const { nysData, criticalAsinEvents, region_id } = props;
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("ascending");

  const onSort = ({ sortColumn, sortDirection }) => {
    setSortDirection(sortDirection)
    setSortColumn(sortColumn)
  };
  // Make a copy of incoming shipments and sort if `sortColumn` exists
  const sortedNysItems = [...nysData];
  if (sortColumn) {
    sortedNysItems.sort((a, b) => {
      if (sortColumn === "backlog") {
        if ((a[transConfirmedUnits] ? a[transConfirmedUnits] : a.wmsQuantityReceived) - a[transReceivedUnits] < (b[transConfirmedUnits] ? b[transConfirmedUnits] : b.wmsQuantityReceived)  - b[transReceivedUnits] ) {
          return sortDirection === "ascending" ? -1 : 1
        };
        if ((a[transConfirmedUnits] ? a[transConfirmedUnits] : a.wmsQuantityReceived) - a[transReceivedUnits]  > (b[transConfirmedUnits] ? b[transConfirmedUnits] : b.wmsQuantityReceived) - b[transReceivedUnits] ) {
          return sortDirection === "ascending" ? 1 : -1
        };
        return 0
      };
      if (a[sortColumn] < b[sortColumn]) {
        return sortDirection === "ascending" ? -1 : 1
      };
      if (a[sortColumn] > b[sortColumn]) {
        return sortDirection === "ascending" ? 1 : -1
      };
      return 0
    });
  };

  const visibleNysItems = sortedNysItems.slice(
    props.visibleIndices[0],
    props.visibleIndices[1]
  );

  return (
    <React.Fragment>
      <Table
        headerRows={1}
        showDividers={true}
        rowComponents={[NYSTableRow, TableRow]}
        stickyHeaderRow={true}
        stickyHeaderColumn={false}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={onSort}
        showStripes={true}
      >
        <TableRow>
          {nysTableColumns
            .map(header => (
              <TableCell
                key={header.id}
                sortColumn={header.id}
                width={header.width}
                alignmentHorizontal={header.alignment}
              >
                {header.label}
              </TableCell>
            ))}
        </TableRow>
        {visibleNysItems.map((nysItem, index) => (
          <NYSTableRow
            key={nysItem.asin}
            nysItem={nysItem}
            criticalAsinEvents={criticalAsinEvents}
            region_id={region_id}
          />
        ))}
      </Table>
    </React.Fragment>
  );
};

NYSTable.propTypes = {
  visibleIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
  nysData: PropTypes.arrayOf(PropTypes.object).isRequired,
  criticalAsinEvents: PropTypes.object.isRequired,
  region_id: PropTypes.number.isRequired,
};

export default NYSTable;
