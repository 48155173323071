/**
 * All the states that a shipment can be in.
 */

const alarmTypes = [
    { id: "OUT_OF_STOCK", label: "OOS" },
    { id: "OUT_OF_STOCK_RISK", label: "OOS Risk" },
  ]
  
  export const getAlarmTypeId = id => alarmTypes.find(loadType => loadType.id === id)
  export const allalarmTypeIds = alarmTypes.map(({ id }) => id)
  
  export default alarmTypes