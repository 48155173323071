import { zonedTimeToUtc } from "date-fns-tz";

// A group by function with the raw data incoming
function groupIOL(incomingIOL: Array<any>, timeZone: string) {

  let iolMap: any = {};
  if (incomingIOL) {
    for (const iolItem of incomingIOL) {
      if (iolItem.quantity <= 0) {
        continue;
      }
      const locationId = iolItem.locationId ? iolItem.locationId : iolItem.location_id;
      const tempZone = iolItem.tempZone ? iolItem.tempZone : iolItem.temp_zone;

      let locationObject: any = iolMap[`${locationId}-${tempZone}`] ? iolMap[`${locationId}-${tempZone}`] : {};
      if (locationObject === undefined) {
        continue
      }
      let quantity = locationObject.quantity ? locationObject.quantity : 0;
      quantity += iolItem.quantity;
      const firstActionTime = iolItem.firstActionTime ? iolItem.firstActionTime : iolItem.first_action_time
      let longestDwell = locationObject.longestDwell ?
          Math.min(locationObject.longestDwell, firstActionTime) : firstActionTime;

      let detailObject: any = {};
      detailObject.id = "";
      detailObject.receivedDate = iolItem.receivedTime ? iolItem.receivedTime : iolItem.first_action_time;
      detailObject.asin = iolItem.asin;
      detailObject.asinTitle = iolItem.asinTitle ? iolItem.asintitle : iolItem.item_name;
      detailObject.cost = iolItem.cost ? iolItem.cost : iolItem.list_price;
      // TODO we need this in the api by the time we go multi region
      detailObject.costCurrency = "USD";
      detailObject.expirationDate = iolItem.expirationQuantityMap ?
          // Since the source table is based on fcsku we should only have one expiration
          zonedTimeToUtc(Object.keys(JSON.parse(iolItem.expirationQuantityMap))[0], timeZone)
          : iolItem.expired_quantity > 0 ? Date.now() - 1000000 : null
      detailObject.units = iolItem.quantity;

      let details = locationObject.details ? locationObject.details : [];
      details.push(detailObject);

      locationObject.id = `${locationId}-${tempZone}`
      locationObject.location = locationId;
      locationObject.tempZone = tempZone;
      locationObject.quantity = quantity;
      locationObject.details = details;
      locationObject.longestDwell = longestDwell;
      locationObject.dwellTimeHours = Math.floor((Date.now() - longestDwell) / (60 * 60 * 1000) * 100) / 100;
      iolMap[`${locationId}-${tempZone}`] = locationObject;
    }
  }
  return  Object.entries(iolMap).map(([key, value]) => value);
}

export { groupIOL };
