/**
 * The search field shown in the dock received shipments table's action bar.
 */

import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import SearchField from "@amzn/meridian/search-field"
import useNotificationsFilters from "../../../hooks/use-notifications-filters"

const NotificationsSearchField = props => {
  const { filters, dispatch } = useNotificationsFilters()
  const [query, setQuery] = useState("")
  const filterValue = filters.get("id")?.value || ""

  const search = useCallback(
    value =>
      value
        ? dispatch({
            type: "UPDATE_FILTER",
            filter: "allFields",
            value,
            tagLabel: `${value}`,
          })
        : dispatch({
            type: "REMOVE_FILTER",
            filter: "allFields",
          }),
    [dispatch]
  )

  // Debounce calls to onSearch
  useEffect(() => {
    const timeout = setTimeout(() => search(query), 300)
    return () => clearTimeout(timeout)
  }, [query, search])

  useEffect(() => {
    setQuery(filterValue)
  }, [filterValue])

  return (
    <SearchField
      placeholder={
        props.mobile ? "Search" : "Search"
      }
      value={query}
      onChange={setQuery}
      onSubmit={search}
      onClear={search}
      searchButton={true}
      width={props.mobile ? 200 : 360}
    />
  )
}

NotificationsSearchField.propTypes = {
  mobile: PropTypes.bool,
}

NotificationsSearchField.defaultProps = {
  mobile: false,
}

export default NotificationsSearchField
