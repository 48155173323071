import React from "react"
import Sheet from "@amzn/meridian/sheet"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import Icon from "@amzn/meridian/icon"
import Text from "@amzn/meridian/text"
import Select, { SelectOption } from "@amzn/meridian/select"
import Responsive from "@amzn/meridian/responsive"
import closeTokens from "@amzn/meridian-tokens/base/icon/close-small"
import useInstockFilters from "../../hooks/use-instock-filters"
import useSideMenuOpen from "../../hooks/use-side-menu-open"
import loadTypes, { getLoadTypeById } from "../../data/load-types"
import alarmTypes, { getAlarmTypeId } from "../../data/alarm-types"
import noos, {getNoos} from "../../data/noos"
const overlayBreakpoint = "700px"
const overlayBreakpointWhenSideMenuOpen = "950px"

const InstockFilterSheet = () => {
  const {
    sheet: [sheetOpen, setSheetOpen],
    dispatch,
    filters,
  } = useInstockFilters()
  const [sideMenuOpen] = useSideMenuOpen()
  const onClickClose = () => setSheetOpen(false)
  const onClose = () => setSheetOpen(false)

  const onChangeFilter = (filter, makeLabel) => value => {
    if (!value || value.length === 0) {
      dispatch({ type: "REMOVE_FILTER", filter })
    } else {
      dispatch({
        type: "UPDATE_FILTER",
        filter,
        value,
        tagLabel: makeLabel(value),
      })
    }
  }

  return (
    <Responsive
      query="min-width"
      props={{
        type: {
          default: "overlay",
          [overlayBreakpoint]: "push",
        },
        typeWhenSideMenuOpen: {
          default: "overlay",
          [overlayBreakpointWhenSideMenuOpen]: "push",
        },
      }}
    >
      {({ type, typeWhenSideMenuOpen }) => (
        <Sheet
          type={sideMenuOpen ? typeWhenSideMenuOpen : type}
          side="end"
          open={sheetOpen}
          onClose={onClose}
          spacingInset="none"
        >
          <Column width={320}>
            <Row alignmentHorizontal="justify" spacingInset="400 200 none 400">
              <Text type="h300">Filter shipments</Text>
              <Button onClick={onClickClose} type="icon">
                <Icon tokens={closeTokens}>
                  Close filter sheet and return to page
                </Icon>
              </Button>
            </Row>
            <Column spacingInset="400">

              <Column spacing="200">
                <Text tag="label" htmlFor="load-type-select">
                  Load Type
                </Text>
                <Select
                  value={filters.get("loadType")?.value || []}
                  onChange={onChangeFilter("loadType", value =>
                    value.map(
                      status => `Load Type: ${getLoadTypeById(status).label}`
                    )
                  )}
                  id="load-type-select"
                  placeholder="Select a load type"
                >
                  {loadTypes.map(({ id, label }) => (
                    <SelectOption key={id} label={label} value={id} />
                  ))}
                </Select>
              </Column>

              <Column spacing="200">
                <Text tag="label" htmlFor="alarm-type-select">
                  Alarm Type
                </Text>
                <Select
                  value={filters.get("alarm")?.value || []}
                  onChange={onChangeFilter("alarm", value =>
                    value.map(
                      status => `Alarm Type: ${getAlarmTypeId(status).label}`
                    )
                  )}
                  id="alarm-type-select"
                  placeholder="Select an alarm type"
                >
                  {alarmTypes.map(({ id, label }) => (
                    <SelectOption key={id} label={label} value={id} />
                  ))}
                </Select>
              </Column>

              <Column spacing="200">
                <Text tag="label" htmlFor="asrs-eligible">
                  NOOS Flag
                </Text>
                <Select
                  value={filters.get("noos")?.value || []}
                  onChange={onChangeFilter("noos", value =>
                    value.map(
                      noos => `NOOS: ${getNoos(noos).label}`
                    )
                  )}
                  id="noos"
                  placeholder="Select NOOS Flag"
                >
                  {noos.map(({ id, label }) => (
                    <SelectOption key={id} label={label} value={id} />
                  ))}
                </Select>
              </Column>

              <Row
                alignmentHorizontal="center"
                spacingInset="200 none 400 none"
                widths="fill"
              >
                <Button type="link" onClick={() => dispatch({ type: "RESET" })}>
                  Reset filters
                </Button>
              </Row>
            </Column>
          </Column>
        </Sheet>
      )}
    </Responsive>
  )
}

export default InstockFilterSheet
