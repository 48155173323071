// /**
//  * This module exports a function that filters an array of networkDwellings according
//  * to a Map of filters.
//  */
//

/**
 * Returns a function that checks if an object's value is in an array of options.
 * @example
 * const checkFoo = isInOptions("foo")
 * const object = { foo: "bar" }
 * checkFoo(object, ["bar", "baz"])  // returns true
 * checkFoo(object, ["baz"]) // returns false
 */

import { searchBarFilter } from "../utilities/search-bar-filter";
import defaultNetworkDwellingTableColumns from "../data/network-dwelling-table-columns";

// An object of filter applications where each key points to a function
// that expects `networkDwelling` and `value` to consider
const applyFilter = {
  allFields: (networkDwelling, value) => {
    // If any field passes, return true
    const searchableFields = defaultNetworkDwellingTableColumns.map(column => column.id);
    const dateKeys = ["lastDockReceiveTime", "reconcileTime"];
    return searchBarFilter(value, networkDwelling, dateKeys, searchableFields);
  },
}

/**
 * This function filters an array of networkDwellings according to a Map of filters.
 *
 * @param  networkDwelling          Array of networkDwelling objects (see `src/prop-types/incoming-iol` to see shape)
 * @param  filters            Map of filter objects
 * @return filteredNetworkDwelling Array of filtered networkDwellings
 */
const filterNetworkDwelling = (networkDwelling, filters) => {
  if (filters.size === 0) return networkDwelling
  let filteredNetworkDwelling = [...networkDwelling]
  // Loop through and apply filter in the Map to the networkDwelling array
  for (const filter of filters) {
    const [key, { value }] = filter
    filteredNetworkDwelling = filteredNetworkDwelling.filter(networkDwelling =>
      applyFilter[key](networkDwelling, value)
    )
  }
  return filteredNetworkDwelling
}

export default filterNetworkDwelling
