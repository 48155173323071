/**
 * This component renders the Shipment table's pagination and "results per page"
 * controls.
 */

import React from "react"
import PropTypes from "prop-types"
import Row from "@amzn/meridian/row"
import Text from "@amzn/meridian/text"
import Pagination from "@amzn/meridian/pagination"
import Select, { SelectOption } from "@amzn/meridian/select"
import HorizontalStickyContainer from "../../app/horizontal-sticky-container"
import paginationPropType from "../../../prop-types/pagination"

const HorizontalStickyRow = props => (
  <HorizontalStickyContainer Component={Row} {...props} />
)

const InstockTableFooter = props => {
  const Wrapper = HorizontalStickyRow 
  return (
    <Wrapper Component={Row} alignmentHorizontal="inherit" wrap="down">
      <Row spacing="200">
        <Text id="number-of-pages-label" tag="label">
          Results per page
        </Text>
        <Select
          value={props.resultsPerPage}
          onChange={props.onChangeResultsPerPage}
          aria-labelledby="number-of-pages-label"
          width={150}
        >
          {props.resultsPerPageOptions.map(option => (
            <SelectOption key={option} label={option} value={option} />
          ))}
        </Select>
      </Row>
      <span>
        <Pagination
          currentPage={props.pagination.currentPage}
          onChange={props.pagination.onChange}
          numberOfPages={props.pagination.numberOfPages}
        />
        {<props.pagination.PaginationAnnouncer />}
      </span>
    </Wrapper>
  )
}
InstockTableFooter.propTypes = {
  pagination: paginationPropType.isRequired,
  resultsPerPage: PropTypes.number.isRequired,
  resultsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  stick: PropTypes.bool.isRequired,
  onChangeResultsPerPage: PropTypes.func.isRequired,
}

export default InstockTableFooter
