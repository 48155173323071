// /**
//  * This module exports a function that filters an array of shipments according
//  * to a Map of filters.
//  */
//

/**
 * Returns a function that checks if an object's value is in an array of options.
 * @example
 * const checkFoo = isInOptions("foo")
 * const object = { foo: "bar" }
 * checkFoo(object, ["bar", "baz"])  // returns true
 * checkFoo(object, ["baz"]) // returns false
 */

import { searchBarFilter } from "../utilities/search-bar-filter";
const isInOptions = key => (obj, options) => options.includes(obj[key])

// An object of filter applications where each key points to a function
// that expects `dockReceived` and `value` to consider
const applyFilter = {
  loadType: isInOptions("loadType"),
  source: ({ source }, value) => source.toLowerCase().includes(value.toLowerCase()),
  loadId: ({ loadId }, value) => loadId.toLowerCase().includes(value.toLowerCase()),
  suppression: ({ suppression }, value) => suppression === value,
  tempZone: isInOptions("tempZone"),
  asrsEligible: (item, value) => {
  
    if(value.length === 2) {
      return true;
    } 
    else {
      if(value[0]==='Y' && item.asrsConfirmedUnits >0 ){
        return true;
      }
      else if(value[0]==='N' && item.asrsConfirmedUnits === 0 ){
        return true;
      }
    }
    return false
  },
  allFields: (dockReceived, value) => {

    // If any field passes, return true
    const searchableFields = [
        "loadType",
        "source",
        "loadId",
        "tempZone",
        "actualArrivalParsed",
        "actualArrival",
        "dockReceivedParsed",
        "dockReceived",
        "lastDockReceiveBy",
        "firstReceiveTimeParsed",
        "firstReceiveTime",
        "confirmedUnits",
        "receivedUnits",
        "nyrnys",
        "progress",
        "dwellDays",
        "notification",
    ];

    const dateKeys = ["actualArrival", "dockReceived", "firstReceiveTime"];
    return searchBarFilter(value, dockReceived, dateKeys, searchableFields);
  },
}

/**
 * This function filters an array of shipments according to a Map of filters.
 *
 * @param  dockReceived          Array of incomingShipment objects (see `src/prop-types/dock-received` to see shape)
 * @param  filters            Map of filter objects (see `src/hooks/use-shipments-filters`)
 * @return filteredDockReceive  Array of filtered dock received shipments
 */
const filterDockReceived = (dockReceived, filters) => {
  if (filters.size === 0) return dockReceived
  let filteredDockReceived = [...dockReceived]
  // Loop through and apply filter in the Map to the dockReceived array
  for (const filter of filters) {
    const [key, { value }] = filter  
    filteredDockReceived = filteredDockReceived.filter(incomingShipment =>
      applyFilter[key](incomingShipment, value)
    )   
  }
  return filteredDockReceived
}

export default filterDockReceived
