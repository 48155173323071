/**
 * A modal that allows user to reconcile a dock received shipment.
 */

import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"

import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import Heading from "@amzn/meridian/heading"
import Text from "@amzn/meridian/text"
import Modal, { ModalFooter } from "@amzn/meridian/modal"

import ReviewPalletTable from "./review-pallets/review-pallets-table";
import useWarehouseData from "../../hooks/use-warehouse-data";
import dockReceivedPropType from "../../prop-types/dock-received"
import { convertUnix } from "../../utilities/convert-unix"
import { getWarehouseAttributes } from "../../utilities/get-warehouse-attributes";
import Link from "@amzn/meridian/link";

const ReviewPalletModal = props => {
  const { dockReceived, warehouseId, dcEscalations, getDCEscalations } = props
  const [open, setOpen] = useState(false);
  const onClickButton = useCallback(() => setOpen(true), []);
  const { region_id } = getWarehouseAttributes({warehouseId: warehouseId, warehouseParams: useWarehouseData()});
  const onCloseButton = () => {
    setOpen(false);
    };
  
  const { time_zone, wms } = getWarehouseAttributes({ warehouseId: warehouseId, warehouseParams: useWarehouseData() });

  return (
    <>
      <Button
        size="small"
        minWidth="8%"
        onClick={onClickButton}
        data-testid="modalButton"
      >
        Review Pallet
      </Button>
      <Modal
        title="Review Transshipment Pallets"
        open={open}
        scrollContainer="viewport"
        describedById="review-pallet-modal-description"
        onClose={onCloseButton}
        closeLabel="Close"
      >
        <Column>
          <Row><Heading level={5}>{"Site Id:"}</Heading><Text>{dockReceived.warehouseId}</Text></Row>
          <Row><Heading level={5}>{"Source:"}</Heading><Text>{dockReceived.source}</Text></Row>
          <Row><Heading level={5}>{"Dock Receive:"}</Heading><Text>{convertUnix(dockReceived.lastDockReceiveTime, time_zone)}</Text></Row>
          <Row>
            <ReviewPalletTable
                transshipmentPallets={dockReceived.palletSummary}
                dcEscalations={dcEscalations}
                getDCEscalations={getDCEscalations}
                warehouseId={warehouseId}
                sourceDC={dockReceived.source}
                regionId={region_id}
                wms={wms}
            />

          </Row>
        </Column>
        <ModalFooter>
        <Row alignmentHorizontal="start" widths="fit">
              <Link
                href="https://w.amazon.com/bin/view/GSF/SupplyChain/IRIS/projects/Pallet_Escalation_Dashboard/"
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
                <Text>Wiki Documentation</Text>
              </Link>
          </Row>
          <Row alignmentHorizontal="end" widths="fit">
            <Button type="secondary" onClick={onCloseButton}>
              Close
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  )
}

ReviewPalletModal.propTypes = {
  dockReceived: dockReceivedPropType.isRequired,
  warehouseId: PropTypes.string.isRequired,
  dcEscalations: PropTypes.arrayOf(PropTypes.object),
  getDCEscalations: PropTypes.func,
}


export default ReviewPalletModal
