function groupSearchedShipments(searchData: Array<any>, shipmentSummaries: Array<any>) {
    let groupedShipments = {};
    const sevenDaysAgoMillis = Date.now() - (7 * 24 * 60 * 60 * 1000); // 7 days in milliseconds

    for (const searchItem of searchData) {
        const uniqueKey = `${searchItem.orderId}_${searchItem.palletId || 'noPallet'}_${searchItem.asin}`;
        const summaryItem = shipmentSummaries.find(summary => summary.orderId === searchItem.orderId);

        if (groupedShipments[uniqueKey]) {
            const groupedShipment = groupedShipments[uniqueKey];
            // Sum up quantities
            groupedShipment.confirmedQuantity = (groupedShipment.confirmedQuantity || 0) + (searchItem.confirmedQuantity || 0);
            groupedShipment.quantityConfirmed = (groupedShipment.quantityConfirmed || 0) + (searchItem.quantityConfirmed || 0);
            groupedShipment.quantityReceived = (groupedShipment.quantityReceived || 0) + (searchItem.quantityReceived || 0);
            groupedShipment.quantitySerenity = (groupedShipment.quantitySerenity || 0) + (searchItem.quantitySerenity || 0);
            groupedShipment.wmsQuantityReceived = (groupedShipment.wmsQuantityReceived || 0) + (searchItem.wmsQuantityReceived || 0);
            groupedShipment.wmsQuantityReceivedStow = (groupedShipment.wmsQuantityReceivedStow || 0) + (searchItem.wmsQuantityReceivedStow || 0);
            groupedShipment.manifestQuantity = (groupedShipment.manifestQuantity || 0) + (searchItem.manifestQuantity || 0);
        } else {
            groupedShipments[uniqueKey] = {
                ...summaryItem,
                ...searchItem,
                shipmentType: searchItem.orderId.split("_")[0],
                source: searchItem.orderId.startsWith('PO') ? summaryItem?.distributorId : summaryItem?.srcWarehouseId,
                arrivalDatetime: summaryItem?.arrivalDatetime || summaryItem?.vrCheckinCompletionTime || summaryItem?.freightActualArrivalTime
            };
        }

        const shipment = groupedShipments[uniqueKey];
        shipment.orderId = searchItem.orderId.replace('Trans_', '').replace('PO_', '');
        shipment.confirmedUnits = shipment.shipmentType === 'PO' ? shipment.quantityConfirmed : shipment.manifestQuantity;
        shipment.receivedUnits = shipment.shipmentType === 'PO' ? shipment.quantityReceived : shipment.wmsQuantityReceivedStow;
        shipment.nyrNys = Math.max((shipment.confirmedUnits || 0) - (shipment.receivedUnits || 0), 0);

        // Check if the shipment should be filtered out based on summary data
        if (summaryItem) {
            const appointmentTimeMillis = Number(summaryItem.firstAppointmentTime);
            if (!summaryItem.firstDockReceiveTime && appointmentTimeMillis < sevenDaysAgoMillis) {
                delete groupedShipments[uniqueKey];
            }
        } else {
            console.warn(`No summary found for orderId: ${searchItem.orderId}`);
        }
    }
    
    return Object.values(groupedShipments);
}

export { groupSearchedShipments };
