import React from "react";
import useNYSFilters from "../../../hooks/use-nys-filters";
import Select, { SelectOption } from "@amzn/meridian/select";
import ocrVendors, {getOcrVendorById} from "../../../data/ocr-vendors";



const NYSCriticalAsinFilter = () => {
  const { filters, dispatch } = useNYSFilters()
  const onChangeFilter = (filter, makeLabel) => value => {
    if (!value || value.length === 0) {
      dispatch({ type: "REMOVE_FILTER", filter })
    } else {
      dispatch({
        type: "UPDATE_FILTER",
        filter,
        value,
        tagLabel: makeLabel(value),
      })
    }
  }

  return (
    <Select
      value={filters.get("isCriticalAsin")?.value || []}
      onChange={onChangeFilter("isCriticalAsin", value =>
        value.map(
          ocrVendor => `Critical ASIN: ${getOcrVendorById(ocrVendor).label}`
        )
      )}
      id="critical-asin-select"
      placeholder="Is critical ASIN?"
    >
      {ocrVendors.map(({ id, label }) => (
        <SelectOption key={id} label={label} value={id} />
      ))}
    </Select>
  )
}

export default NYSCriticalAsinFilter