import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

// Amplify
import { Analytics } from "aws-amplify";

// Meridian
import Loader from "@amzn/meridian/loader";
import Select, { SelectOption } from "@amzn/meridian/select";
import Row from "@amzn/meridian/row";
import escapeRegExp from "lodash/escapeRegExp"


// Tabs
import BuildingTabs from "../tabs/building-tabs";

// Hooks
import useSelectedOptions from "../hooks/use-selected-options";
import useWarehouseData from "../hooks/use-warehouse-data";

import PageLayout from "../components/app/page-layout";

const BuildingScreen = (props) => {
  const [searchQuery, setSearchQuery] = useState()
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const routeOptionKey = "/building";
  const [selectedOptions, setSelectedOptions] = useSelectedOptions();
  const [warehouseId, setWarehouseId] = useState(params.warehouseId ? params.warehouseId : selectedOptions[routeOptionKey]);
  const [tabScreen, setTabScreen] = useState(<></>);
  const warehouseData = useWarehouseData();
  const warehouseIds = warehouseData ? [...warehouseData].sort((a,b) => a.warehouse_id.localeCompare(b.warehouse_id)) : undefined;
  const searchRegExp = new RegExp(escapeRegExp(searchQuery), "i")
  
  // This is the navigation for the select
  const navigateWarehouse = useCallback(
    value => {
      const newWarehouseId = value;
      const newSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
      newSelectedOptions[routeOptionKey] = newWarehouseId;
      setWarehouseId(newWarehouseId);
      let locationArray = location.pathname.split("/");
      locationArray[2] = newWarehouseId;
      const newLocation = locationArray.join("/");
      setSelectedOptions(newSelectedOptions);
      
      navigate(`../${newLocation}`)
      setTabScreen(
        <BuildingTabs
          warehouseId={newWarehouseId}
        />
      );
    },
    [navigate, location, selectedOptions, setSelectedOptions]
  )

  // This triggers when building is provided in url
  useEffect(() => {
    if (warehouseId !== undefined) {
        const newSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
        newSelectedOptions[routeOptionKey] = warehouseId;
        setSelectedOptions(newSelectedOptions);
        // Record selection
        Analytics.record({ name: 'Store FC Warehouse Id Selection', attributes: { warehouseId: warehouseId } });
        setTabScreen(
          <BuildingTabs
            warehouseId={warehouseId}
          />
        );
    };
    // eslint-disable-next-line
  }, [warehouseId, setSelectedOptions]);


  return (
    <PageLayout
      loading={!warehouseIds}
      width={warehouseIds ? "inherit" : undefined}
      horizontallySticky={false}
      maxWidth={undefined}
      alignmentHorizontal={"start"}
    >
      <Row>
        {
        warehouseIds ?
          <Select
            value={warehouseId}
            onChange={navigateWarehouse}
            id="warehouse-select"
            placeholder="Select a building"
            searchQuery={searchQuery}
            onSearch={setSearchQuery}
          >
            { 
             warehouseIds.map( ({warehouse_id}) => ((!searchQuery || searchRegExp.test(warehouse_id)) &&
              <SelectOption key={warehouse_id} label={warehouse_id} value={warehouse_id} />
              
            ))}
          </Select>
          : <Loader/>}
      </Row>
      {
      tabScreen}
    </PageLayout>
  )
}

export default BuildingScreen;