/**
 * A component that uses react-router to render the current application screen
 */

import React, { useEffect } from 'react';
import { Routes, Route, Outlet } from "react-router-dom";
import sentryFetch from "@amzn/sentry-fetch";
// Screens
import Landing from "../screens/landing";
import SummaryScreen from '../screens/summary-screen';
import BuildingScreen from '../screens/building-screen';
import EscalationScreen from '../screens/escalation-screen';

// Context
import { IncomingShipmentsViewPreferencesProvider } from "../hooks/use-incoming-shipments-view-preferences"
import { DockReceivedViewPreferencesProvider } from "../hooks/use-dock-received-view-preferences"
import { IOLViewPreferencesProvider } from "../hooks/use-iol-view-preferences"
import { IOLDetailsViewPreferencesProvider } from "../hooks/use-iol-details-view-preferences"
import { useLocation } from "react-router-dom";

const PrintCurrentPage = (props) => {
  const location = useLocation();
  let stage = 'prod';
  if (window.location.hostname.includes('beta')) {
    stage = 'beta';
  } else if (window.location.hostname.includes('dev-dsk')) {
    stage = 'dev';
  }
  const iris_api_stage = stage === 'prod' ? 'prod' : 'gamma'
  const storedUuid = sessionStorage.getItem('uuid');
  if (!storedUuid) {
    // Generate a UUID if not found in session storage
    const generatedUuid = crypto.randomUUID();
    sessionStorage.setItem('uuid', generatedUuid);

  }
  let locationArray = location.pathname.split("/");
  useEffect(() => {
    let body = {
      alias: props.user.name || 'unknown',
      iam_auth_caller_arn: null,
      iam_auth_caller: null,
      application: 'Marauders Map v2',
      aws_region: 'us-east-1',
      metric_name: `Page View (${locationArray[3]})`,
      metric_value: location.pathname,
      session: sessionStorage.getItem('uuid'),
      stage: stage

    };
    if (locationArray.length > 3) {
      sentryFetch(`https://${iris_api_stage}.api.iris.gsf.amazon.dev/common/iris_tool_usage_tracker`,
        {
          method: 'POST',
          mode: "cors",
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify(body)
        })
        .then((response) => response.json())
    }
  },);
  return null;
};

const CurrentScreen = (props) => {

  return (
    <>
      <PrintCurrentPage user={props.user} />
      <Routes>
        {/* <PrintCurrentPage /> */}
        <Route path="/" element={<Landing />} />
        <Route path="/summary" element={<SummaryScreen />} />
        <Route path="/summary/building" element={<SummaryScreen />} />
        <Route path="/summary/building/:warehouseId" element={<SummaryScreen />} />
        <Route path="/summary/network/" element={<SummaryScreen />} />
        <Route path="/summary/network/:group" element={<SummaryScreen />} />
        <Route path="/escalation" element={<EscalationScreen />} />
        {/* We use Outlets to wrap views with context or components */}

        <Route element={<IncomingShipmentsViewPreferencesProvider><Outlet /></IncomingShipmentsViewPreferencesProvider>}>
          <Route element={<DockReceivedViewPreferencesProvider><Outlet /></DockReceivedViewPreferencesProvider>}>
            <Route element={<IOLViewPreferencesProvider><Outlet /></IOLViewPreferencesProvider>}>
              <Route element={<IOLDetailsViewPreferencesProvider><Outlet /></IOLDetailsViewPreferencesProvider>}>
                <Route path="/building" element={<BuildingScreen user={props.user.name} />} />
                <Route path="/building/:warehouseId" element={<BuildingScreen user={props.user.name} />} />
                <Route path="/building/:warehouseId/:selectedTab" element={<BuildingScreen user={props.user.name} />} />
              </Route>
            </Route>
          </Route>
        </Route>

      </Routes>
    </>
  )
}

export default CurrentScreen