/**
 * The action bar shown above the network table. Includes a search field
 */

import React from "react";
import PropTypes from "prop-types";
import { TableActionBar } from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import NYRSearchField from "./nyr-search-field";
import HorizontalStickyContainer from "../../app/horizontal-sticky-container";
import usePageLayoutContentRectangle from "../../../hooks/use-page-layout-content-rectangle";
import NYRCriticalAsinFilter from "./nyr-critical-asin-filter";
import NYRAsrsAsinFilter from "./nyr-asrs-asin-filter.js";

const HorizontalStickyRow = props => (
  <HorizontalStickyContainer Component={Row} {...props} />
)


const NYRActionBar = props => {
  // const { nyrData } = props;
  const Wrapper = HorizontalStickyRow;
  const { width: contentWidth = 1200 } = usePageLayoutContentRectangle();
  const mobile = contentWidth < 600;

  return (
    <Wrapper widths="fill">
      <TableActionBar alignmentHorizontal="justify">
        <Row spacing="200">
          <NYRSearchField mobile={mobile}/>
          <NYRCriticalAsinFilter />
          <NYRAsrsAsinFilter />
        </Row>
        <Row spacing="200">
        </Row>
      </TableActionBar>
    </Wrapper>
  );
};

NYRActionBar.propTypes = {
  nyrData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default NYRActionBar;
