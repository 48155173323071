/**
 * A table of inventory in odd locations (iol) in detail
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import Table, { TableRow, TableCell } from "@amzn/meridian/table"
import IOLDetailsTableRow from "./iol-details-table-row"
import iolDetailsPropType from "../../../prop-types/iol-details"
import iolDetailsTableColumns from "../../../data/iol-details-table-columns"
import useIOLDetailsViewPreferences from "../../../hooks/use-iol-details-view-preferences"

const IOLDetailsTable = props => {
  const { iolDetails, warehouseId } = props
  const [sortColumn, setSortColumn] = useState("")
  const [viewPreferences] = useIOLDetailsViewPreferences()
  const [sortDirection, setSortDirection] = useState("ascending")

  const visibleColumns = viewPreferences.get("columns")

  const onSort = ({ sortColumn, sortDirection }) => {
    setSortDirection(sortDirection)
    setSortColumn(sortColumn)
  }

  // Make a copy of incoming shipments and sort if `sortColumn` exists
  const sortedIOLDetails = [...iolDetails]
  if (sortColumn) {
    sortedIOLDetails.sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) {
        return sortDirection === "ascending" ? -1 : 1
      }
      if (a[sortColumn] > b[sortColumn]) {
        return sortDirection === "ascending" ? 1 : -1
      }
      return 0
    })
  }

  const visibleIOLDetails = sortedIOLDetails.slice(
    props.visibleIndices[0],
    props.visibleIndices[1]
  )

  return (
    <React.Fragment>
      <Table
        headerRows={1}
        showDividers={true}
        rowComponents={[IOLDetailsTableRow, TableRow]}
        stickyHeaderRow={true}
        stickyHeaderColumn={false}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={onSort}
        showStripes={viewPreferences.get("stripes")}
        spacing={viewPreferences.get("spacing")}
      >
        <TableRow>
          {iolDetailsTableColumns
            // Only include columns that have been set to "visible"
            .filter(({ id }) => visibleColumns[id])
            .map(header => (
              <TableCell
                key={header.id}
                sortColumn={header.id}
                width={header.width}
                alignmentHorizontal={header.alignment}
              >
                {header.label}
              </TableCell>
            ))}
          {/* Empty header cell to create space for the table row details button */}
          <TableCell />
        </TableRow>
        {visibleIOLDetails.map((iolDetail, index) => (
          <IOLDetailsTableRow
            key={`${iolDetail.asin}-${iolDetail.receivedDate}`}
            iolDetail={iolDetail}
            warehouseId={warehouseId}
          />
        ))}
      </Table>
    </React.Fragment>
  )
}

IOLDetailsTable.propTypes = {
  visibleIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
  iolDetails: PropTypes.arrayOf(iolDetailsPropType),
  warehouseId: PropTypes.string.isRequired,
}

IOLDetailsTable.defaultProps = {
  areAllSelected: false,
}

export default IOLDetailsTable
