/**
 * Ranges of dates.
 */

const dateRanges = [
  { id: "yesterday", label: "Yesterday", max: 0},
  { id: "one-day", label: "1-day (today)", max: 1 },
  { id: "two-day", label: "2-day", max: 2 },
  { id: "three-day", label: "3-day", max: 3 },
  { id: "four-day", label: "4-day", max: 4 },
]

export const getDateRangeById = id =>
  dateRanges.find(range => range.id === id)

export default dateRanges
