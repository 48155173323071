/**
 * A modal that allows user to reconcile a dock received shipment.
 */

import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Link from "@amzn/meridian/link";
import Loader from "@amzn/meridian/loader";
import Heading from "@amzn/meridian/heading";
import Text from "@amzn/meridian/text";
import Modal, { ModalFooter } from "@amzn/meridian/modal";

import NYRTable from "./nyr-table";
import NYRActionBar from "./nyr-action-bar";
import TableFooter from "../../app/table-footer";
import filterNyr from "../../../filters/filter-nyr";
import FilterTags from "../../app/filter-tags";
import useFilters from "../../../hooks/use-filters";
import useNYRFilters from "../../../hooks/use-nyr-filters";
import usePagination from "../../../hooks/use-pagination";

import { defaultResultsPerPage, resultsPerPageOptions } from "../../Constants";

// API
import { getNYRDetails } from "../../../graphql/queries";
import { getPaginatedAPICall } from "../../../utilities/get-paginated-api-call";

import { shipmentQuantityKeys } from "../../Constants";

const { vendorConfirmedUnits, vendorReceivedUnits } = shipmentQuantityKeys;


const NYRModal = props => {
  const { nyr, po, tempZone, criticalAsinEvents, region_id } = props
  const [open, setOpen] = useState(false);
  const [incomingNYRData, setIncomingNYRData] = useState();
  const [resultsPerPage, setResultsPerPage] = useState(defaultResultsPerPage);
  const [selectedResultsPerPage, setSelectedResultsPerPage] = useState(defaultResultsPerPage);
  const { filters } = useNYRFilters();

  useEffect(() => {
    const getPOData = async () => {
      const params = {warehouse: { po: po, tempZone: tempZone }};
      const data = await getPaginatedAPICall(params, getNYRDetails, "getNYRDetails");
      if (data) {
        setIncomingNYRData(data.filter(nyr => nyr[vendorConfirmedUnits] > nyr[vendorReceivedUnits]));
      };
    };
    if (po !== "" && open){
      getPOData();
    };
  }, [setIncomingNYRData, po, tempZone, open]);

  const nyrData = useFilters(incomingNYRData, filterNyr, filters);

  const pagination = usePagination({
    numberOfItemsPerPage: resultsPerPage,
    numberOfResults: nyrData?.length || 0,
  });

  const onChangeResultsPerPage = resultsPerPage => {
    // Update local state variable
    setSelectedResultsPerPage(resultsPerPage)
    if (resultsPerPage === "All") {
      setResultsPerPage(nyrData?.length || 0)
    } else {
      setResultsPerPage(resultsPerPage)
    }
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    pagination.onChange(1)
  }

  // Set pagination back to page 1 when filters change
  useEffect(() => {
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    onChangeResultsPerPage(selectedResultsPerPage)
    // We disable the exhaustive-deps hooks rule here because including `pagination`
    // will cause the effect to be called over-and-over.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filters])


  const { visibleIndices } = pagination;

  const onClickButton = useCallback(() => setOpen(true), []);
  const onCloseButton = () => {
    setOpen(false);
    setIncomingNYRData();
    pagination.onChange(1)
    };

  return (
    <>
      <Link
        onClick={onClickButton}
      >
          {nyr.toLocaleString()}
      </Link>
      <Modal
        title="NYR Details"
        open={open}
        scrollContainer="viewport"
        onClose={onCloseButton}
        closeLabel="Close"
      >
        {nyrData ?
          <Column>
            <Row><Heading level={5}>{"PO:"}</Heading><Text>{po}</Text></Row>
            <Row><NYRActionBar/></Row>
            <Row><FilterTags stick={true} filtersHook={useNYRFilters}/></Row>
            <Row><NYRTable nyrData={nyrData} visibleIndices={visibleIndices} criticalAsinEvents={criticalAsinEvents} region_id={region_id}/></Row>
            <Row>
            {nyrData && nyrData.length > 0 ? (
                <TableFooter
                  pagination={pagination}
                  stick={false}
                  resultsPerPage={resultsPerPage}
                  onChangeResultsPerPage={onChangeResultsPerPage}
                  resultsPerPageOptions={resultsPerPageOptions}
                  selectedResultsPerPage={selectedResultsPerPage}
                />
                ) : null
              }
            </Row>
          </Column>
        : <Loader/>
        }
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button type="secondary" onClick={onCloseButton}>
              Close
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  )
}

NYRModal.propTypes = {
  nyr: PropTypes.number.isRequired,
  po: PropTypes.string.isRequired,
  tempZone: PropTypes.string.isRequired,
  criticalAsinEvents: PropTypes.object.isRequired,
  region_id: PropTypes.number.isRequired,
}


export default NYRModal
