/**
 * A table of current not yet received snapshots at building level
 */

import React from "react";
import PropTypes from "prop-types";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import CurrentNYRSnapshotTableRow from "./current-nyr-snapshot-table-row";
// import eosSnapshotPropType from "../../prop-types/eos-snapshot";



const CurrentNYRSnapshotTable = props => {
  const { currentNYRSnapshot } = props;
  return (
    <React.Fragment>
      <Table
        headerRows={1}
        showDividers={true}
        rowComponents={[CurrentNYRSnapshotTableRow, TableRow]}
        stickyHeaderRow={true}
        stickyHeaderColumn={false}
        // sortColumn={sortColumn}
        // sortDirection={sortDirection}
        // onSort={onSort}
        showStripes={true}
        // spacing={viewPreferences.get("spacing")}
      >
        <TableRow>
          <TableCell>Site ID</TableCell>
          <TableCell>Temp Zone</TableCell>
          <TableCell>Units</TableCell>
          <TableCell>Dwell Days</TableCell>
        </TableRow>
        {currentNYRSnapshot.map((currentSnapshot, index) => (
          <CurrentNYRSnapshotTableRow
            key={currentSnapshot.warehouseId + currentSnapshot.tempZone}
            currentSnapshot={currentSnapshot}
          />
        ))}
      </Table>
    </React.Fragment>
  )
};

CurrentNYRSnapshotTable.propTypes = {
  currentNYRSnapshot: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CurrentNYRSnapshotTable;
