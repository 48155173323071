/**
 * All "My shipment" table columns. Each column has an `id` (used as a key and for
 * sorting), `label` (the header column text), `width` (how wide the column should
 * be in pixels), and `alignment` (which side the column's content "sticks" to).
 */

const dockFullnessTableColumns = [
  {
    id: "tempZone",
    label: "Temp Zone",
    width: 95,
    alignment: "center",
  },
  {
    id: "units",
    label: "Total NYR/NYS Units",
    width: 95,
    alignment: "center",
  },
  {
    id: "tempZonePallets",
    label: "Total NYR/NYS Pallet Space",
    width: 95,
    alignment: "center",
  },
  {
    id: "estimatedPallets",
    label: "Estimated NYR/NYS Pallets (approx)",
    width: 95,
    alignment: "center",
  },
  {
    id: "remaining",
    label: "Remaining NYR/NYS Pallet Space",
    width: 95,
    alignment: "center",
  },
]

// By default all visible table columns are set to visible (`true`)
export const defaultDockFullnessTableColumns = dockFullnessTableColumns.reduce(
  (columns, column) => ({
    ...columns,
    [column.id]: true,
  }),
  {}
)

export const getDockFullnessTableColumnById = id =>
  dockFullnessTableColumns.find(column => column.id === id)

export default dockFullnessTableColumns
