// /**
//  * This module exports a function that filters an array of iols according
//  * to a Map of filters.
//  */
//

/**
 * Returns a function that checks if an object's value is in an array of options.
 * @example
 * const checkFoo = isInOptions("foo")
 * const object = { foo: "bar" }
 * checkFoo(object, ["bar", "baz"])  // returns true
 * checkFoo(object, ["baz"]) // returns false
 */

import { searchBarFilter } from "../utilities/search-bar-filter";
import defaultIOLDetailsTableColumns from "../data/iol-details-table-columns";

// An object of filter applications where each key points to a function
// that expects `iol` and `value` to consider
const applyFilter = {
  allFields: (iol, value) => {
    // If any field passes, return true
    const searchableFields = defaultIOLDetailsTableColumns.map(column => column.id);
    const dateKeys = ["receivedDate"];
    return searchBarFilter(value, iol, dateKeys, searchableFields);
  },
}

/**
 * This function filters an array of iols according to a Map of filters.
 *
 * @param  iol          Array of iol objects (see `src/prop-types/incoming-iol` to see shape)
 * @param  filters            Map of filter objects (see `src/hooks/use-iols-filters`)
 * @return filteredShipments  Array of filtered iols
 */
const filterIOL = (iol, filters) => {
  if (filters.size === 0) return iol
  let filteredIOL = [...iol]
  // Loop through and apply filter in the Map to the iol array
  for (const filter of filters) {
    const [key, { value }] = filter
    filteredIOL = filteredIOL.filter(iol =>
      applyFilter[key](iol, value)
    )
  }
  return filteredIOL
}

export default filterIOL
