/**
 * All "My shipment" table columns. Each column has an `id` (used as a key and for
 * sorting), `label` (the header column text), `width` (how wide the column should
 * be in pixels), and `alignment` (which side the column's content "sticks" to).
 */

const networkDwellingTableColumns = [
  {
    id: "warehouseId",
    label: "Site Id",
    width: 95,
    alignment: "center",
  },
  {
    id: "orderId",
    label: "Load ID",
    width: 95,
    alignment: "center",
  },
  {
    id: "source",
    label: "Source",
    width: 95,
    alignment: "center",
  },

  {
    id: "lastDockReceiveTime",
    label: "Dock Received",
    width: 165,
    alignment: "center",
  },
  {
    id: "lastDockReceiveBy",
    label: "Checked in by",
    width: 95,
    alignment: "center",
  },
  {
    id: "reconcileTime",
    label: "Reconciliation Time",
    width: 165,
    alignment: "center",
  },
  {
    id: "reconcileDuration",
    label: "Dwell Hours",
    width: 95,
    alignment: "center",
  }
]

export const defaultNetworkDwellingTableColumns = networkDwellingTableColumns.reduce(
  (columns, column) => ({
    ...columns,
    [column.id]: true,
  }),
  {}
)

export const getNetworkDwellingTableColumnById = id =>
  networkDwellingTableColumns.find(column => column.id === id)

export default networkDwellingTableColumns
