/**
 * This hook returns the latest width, height, left, and right layout values for
 * the "content" wrapper described in `components/app/page-layout.js`
 */
import { useState, useEffect } from "react"
import { contentId } from "../components/app/page-layout"

const usePageLayoutContentRectangle = () => {
  // The state variable we keep the current content rectangle values.
  const [contentRect, setContentRect] = useState({})

  useEffect(() => {
    // The element we'll be watching for "resize" events
    const contentElement = document.getElementById(contentId)

    // Create a resize observer that updates the local state value with the latest
    // contentRect (learn more about contentRect here: https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/contentRect)
    const observer = new ResizeObserver(([entry]) => {
      const { width, height, top, bottom, left, right } = entry.contentRect
      setContentRect({ width, height, top, bottom, left, right })
    })
    // Start observing
    if (contentElement) {
      observer.observe(contentElement)
    }
    // Disconnect the observer when the component using this hook unmounts
    return () => observer.disconnect()
  }, [])

  return contentRect
}

export default usePageLayoutContentRectangle