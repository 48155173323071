function calculateIncomingUnits(incomingShipments: Array<any>) {
  let expectedIncomingShipments = {};

  for (const incomingShipment of incomingShipments) {
    const warehouseId = incomingShipment.warehouseId;
    // Need to handle this at the source. Somehow TSI prediction is coming through without a warehouseID
    if (!warehouseId) {
      continue
    }
    expectedIncomingShipments[warehouseId] = expectedIncomingShipments[warehouseId] ?
        expectedIncomingShipments[warehouseId] : {};

    expectedIncomingShipments[warehouseId]["TOTAL"] = expectedIncomingShipments[warehouseId]["TOTAL"]  ?
        expectedIncomingShipments[warehouseId]["TOTAL"]  : {};

    const expectedQuantityScip = JSON.parse(incomingShipment.expectedQuantityScip);

    const day = incomingShipment.expectedArrivalDateScip;
    let total = expectedIncomingShipments[warehouseId]["TOTAL"][day] ?
        expectedIncomingShipments[warehouseId]["TOTAL"][day] : 0;
    for (const [tempZone, tempZoneUnits] of Object.entries(expectedQuantityScip)) {
      expectedIncomingShipments[warehouseId][tempZone] = expectedIncomingShipments[warehouseId][tempZone] ?
          expectedIncomingShipments[warehouseId][tempZone] : {};
      let currentUnits = expectedIncomingShipments[warehouseId][tempZone][day] ?
          expectedIncomingShipments[warehouseId][tempZone][day] : 0;
      currentUnits += tempZoneUnits;
      total += tempZoneUnits;
      expectedIncomingShipments[warehouseId][tempZone][day] = currentUnits;
    };
    expectedIncomingShipments[warehouseId]["TOTAL"][day] = total;
    expectedIncomingShipments[warehouseId] = Object.fromEntries(Object.entries(expectedIncomingShipments[warehouseId]).sort())
  };
  return expectedIncomingShipments;
};

export {calculateIncomingUnits};