/**
 * A hook that that gives components access to the current array of dock received.
 * The component will be re-rendered with the latest dock received shipments any time the
 * data changes.
 */

import { useMemo } from "react"
import filterDockReceived from "../filters/filter-dock-received"

const useDockReceived = (incomingDockReceived, filters = new Map()) => {

  const filteredDockReceived = useMemo(() => {
    if (incomingDockReceived) {
      // Sorting them by scheduled arrival and temp zone
      const dockReceived = incomingDockReceived.sort(function (a, b) {
        return (a.lastDockReceiveTime + (a.sla ? Math.min(a.sla, 24) : 24) * 60 * 60 * 1000)
            - (b.lastDockReceiveTime + (b.sla ? Math.min(b.sla, 24) : 24) * 60 * 60 * 1000);
      })
      return filterDockReceived(dockReceived, filters)
    } else {
      return null
    }
  }, [filters, incomingDockReceived])

  return filteredDockReceived
}

export default useDockReceived
