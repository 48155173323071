/**
 * All the states that a shipment can be in.
 */

const loadTypes = [
  { id: "Vendor", label: "Vendor" },
  { id: "Transship", label: "Transship" },
]

export const getLoadTypeById = id => loadTypes.find(loadType => loadType.id === id)
export const allLoadTypesIds = loadTypes.map(({ id }) => id)

export default loadTypes