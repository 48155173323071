/**
 * A single row in the iol details table. Shows details for a item.
 */

import React from "react";
import { TableRow, TableCell } from "@amzn/meridian/table";
import Text from "@amzn/meridian/text";
import iolDetailsPropType from "../../../prop-types/iol-details";
import useIOLDetailsViewPreferences from "../../../hooks/use-iol-details-view-preferences";
import useWarehouseData from "../../../hooks/use-warehouse-data";
import { convertUnix } from "../../../utilities/convert-unix";
import { getWarehouseAttributes } from "../../../utilities/get-warehouse-attributes";

const rowShouldNotUpdate = (prevProps, nextProps) =>
  !(
    prevProps.selected !== nextProps.selected ||
    prevProps.iol !== nextProps.iol
  )

const IOLDetailsTableRow = React.memo(props => {
  const { iolDetail, warehouseId } = props
  const [viewPreferences] = useIOLDetailsViewPreferences()

  const { time_zone } = getWarehouseAttributes({ warehouseId: warehouseId, warehouseParams: useWarehouseData() });

  const visibleColumns = viewPreferences.get("columns")

  return (
    <TableRow key={`${iolDetail.asin}-${iolDetail.receivedDate}`} rowComponents={props.rowComponents}>
      {visibleColumns.receivedDate && (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            {convertUnix(iolDetail.receivedDate, time_zone)}
          </Text>
        </TableCell>
      )}
      {visibleColumns.asin && (
        <TableCell>
          <Text tag="p">
            {iolDetail.asin}
          </Text>
        </TableCell>
      )}
      {visibleColumns.asinTitle && (
        <TableCell>
          <Text tag="p">
            {iolDetail.asinTitle}
          </Text>
        </TableCell>
      )}
      {visibleColumns.cost && (
        <TableCell>
          <Text tag="p">
            {iolDetail.cost ? `${iolDetail.cost}` : "Unknown"}
          </Text>
        </TableCell>
      )}
      {visibleColumns.expired && (
        <TableCell>
          <Text tag="p">
            {iolDetail.expirationDate ? iolDetail.expirationDate < Date.now() / 1000 ? "Y" : "N" : "N"}
          </Text>
        </TableCell>
      )}
      {visibleColumns.units && (
        <TableCell>
          {iolDetail.units ? iolDetail.units.toLocaleString() : 0}
        </TableCell>
      )}
      {visibleColumns.dwellHours && (
        <TableCell>
          {iolDetail.receivedDate ? Math.floor((Date.now() - iolDetail.receivedDate) / (60 * 60 * 1000)).toLocaleString() : null}
        </TableCell>
      )}
    </TableRow>
  )
}, rowShouldNotUpdate)

IOLDetailsTableRow.propTypes = {
  iolDetail: iolDetailsPropType.isRequired,
  rowComponents: TableRow.propTypes.rowComponents,
}

export default IOLDetailsTableRow
