/**
 * The search field shown in the network dwelling shipments table's action bar.
 */

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import SearchField from "@amzn/meridian/search-field";
import useNYSFilters from "../../../hooks/use-nys-filters";

const NYSSearchField = props => {
  const {filters, dispatch} = useNYSFilters();
  const [query, setQuery] = useState("");
  const filterValue = filters.get("allFields")?.value || "";

  const search = useCallback(
    value =>
      value
        ? dispatch({
            type: "UPDATE_FILTER",
            filter: "allFields",
            value,
            tagLabel: `${value}`,
          })
        : dispatch({
            type: "REMOVE_FILTER",
            filter: "allFields",
          }),
    [dispatch]
  );

  // Debounce calls to onSearch
  useEffect(() => {
    const timeout = setTimeout(() => search(query), 300)
    return () => clearTimeout(timeout)
  }, [query, search]);

  useEffect(() => {
    setQuery(filterValue)
  }, [filterValue]);

  return (
    <SearchField
      placeholder={
        props.mobile ? "Search" : "Search"
      }
      value={query}
      onChange={setQuery}
      onSubmit={search}
      onClear={search}
      searchButton={true}
      width={props.mobile ? 200 : 360}
    />
  );
};

NYSSearchField.propTypes = {
  mobile: PropTypes.bool,
  tableType: PropTypes.string.isRequired,
};

NYSSearchField.defaultProps = {
  mobile: false,
};

export default NYSSearchField;
