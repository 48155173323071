import PropTypes from "prop-types"

// Disable alphabetical ordering to make it easier to scan for pairings (e.g.
// origin + destination)
/* eslint-disable react/sort-prop-types */
export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  loadType: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  loadId: PropTypes.string.isRequired,
  tempZone: PropTypes.string.isRequired,
  actualArrival: PropTypes.number,
  dockReceived: PropTypes.number.isRequired,
  confirmedUnits: PropTypes.number,
  receivedUnits: PropTypes.number,
})
