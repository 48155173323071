/**
 * The action bar shown above the shipments table. Includes a search field and
 * filters to help find shipments. Also includes a create shipment button and
 * actions for bulk-editing shipments.
 */
import React, { useRef } from "react";
import { TableActionBar } from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import Heading from "@amzn/meridian/heading";
import Text from "@amzn/meridian/text";
import HorizontalStickyContainer from "../app/horizontal-sticky-container";
import useInstockFilters from "../../hooks/use-instock-filters";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Tooltip from "@amzn/meridian/tooltip";
import filterIconTokens from "@amzn/meridian-tokens/base/icon/filter";

const FilterButton = () => {
  const {
    sheet: [sheetOpen, setSheetOpen],
  } = useInstockFilters()
  const buttonRef = useRef()
  return (
    <Tooltip title="Filters" position="top">
      <Button
        ref={buttonRef}
        onClick={() => setSheetOpen(!sheetOpen)}
        type="icon"
      >
        <Icon tokens={filterIconTokens}>Filters</Icon>
      </Button>
    </Tooltip>
  )
}
const HorizontalStickyRow = props => (
  <HorizontalStickyContainer Component={Row} {...props} />
)
const InstockActionBar = props => {
  const Wrapper = props.stick ? HorizontalStickyRow : Row
  const oosAsinCount = props.oosAsinCount;
  const oosRiskAsinCount = props.oosRiskAsinCount;
  const vbiCtm = props.vbiCtm;
  const noosCount = props.noosCount;
  return (
    <Wrapper widths="fill">
      <TableActionBar alignmentHorizontal="end">
        <FilterButton></FilterButton>
      <Row>
            <Heading level={6}>Total NOOS ASINs:</Heading>
            <Text>{noosCount}</Text>
            <Heading level={6}>Total OOS ASINs:</Heading>
            <Text>{oosAsinCount}</Text>
            <Heading level={6}>Total OOS Risk ASINs:</Heading>
            <Text>{oosRiskAsinCount}</Text>
            <Heading level={6}>Total VBI CTM (bps):</Heading>
            <Text>{Math.round(vbiCtm)}</Text>
          </Row>
      </TableActionBar>
    </Wrapper>
  )
}

export default InstockActionBar
