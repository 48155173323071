import React, { useState } from "react"
import Table, { TableRow, TableCell } from "@amzn/meridian/table"
import InstockTableRow from "./instock-table-row"
import instockTableColumns from "../../data/instock-table-columns"

const InstockTable = props => {
    const { visibleIndices, instock, warehouseId, instockSnapshot } = props
    const [sortColumn, setSortColumn] = useState("")
    const [sortDirection, setSortDirection] = useState("ascending")
    const onSort = ({ sortColumn, sortDirection }) => {
      setSortDirection(sortDirection)
      setSortColumn(sortColumn)
    }
    const sortedInstockShipments = [...instock]
    if (sortColumn) {
      sortedInstockShipments.sort((a, b) => {
          if (a[sortColumn] < b[sortColumn]) {
            return sortDirection === "ascending" ? -1 : 1
          }
          else if (a[sortColumn] > b[sortColumn]) {
            return sortDirection === "ascending" ? 1 : -1
          }
          return 0
        })
      }
    const visibleInstockShipments = sortedInstockShipments.slice(
      visibleIndices[0],
      visibleIndices[1]
    )
    return (
        <React.Fragment>
          <Table
            headerRows={1}
            showDividers={true}
            rowComponents={[InstockTableRow, TableRow]}
            stickyHeaderRow={true}
            stickyHeaderColumn={false}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onSort={onSort}
            showStripes={'True'}
            spacing={'medium'}
          >
            <TableRow>
              {instockTableColumns
                .map(header => (
                  <TableCell
                    key={header.id}
                    sortColumn={header.id}
                    width={header.width}
                    alignmentHorizontal={header.alignment}
                  >
                  {header.label}
                  </TableCell>
                ))
              }
            </TableRow>
            {visibleInstockShipments.map((instock, index) => (
            <InstockTableRow
              instock={instock}
              warehouseId={warehouseId}
              instockSnapshot={instockSnapshot}
            />
            ))}
          </Table>
        </React.Fragment>
      )
}
export default InstockTable;