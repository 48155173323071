/**
 * A single row in the po deep dive table
 */

import React from "react"
import PropType from "prop-types"
import { TableRow, TableCell } from "@amzn/meridian/table"
import Text from "@amzn/meridian/text"
import PropTypes from "prop-types";
import Row from "@amzn/meridian/row";
import EventIcon from "../../event/event-icon";
import Column from "@amzn/meridian/column";


const NysTableRow = props => {
  const { nysItem, criticalAsinEvents, region_id } = props
  const nysQuantity = nysItem.createdBySource === 'AFTLite' ? nysItem.nysQuantityWMS : nysItem.wmsQuantityReceived - nysItem.wmsQuantityReceivedStow;
    const criticalEvents = nysItem.criticalEvent ? criticalAsinEvents ?
        criticalAsinEvents.filter((criticalAsinEvent) => nysItem.criticalEvent.includes(criticalAsinEvent.eventName))
        : [] : [];
  return (
    <TableRow key={nysItem.asin}>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={nysItem.tempZone ? nysItem.tempZone : ""}>
          {nysItem.tempZone ? nysItem.tempZone : ""}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={nysItem.toteId ? nysItem.toteId : ""}>
          {nysItem.toteId ? nysItem.toteId : ""}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
          <Column>
            <Text tag="p" children={nysItem.asin ? nysItem.asin : ""}>
              {nysItem.asin ? nysItem.asin : ""}
            </Text>
              { criticalEvents.length > 0 &&
                  <Row alignmentHorizontal="center">
                      {criticalEvents.map(criticalEvent => <EventIcon event={criticalEvent.eventName} regionId={region_id}/>)}
                  </Row>
              }
          </Column>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={nysItem.asinTitle ? nysItem.asinTitle : "Unknown Title"}>
          {nysItem.asinTitle ? nysItem.asinTitle : "Unknown Title"}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={nysItem.wmsQuantityReceived ? nysItem.wmsQuantityReceived : 0}>
          {nysItem.wmsQuantityReceived ? nysItem.wmsQuantityReceived.toLocaleString() : 0}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={nysItem.wmsQuantityReceivedStow ? nysItem.wmsQuantityReceivedStow : 0}>
          {nysItem.wmsQuantityReceivedStow ? nysItem.wmsQuantityReceivedStow.toLocaleString() : 0}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={nysQuantity}>
          {nysQuantity.toLocaleString()}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={nysItem.isCriticalAsin}>
          {nysItem.isCriticalAsin}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={nysItem.isAsrsAsin}>
          {nysItem.asrsEligible!=null && nysItem.asrsEligible===1 ? "Y": "N"}
        </Text>
      </TableCell>
    </TableRow>
  )
}

NysTableRow.propTypes = {
  nysItem: PropType.object.isRequired,
  rowComponents: TableRow.propTypes.rowComponents,
  criticalAsinEvents: PropTypes.object.isRequired,
  region_id: PropTypes.number.isRequired,
}

export default NysTableRow
