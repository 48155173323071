/**
 * A table of shipments. Rendered when the "My shipments" page view is set to
 * "table".
 */

import React, { useState } from "react"
import PropTypes from "prop-types"

import Table, { TableRow, TableCell } from "@amzn/meridian/table"
import NotificationsTableRow from "./notifications-table-row"
import notificationsTableColumns from "../../../data/notifications-table-columns"

const NotificationsTable = props => {
  const { notifications, warehouseId } = props
  const [sortColumn, setSortColumn] = useState("")
  const [sortDirection, setSortDirection] = useState("ascending")

  const onSort = ({ sortColumn, sortDirection }) => {
    setSortDirection(sortDirection)
    setSortColumn(sortColumn)
  }

  // Make a copy of dock received shipments and sort if `sortColumn` exists
  const sortedNotifications = [...notifications]
  if (sortColumn) {
    if (sortColumn === "timeToSLA") {
      sortedNotifications.sort((a, b) => {
        if (a.lastDockReceiveTime + (a.sla ? Math.min(a.sla, 24) : 24) * 60 * 60 * 1000 
          > b.lastDockReceiveTime + (a.sla ? Math.min(a.sla, 24) : 24) * 60 * 60 * 1000) {
            return sortDirection === "ascending" ? -1 : 1
        }
        if (a.lastDockReceiveTime + (a.sla ? Math.min(a.sla, 24) : 24) * 60 * 60 * 1000
          < b.lastDockReceiveTime + (a.sla ? Math.min(a.sla, 24) : 24) * 60 * 60 * 1000) {
            return sortDirection === "ascending" ? 1 : -1
        }
        return 0
      })
    }
    else if (sortColumn === "progress") {
      sortedNotifications.sort((a, b) => {
        if (a.receivedUnits / a.confirmedUnits < b.receivedUnits / b.confirmedUnits) {
          return sortDirection === "ascending" ? -1 : 1
        }
        if (a.receivedUnits / a.confirmedUnits > b.receivedUnits / b.confirmedUnits) {
          return sortDirection === "ascending" ? 1 : -1
        }
        return 0
      })
    }
    else if (sortColumn === "nyrnys") {
      sortedNotifications.sort((a, b) => {
        if (a.confirmedUnits - a.receivedUnits < b.confirmedUnits - b.receivedUnits) {
          return sortDirection === "ascending" ? -1 : 1
        }
        if (a.confirmedUnits - a.receivedUnits > b.confirmedUnits - b.receivedUnits) {
          return sortDirection === "ascending" ? 1 : -1
        }
        return 0
      })
    }
    else {
      sortedNotifications.sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) {
          return sortDirection === "ascending" ? -1 : 1
        }
        if (a[sortColumn] > b[sortColumn]) {
          return sortDirection === "ascending" ? 1 : -1
        }
        return 0
      })
    }
  }

  const visibleNotifications = sortedNotifications.slice(
    props.visibleIndices[0],
    props.visibleIndices[1]
  )

  return (
    <React.Fragment>
      <Table
        headerRows={1}
        showDividers={true}
        rowComponents={[NotificationsTableRow, TableRow]}
        stickyHeaderRow={true}
        stickyHeaderColumn={false}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={onSort}
        showStripes={true}
      >
        <TableRow>
          {notificationsTableColumns
            .map(header => (
              <TableCell
                key={header.id}
                sortColumn={header.id}
                width={header.width}
                alignmentHorizontal={header.alignment}
              >
                {header.label}
              </TableCell>
            ))}
        </TableRow>
        {visibleNotifications.map((notification, index) => (
          <NotificationsTableRow
            notification={notification}
            warehouseId={warehouseId}
          />
        ))}
      </Table>
    </React.Fragment>
  )
}

NotificationsTable.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object),
  visibleIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
  warehouseId: PropTypes.string.isRequired,
}

NotificationsTable.defaultProps = {
  areAllSelected: false,
}

export default NotificationsTable
