import PropTypes from "prop-types";

import Column from "@amzn/meridian/column";
import SearchField from "@amzn/meridian/search-field"
import {useRef, useState} from "react";
import Row from "@amzn/meridian/row";
import Icon from "@amzn/meridian/icon";
import infoFilledTokens from "@amzn/meridian-tokens/base/icon/info"
import Popover, { PopoverHeader } from "@amzn/meridian/popover"
import Text from "@amzn/meridian/text"
import Button from "@amzn/meridian/button";


export const ShipmentSearchBar = props => {
    const {searchInput, setSearchInput, getSearchData, setSearchResults} = props;
    const [disabled, setDisabled] = useState(false);
    const [open, setOpen] = useState(false);
    const buttonRef = useRef()

    return (
        <Column>
          <Row>
            <SearchField
                value={searchInput}
                onChange={setSearchInput}
                onSubmit={() => {
                    setDisabled(true);
                    try {
                        getSearchData()
                    } catch (error) {
                        console.log(error);
                        setSearchResults([]);
                    }
                    setDisabled(false);
                }}
                searchButton={true}
                width={360}
                disabled={disabled}
                helperText={'UPC, EAN, ISBN, ASIN or Pallet Id'}
            />
              <Button type="icon" onClick={() => setOpen(true)} ref={buttonRef}>
                <Icon tokens={infoFilledTokens} />
              </Button>
              <Popover
                anchorNode={buttonRef.current}
                open={open}
                onClose={() => setOpen(false)}
                position="bottom"
              >
                <PopoverHeader closeLabel="Close">Search Ranges</PopoverHeader>
                <Column>
                  <Text><strong>Incoming Shipments</strong></Text>
                  <Text>Yesterday to three days from now</Text>
                  <Text><strong>Dock Received</strong></Text>
                  <Text>Today to two weeks ago</Text>
                  <Text><strong>Pallet Id</strong></Text>
                  <Text>Today to 90 days ago</Text>
                </Column>
              </Popover>
          </Row>
        </Column>
    );
};

ShipmentSearchBar.propTypes = {
    searchInput: PropTypes.string,
    setSearchInput: PropTypes.func.isRequired,
    getSearchData: PropTypes.func.isRequired,
    setSearchResults: PropTypes.func.isRequired,
};
