/**
 * A component that will render a Row or a Column with provided
 * props based on a direction.
 *
 * Use this with a Responsive parent to
 * conditionally render a Row or Column based on a screen size,
 *
 * eg: The following will render a Row for widths > 720px, otherwise
 *     it will render a Column
 *
 * <Responsive
 *   query="min-width"
 *   props={{
 *    direction: {
 *      default: "column",
 *      "720px": "row",
 *      },
 *    }}
 * >
 *   {props =>
 *    <RowOrColumn
 *      direction={props.direction}
 *    >
 *   }
 * </Responsive>
 *
 */

import React from "react"
import PropTypes from "prop-types"
import Box from "@amzn/meridian/box"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"

const RowOrColumn = props => {
  const Component = props.direction === "column" ? Column : Row
  const rowOrColumnProps =
    props.direction === "column" ? props.columnProps : props.rowProps
  const sharedProps = props.sharedProps

  return (
    <Component {...sharedProps} {...rowOrColumnProps}>
      {props.children}
    </Component>
  )
}

RowOrColumn.propTypes = {
  children: PropTypes.node.isRequired,
  columnProps: PropTypes.shape(Column.propTypes),
  direction: PropTypes.oneOf(["column", "row"]),
  rowProps: PropTypes.shape(Row.propTypes),
  sharedProps: PropTypes.shape(Box.propTypes),
}

RowOrColumn.defaultProps = {
  direction: "row",
  rowProps: {},
  columnProps: {},
  sharedProps: {},
}

export default RowOrColumn