import { convertUnix } from "./convert-unix";
function searchBarFilter(
    searchValue: string,
    incomingData: any,
    dateKeys: Array<string> = [],
    searchableFields: Array<string>
) {
    // default to all fields if no searchable fields are provided
    const seachFields = searchableFields ? searchableFields : Object.keys(incomingData);
    for (const searchField of seachFields) {
      try {
          // Small concern for tech debt here. We cannot have another notification key in any other dataset we're searching
          // We'll either have to specifically note if this is dock received or just 100% be sure we don't duplciate that key
          if (searchField === "notification") {
              // we're calculating this live because the ui does it in real time too
            if (((incomingData.lastDockReceiveTime) + (incomingData.sla ? incomingData.sla : 24) * 60 * 60 * 1000)
                - Date.now() <= 2 * 60 * 60 * 1000) {
                if ("Near SLA".toLowerCase().includes(String(searchValue).toLowerCase())) {
                    return true;
                }
            } else if (((incomingData.lastDockReceiveTime) + (incomingData.sla ? incomingData.sla : 24) * 60 * 60 * 1000)
                - Date.now() <= 0) {
                if ("Past SLA".toLowerCase().includes(String(searchValue).toLowerCase())) {
                    return true;
                }
            }
            return false;
          } else {
              if (Array.isArray(incomingData[searchField])) {
                  if (dateKeys.includes(searchField)) {
                      if (incomingData[searchField].some(data => String(convertUnix(data, incomingData.timeZone)).toLowerCase().includes(String(searchValue).toLowerCase()))) {
                          return true;
                      }
                  } else {
                      if (incomingData[searchField].some(data => String(data).toLowerCase().includes(String(searchValue).toLowerCase()))) {
                          return true;
                      }
                  }
              } else {
                  if (dateKeys.includes(searchField)) {
                      if (String(convertUnix(incomingData[searchField], incomingData.timeZone)).toLowerCase().includes(String(searchValue).toLowerCase())) {
                          return true;
                      }
                  } else {
                      if (String(incomingData[searchField]).toLowerCase().includes(String(searchValue).toLowerCase())) {
                          return true
                      }
                  }
              }
          }
      }
      catch {
        return false
      }
    }
    return false
}

export { searchBarFilter };