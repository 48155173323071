/**
 * A modal that allows user to reconcile a dock received shipment.
 */

import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Checkbox from "@amzn/meridian/checkbox";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Heading from "@amzn/meridian/heading";
import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import Text from "@amzn/meridian/text";
import Textarea from "@amzn/meridian/textarea";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Select, { SelectOption } from "@amzn/meridian/select";

import dockReceivedPropType from "../../prop-types/dock-received";
import useWarehouseData from "../../hooks/use-warehouse-data";
import { convertUnix } from "../../utilities/convert-unix";
import { getWarehouseAttributes } from "../../utilities/get-warehouse-attributes";
import useAuth from "../../hooks/use-auth";

// API
import { suppressShipment } from "../../graphql/mutations";
import { API } from "aws-amplify";

import { ticketLinkBoxCharacterLimit } from "../Constants";

import { shipmentQuantityKeys } from "../Constants";

const {
  vendorConfirmedUnits,
  vendorReceivedUnits,
  transConfirmedUnits,
  transReceivedUnits
} = shipmentQuantityKeys;

const SuppressModal = props => {
  const { dockReceived, warehouseId } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [suppressionReason, setSuppressionReason] = useState("");
  const [detailedReason, setDetailedReason] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [showTicketBox, setShowTicketBox] = useState(false);
  const userData = useAuth();
  
  const { time_zone } = getWarehouseAttributes({ warehouseId: warehouseId, warehouseParams: useWarehouseData() });

  let suppressionHistory = dockReceived.suppressionHistory ? JSON.parse(dockReceived.suppressionHistory) : [];
  const orderId = dockReceived.orderId;
  const recordType = dockReceived.recordType;

  const currentSuppressionState = dockReceived.suppression ? dockReceived.suppression : false;
  
  const suppressReasons = [
    "Split PO",
    "Vendor Shorts",
    "Rejections - *Provide tt link required*",
    "Received all available units"
    // "Other - *With detailed reasoning required*"
  ];
  const unsuppressReasons = ["Split PO", "Receive more units"]

  // Enforcing our form requirements for reasons
  useEffect(() => {
    // show ticket box if rejections are selected
    setShowTicketBox(suppressionReason.includes("Provide tt link required"));
    if (suppressionReason === "") {
      setDisabled(true)
    }
    else if (suppressionReason === "Rejections - *Provide tt link required*" && checkedBoxes.length > 0) {
      setDisabled(!["sim.amazon.com", "t.corp.amazon.com"].some(substring => detailedReason.includes(substring)))
    }
    else if (suppressionReason === "Other - *With detailed reasoning required*" && checkedBoxes.length > 0) {
      setDisabled(detailedReason === "")
    }
    else {
      setDisabled(!checkedBoxes.length > 0)
    }

  }, [setDisabled, suppressionReason, detailedReason, checkedBoxes, setShowTicketBox]);

  const onClickButton = useCallback(() => setOpen(true), []);
  const onCloseButton = () => {
    setOpen(false);
    };

  const updateSuppressionStatus = useCallback(async (newSuppressionHistory) => {
      const incomingData = await API.graphql({
        query: suppressShipment,
        // 12 hour dwell until considered IOL
        variables: {orderId: orderId, recordType: recordType, suppressionHistory: JSON.stringify(newSuppressionHistory)}
      })
      if (incomingData.data) {
        setUpdated(true);
      }
    }, [orderId, recordType, setUpdated]);

  const onConfirm = async () => {
    // Send API signal to suppress selected temp zones
    // Refresh UI/Appsync if possible
    for (const tempZone of checkedBoxes) {
      suppressionHistory.push(
        {
          tempZone: tempZone,
          suppressionTime: Date.now(),
          user: userData.username,
          suppressed: !currentSuppressionState,
          suppressionReason: suppressionReason,
          suppressionDetails: detailedReason
        }
      )
    }
    updateSuppressionStatus(suppressionHistory);
  };

  const onCheck = (checkEvent, checkedTempZone) => {
    if ( checkEvent ) {
      setCheckedBoxes([...checkedBoxes, checkedTempZone])
    }
    else {
      setCheckedBoxes(checkedBoxes.filter( tempZones => tempZones !== checkedTempZone ))
    };
  };
  const summary = dockReceived.poItemSummary ? JSON.parse(dockReceived.poItemSummary) : {summaryByTempZone: {}};

  useEffect(() => {
    if (updated) {
      navigate(0)
    }
  }, [updated, navigate]);

  const newTypedValue = useCallback(value => {
    if (value.length <= ticketLinkBoxCharacterLimit) {
      setDetailedReason(value)
    } else {
      setDetailedReason(value.slice(0,-1))
    }
  }, []);

  return (
    <>
      <Button
        size="small"
        minWidth="8%"
        onClick={onClickButton}
        data-testid="modalButton"
      >
        {
          currentSuppressionState ? "Unsuppress Load": "Suppress Load"
        }
      </Button>
      <Modal
        title= {currentSuppressionState ? "Unsuppress Load": "Suppress Load"}
        open={open}
        scrollContainer="viewport"
        describedById="suppress-modal-description"
        onClose={onCloseButton}
        closeLabel="Close"
      >
        <Row spacing={"600"} alignmentVertical={"top"}>
          <Column>
            <Heading level={3}>Select Temp Zones to action on</Heading>
            <Table headerRows={1} showDividers={true}>
              <TableRow>
                <TableCell/>
                <TableCell header={true}><Heading level={5}>Temp Zone</Heading></TableCell>
                <TableCell header={true}><Heading level={5}>Expected Units</Heading></TableCell>
                <TableCell header={true}><Heading level={5}>Received Units</Heading></TableCell>
                <TableCell header={true}><Heading level={5}>Receive Progress</Heading></TableCell>
              </TableRow>
              {Object.entries(summary.summaryByTempZoneDetailed).map(([tempZone, tempZoneValues]) =>
                <TableRow>
                  <TableCell><Checkbox checked={checkedBoxes.includes(tempZone)} onChange={(checkEvent) => onCheck(checkEvent, tempZone)} name={tempZone} id={tempZone}/></TableCell>
                  <TableCell><Text>{tempZone}</Text></TableCell>
                  <TableCell>
                    <Text>
                      {dockReceived.loadType === "Vendor" ?
                          tempZoneValues[vendorConfirmedUnits]
                          : tempZoneValues[transConfirmedUnits] ?
                              tempZoneValues[transConfirmedUnits] :
                              tempZoneValues.wmsQuantityReceived
                      }
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text>
                      {dockReceived.loadType === "Vendor" ?
                          tempZoneValues[vendorReceivedUnits]
                          : tempZoneValues[transReceivedUnits]
                      }
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text>
                      {dockReceived.loadType === "Vendor" ?
                        `${Math.floor(tempZoneValues[vendorReceivedUnits] / tempZoneValues[vendorConfirmedUnits] * 10000) / 100}%`
                          : `${Math.floor(
                              tempZoneValues[transReceivedUnits] / (tempZoneValues[transConfirmedUnits] ? tempZoneValues[transConfirmedUnits] : tempZoneValues.wmsQuantityReceived) * 10000) / 100}%`
                      }
                    </Text>
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </Column>
          <Column>
            <Row><Heading level={3}>{currentSuppressionState? "Unsuppression Form" : "Suppression Form" }</Heading></Row>
            <Row><Heading level={4}>{"Load ID:"}</Heading><Text>{dockReceived.loadId}</Text></Row>
            <Row><Heading level={4}>{"Dock Received:"}</Heading><Text>{convertUnix(dockReceived.lastDockReceiveTime, time_zone)}</Text></Row>
            <Row>
              <Heading level={4}>{"Reason:"}</Heading>
              <Select
                value={suppressionReason}
                onChange={setSuppressionReason}
                placeholder={currentSuppressionState ? "Enter un-suppression reason..." : "Enter suppression reason..."}
                width={300}
              >
                {currentSuppressionState? unsuppressReasons.map(unsuppressReason => <SelectOption value={unsuppressReason} label={unsuppressReason} />):
                suppressReasons.map(suppressReason => <SelectOption value={suppressReason} label={suppressReason} />)}
              </Select>
            </Row>
            { showTicketBox &&
              <Row>
                <Textarea
                  value={detailedReason}
                  onChange={newTypedValue}
                  placeholder="Enter Ticket"
                  label="SIM/Trouble Ticket Link"
                  width={390}
                />
              </Row>
            }
          </Column>
        </Row>
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button type="secondary" onClick={onCloseButton}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={onConfirm}
              disabled={disabled}
            >
              {currentSuppressionState ? "Unsuppress Load" : "Suppress Load" }
              
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  )
}

SuppressModal.propTypes = {
  dockReceived: dockReceivedPropType.isRequired,
  warehouseId: PropTypes.string.isRequired,
}


export default SuppressModal
