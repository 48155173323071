/**
 * A context-based hook that allows components to consume warehouse param data
 */

import React, { useState, useContext, createContext, useEffect } from "react";
import PropTypes from "prop-types";

import { getPaginatedAPICall } from "../utilities/get-paginated-api-call";
import { getWarehouses } from "../graphql/queries";

const WarehouseDataContext = createContext();

const WarehouseDataContextProvider = props => {
  const [data, setData] = useState();
  useEffect(() => {
    const getWarehouseData = async () => {
      const params = {"none": {}};
      let incomingData = await getPaginatedAPICall(params, getWarehouses, "getWarehouses")
      if (incomingData) {
        incomingData = incomingData.map(data => {
          data.geography = data.geography ? data.geography : data.city
          return data
        })
        setData(incomingData.sort((a,b) =>  a.warehouse_id.localeCompare(b.warehouse_id)))
      };
    }
    getWarehouseData()
  }, [setData]);
  return (
    <WarehouseDataContext.Provider value={data}>
      {props.children}
    </WarehouseDataContext.Provider>
  );
};

WarehouseDataContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useWarehouseData = () => useContext(WarehouseDataContext);

export { WarehouseDataContextProvider };
export default useWarehouseData;