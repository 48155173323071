/**
 * When mounted this resets the scroll position any time the pathname changes.
 * Thanks react-router!
 * https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
 */

import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { appLayoutContentId } from "@amzn/meridian/app-layout"

const ResetScroll = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    const appContent = document.getElementById(appLayoutContentId)
    if (appContent) {
      appContent.scrollTop = 0
    }
  }, [pathname])

  return null
}

export default ResetScroll