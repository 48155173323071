/**
 * A modal that allows user to reconcile a dock received shipment.
 */

import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Link from "@amzn/meridian/link";
import Loader from "@amzn/meridian/loader";
import Heading from "@amzn/meridian/heading";
import Text from "@amzn/meridian/text";
import Modal, { ModalFooter } from "@amzn/meridian/modal";

import NYSTable from "./nys-table";
import NYSActionBar from "./nys-action-bar";
import TableFooter from "../../app/table-footer";
import filterNys from "../../../filters/filter-nys";
import FilterTags from "../../app/filter-tags";
import useFilters from "../../../hooks/use-filters";
import useNYSFilters from "../../../hooks/use-nys-filters";
import usePagination from "../../../hooks/use-pagination";

import { defaultResultsPerPage, resultsPerPageOptions } from "../../Constants";

// API
import { getNYSDetails } from "../../../graphql/queries";
import { getPaginatedAPICall } from "../../../utilities/get-paginated-api-call";

import { shipmentQuantityKeys } from "../../Constants";

const { transConfirmedUnits, transReceivedUnits } = shipmentQuantityKeys;


const NYSModal = props => {
  const { nys, manifestId, tempZone, criticalAsinEvents, region_id } = props
  const [open, setOpen] = useState(false);
  const [incomingNYSData, setIncomingNYSData] = useState();
  const [resultsPerPage, setResultsPerPage] = useState(defaultResultsPerPage);
  const [selectedResultsPerPage, setSelectedResultsPerPage] = useState(defaultResultsPerPage);
  const { filters } = useNYSFilters();

  useEffect(() => {
    const getPOData = async () => {
      const params = {warehouse: { manifestId: manifestId, tempZone: tempZone }};
      const data = await getPaginatedAPICall(params, getNYSDetails, "getNYSDetails");
      const dataFromAFTLite = (data.filter(aftlite => aftlite.createdBySource ==='AFTLite'));
      if(dataFromAFTLite.length > 0){
        setIncomingNYSData(dataFromAFTLite.filter(nyr => nyr["nysQuantityWMS"] > 0));
      }
      else {
        setIncomingNYSData(data.filter(nyr => nyr[transConfirmedUnits] ? nyr[transConfirmedUnits] > nyr[transReceivedUnits] : nyr.wmsQuantityReceived > nyr[transReceivedUnits]));
      }
    };
    
    if (manifestId !== "" && open){
      getPOData();
    };
  }, [setIncomingNYSData, manifestId, tempZone, open]);
  
  const nysData = useFilters(incomingNYSData, filterNys, filters);

  const pagination = usePagination({
    numberOfItemsPerPage: resultsPerPage,
    numberOfResults: nysData?.length || 0,
  });

  const onChangeResultsPerPage = resultsPerPage => {
    // Update local state variable
    setSelectedResultsPerPage(resultsPerPage)
    if (resultsPerPage === "All") {
      setResultsPerPage(nysData?.length || 0)
    } else {
      setResultsPerPage(resultsPerPage)
    }
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    pagination.onChange(1)
  }

  // Set pagination back to page 1 when filters change
  useEffect(() => {
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    onChangeResultsPerPage(selectedResultsPerPage)
    // We disable the exhaustive-deps hooks rule here because including `pagination`
    // will cause the effect to be called over-and-over.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filters])

  const { visibleIndices } = pagination;

  const onClickButton = useCallback(() => setOpen(true), []);
  const onCloseButton = () => {
    setOpen(false);
    setIncomingNYSData();
    pagination.onChange(1)
    };

  return (
    <>
      <Link
        onClick={onClickButton}
      >
          {nys.toLocaleString()}
      </Link>
      <Modal
        title="NYS Details"
        open={open}
        scrollContainer="viewport"
        onClose={onCloseButton}
        closeLabel="Close"
      >
        {nysData ?
          <Column>
            <Row><Heading level={5}>{"Manifest ID:"}</Heading><Text>{manifestId}</Text></Row>
            <Row><NYSActionBar/></Row>
            <Row><FilterTags stick={true} filtersHook={useNYSFilters}/></Row>
            <Row><NYSTable nysData={nysData} visibleIndices={visibleIndices} criticalAsinEvents={criticalAsinEvents} region_id={region_id}/></Row>
            <Row>
              {nysData && nysData.length > 0 ? (
                <TableFooter
                  pagination={pagination}
                  stick={false}
                  resultsPerPage={resultsPerPage}
                  onChangeResultsPerPage={onChangeResultsPerPage}
                  resultsPerPageOptions={resultsPerPageOptions}
                  selectedResultsPerPage={selectedResultsPerPage}
                />
                ) : null
              }
            </Row>
          </Column>
        : <Loader/>
        }
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button type="secondary" onClick={onCloseButton}>
              Close
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  )
}

NYSModal.propTypes = {
  nys: PropTypes.number.isRequired,
  manifestId: PropTypes.string.isRequired,
  tempZone: PropTypes.string.isRequired,
  criticalAsinEvents: PropTypes.object.isRequired,
  region_id: PropTypes.number.isRequired,
}


export default NYSModal
