/**
 * "Incoming Network Shipments" report - view incoming POs and Transshipments aggregated
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Column from "@amzn/meridian/column"
import Heading from "@amzn/meridian/heading"
import Row from "@amzn/meridian/row"

import IncomingNetworkShipmentsTable from "../../components/incoming-network-shipments/incoming-network-shipments-table"
import TableFooter from "../../components/app/table-footer"
import usePagination from "../../hooks/use-pagination"


const IncomingNetworkShipments = props => {
  const { expectedIncomingShipments } = props
  const [resultsPerPage, setResultsPerPage] = useState(3)
  const [selectedResultsPerPage, setSelectedResultsPerPage] = useState(3)

  // just getting a count of rows
  let incomingNetworkShipments = [];
  let incomingNetworkShipmentsColumns = [];
  for (const [warehouseId, tempZones] of Object.entries(expectedIncomingShipments)) {
    incomingNetworkShipments.push({warehouseId: warehouseId, tempZones: tempZones})
    // eslint-disable-next-line
    for (const [tempZone, dates] of Object.entries(tempZones)){
      for (const date of Object.keys(dates)) {
        incomingNetworkShipmentsColumns.push(date)
      }
    };
  };

  // Unique and ordered dates
  incomingNetworkShipmentsColumns = [...new Set(incomingNetworkShipmentsColumns)].sort((a, b) => a.localeCompare(b));

  const pagination = usePagination({
    numberOfItemsPerPage: resultsPerPage,
    numberOfResults: incomingNetworkShipments?.length || 0,
  })

  const { visibleIndices } = pagination

  const onChangeResultsPerPage = resultsPerPage => {
    // Update local state variable
    setSelectedResultsPerPage(resultsPerPage)
    if (resultsPerPage === "All") {
      setResultsPerPage(incomingNetworkShipments?.length || 0)
    } else {
      setResultsPerPage(resultsPerPage)
    }
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    pagination.onChange(1)
  }

  // Set pagination back to page 1 when data changes
  useEffect(() => {
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    onChangeResultsPerPage(selectedResultsPerPage)
    // We disable the exhaustive-deps hooks rule here because including `pagination`
    // will cause the effect to be called over-and-over.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [expectedIncomingShipments])

  const resultsPerPageOptions = [3, 6, 9, "All"]


  return (
        <Column spacing="300">
          <br/>
          <Row><Heading level={2}>Expected Incoming Shipments</Heading></Row>
          <Row>
            <IncomingNetworkShipmentsTable
              incomingNetworkShipments={incomingNetworkShipments}
              incomingNetworkShipmentsTableColumns={incomingNetworkShipmentsColumns}
              visibleIndices={visibleIndices}
            />
          </Row>
          {incomingNetworkShipments ? (
            <Row>
              <TableFooter
                pagination={pagination}
                stick={false}
                resultsPerPage={resultsPerPage}
                onChangeResultsPerPage={onChangeResultsPerPage}
                resultsPerPageOptions={resultsPerPageOptions}
                selectedResultsPerPage={selectedResultsPerPage}
              />
            </Row>
          ) : null}
        </Column>
  )
}

IncomingNetworkShipments.propTypes = {
  expectedIncomingShipments: PropTypes.arrayOf(PropTypes.object),
  warehouseParams: PropTypes.arrayOf(PropTypes.object)
}

export default IncomingNetworkShipments