/**
 * "IOL" page - view inventory that is not in a prime/
 */

import React, {
  useRef,
  useState,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

import PageLayout from "../components/app/page-layout";
import IOLTable from "../components/iol/iol-table";
import TableFooter from "../components/app/table-footer";
import IOLEmpty from "../components/iol/iol-empty";
import IOLActionBar from "../components/iol/iol-action-bar";
import FilterTags from "../components/iol/filter-tags";
import usePagination from "../hooks/use-pagination";
import useIOLFilters from "../hooks/use-iol-filters";
import useFilters from "../hooks/use-filters";
import filterIOL from "../filters/filter-iol";
import usePageLayoutContentRectangle from "../hooks/use-page-layout-content-rectangle";
import useWarehouseData from "../hooks/use-warehouse-data";

import { convertUnix } from "../utilities/convert-unix";
import { getWarehouseAttributes } from "../utilities/get-warehouse-attributes";
import { defaultResultsPerPage, resultsPerPageOptions } from "../components/Constants";


const getTableWidth = wrapperElement => {
  try {
    return wrapperElement.querySelector("table").clientWidth
  } catch (_) {
    return undefined
  }
};

const IOL = props => {
  const { warehouseId, incomingIOL, iolLastRefreshTime } = props;
  const { filters } = useIOLFilters();
  const { width: contentWidth = 1200 } = usePageLayoutContentRectangle();
  const tableWrapperRef = useRef();
  const [resultsPerPage, setResultsPerPage] = useState(defaultResultsPerPage);
  const [selectedResultsPerPage, setSelectedResultsPerPage] = useState(defaultResultsPerPage);

  const { time_zone } = getWarehouseAttributes({ warehouseId: warehouseId, warehouseParams: useWarehouseData() });

  const iol = useFilters(incomingIOL, filterIOL, filters);

  const pagination = usePagination({
    numberOfItemsPerPage: resultsPerPage,
    numberOfResults: iol?.length || 0,
  });


  const tableWidth = getTableWidth(tableWrapperRef.current);
  const isSticky = contentWidth < tableWidth;

  const { visibleIndices } = pagination;


  const onChangeResultsPerPage = resultsPerPage => {
    // Update local state variable
    setSelectedResultsPerPage(resultsPerPage)
    if (resultsPerPage === "All") {
      setResultsPerPage(iol?.length || 0)
    } else {
      setResultsPerPage(resultsPerPage)
    }
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    pagination.onChange(1)
  }

  // Set pagination back to page 1 when filters change
  useEffect(() => {
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    onChangeResultsPerPage(selectedResultsPerPage)
    // We disable the exhaustive-deps hooks rule here because including `pagination`
    // will cause the effect to be called over-and-over.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filters, incomingIOL])


  return (
    <PageLayout
      loading={!iol}
      width={iol ? "inherit" : undefined}
      horizontallySticky={isSticky}
      maxWidth={isSticky ? "none" : undefined}
      alignmentHorizontal={isSticky ? "start" : "center"}
    >
        <Column spacing="300">
          <Row><Text type={"b100"}>Last Refresh: {convertUnix(iolLastRefreshTime, time_zone)}</Text></Row>
          <Row>
            <IOLActionBar
              stick={isSticky}
              iol={iol}
              warehouseId={warehouseId}
            />
          </Row>
          <Row><FilterTags stick={isSticky} /></Row>
          <Row
            spacingInset={isSticky ? "none 500 none none" : undefined}
            ref={tableWrapperRef}
          >
            <IOLTable
              iol={iol}
              visibleIndices={visibleIndices}
              warehouseId={warehouseId}
            />
          </Row>

          {iol && iol.length === 0 ? (
            <Row><IOLEmpty searching={Boolean(filters.size)} /></Row>
          ) : null}
          {iol && iol.length > 0 ? (
            <Row>
              <TableFooter
                pagination={pagination}
                stick={isSticky}
                resultsPerPage={resultsPerPage}
                onChangeResultsPerPage={onChangeResultsPerPage}
                resultsPerPageOptions={resultsPerPageOptions}
                selectedResultsPerPage={selectedResultsPerPage}
              />
            </Row>
          ) : null}
        </Column>
    </PageLayout>
  );
};

IOL.propTypes = {
  incomingIOL: PropTypes.arrayOf(PropTypes.object.isRequired),
  iolLastRefreshTime: PropTypes.number.isRequired,
  warehouseId: PropTypes.string.isRequired,
};

export default IOL;