/**
 * A single row in the iol details table. Shows details for a item.
 */

import React from "react";
import PropType from "prop-types";

import { TableRow, TableCell } from "@amzn/meridian/table";
import Text from "@amzn/meridian/text";

import useWarehouseData from "../../hooks/use-warehouse-data";

import { getWarehouseAttributes } from "../../utilities/get-warehouse-attributes";
import { convertUnix } from "../../utilities/convert-unix";
import Tooltip from "@amzn/meridian/tooltip";


const NetworkPODefectsTableRow = props => {
  const { networkPODefect } = props;
  const warehouseParams = useWarehouseData();
  const { time_zone } = getWarehouseAttributes(networkPODefect.warehouseId, warehouseParams);
  return (
    <TableRow key={networkPODefect.orderId}>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {networkPODefect.warehouseId}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        {
          networkPODefect.defects.map(defect =>
              <Tooltip position={"end"} title={networkPODefect.defectDetails[defect].description}>
                <Text tag="p">
                  {networkPODefect.defectDetails[defect].title}
                </Text>
              </Tooltip>
          )
        }
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {networkPODefect.orderId.substring(networkPODefect.orderId.indexOf('_') + 1)}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {networkPODefect.orderId.startsWith("PO") ? networkPODefect.distributorId : networkPODefect.srcWarehouseId}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {convertUnix(networkPODefect.firstAppointmentTime, time_zone)}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {convertUnix(networkPODefect.firstDockReceiveTime, time_zone)}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {networkPODefect.firstDockReceiveBy}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {convertUnix(networkPODefect.handoffWindowStartDateUTC, time_zone)}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {convertUnix(networkPODefect.handoffWindowEndDateUTC, time_zone)}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {convertUnix(networkPODefect.firstReceiveTime, time_zone)}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {networkPODefect.confirmedUnits}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {networkPODefect.receivedUnits}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {Math.floor((networkPODefect.reconcileDuration / 24 / 60 / 60) * 100) / 100}
        </Text>
      </TableCell>
      {/*<TableCell alignmentHorizontal="center">*/}
      {/*  <Text tag="p">*/}
      {/*    {networkPODefect.isqGreaterThan}*/}
      {/*  </Text>*/}
      {/*</TableCell>*/}
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {networkPODefect.firstAppointmentTime > networkPODefect.handoffWindowEndDateUTC ? "Y" : "N"}
        </Text>
      </TableCell>
    </TableRow>
  )
}

NetworkPODefectsTableRow.propTypes = {
  networkPODefect: PropType.object.isRequired,
  rowComponents: TableRow.propTypes.rowComponents,
}

export default NetworkPODefectsTableRow
