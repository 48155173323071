/**
 * All the states that a shipment can be in.
 */

export const tempZones = [
  { id: "AMBIENT", label: "AMBIENT" },
  { id: "AMBIENT-3", label: "AMBIENT-3" },
  { id: "AMBIENT-24", label: "AMBIENT-24" },
  { id: "CHILLED", label: "CHILLED" },
  { id: "CHILLED-3", label: "CHILLED-3" },
  { id: "CHILLED-24", label: "CHILLED-24" },
  { id: "FROZEN", label: "FROZEN" },
  { id: "FROZEN-24", label: "FROZEN-24" },
  { id: "PRODUCE", label: "PRODUCE" },
  { id: "PROTEIN", label: "PROTEIN" },
]

export const getTempZoneById = id => tempZones.find(tempZone => tempZone.id === id)
export const allTempZonesIds = tempZones.map(({ id }) => id)

export default tempZones 