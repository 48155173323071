import PropTypes from "prop-types";

import { ShipmentSearchBar } from "../components/shipment-search/shipment-search-bar";
import { ShipmentSearchResult } from "../components/shipment-search/shipment-search-result";

import Column from "@amzn/meridian/column";
import React from "react";
import Loader from "@amzn/meridian/loader";

export const ShipmentSearch = props => {
    const {
        searchResults,
        searchInput,
        setSearchInput,
        getSearchData,
        setSearchResults,
        searchLoading
    } = props;

    return (
        <Column>
            <ShipmentSearchBar
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                getSearchData={getSearchData}
                setSearchResults={setSearchResults}
            />
            {searchLoading ?
                <Column alignmentHorizontal="center" spacingInset="600 none">
                    <Loader type="circular" label="Loading shipments" />
                </Column>
                :
                <ShipmentSearchResult result={searchResults} />}

        </Column>
    );
};

ShipmentSearch.propTypes = {
    searchResults: PropTypes.arrayOf(
        PropTypes.shape(
            {
                asin: PropTypes.string,
                orderId: PropTypes.string.isRequired,
                palletId: PropTypes.string,
                source: PropTypes.string,
                destination: PropTypes.string,
                manifestTime: PropTypes.number,
                appointmentTime: PropTypes.number,
                actualArrival: PropTypes.number,
                dockReceivedTime: PropTypes.number,
                status: PropTypes.string,
                nyr_nys: PropTypes.number,
                searchInput: PropTypes.string,
                searchId: PropTypes.string,
            }
        )
    ).isRequired,
    searchInput: PropTypes.string,
    setSearchInput: PropTypes.func.isRequired,
    getSearchData: PropTypes.func.isRequired,
    setSearchResults: PropTypes.func.isRequired,
    searchLoading: PropTypes.bool.isRequired,
};
