import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"
import Modal from "@amzn/meridian/modal"
import Button from "@amzn/meridian/button"
import Column from "@amzn/meridian/column"
import Heading from "@amzn/meridian/heading"
import Row from "@amzn/meridian/row"
import Text from "@amzn/meridian/text"
import Iframe from 'react-iframe'

const RiverModal = props => {
  const { buttonTitle, data, modalTitle, riverWorkflows } = props;
  const [open, setOpen] = useState(false);
  const onClickButton = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => {
      setOpen(false)
      setUrl("")
  }, []);
  const [url, setUrl] = useState("");

  return (
    <React.Fragment>
      <Button
        size="small"
        minWidth="8%"
        onClick={onClickButton}
      >
        {buttonTitle}
      </Button>
      <Modal
        title={modalTitle}
        open={open}
        onClose={onClose}
        scrollContainer="viewport"
        closeLabel="Close"
        aria-describedby={modalTitle}
        width={"100vw"}
      >
        <Column>
          {/* Add any information that may be needed while entering a ticket */}
          <Row>
            {data.loadId && <Row spacing={"200"}><Heading level={6}>Order ID:</Heading><Text>{data.loadId}</Text></Row>}
            {data.source && <Row spacing={"200"}><Heading level={6}>Source:</Heading><Text>{data.source}</Text></Row>}
            {/*{data.tempZone && <Row spacing={"200"}><Heading level={6}>Temp Zone:</Heading><Text>{data.tempZone}</Text></Row>}*/}
            {/*{data.confirmedUnits && <Row spacing={"200"}><Heading level={6}>Confirmed Units:</Heading><Text>{data.confirmedUnits}</Text></Row>}*/}
            {/*{data.receivedUnits && <Row spacing={"200"}><Heading level={6}>Confirmed Units:</Heading><Text>{data.confirmedUnits}</Text></Row>}*/}
          </Row>
          {/*  Gonna want to loop all the riverWorkflows here They'll be in buttons to change the url of the iframe */}
          <Row>
            {riverWorkflows.map((workflow, index) => {
              return (
                <Button
                  size="small"
                  minWidth="8%"
                  onClick={() => setUrl(workflow.url)}
                >
                  {workflow.title}
                </Button>
              )
            })}
          </Row>
        </Column>
        {url !== "" &&
          <Iframe
            url={url}
            width="100%"
            height="500px"
            display="block"
            position="relative"
          />
        }
      </Modal>
    </React.Fragment>
  )
}

RiverModal.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  data: PropTypes.object,
  modalTitle: PropTypes.string.isRequired,
  riverWorkflows: PropTypes.object.isRequired,
}

export default RiverModal;