/**
 * The date range selector for incoming shipments
 */

import React from "react";
import useIOLFilters from "../../hooks/use-iol-filters";
import Select, { SelectOption } from "@amzn/meridian/select";
import tempZones, { getTempZoneById } from "../../data/temp-zones";


const IOLTempZoneFilter = () => {
  const { filters, dispatch } = useIOLFilters()
  const onChangeFilter = (filter, makeLabel) => value => {
    if (!value || value.length === 0) {
      dispatch({ type: "REMOVE_FILTER", filter })
    } else {
      dispatch({
        type: "UPDATE_FILTER",
        filter,
        value,
        tagLabel: makeLabel(value),
      })
    }
  }

  return (
    // <Select
    //   value={filters.get("tempZone")?.value || ""}
    //   onChange={onChangeFilter("tempZone", value => `Temp Zone: ${getTempZoneById(value).label}`)}
    //   id="temp-zone-select"
    //   placeholder="Select a temp Zone"
    // >
      <Select
        value={filters.get("tempZone")?.value || []}
        onChange={onChangeFilter("tempZone", value =>
          value.map(
            tempZone => `Temp Zone: ${getTempZoneById(tempZone).label}`
          )
        )}
        id="temp-zone-select"
        placeholder="Select a temp profile"
      >
      {tempZones.map(({ id, label }) => (
        <SelectOption key={id} label={label} value={id} />
      ))}
    </Select>
  )
}

export default IOLTempZoneFilter
