function mapRegion(regionId: number) {
  const regionMap = {
    1: "NA",
    2: "EU",
    3: "FE"
  };
  return regionMap[regionId] ? regionMap[regionId] : "?"
};

export { mapRegion };
