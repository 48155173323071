/**
 * This component renders an "empty" table illustration and a call to action
 * to create a new shipment. The messaging is different between a) no shipments
 * exists and b) no shipments were found with a particular query + filter combination.
 */

import React from "react"
import PropTypes from "prop-types"
import Column from "@amzn/meridian/column"
import Text from "@amzn/meridian/text"
import HorizontalStickyContainer from "../app/horizontal-sticky-container"
// import { ReactComponent as EmptyStateIllustration } from "../../assets/marauders-map.svg"

const ShipmentSearchEmpty = props => {
    return (
        <HorizontalStickyContainer
            Component={Column}
            alignmentHorizontal="center"
            spacingInset="400 none none none"
        >
            {props.searching ? (
                <Column alignmentHorizontal="center">
                    {/*<EmptyStateIllustration />*/}
                    <Text tag="p" alignment="center">
                        No shipments found matching the given criteria
                        <br />
                        Try a different search
                    </Text>
                </Column>
            ) : (
                <Column alignmentHorizontal="center">
                    {/*<EmptyStateIllustration />*/}
                    <Text>
                        Please scan a barcode (UPC/EAN/ISBN) or input an ASIN or Pallet Id in the search bar above
                    </Text>
                </Column>
            )}
        </HorizontalStickyContainer>
    )
}

ShipmentSearchEmpty.propTypes = {
    searching: PropTypes.bool,
}

ShipmentSearchEmpty.defaultProps = {
    searching: false,
}

export default ShipmentSearchEmpty
