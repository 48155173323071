/**
 * The footer shown below the current page's content.
 */

import React from "react"
import { css } from "emotion"
import { colorGray200 } from "@amzn/meridian-tokens/base/color"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Text from "@amzn/meridian/text"
import Link from "@amzn/meridian/link"

const footerStyle = css({
  borderTop: `1px solid ${colorGray200}`,
  // Used on Shipments table page
  position: "sticky",
  left: 0,
})

const Footer = () => (
  <div className={footerStyle}>
    <Column
      alignmentHorizontal="center"
      backgroundColor="alternateSecondary"
      spacingInset="400 500"
    >
      <Row alignmentHorizontal="justify" width={950} maxWidth="100%">
        <Link
          href="https://w.amazon.com/bin/view/GSF/SupplyChain/IRIS/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Developed by GSF IRIS
        </Link>
        <Link
          href="https://issues.amazon.com/issues/create?template=587c01d6-ef42-4ce0-99ee-ba30ae769e80"
          target="_blank"
          rel="noopener noreferrer"
        >
          Feedback?
        </Link>
        <Text>Confidential, for Amazon use only.</Text>
      </Row>
    </Column>
  </div>
)

export default Footer