/**
 * "Incoming IncomingShipments" page - view incoming POs and Transshipments
 */

import React, {
  useRef,
  useState,
  useEffect,
} from "react"
import PropTypes from "prop-types"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Text from "@amzn/meridian/text"
import PageLayout from "../components/app/page-layout"
import IncomingShipmentsTable from "../components/incoming-shipments/incoming-shipments-table"
import TableFooter from "../components/app/table-footer"
import IncomingShipmentsEmpty from "../components/incoming-shipments/incoming-shipments-empty"
import IncomingShipmentsActionBar from "../components/incoming-shipments/incoming-shipments-action-bar"
import FilterTags from "../components/incoming-shipments/filter-tags"
import usePagination from "../hooks/use-pagination"
import useIncomingShipmentsFilters from "../hooks/use-incoming-shipments-filters"
import useFilters from "../hooks/use-filters"
import usePageLayoutContentRectangle from "../hooks/use-page-layout-content-rectangle"
import filterIncomingShipments from "../filters/filter-incoming-shipments"
import useWarehouseData from "../hooks/use-warehouse-data";

import { convertUnix } from "../utilities/convert-unix";
import { getWarehouseAttributes } from "../utilities/get-warehouse-attributes";
import { resultsPerPageOptions } from "../components/Constants";


const getTableWidth = wrapperElement => {
  try {
    return wrapperElement.querySelector("table").clientWidth
  } catch (_) {
    return undefined
  }
}

const IncomingShipments = props => {
  const { warehouseId, incomingIncomingShipments, lastRefreshTime } = props
  const { filters } = useIncomingShipmentsFilters()
  const { width: contentWidth = 1200 } = usePageLayoutContentRectangle()
  const tableWrapperRef = useRef()
  const [resultsPerPage, setResultsPerPage] = useState("All")
  const [selectedResultsPerPage, setSelectedResultsPerPage] = useState("All")
  const shipments = useFilters(incomingIncomingShipments, filterIncomingShipments, filters)

  const { time_zone } = getWarehouseAttributes({ warehouseId: warehouseId, warehouseParams: useWarehouseData() });

  let totalIncomingUnits = 0;
  let totalIncomingPallets = 0;
  if (shipments) {
    for (const Shipment of shipments) {
      totalIncomingUnits += Shipment.expectedUnits;
      totalIncomingPallets += Shipment.expectedPallets;
    }
  }

  const pagination = usePagination({
    numberOfItemsPerPage: resultsPerPage,
    numberOfResults: shipments?.length || 0,
  })
  const tableWidth = getTableWidth(tableWrapperRef.current)
  const isSticky = contentWidth < tableWidth

  const { visibleIndices } = pagination

  const onChangeResultsPerPage = resultsPerPage => {
    // Update local state variable
    setSelectedResultsPerPage(resultsPerPage)
    if (resultsPerPage === "All") {
      setResultsPerPage(shipments?.length || 0)
    } else {
      setResultsPerPage(resultsPerPage)
    }
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    pagination.onChange(1)
  }

  // Set pagination back to page 1 when filters change
  useEffect(() => {
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    onChangeResultsPerPage(selectedResultsPerPage)
    // We disable the exhaustive-deps hooks rule here because including `pagination`
    // will cause the effect to be called over-and-over.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filters, incomingIncomingShipments])


  return (
    <PageLayout
      loading={!shipments}
      width={shipments ? "inherit" : undefined}
      horizontallySticky={isSticky}
      maxWidth={isSticky ? "none" : undefined}
      alignmentHorizontal={isSticky ? "start" : "center"}
    >
        <Column spacing="300">
          <Text type={"b100"}>Last Refresh: {convertUnix(lastRefreshTime, time_zone)}</Text>
          <IncomingShipmentsActionBar
            stick={isSticky}
            incomingShipments={shipments}
            totalIncomingUnits={totalIncomingUnits}
            totalIncomingPallets={totalIncomingPallets}
          />
          <FilterTags stick={isSticky} />
          <Row
            spacingInset={isSticky ? "none 500 none none" : undefined}
            ref={tableWrapperRef}
          >
            <IncomingShipmentsTable
              incomingShipments={shipments}
              visibleIndices={visibleIndices}
              warehouseId={warehouseId}
            />
          </Row>

          {shipments && shipments.length === 0 ? (
            <IncomingShipmentsEmpty searching={Boolean(filters.size)} />
          ) : null}
          {shipments && shipments.length > 1 ? (
            <Row>
              <TableFooter
                pagination={pagination}
                stick={isSticky}
                resultsPerPage={resultsPerPage}
                onChangeResultsPerPage={onChangeResultsPerPage}
                resultsPerPageOptions={resultsPerPageOptions}
                selectedResultsPerPage={selectedResultsPerPage}
              />
            </Row>
          ) : null}
        </Column>
    </PageLayout>
  )
}

IncomingShipments.propTypes = {
  incomingIncomingShipments: PropTypes.arrayOf(PropTypes.object),
  lastRefreshTime: PropTypes.number.isRequired,
  warehouseId: PropTypes.string.isRequired,
}

export default IncomingShipments