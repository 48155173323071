import PropTypes from "prop-types"

// Disable alphabetical ordering to make it easier to scan for pairings (e.g.
// origin + destination)
/* eslint-disable react/sort-prop-types */
export default PropTypes.shape({
  businessType: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  regionId: PropTypes.number.isRequired,
  timeZone: PropTypes.string.isRequired,
  warehouseId: PropTypes.string.isRequired,
  wms: PropTypes.string.isRequired,
})
