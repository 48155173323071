/**
 * The date range selector for incoming shipments
 */

import React from "react";
import useIncomingShipmentsFilters from "../../hooks/use-incoming-shipments-filters";
import Select, { SelectOption } from "@amzn/meridian/select";
import dateRanges, { getDateRangeById } from "../../data/date-ranges";



const IncomingShipmentsDateFilter = () => {
  const { filters, dispatch } = useIncomingShipmentsFilters()
  const onChangeFilter = (filter, makeLabel) => value => {
    if (!value || value.length === 0) {
      dispatch({ type: "REMOVE_FILTER", filter })
    } else {
      dispatch({
        type: "UPDATE_FILTER",
        filter,
        value,
        tagLabel: makeLabel(value),
      })
    }
  }

  return (
    <Select
      value={filters.get("dateRange")?.value || []}
      onChange={onChangeFilter("dateRange", value =>
        value.map(
          dateRange => `Date: ${getDateRangeById(dateRange).label}`
        )
      )}
      id="date-range-select"
      placeholder="Select a date range"
    >
      {dateRanges.map(({ id, label }) => (
        <SelectOption key={id} label={label} value={id} />
      ))}
    </Select>
  )
}

export default IncomingShipmentsDateFilter
