import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams, useLocation } from "react-router-dom";

// Amplify
import { Analytics } from "aws-amplify";

import Select, { SelectOption } from "@amzn/meridian/select";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import PageLayout from "../components/app/page-layout";

import NetworkScreen from "../screens/network-screen";

import useWarehouseData from "../hooks/use-warehouse-data";
import useSelectedOptions from "../hooks/use-selected-options";

// Helpers
import { mapRegion } from "../utilities/map-region";
import { mapNetwork } from "../utilities/map-network";

const NetworkTab = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const routeOptionKey = "/summary/network";
  const [selectedOptions, setSelectedOptions] = useSelectedOptions();

  if (searchParams.get("region") && searchParams.get("country") && searchParams.get("network") && searchParams.get("group")
      && !selectedOptions[routeOptionKey].region && !selectedOptions[routeOptionKey].country
      && !selectedOptions[routeOptionKey].network && !selectedOptions[routeOptionKey].group
      && location.pathname.includes("network")
  ) {
    const newSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
    newSelectedOptions[routeOptionKey] = {
      "region": searchParams.get("region"),
      "country": searchParams.get("country"),
      "network": searchParams.get("network"),
      "group": searchParams.get("group")
    }
    setSelectedOptions(newSelectedOptions);
  } else if (!searchParams.get("region") && !searchParams.get("country") && !searchParams.get("network") && !searchParams.get("group")
      && selectedOptions[routeOptionKey].region && selectedOptions[routeOptionKey].country
      && selectedOptions[routeOptionKey].network && selectedOptions[routeOptionKey].group
      && location.pathname.includes("network")
  ) {
      setSearchParams(
        {
          "region": selectedOptions[routeOptionKey].region,
          "country": selectedOptions[routeOptionKey].country,
          "network": selectedOptions[routeOptionKey].network,
          "group": selectedOptions[routeOptionKey].group
        }
      );
  }

  // NA, EU, FE etc
  const [region, setRegion] = useState(searchParams.get("region") ? searchParams.get("region") : selectedOptions[routeOptionKey].region);
  const [regions, setRegions] = useState();

  // USA, GB, IT etc
  const [country, setCountry] = useState(searchParams.get("country") ? searchParams.get("country") : selectedOptions[routeOptionKey].country);
  const [countries, setCountries] = useState([]);
  // FCs, Stores, Network
  const [network, setNetwork] = useState(searchParams.get("network") ? searchParams.get("network") : selectedOptions[routeOptionKey].network);
  const [networks, setNetworks] = useState([]);
  // Geograpy of the building. Will need to figure this out for AFTLite
  const [group, setGroup] = useState(searchParams.get("group") ? searchParams.get("group") : selectedOptions[routeOptionKey].group);
  const [groups, setGroups] = useState([]);
  const [warehouseIds, setWarehouseIds] = useState([]);

  const warehouseParams = useWarehouseData();

  // Sets Regions when warehouse data is loaded
  useEffect(() => {
    try {
      setRegions([...new Set(warehouseParams.map(({ region_id }) => (mapRegion(region_id))))])
    }
    catch {
      setRegions([])
    }
  }, [setRegions, warehouseParams]);

  // Sets Countries
  useEffect(() => {
    let gatheredCountries = [];
      const sortedParams = warehouseParams ? [...warehouseParams].sort((a,b) => a.country_code.localeCompare(b.country_code)) : [];
      try {
        for (const warehouseParam of sortedParams) {
          if (mapRegion(warehouseParam.region_id) === region || region === "All") {
            gatheredCountries.push(warehouseParam.country_code)
          };
        };
      }
      catch {

      }
    gatheredCountries = [...new Set(gatheredCountries)];
    setCountries(gatheredCountries);
  }, [setCountries, region, warehouseParams]);

  // Sets Networks
  useEffect(() => {
      let gatheredNetworks = [];
      const sortedParams = warehouseParams ? [...warehouseParams].sort((a,b) => a.business_type.localeCompare(b.business_type)) : [];
      try {
        for (const warehouseParam of sortedParams) {
          if (
            (warehouseParam.country_code === country || country === "All")
            && (mapRegion(warehouseParam.region_id) === region || region === "All")
          ) {
            gatheredNetworks.push(mapNetwork(warehouseParam.business_type))
          };
      };
     }
     catch {}
    gatheredNetworks = [...new Set(gatheredNetworks)];
    setNetworks(gatheredNetworks);
  }, [setNetworks, country, warehouseParams, region]);

  // Sets Groups
  useEffect(() => {
    let gatheredGroups = [];
    const sortedParams = warehouseParams ? [...warehouseParams].sort((a,b) => a.geography.localeCompare(b.geography)) : [];
    try {
      for (const warehouseParam of sortedParams) {
        if (
          (mapNetwork(warehouseParam.business_type) === network || network === "All")
          && (warehouseParam.country_code === country || country === "All")
          && (mapRegion(warehouseParam.region_id) === region || region === "All")
        ) {
            // Placeholder for actual groupings
          gatheredGroups.push(warehouseParam.geography)
        };
      };
    }
    catch {}
    gatheredGroups = [...new Set(gatheredGroups)];
    setGroups(gatheredGroups);
  }, [setGroups, network, warehouseParams, region, country]);

  // Sets Groups
  useEffect(() => {
    let gatheredWarehouses = [];
    try {
      for (const warehouseParam of warehouseParams) {
        // Complex if statement incoming
        if (
            (mapRegion(warehouseParam.region_id) === region || region === "All")
            && (warehouseParam.country_code === country || country === "All")
            && (mapNetwork(warehouseParam.business_type) === network || network === "All")
            && (warehouseParam.geography === group || group === "All")
        ) {
          gatheredWarehouses.push(warehouseParam)
        };
      };
    }
    catch {}
    gatheredWarehouses = [...new Set(gatheredWarehouses)];
    setWarehouseIds(gatheredWarehouses);
  }, [region, country, network, group, warehouseParams, setWarehouseIds]);

  // This is the navigation for the select region
  const navigateRegion = useCallback(
    value => {
      const newRegion = value;
      const newSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
      newSelectedOptions[routeOptionKey].region = newRegion;
      setSelectedOptions(newSelectedOptions);
      setSearchParams({"region": newRegion});
      setRegion(newRegion);
      setCountry(undefined);
      setNetwork(undefined);
      setGroup(undefined);
    },
      // eslint-disable-next-line
    [setSearchParams, setRegion, setSelectedOptions]
  );

  // This is the navigation for the select country
  const navigateCountry = useCallback(
    value => {
      const newCountry = value;
      const newSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
      newSelectedOptions[routeOptionKey].region = region;
      newSelectedOptions[routeOptionKey].country = newCountry;
      setSelectedOptions(newSelectedOptions);
      setSearchParams(
        {
          "region": region,
          "country": newCountry
        }
      );
      setCountry(newCountry);
      setNetwork(undefined);
      setGroup(undefined);
    },
      // eslint-disable-next-line
    [setSearchParams, setCountry, region, setSelectedOptions]
  );

  // This is the navigation for the select network
  const navigateNetwork = useCallback(
    value => {
      const newNetwork = value;
      const newSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
      newSelectedOptions[routeOptionKey].region = region;
      newSelectedOptions[routeOptionKey].country = country;
      newSelectedOptions[routeOptionKey].network = newNetwork;
      setSelectedOptions(newSelectedOptions);
      setSearchParams(
        {
          "region": region,
          "country": country,
          "network": newNetwork
        }
      );
      setNetwork(newNetwork);
      setGroup(undefined);
    },
      // eslint-disable-next-line
    [setSearchParams, setNetwork, region, country, setSelectedOptions]
  );

  // This is the navigation for the select group
  const navigateGroup = useCallback(
    value => {
      const newGroup = value;
      const newSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
      newSelectedOptions[routeOptionKey].region = region;
      newSelectedOptions[routeOptionKey].country = country;
      newSelectedOptions[routeOptionKey].network = network;
      newSelectedOptions[routeOptionKey].group = newGroup;
      setSelectedOptions(newSelectedOptions);
      // Record selection
        Analytics.record({ name: 'Summary Network Selection', attributes: newSelectedOptions[routeOptionKey] });
      setSearchParams(
        {
          "region": region,
          "country": country,
          "network": network,
          "group": newGroup
        }
      );
      setGroup(newGroup);
    },
      // eslint-disable-next-line
    [setSearchParams, setGroup, region, country, network, setSelectedOptions]
  );

  return (
    <PageLayout
      title={""}
      loading={!warehouseParams}
      width={"inherit"}
      horizontallySticky={true}
      maxWidth={"none"}
      alignmentHorizontal={"start"}
    >
      <Column>
        <Row>
          { regions &&
            <Column>
              <Text>Region</Text>
              <Select
                value={region}
                onChange={navigateRegion}
                id="region-select"
                placeholder="Select a region"
              >
              { regions.length > 1 && <SelectOption key="All" label="All" value="All"/>}
              {regions.map(region => (
                <SelectOption key={region} label={region} value={region}/>
              ))}
              </Select>
            </Column>
          }
          { countries.length > 0 &&
            <Column>
              <Text>Country</Text>
              <Select
                value={country}
                onChange={navigateCountry}
                id="country-select"
                placeholder="Select a country"
              >
                {countries.length > 1 && <SelectOption key="All" label="All" value="All"/>}
                {countries.map(country => (
                  <SelectOption key={country} label={country} value={country}/>
                 ))}
              </Select>
            </Column>
          }
          { networks.length > 0 &&
            <Column>
              <Text>Network</Text>
              <Select
                value={network}
                onChange={navigateNetwork}
                id="network-select"
                placeholder="Select a network"
              >
                {networks.length > 1 && <SelectOption key="All" label="All" value="All"/>}
                {networks.map(network => (
                  <SelectOption key={network} label={network} value={network}/>
                 ))}
              </Select>
            </Column>
          }
          { groups.length > 0 &&
            <Column>
              <Text>Group</Text>
              <Select
                value={group}
                onChange={navigateGroup}
                id="group-select"
                placeholder="Select a group"
              >
                {groups.length > 1 && <SelectOption key="All" label="All" value="All"/>}
                {groups.map(group => (
                  <SelectOption key={group} label={group} value={group}/>
                 ))}
              </Select>
            </Column>
          }
        </Row>
        { warehouseIds.length > 0 &&
          <NetworkScreen
            warehouseParams={warehouseIds}
          />
        }
      </Column>
    </PageLayout>
  )
}

export default NetworkTab;