/**
 * A single row in the dock received shipments table. Shows details for a single shipment.
 */

import React from "react";
import { TableRow, TableCell } from "@amzn/meridian/table";
import Text from "@amzn/meridian/text";
import dockFullnessPropType from "../../prop-types/dock-fullness";

import useWarehouseData from "../../hooks/use-warehouse-data";
import { getWarehouseAttributes } from "../../utilities/get-warehouse-attributes";

const DockFullnessTableRow = props => {
  const { dockFullness } = props;
  const warehouseId = dockFullness.warehouseId;
  const warehouseParam = getWarehouseAttributes({warehouseId: warehouseId, warehouseParams: useWarehouseData()})
  const tempZoneMap = {
    "AMBIENT": warehouseParam.ambient_nyr_pallets,
    "CHILLED": warehouseParam.chilled_nyr_pallets,
    "FROZEN": warehouseParam.frozen_nyr_pallets
  };
  const tempZonePallets = tempZoneMap[dockFullness.tempZone] ? tempZoneMap[dockFullness.tempZone] : 0;
  return (
    <TableRow key={dockFullness.tempZone + dockFullness.day} rowComponents={props.rowComponents}>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {dockFullness.tempZone}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {dockFullness.units ? dockFullness.units.toLocaleString() : 0}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {tempZonePallets ? tempZonePallets.toLocaleString() : 0}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {dockFullness.estimatedPallets ? dockFullness.estimatedPallets.toLocaleString() : 0}
        </Text>
      </TableCell>
        <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {(Math.max((tempZonePallets ? tempZonePallets : 0) - (dockFullness.estimatedPallets ? dockFullness.estimatedPallets : 0), 0)).toLocaleString()}
        </Text>
      </TableCell>
    </TableRow>
  );
};

DockFullnessTableRow.propTypes = {
  dockFullness: dockFullnessPropType.isRequired,
};

export default DockFullnessTableRow;
