/**
 * This component renders an "empty" table illustration 
 */

import React from "react"
import PropTypes from "prop-types"
import Column from "@amzn/meridian/column"
import Text from "@amzn/meridian/text"
import HorizontalStickyContainer from "../app/horizontal-sticky-container"
// import { ReactComponent as EmptyStateIllustration } from "../../assets/marauders-map.svg"

const InstockEmpty = props => {
  return (
    <HorizontalStickyContainer
      Component={Column}
      alignmentHorizontal="center"
      spacingInset="400 none none none"
    >
    {props.searching ? (
      <Column alignmentHorizontal="center">
        {/*<EmptyStateIllustration />*/}
        <Text tag="p" alignment="center">
          No Instock Risk shipments found matching the given criteria.
          <br />
          Try a different search
        </Text>
      </Column>
    ) : (
      <Column alignmentHorizontal="center">
        {/*<EmptyStateIllustration />*/}
        <Text>
          You have no instock risk shipments.
        </Text>
      </Column>
    )}
    </HorizontalStickyContainer>
  )
}

InstockEmpty.propTypes = {
  searching: PropTypes.bool,
}

InstockEmpty.defaultProps = {
  searching: false,
}

export default InstockEmpty
