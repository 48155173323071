/**
 * All "My shipment" table columns. Each column has an `id` (used as a key and for
 * sorting), `label` (the header column text), `width` (how wide the column should
 * be in pixels), and `alignment` (which side the column's content "sticks" to).
 */

const networkPODefectsTableColumns = [
  {
    id: "warehouseId",
    label: "Site Id",
    width: 95,
    alignment: "center",
  },
  {
    id: "defects",
    label: "Defects",
    width: 230,
    alignment: "center"
  },
  {
    id: "orderId",
    label: "Load ID",
    width: 95,
    alignment: "center",
  },
  {
    id: "source",
    label: "Source",
    width: 95,
    alignment: "center",
  },
  {
    id: "firstAppointmentTime",
    label: "ScheduledArrival",
    width: 165,
    alignment: "center",
  },
  {
    id: "firstDockReceiveTime",
    label: "Dock Received",
    width: 165,
    alignment: "center",
  },
  {
    id: "firstDockReceiveBy",
    label: "Checked in by",
    width: 95,
    alignment: "center",
  },
  {
    id: "handoffWindowStartDateUTC",
    label: "EVDD",
    width: 165,
    alignment: "center",
  },
  {
    id: "handoffWindowEndDateUTC",
    label: "LVDD",
    width: 165,
    alignment: "center",
  },
  {
    id: "firstReceiveTime",
    label: "First Received",
    width: 165,
    alignment: "center",
  },
  {
    id: "confirmedUnits",
    label: "Confirmed Units",
    width: 95,
    alignment: "center",
  },
  {
    id: "receivedUnits",
    label: "Received Units",
    width: 95,
    alignment: "center",
  },
  {
    id: "reconcileDuration",
    label: "Dwell Days",
    width: 95,
    alignment: "center",
  },
  // {
  //   id: "isqGreaterThan",
  //   label: "ISQ greater than 10%",
  //   width: 95,
  //   alignment: "center",
  // },
  {
    id: "appointmentScheduledPostLvdd",
    label: "Appointment Scheduled Post LVDD",
    width: 95,
    alignment: "center",
  }
]

export const defaultNetworkPODefectsTableColumns = networkPODefectsTableColumns.reduce(
  (columns, column) => ({
    ...columns,
    [column.id]: true,
  }),
  {}
)

export const getNetworkPODefectsTableColumnById = id =>
  networkPODefectsTableColumns.find(column => column.id === id)

export default networkPODefectsTableColumns
