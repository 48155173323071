/**
 * All "My shipment" table columns. Each column has an `id` (used as a key and for
 * sorting), `label` (the header column text), `width` (how wide the column should
 * be in pixels), and `alignment` (which side the column's content "sticks" to).
 */

const incomingShipmentsTableColumns = [
  {
    id: "loadType",
    label: "Load Type",
    width: 95,
    alignment: "center",
  },
  {
    id: "source",
    label: "Source",
    width: 95,
    alignment: "center",
  },
  {
    id: "loadId",
    label: "Load ID",
    width: 95,
    alignment: "center",
  },
  {
    id: "tempZone",
    label: "Temp Profile",
    width: 95,
    alignment: "center",
  },
  {
    id: "criticalAsinEvents",
    label: "Events",
    width: 95,
    alignment: "center"
  },
  {
    id: "status",
    label: "Status",
    width: 95,
    alignment: "center",
  },
  {
    id: "scheduledArrivals",
    label: "Scheduled Arrival",
    width: 170,
    alignment: "center",
  },
  {
    id: "expectedArrivalTime",
    label: "Expected Arrival",
    width: 170,
    alignment: "center"
  },
  {
    id: "arrivalDatetime",
    label: "Actual Arrival",
    width: 170,
    alignment: "center",
  },
  {
    id: "expectedUnits",
    label: "Expected Units",
    width: 95,
    alignment: "center",
  },
  {
    id: "expectedAsrsUnits",
    label: "Expected Automation Units",
    width: 95,
    alignment: "center",
  },
  {
    id: "expectedAsrsUnitsPercent",
    label: "Expected Automation Units %",
    width: 95,
    alignment: "center",
  },
  {
    id: "expectedPallets",
    label: "Expected Pallets (approx)",
    width: 95,
    alignment: "center",
  },
  // {
  //   id: "expectedUnitSource",
  //   label: "Expected Unit Source",
  //   width: 95,
  //   alignment: "start",
  // },
  {
    id: "shipmentId",
    label: "Shipment ID",
    width: 95,
    alignment: "center",
  },
  {
    id: "ocrVendor",
    label: "OCR vendor (Y/N)",
    width: 95,
    alignment: "center",
  },
  {
    id: "scac",
    label: "SCAC",
    width: 95,
    alignment: "center",
  }
]

export const defaultIncomingShipmentsTableColumns = incomingShipmentsTableColumns.reduce(
  (columns, column) => ({
    ...columns,
    [column.id]: true,
  }),
  {}
)

export const getIncomingShipmentsTableColumnById = id =>
  incomingShipmentsTableColumns.find(column => column.id === id)

export default incomingShipmentsTableColumns
