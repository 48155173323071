/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "0818936b1e59426ab89aa03a73bca7b3",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "0818936b1e59426ab89aa03a73bca7b3",
            "region": "us-east-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "IBMaraudersMapRest",
            "endpoint": "https://8xfb672y3m.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://4ul2x53hwndkbnz6zaasxbsejq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-xsjf4nbd4jf7zn2oxuzrdblqtq",
    "aws_cognito_identity_pool_id": "us-east-1:e1330c10-b061-45ef-8f51-686c0f41be78",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_89DVfn8yB",
    "aws_user_pools_web_client_id": "rd960l5h7764kt7oreu56bpf0",
    "oauth": {
        "domain": "iris-ibmm-afg-midway-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://dev-dsk-kcrich-1e-696087e8.us-east-1.amazon.com:3000/",
        "redirectSignOut": "https://dev-dsk-kcrich-1e-696087e8.us-east-1.amazon.com:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
