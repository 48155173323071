// /**
//  * This module exports a function that filters an array of shipments according
//  * to a Map of filters.
//  */
//
import { getDateRangeById } from "../data/date-ranges";
import { getStartOfDay } from "../utilities/get-start-of-day";
import { searchBarFilter } from "../utilities/search-bar-filter";
import defaultIncomingShipmentsTableColumns from "../data/incoming-shipments-table-columns";

/**
 * Returns a function that checks if an object's value is in an array of options.
 * @example
 * const checkFoo = isInOptions("foo")
 * const object = { foo: "bar" }
 * checkFoo(object, ["bar", "baz"])  // returns true
 * checkFoo(object, ["baz"]) // returns false
 */
const isInOptions = key => (obj, options) => options.includes(obj[key])

// An object of filter applications where each key points to a function
// that expects `shipment` and `value` to consider
const applyFilter = {
  loadType: isInOptions("loadType"),
  source: ({ source }, value) => source.toLowerCase().includes(value.toLowerCase()),
  loadId: ({ loadId }, value) => loadId.toLowerCase().includes(value.toLowerCase()),
  tempZone: isInOptions("tempZone"),
  status: isInOptions("status"),
  shipmentId: ({ shipmentId }, value) => shipmentId.toLowerCase().includes(value.toLowerCase()),
  ocrVendor: isInOptions("ocrVendor"),
  scac: ({ scac }, value) => scac.toLowerCase().includes(value.toLowerCase()),
  dateRange: (shipment, value) => {
    for (const dateRange of value) {
      // If any date is in the range selected, return true
      const { max } = getDateRangeById(dateRange)
      for (const shipmentIndex in shipment.scheduledArrivals) {
      if (shipment.scheduledArrivals[shipmentIndex] >= getStartOfDay(shipment.timeZone, max - 1)
          && shipment.scheduledArrivals[shipmentIndex] < getStartOfDay(shipment.timeZone, max)) {
        return true
      }
    }
    }

    return false
  },
  asrsEligible: (item, value) => {
  
    if(value.length === 2) {
      return true;
    } 
    else {
      if(value[0]==='Y' && item.asrsConfirmedUnits >0 ){
        return true;
      }
      else if(value[0]==='N' && item.asrsConfirmedUnits === 0 ){
        return true;
      }
    }
    return false
  },
  allFields: (shipment, value) => {
    // If any field passes, return true
    const searchableFields = defaultIncomingShipmentsTableColumns.map(column => column.id);
    const dateKeys = ["scheduledArrivals", "expectedArrivalTime", "arrivalDatetime"];
    return searchBarFilter(value, shipment, dateKeys, searchableFields);
  },
}

/**
 * This function filters an array of shipments according to a Map of filters.
 *
 * @param  incomingShipments          Array of incomingShipment objects (see `src/prop-types/incoming-shipment` to see shape)
 * @param  filters            Map of filter objects (see `src/hooks/use-shipments-filters`)
 * @return filteredShipments  Array of filtered shipments
 */
const filterIncomingShipments = (incomingShipments, filters) => {
  if (filters.size === 0) return incomingShipments
  let filteredIncomingShipments = [...incomingShipments]
  // Loop through and apply filter in the Map to the incomingShipments array
  for (const filter of filters) {
    const [key, { value }] = filter
    filteredIncomingShipments = filteredIncomingShipments.filter(incomingShipment =>
      applyFilter[key](incomingShipment, value)
    )
  }
  return filteredIncomingShipments
}

export default filterIncomingShipments
