/**
 * SLA exceptions for shipments
 */

// exceptionTypes = one of:
//   "theme"
//   "neutral"
//   "success"
//   "warning"
//   "error"
const exceptions = [
  { id: "nearSLA", label: "Near SLA", type: "warning" },
  { id: "pastSLA", label: "Past SLA", type: "error" },
  { id: "late", label: "Late", type: "error"},
  { id: "splitPO", label: "Split PO", type: "neutral"},
  { id: "suppressed", label: "Suppressed", type: "error"},
  { id : "arrived", label: "Arrived", type: "warning"},
  { id: "noos", label: "NOOS OOS", type: "error"}
]

export const getExceptionById = id =>
  exceptions.find(exception => exception.id === id)
export const allExceptionIds = exceptions.map(({ id }) => id)

export default exceptions
