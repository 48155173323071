import React from "react";
import PropTypes from "prop-types";
import Heading from "@amzn/meridian/heading";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";

import NetworkNYRBreakdownPie from "../../components/network-nyr-breakdown/network-nyr-breakdown-pie";

const NetworkNYRBreakdown = props => {
  const { data } = props;

  return (
    <Column>
    <Heading level={2}>NYR</Heading>
      <Row>
        {Object.entries(data).map(([chartTitle, chartData]) =>
          <NetworkNYRBreakdownPie
            chartData={chartData}
            chartTitle={chartTitle}
          />
        )}
      </Row>
    </Column>
  );
};

NetworkNYRBreakdown.props = {
  data: PropTypes.arrayOf(PropTypes.object)
}

export default NetworkNYRBreakdown;