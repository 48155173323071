/**
 * "Dock Received" page - view incoming POs and Transshipments
 */

import React, {
  useRef,
  useState,
  useEffect,
} from "react"
import PropTypes from "prop-types"

import Badge from "@amzn/meridian/badge";
import Button from "@amzn/meridian/button"
import Column from "@amzn/meridian/column"
import Divider from "@amzn/meridian/divider"
import Heading from "@amzn/meridian/heading"
import Icon from "@amzn/meridian/icon"
import Row from "@amzn/meridian/row"
import Text from "@amzn/meridian/text"
import minusCircleTokens from "@amzn/meridian-tokens/base/icon/minus-circle";
import plusCircleTokens from "@amzn/meridian-tokens/base/icon/plus-circle";



import PageLayout from "../components/app/page-layout"
import DockReceivedTable from "../components/dock-received/dock-received-table"
import TableFooter from "../components/app/table-footer"
import DockReceivedEmpty from "../components/dock-received/dock-received-empty"
import DockReceivedActionBar from "../components/dock-received/dock-received-action-bar"
import FilterTags from "../components/dock-received/filter-tags"
import usePagination from "../hooks/use-pagination"
import useDockReceivedFilters from "../hooks/use-dock-received-filters"
import useDockReceived from "../hooks/use-dock-received"
import usePageLayoutContentRectangle from "../hooks/use-page-layout-content-rectangle"
import dockReceivedPropType from "../prop-types/dock-received"
import DockReceivedNotifications from "../components/dock-received/dock-received-notifications"
import useWarehouseData from "../hooks/use-warehouse-data";
import DockFullnessTable from "../components/dock-fullness/dock-fullness-table";

import { convertUnix } from "../utilities/convert-unix";
import { getWarehouseAttributes } from "../utilities/get-warehouse-attributes";
import { resultsPerPageOptions } from "../components/Constants";
import Tooltip from "@amzn/meridian/tooltip";


const getTableWidth = wrapperElement => {
  try {
    return wrapperElement.querySelector("table").clientWidth
  } catch (_) {
    return undefined
  }
}

const DockReceived = props => {
  const { dockFullness, incomingDockReceived, lastRefreshTime, notifications, warehouseId, dcEscalations, getDCEscalations } = props
  const { filters } = useDockReceivedFilters()
  const { width: contentWidth = 1200 } = usePageLayoutContentRectangle()
  const tableWrapperRef = useRef()
  const [resultsPerPage, setResultsPerPage] = useState("All")
  const [selectedResultsPerPage, setSelectedResultsPerPage] = useState("All")
  const [alarmsVisible, setAlarmsVisible] = useState(false)
  const [renderedNotifications, setRenderedNotifications] = useState(<DockReceivedNotifications notifications={notifications} warehouseId={warehouseId}/>)

  // Need to count unfiltered so filters do not affect count
  const totalSuppressions = incomingDockReceived ? incomingDockReceived.filter(x => x.suppression === true).length : 0

  const dockReceived = useDockReceived(incomingDockReceived, filters)

  const { time_zone } = getWarehouseAttributes({ warehouseId: warehouseId, warehouseParams: useWarehouseData() });

  let totalExpectedUnits = 0;
  let totalReceivedUnits = 0;
  if (dockReceived) {
    for (const Shipment of dockReceived) {
      totalExpectedUnits += Math.max(Shipment.confirmedUnits, 0);
      totalReceivedUnits += Math.max(Shipment.receivedUnits, 0);
    }
  }
  const totalNYR = totalExpectedUnits - totalReceivedUnits;

  const pagination = usePagination({
    numberOfItemsPerPage: resultsPerPage,
    numberOfResults: dockReceived?.length || 0,
  })
  const tableWidth = getTableWidth(tableWrapperRef.current)
  const isSticky = contentWidth < tableWidth

  const { visibleIndices } = pagination

  const onChangeResultsPerPage = resultsPerPage => {
    // Update local state variable
    setSelectedResultsPerPage(resultsPerPage)
    if (resultsPerPage === "All") {
      setResultsPerPage(dockReceived?.length || 0)
    } else {
      setResultsPerPage(resultsPerPage)
    }
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    pagination.onChange(1)
  }

  // Set pagination back to page 1 when filters change
  useEffect(() => {
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    onChangeResultsPerPage(selectedResultsPerPage)
    // We disable the exhaustive-deps hooks rule here because including `pagination`
    // will cause the effect to be called over-and-over.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filters, dockReceived])

  useEffect(() => {
    setRenderedNotifications(<DockReceivedNotifications notifications={notifications} warehouseId={warehouseId}/>)
  }, [setRenderedNotifications, notifications, warehouseId])
  
  return (
    <PageLayout
      loading={!dockReceived}
      width={dockReceived ? "inherit" : undefined}
      horizontallySticky={isSticky}
      maxWidth={isSticky ? "none" :  undefined}
      alignmentHorizontal={isSticky ? "start" : "center"}
    >
        <Column spacing="300">
          <Row>
            <Heading level={2}>Alarms</Heading>
              <Badge value={notifications.pastSLA.length + notifications.nearSLA.length} type="error" />
            <Tooltip
                position="end"
                title={alarmsVisible ? "Hide alarms" : "Show alarms"}
                id={1}
            >
              <Button
                type="icon"
                size="small"
                onClick={() => {
                  alarmsVisible ? setAlarmsVisible(false) : setAlarmsVisible(true)
                }}
              >
                {alarmsVisible ? (
                  <Icon tokens={minusCircleTokens}>Collapse rows</Icon>
                  ) : (
                    <Icon tokens={plusCircleTokens}>Expand rows</Icon>
                  )
                }
              </Button>
            </Tooltip>
          </Row>
          {alarmsVisible && renderedNotifications}
          <br/>
          <Divider/>
          <br/>
          <Column>
            <Row><Heading level={2}>Details</Heading></Row>
            <Row><Text type={"b100"}>Last Refresh: {convertUnix(lastRefreshTime, time_zone)}</Text></Row>
            <Row>
              <DockReceivedActionBar
                stick={isSticky}
                dockReceived={dockReceived}
                notifications={notifications}
                totalSuppressions={totalSuppressions}
                totalExpectedUnits={totalExpectedUnits}
                totalReceivedUnits={totalReceivedUnits}
                totalNYR={totalNYR}
                warehouseId={warehouseId}
                dcEscalations={dcEscalations}
                getDCEscalations={getDCEscalations}
              />
            </Row>
            <Row><FilterTags stick={isSticky} /></Row>
            <Row
              spacingInset={isSticky ? "none 500 none none" : undefined}
              ref={tableWrapperRef}
            >

              <DockReceivedTable
                dockReceived={dockReceived}
                visibleIndices={visibleIndices}
                notifications={notifications}
                warehouseId={warehouseId}
                dcEscalations={dcEscalations}
                getDCEscalations={getDCEscalations}
              />
            </Row>

            {dockReceived && dockReceived.length === 0 ? (
              <Row><DockReceivedEmpty searching={Boolean(filters.size)} /></Row>
            ) : null}
            {dockReceived && dockReceived.length > 0 ? (
              <Row>
                <TableFooter
                  pagination={pagination}
                  stick={false}
                  resultsPerPage={resultsPerPage}
                  onChangeResultsPerPage={onChangeResultsPerPage}
                  resultsPerPageOptions={resultsPerPageOptions}
                  selectedResultsPerPage={selectedResultsPerPage}
                />
              </Row>
              ) : null
            }
          </Column>
          <br/>
          <Divider/>
          <br/>
          <Column>
            <Row><Heading level={2}>Dock Fullness</Heading></Row>
            <Row><DockFullnessTable dockFullness={dockFullness} warehouseId={warehouseId}/></Row>
          </Column>
        </Column>
    </PageLayout>
  )
}

DockReceived.propTypes = {
  dockFullness: PropTypes.arrayOf(PropTypes.object),
  incomingDockReceived: PropTypes.arrayOf(dockReceivedPropType),
  lastRefreshTime: PropTypes.number.isRequired,
  notifications: PropTypes.object,
  warehouseId: PropTypes.string.isRequired,
  dcEscalations: PropTypes.arrayOf(PropTypes.object),
  getDCEscalations: PropTypes.func
}

export default DockReceived