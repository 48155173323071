/**
 * This component renders an "empty" table illustration
 */

import React from "react"
import PropTypes from "prop-types"
import Column from "@amzn/meridian/column"
import Text from "@amzn/meridian/text"
import HorizontalStickyContainer from "../app/horizontal-sticky-container"
// import { ReactComponent as EmptyStateIllustration } from "../../assets/marauders-map.svg"

const EmptyTable = props => {
  return (
    <HorizontalStickyContainer
      Component={Column}
      alignmentHorizontal="center"
      spacingInset="400 none none none"
    >
      {props.searching ? (
        <Column alignmentHorizontal="center">
          {/*<EmptyStateIllustration />*/}
          <Text tag="p" alignment="center">
            No data found matching the given criteria.
            <br />
            Try a different search
          </Text>
        </Column>
      ) : (
        <Column alignmentHorizontal="center">
          {/*<EmptyStateIllustration />*/}
          <Text>
            No data.{" "}
            .
          </Text>
        </Column>
      )}
    </HorizontalStickyContainer>
  )
}

EmptyTable.propTypes = {
  searching: PropTypes.bool,
}

EmptyTable.defaultProps = {
  searching: false,
}

export default EmptyTable
