/**
 * A Modal for customizing the "Dock Received" view.
 */

import React, { useState } from "react"
import { css } from "emotion"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import Icon from "@amzn/meridian/icon"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import Tooltip from "@amzn/meridian/tooltip"
import Checkbox from "@amzn/meridian/checkbox"
import Text from "@amzn/meridian/text"
import { useTheme } from "@amzn/meridian/theme"
import viewIconTokens from "@amzn/meridian-tokens/base/icon/view"
import modalTokens from "@amzn/meridian-tokens/component/modal"
import useDockReceivedViewPreferences from "../../hooks/use-dock-received-view-preferences"
import dockReceivedTableColumns from "../../data/dock-received-table-columns"

const fieldsetResetStyles = css({
  border: 0,
  padding: 0,
  margin: 0,
  "& legend": {
    padding: 0,
  },
})

const DockReceivedViewPreferences = () => {
  // Create a local state variable to handle the Modal's open state
  const [modalOpen, setModalOpen] = useState(false)
  // Load preferences from context
  const [savedPreferences, setSavedPreferences] = useDockReceivedViewPreferences()
  // Create a local state variable to store local changes from the saved preferences
  const [localPreferences, setLocalPreferences] = useState(savedPreferences)
  // Get the Meridian Modal footer border color and width. Used to create the
  // vertical rule separating the two columns in the Modal.
  const {
    tokens: { modalFooterBorderColor, modalFooterBorderWidth },
  } = useTheme(modalTokens, "modal")

  const isCardView = localPreferences.get("view") === "card"

  // Helper function for updating local values
  const updateLocalValue = id => value => {
    // Create a clone of the previous preferences
    const nextLocalPreferences = new Map(localPreferences)
    // Update the preference with the new value
    nextLocalPreferences.set(id, value)
    setLocalPreferences(nextLocalPreferences)
  }

  // Called when the user presses "Update preferences" button. Close modal and
  // save the local values
  const onUpdatePreferences = () => {
    setModalOpen(false)
    setSavedPreferences(localPreferences)
  }

  // Called when the user presses "Cancel" button. Close modal and reset local
  // preferences to the saved preferences
  const onCancel = () => {
    setModalOpen(false)
    setLocalPreferences(savedPreferences)
  }

  // Called when the user toggles a table column's visibility.
  const toggleColumn = columnId => value =>
    updateLocalValue("columns")({
      ...localPreferences.get("columns"),
      [columnId]: value,
    })

  return (
    <React.Fragment>
      <Tooltip title="Viewing preferences" position="top">
        <Button
          onClick={() => setModalOpen(true)}
          type="icon"
          iconTokens={viewIconTokens}
        >
          <Icon tokens={viewIconTokens}>Viewing preferences</Icon>
        </Button>
      </Tooltip>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        position="bottom"
        title="Viewing preferences"
        bodySpacingInset="none"
      >
        <Row
          alignmentVertical="stretch"
          widths={["fill", "fit", "fill"]}
          spacing="none"
          maxWidth={715}
        >
          <Column spacingInset="500">
            <Column
              tag="fieldset"
              className={fieldsetResetStyles}
              spacing="300"
            >
              <Text tag="legend" type="h100">
                Visible columns
              </Text>
              <Text type="b200" color="secondary">
                Show or hide columns by selecting or deselecting from the list
                below.
              </Text>
              {dockReceivedTableColumns.map(({ id, label }) => (
                <Checkbox
                  key={id}
                  value={id}
                  name="visible-columns"
                  checked={localPreferences.get("columns")[id]}
                  onChange={toggleColumn(id)}
                  disabled={isCardView}
                >
                  {label}
                </Checkbox>
              ))}
            </Column>
          </Column>
          <div
            style={{
              width: modalFooterBorderWidth,
              background: modalFooterBorderColor,
            }}
          />
          <Column spacing="450" spacingInset="500">
            {/** Show stripes **/}
            <Column spacing="300">
              <Text type="h100">Show stripes</Text>
              <Text type="b200" color="secondary" id="show-stripes-description">
                Add background shading to alternating rows
              </Text>
              <Checkbox
                checked={localPreferences.get("stripes")}
                onChange={updateLocalValue("stripes")}
                aria-describedby="show-stripes-description"
                disabled={isCardView}
              >
                Show stripes
              </Checkbox>
            </Column>
          </Column>


        </Row>
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button type="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" onClick={onUpdatePreferences}>
              Update preferences
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default DockReceivedViewPreferences
