import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import Row from "@amzn/meridian/row";
import NotificationsTable from "./notifications/notifications-table";
import TableFooter from "../app/table-footer";
import Column from "@amzn/meridian/column";
import {defaultResultsPerPage, resultsPerPageOptions} from "../Constants";
import usePagination from "../../hooks/use-pagination";
import useNotificationsFilters from "../../hooks/use-notifications-filters";
import useFilters from "../../hooks/use-filters";
import NotificationsEmpty from "./notifications/notifications-empty";
import filterNotifications from "../../filters/filter-notifications";
import NotificationsActionBar from "./notifications/notifications-action-bar";


const DockReceivedNotifications = props => {
  const { notifications, warehouseId } = props;
  const { filters } = useNotificationsFilters()
  const [resultsPerPage, setResultsPerPage] = useState(defaultResultsPerPage)
  const [selectedResultsPerPage, setSelectedResultsPerPage] = useState(defaultResultsPerPage)

  let notificationArray = [];
  for (const alarmCategory in notifications) {
    for (const shipment of notifications[alarmCategory]) {
      shipment.alarmCategory = alarmCategory
      notificationArray.push(shipment)
    }
  }

  const filteredNotifications = useFilters(notificationArray, filterNotifications, filters);

  const pagination = usePagination({
    numberOfItemsPerPage: resultsPerPage,
    numberOfResults: filteredNotifications?.length || 0,
  })

  const { visibleIndices } = pagination

  const onChangeResultsPerPage = resultsPerPage => {
    // Update local state variable
    setSelectedResultsPerPage(resultsPerPage)
    if (resultsPerPage === "All") {
      setResultsPerPage(filteredNotifications?.length || 0)
    } else {
      setResultsPerPage(resultsPerPage)
    }
  }

  // Set pagination back to page 1 when filters change
  useEffect(() => {
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    onChangeResultsPerPage(selectedResultsPerPage)
    // We disable the exhaustive-deps hooks rule here because including `pagination`
    // will cause the effect to be called over-and-over.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filters, filteredNotifications])

  return (
      <Column>
        <Row><NotificationsActionBar notifications={filteredNotifications} /></Row>
        <Row>
          <NotificationsTable
            notifications={filteredNotifications}
            visibleIndices={visibleIndices}
            warehouseId={warehouseId}
          />
        </Row>
        {filteredNotifications && filteredNotifications.length > 0 ? (
              <Row>
                <TableFooter
                  pagination={pagination}
                  stick={false}
                  resultsPerPage={resultsPerPage}
                  onChangeResultsPerPage={onChangeResultsPerPage}
                  resultsPerPageOptions={resultsPerPageOptions}
                  selectedResultsPerPage={selectedResultsPerPage}
                />
              </Row>
              ) : <NotificationsEmpty/>
            }
      </Column>
  )
};

DockReceivedNotifications.propTypes = {
  notifications: PropTypes.object,
  warehouseId: PropTypes.string.isRequired,
}

export default DockReceivedNotifications;