import { shipmentQuantityKeys } from "../components/Constants";

const { vendorConfirmedUnits, vendorReceivedUnits} = shipmentQuantityKeys;

function findNetworkPoDefects(pastShipments: Array<any>) {

  const mapDefects = (defects) => (defects.map(shipmentMap => {
        shipmentMap.poItemSummary = typeof shipmentMap.poItemSummary === "string" ? JSON.parse(shipmentMap.poItemSummary)
            : typeof shipmentMap.poItemSummary === "object" ? shipmentMap.poItemSummary
                : {totalQuantityConfirmed: 0, totalQuantityReceived: 0};
        shipmentMap.confirmedUnits = shipmentMap.poItemSummary[vendorConfirmedUnits];
        shipmentMap.receivedUnits = shipmentMap.poItemSummary[vendorReceivedUnits];
        return shipmentMap;
      }));

  const defectMap =  {
      receiveBeforeLVDD: pastShipments.filter(pastShipment =>
          (pastShipment.firstReceiveTime < pastShipment.handoffWindowStartDateUTC
            || pastShipment.firstDockReceiveTime < pastShipment.handoffWindowStartDateUTC)
          && pastShipment.orderId.startsWith("PO")
        ),
      receiveBeforeDockReceive: pastShipments.filter(pastShipment =>
        pastShipment.firstReceiveTime < pastShipment.firstDockReceiveTime &&
          pastShipment.orderId.startsWith("PO")
        )
  };

  const defectDescriptionMap = {
      receiveBeforeLVDD: {
          id: "receiveBeforeLVDD",
          title: "Receive Before LVDD",
          description: "Dock receive time or first receive against PO is before LVDD"
      },
      receiveBeforeDockReceive: {
          id: "receiveBeforeDockReceive",
          title: "Receive Before Dock Receive",
          description: "First receive against PO is before dock receive"
      }
  };

  let networkPODefectMap = {};

  for (const defect in defectMap) {
      for (const shipmentDefect of defectMap[defect]) {
          const orderId = shipmentDefect.orderId;
          const editShipment = networkPODefectMap[orderId] ? networkPODefectMap[orderId] : shipmentDefect;
          const shipmentDefects = editShipment.defectDetails ? editShipment.defectDetails : {};
          shipmentDefects[defect] = defectDescriptionMap[defect];
          editShipment.defectDetails = shipmentDefects;
          editShipment.defects = Object.keys(shipmentDefects).sort();
          networkPODefectMap[orderId] = editShipment;
      }
  }

  return mapDefects(Object.values(networkPODefectMap));

}

export { findNetworkPoDefects };