/**
 * A context-based hook that allows components to determine the view of the
 * "Incoming shipments" page. The API is the same as useContext.
 */

import React, { useState, useCallback, useContext, createContext } from "react"
import PropTypes from "prop-types"
import useLocalStorage from "./use-local-storage"
import { defaultIncomingShipmentsTableColumns } from "../data/incoming-shipments-table-columns"

const IncomingShipmentsViewPreferencesContext = createContext()

export const IncomingShipmentsViewPreferencesProvider = props => {
  // Get shipment view preferences out of local storage
  const [savedPreferences, persistPreferences] = useLocalStorage(
    "incoming-shipments-view-preferences"
  )

  // Initialize state value falling back to the default values defined above
  const [preferences, setPreferences] = useState(
    // Revive preference map from local storage if it exists
    savedPreferences
      ? new Map(savedPreferences)
      : new Map([
          ["stripes", true],
          ["columns", defaultIncomingShipmentsTableColumns],
        ])
  )

  // When the user changes the preferences, persist the value to local storage
  // and update the state value
  const changePreferences = useCallback(
    values => {
      setPreferences(values)
      // Convert the values Map to an array when putting into local storage
      // so it can be serialized with JSON.stringify
      persistPreferences(Array.from(values.entries()))
    },
    [persistPreferences]
  )

  return (
    <IncomingShipmentsViewPreferencesContext.Provider
      value={[preferences, changePreferences]}
    >
      {props.children}
    </IncomingShipmentsViewPreferencesContext.Provider>
  )
}

IncomingShipmentsViewPreferencesProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useIncomingShipmentsViewPreferences = () =>
  useContext(IncomingShipmentsViewPreferencesContext)

export default useIncomingShipmentsViewPreferences