const instockTableColumns = [
    {
      id: "alarm",
      label: "Alarm",
      width: 10,
      alignment: "center",
    },
    {
      id: "id",
      label: "Load ID",
      width: 100,
      alignment: "center",
    },
    {
      id: "firstDockReceiveTime",
      label: "Dock Received",
      width: 150,
      alignment: "center",
    },
    {
      id: "oos_asin_category",
      label: "OOS ASIN Overview",
      width: 250,
      alignment: "center",
    },
    {
      id: "oos_quantity",
      label: "OOS ASIN Count",
      width: 100,
      alignment: "center",
    },
    {
      id: "oos_risk_quantity",
      label: "OOS Risk ASIN Count",
      width: 100,
      alignment: "center",
    },
    {
      id: "vbi",
      label: "VBI CTM (bps)",
      width: 50,
      alignment: "center",
    },
    {
      id: "details",
      label: "Details",
      width: 150,
      alignment: "center",
    },
  
  ]
  
  export const defaultInstockTableColumns = instockTableColumns.reduce(
    (columns, column) => ({
      ...columns,
      [column.id]: true,
    }),
    {}
  )
  
  export const getinstockTableColumnById = id =>
    instockTableColumns.find(column => column.id === id)
  
  export default instockTableColumns