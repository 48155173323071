// /**
//  * This module exports a function that filters an array of shipments according
//  * to a Map of filters.
//  */
//

/**
 * Returns a function that checks if an object's value is in an array of options.
 * @example
 * const checkFoo = isInOptions("foo")
 * const object = { foo: "bar" }
 * checkFoo(object, ["bar", "baz"])  // returns true
 * checkFoo(object, ["baz"]) // returns false
 */

import { searchBarFilter } from "../utilities/search-bar-filter";
import defaultIOLTableColumns from "../data/iol-table-columns";
const isInOptions = key => (obj, options) => options.includes(obj[key])

// An object of filter applications where each key points to a function
// that expects `shipment` and `value` to consider
const applyFilter = {
  allFields: (iol, value) => {
    // If any field passes, return true
    const searchableFields = defaultIOLTableColumns.map(column => column.id);
    const dateKeys = [];
    return searchBarFilter(value, iol, dateKeys, searchableFields);
  },
  location: ({ location }, value) => location.toLowerCase().includes(value.toLowerCase()),
  tempZone: isInOptions("tempZone"),
}

/**
 * This function filters an array of shipments according to a Map of filters.
 *
 * @param  iol          Array of iol objects (see `src/prop-types/incoming-shipment` to see shape)
 * @param  filters            Map of filter objects (see `src/hooks/use-shipments-filters`)
 * @return filteredShipments  Array of filtered shipments
 */
const filterIOL = (iol, filters) => {
  if (filters.size === 0) return iol
  let filteredIOL = [...iol]
  // Loop through and apply filter in the Map to the iol array
  for (const filter of filters) {
    const [key, { value }] = filter
    filteredIOL = filteredIOL.filter(iol =>
      applyFilter[key](iol, value)
    )
  }
  return filteredIOL
}

export default filterIOL
