import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Heading from "@amzn/meridian/heading";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";

import usePagination from "../../hooks/use-pagination";
import useFilters from "../../hooks/use-filters";
import useNetworkPODeepDiveFilters from "../../hooks/use-network-po-deep-dive-filters";
import NetworkPODeepDiveActionBar from "../../components/network-po-deep-dive/network-po-deep-dive-action-bar";
import NetworkPODeepDiveTable from "../../components/network-po-deep-dive/network-po-deep-dive-table";
import TableFooter from "../../components/app/table-footer";
import EmptyTable from "../../components/app/empty-table";
import filterNetworkPODeepDive from "../../filters/filter-network-po-deep-dive";
import FilterTags from "../../components/app/filter-tags";
import useWarehouseData from "../../hooks/use-warehouse-data";
import { parseFalconTrout } from "../../utilities/parse-falcon-trout";
import { convertUnix } from "../../utilities/convert-unix";
import { getWarehouseAttributes } from "../../utilities/get-warehouse-attributes";
import { getPaginatedAPICall } from "../../utilities/get-paginated-api-call";
import { getFalconTroutDetails } from "../../graphql/queries";
import { defaultResultsPerPage, resultsPerPageOptions } from "../Constants";


const NetworkPODeepDiveModal = props => {
  const { po, setPO, open, setOpen } = props;
  const warehouseData = useWarehouseData();
  const [resultsPerPage, setResultsPerPage] = useState(defaultResultsPerPage);
  const [selectedResultsPerPage, setSelectedResultsPerPage] = useState(defaultResultsPerPage);
  const { filters } = useNetworkPODeepDiveFilters();
  const [incomingNetworkPODeepDiveItems, setIncomingNetworkDeepDiveItems] = useState();
  const [incomingNetworkPODeepDiveSummary, setIncomingNetworkPODeepDiveSummary] = useState();

  useEffect(() => {
    const getPOData = async () => {
      const params = {po: {"po": po}};
      const data = await getPaginatedAPICall(params, getFalconTroutDetails, "getFalconTroutDetails");
      if (data) {
        parseFalconTrout(data, warehouseData, setIncomingNetworkDeepDiveItems, setIncomingNetworkPODeepDiveSummary);
      };
    };
    if (po !== "" && open){
      getPOData();
    };
  }, [setIncomingNetworkDeepDiveItems, setIncomingNetworkPODeepDiveSummary, po, open, warehouseData]);

  const networkPODeepDiveItems = useFilters(incomingNetworkPODeepDiveItems, filterNetworkPODeepDive, filters);

  const pagination = usePagination({
    numberOfItemsPerPage: resultsPerPage,
    numberOfResults: networkPODeepDiveItems?.length || 0,
  });

  const onChangeResultsPerPage = resultsPerPage => {
    // Update local state variable
    setSelectedResultsPerPage(resultsPerPage)
    if (resultsPerPage === "All") {
      setResultsPerPage(networkPODeepDiveItems?.length || 0)
    } else {
      setResultsPerPage(resultsPerPage)
    }
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    pagination.onChange(1)
  }

  const { visibleIndices } = pagination;

  const onClickFooterButton = useCallback(() => {
        pagination.onChange(1)
        setPO("");
        setOpen(false);
        setIncomingNetworkDeepDiveItems();
    }, [setPO, setOpen, pagination]);
  const { time_zone } = incomingNetworkPODeepDiveSummary ? getWarehouseAttributes(incomingNetworkPODeepDiveSummary.warehouseId) : { time_zone: "UTC"}
  return (
    <Modal
      title={`PO Deep Dive: ${po}`}
      open={open}
      scrollContainer="viewport"
      describedById="iol-details-modal-description"
      onClose={onClickFooterButton}
      closeLabel="Close"
    >
      <Column>
        <Heading level={2}>{`PO: ${po}`}</Heading>
        <Row>
          <Text>
            <strong>{"Site Id: "}</strong>
            {incomingNetworkPODeepDiveSummary ?
                incomingNetworkPODeepDiveSummary.warehouseId ? incomingNetworkPODeepDiveSummary.warehouseId : ""
                : ""
            }
          </Text>
          <Text>
            <strong>{"Status: "}</strong>
            {incomingNetworkPODeepDiveSummary ?
                incomingNetworkPODeepDiveSummary.status ? incomingNetworkPODeepDiveSummary.status : "UNKNOWN"
                : ""
            }
          </Text>
          <Text>
            <strong>{"Vendor: "}</strong>
            {incomingNetworkPODeepDiveSummary ?
                incomingNetworkPODeepDiveSummary.distributorId ? incomingNetworkPODeepDiveSummary.distributorId : "UNKNOWN"
                : ""
            }
          </Text>
          <Text>
            <strong>{"Expected Arrival: "}</strong>
            {incomingNetworkPODeepDiveSummary ?
                incomingNetworkPODeepDiveSummary.expectedArrival ? incomingNetworkPODeepDiveSummary.expectedArrival : "UNKNOWN"
                : ""
            }
          </Text>
          <Text>
            <strong>{"Dock Received: "}</strong>
            {incomingNetworkPODeepDiveSummary ?
                incomingNetworkPODeepDiveSummary.lastDockReceiveTime ? convertUnix(incomingNetworkPODeepDiveSummary.lastDockReceiveTime, time_zone) : "UNKNOWN"
                : ""
            }
          </Text>
        </Row>
        <Column>
          <Row><NetworkPODeepDiveActionBar /></Row>
          <Row><FilterTags stick={true} filtersHook={useNetworkPODeepDiveFilters}/></Row>
          <Row><NetworkPODeepDiveTable networkPODeepDiveItems={networkPODeepDiveItems} visibleIndices={visibleIndices}/></Row>
          {networkPODeepDiveItems.length === 0 ? (
            <Row><EmptyTable searching={Boolean(filters.size)} /></Row>
            ) : null
          }
          <Row>
            {networkPODeepDiveItems && networkPODeepDiveItems.length > 0 ? (
                <TableFooter
                  pagination={pagination}
                  stick={false}
                  resultsPerPage={resultsPerPage}
                  onChangeResultsPerPage={onChangeResultsPerPage}
                  resultsPerPageOptions={resultsPerPageOptions}
                  selectedResultsPerPage={selectedResultsPerPage}
                />
                ) : null
              }
          </Row>
        </Column>
      </Column>
      <ModalFooter>
        <Row alignmentHorizontal="end" widths="fit">
          <Button type="secondary" onClick={onClickFooterButton}>
            Close
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

NetworkPODeepDiveModal.propTypes = {
  po: PropTypes.string.isRequired,
  setPO: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
};

export default NetworkPODeepDiveModal;