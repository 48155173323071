/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const suppressShipment = /* GraphQL */ `mutation SuppressShipment(
  $orderId: String!
  $recordType: String!
  $suppressionHistory: String!
) {
  suppressShipment(
    orderId: $orderId
    recordType: $recordType
    suppressionHistory: $suppressionHistory
  )
}
`;
export const reconcileShipment = /* GraphQL */ `mutation ReconcileShipment(
  $orderId: String!
  $recordType: String!
  $reconciliationDetails: String!
) {
  reconcileShipment(
    orderId: $orderId
    recordType: $recordType
    reconciliationDetails: $reconciliationDetails
  )
}
`;
