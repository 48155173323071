import React, { useState } from "react"
import Table, { TableRow, TableCell } from "@amzn/meridian/table"
import InstockDetailsTableRow from "./instock-details-table-row"
import InstockDetailsTableColumns from "../../../data/instock-details-table-columns"

const InstockDetailsTable = props => {
    const { instock, warehouseId } = props
    const [sortColumn, setSortColumn] = useState("")
    const [sortDirection, setSortDirection] = useState("ascending")

    const onSort = ({ sortColumn, sortDirection }) => {
      setSortDirection(sortDirection)
      setSortColumn(sortColumn)
    }

    const sortedInstockShipments = [...instock]
    if (sortColumn) {
      sortedInstockShipments.sort((a, b) => {
          if (a[sortColumn] < b[sortColumn]) {
            return sortDirection === "ascending" ? -1 : 1
          }
          else if (a[sortColumn] > b[sortColumn]) {
            return sortDirection === "ascending" ? 1 : -1
          }
          return 0
        })
    }
    const visibleInstock = sortedInstockShipments.slice(
      props.visibleIndices[0],
      props.visibleIndices[1]
    )
    return (
        <React.Fragment>
          <Table
            headerRows={1}
            showDividers={true}
            rowComponents={[InstockDetailsTableRow , TableRow]}
            stickyHeaderRow={true}
            stickyHeaderColumn={false}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onSort={onSort}
            showStripes={'True'}
            spacing={'medium'}
          >
          <TableRow>
            {InstockDetailsTableColumns
              .map(header => (
                <TableCell
                  key={header.id}
                  sortColumn={header.id}
                  width={header.width}
                  alignmentHorizontal={header.alignment}
                >
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
            {visibleInstock.map((instockDetail, index) => (
              <InstockDetailsTableRow
                key={`${instockDetail.asin}-${instockDetail.receivedDate}`}
                instockDetail={instockDetail}
                warehouseId={warehouseId}
              />)
              )
            }
          </Table>
        </React.Fragment>
    )
}
export default InstockDetailsTable;