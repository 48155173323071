/**
 * A table of shipments. Rendered when the "My shipments" page view is set to
 * "table".
 */

import React from "react"
import PropTypes from "prop-types"
import Table, { TableRow, TableCell } from "@amzn/meridian/table"
import DockFullnessTableRow from "./dock-fullness-table-row"
import dockFullnessPropType from "../../prop-types/dock-fullness"
import dockFullnessTableColumns from "../../data/dock-fullness-table-columns"

import { basicTempZones } from "../Constants";

const DockFullnessTable = props => {
  const { dockFullness, warehouseId } = props
  const incomingDockFullness = basicTempZones.map(tempZone => {
    const filteredData = dockFullness.filter(dock => dock.tempZone === tempZone);
    if (filteredData.length > 0) {
      return filteredData[0]
    }
    return {warehouseId: warehouseId, tempZone: tempZone, estimatedPallets: 0}
  });

  return (
    <React.Fragment>
      <Table
        headerRows={1}
        layout={"fixed"}
        showDividers={true}
        rowComponents={[DockFullnessTableRow, TableRow]}
        stickyHeaderRow={true}
        stickyHeaderColumn={false}
        // sortColumn={sortColumn}
        // sortDirection={sortDirection}
        // onSort={onSort}
        showStripes={true}
      >
        <TableRow>
          {dockFullnessTableColumns
            .map(header => (
              <TableCell
                key={header.id}
                sortColumn={header.id}
                width={header.width}
                alignmentHorizontal={header.alignment}
              >
                {header.label}
              </TableCell>
            ))}
        </TableRow>
        {incomingDockFullness.map((dockFullnessData, index) => (
          <DockFullnessTableRow
            key={dockFullnessData.tempZone}
            dockFullness={dockFullnessData}
          />
        ))}
      </Table>
    </React.Fragment>
  )
}

DockFullnessTable.propTypes = {
  dockFullness: PropTypes.arrayOf(dockFullnessPropType),
  warehouseId: PropTypes.string.isRequired,
}

export default DockFullnessTable
