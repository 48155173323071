/**
 * The action bar shown above the network table. Includes a search field
 */

import React from "react";
import PropTypes from "prop-types";
import { TableActionBar } from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import NYSSearchField from "./nys-search-field";
import NYSAsrsAsinFilter from "./nys-asrs-asin-filter";
import HorizontalStickyContainer from "../../app/horizontal-sticky-container";
import usePageLayoutContentRectangle from "../../../hooks/use-page-layout-content-rectangle";
import NYSCriticalAsinFilter from "./nys-critical-asin-filter";

const HorizontalStickyRow = props => (
  <HorizontalStickyContainer Component={Row} {...props} />
)


const NYSActionBar = props => {
  // const { nysData } = props;
  const Wrapper = HorizontalStickyRow;
  const { width: contentWidth = 1200 } = usePageLayoutContentRectangle();
  const mobile = contentWidth < 600;

  return (
    <Wrapper widths="fill">
      <TableActionBar alignmentHorizontal="justify">
        <Row spacing="200">
          <NYSSearchField mobile={mobile}/>
          <NYSCriticalAsinFilter />
          <NYSAsrsAsinFilter />
        </Row>
        <Row spacing="200">
        </Row>
      </TableActionBar>
    </Wrapper>
  );
};

NYSActionBar.propTypes = {
  nysData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default NYSActionBar;
