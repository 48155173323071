/**
 * A context-based hook that allows components to consume auth data we'll mainly use to capture user actions
 */

import React, { useState, useContext, createContext, useEffect } from "react";
import PropTypes from "prop-types";

import { authenticateAmplify } from '../components/auth/midwayAuth';;

const AuthContext = createContext();

const AuthContextProvider = props => {
  const [userDetails, setUserDetails] = useState({});
  useEffect(() => {
    const authUser = async () => {
      let loggedInUser: AuthDetails = await authenticateAmplify();

      if (loggedInUser['username'] === '') {
        loggedInUser["authorized"] = true;
      } else {
        loggedInUser["authorized"] = true;
      }
      setUserDetails(loggedInUser);
    }
    if (!userDetails.authorized) {
      authUser();
    }
  }, [userDetails, setUserDetails]);

  return (
    <AuthContext.Provider value={userDetails}>
      {props.children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAuth = () => useContext(AuthContext);

export { AuthContextProvider };
export default useAuth;