import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import SearchField from "@amzn/meridian/search-field";

import NetworkPODeepDiveModal from "../../components/network-po-deep-dive/network-po-deep-dive-modal";


const NetworkPODeepDive = props => {
  const [po, setPO] = useState("");
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);

  const search = useCallback(value => setPO(value), [setPO]);

  useEffect(() => po !== "" && po ? setOpen(true) : setOpen(false), [po, setOpen]);

  return (
    <Column>
      <Heading level={2}>{"PO deep dive"}</Heading>
      <SearchField
        placeholder={
          props.mobile ? "Search" : "Enter a purchase order"
        }
        value={query}
        onChange={setQuery}
        onSubmit={search}
        onClear={search}
        searchButton={true}
        width={props.mobile ? 200 : 360}
      />
      <NetworkPODeepDiveModal po={po} setPO={setPO} open={open} setOpen={setOpen} setQuery={setQuery}/>
    </Column>
  );
};

NetworkPODeepDive.propTypes = {
  mobile: PropTypes.bool,
}

NetworkPODeepDive.defaultProps = {
  mobile: false,
}

export default NetworkPODeepDive;