/**
 * Exposes a [`useContext` hook](https://reactjs.org/docs/hooks-reference.html#usecontext)
 * that returns the value (and setter) of the current filters applied to the
 * iol table.
 *
 * React Context is powerful because it allows us to give descendants access to
 * variables without passing them down to every descendant (w/o Context:
 * grandparent -> parent w/ prop -> child w/prop -- w/ Context: grandparent
 * provides value -> parent -> child consume value).
 */

import React, { useState, useContext, useReducer, createContext } from "react"
import PropTypes from "prop-types"

const FiltersContext = createContext()

// This reducer handles updating, removing, and resetting a Map of filters
const reducer = (previousState, action) => {
  switch (action.type) {
    case "UPDATE_FILTER": {
      // Clone state from previous state
      const state = new Map(previousState)
      // Update the filter with new value and tag label
      const { filter, value, tagLabel } = action
      state.set(filter, { value, tagLabel })
      // Return updated state
      return state
    }

    case "REMOVE_FILTER": {
      // Clone state from previous state
      const state = new Map(previousState)
      const { filter, value } = action
      // If the filter is not yet in the Map, return the previous state
      if (state.get(filter) === undefined) {
        return previousState
      }
      // If the value of the filter is not an array, simply delete from map
      if (!Array.isArray(state.get(filter).value)) {
        state.delete(filter)
      }
      // Else, remove individual value from the array
      else {
        const currentFilter = state.get(filter)
        // Get the index of the value
        const valueIndex = currentFilter.value.indexOf(value)
        // Clone the value and tag label arrays
        const nextValue = [...currentFilter.value]
        const nextTagLabel = [...currentFilter.tagLabel]
        // Remove value index from both arrays
        nextValue.splice(valueIndex, 1)
        nextTagLabel.splice(valueIndex, 1)
        // If the resulting array is empty, delete filter from map
        if (nextValue.length === 0) {
          state.delete(filter)
        }
        // Else, update filter with new arrays
        else {
          state.set(filter, { value: nextValue, tagLabel: nextTagLabel })
        }
      }
      // Return updated state
      return state
    }

    case "RESET":
      return new Map()

    default:
      throw new Error(`Unknown action type: ${action.type}`)
  }
}

const NetworkDwellingPosFiltersContextProvider = props => {
  const [filters, dispatch] = useReducer(reducer, new Map())
  const sheetOpenState = useState(false)
  return (
    <FiltersContext.Provider
      value={{ filters, dispatch, sheet: sheetOpenState }}
    >
      {props.children}
    </FiltersContext.Provider>
  )
}

NetworkDwellingPosFiltersContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useNetworkDwellingPosFilters = () => useContext(FiltersContext)

export { NetworkDwellingPosFiltersContextProvider }
export default useNetworkDwellingPosFilters