/**
 * A table of dwelling shipments past SLA
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import Table, { TableRow, TableCell } from "@amzn/meridian/table"
import NetworkDwellingTableRow from "./network-dwelling-table-row"
import networkDwellingTableColumns from "../../data/network-dwelling-table-columns"

const NetworkDwellingTable = props => {
  const { networkDwelling } = props
  const [sortColumn, setSortColumn] = useState("")
  const [sortDirection, setSortDirection] = useState("ascending")

  const onSort = ({ sortColumn, sortDirection }) => {
    setSortDirection(sortDirection)
    setSortColumn(sortColumn)
  }

  // Make a copy of incoming shipments and sort if `sortColumn` exists
  const sortedNetworkDwelling = [...networkDwelling]
  if (sortColumn) {
    sortedNetworkDwelling.sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) {
        return sortDirection === "ascending" ? -1 : 1
      }
      if (a[sortColumn] > b[sortColumn]) {
        return sortDirection === "ascending" ? 1 : -1
      }
      return 0
    })
  }

  const visibleNetworkDwelling = sortedNetworkDwelling.slice(
    props.visibleIndices[0],
    props.visibleIndices[1]
  )

  return (
    <React.Fragment>
      <Table
        headerRows={1}
        showDividers={true}
        rowComponents={[NetworkDwellingTableRow, TableRow]}
        stickyHeaderRow={true}
        stickyHeaderColumn={false}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={onSort}
        showStripes={true}
      >
        <TableRow>
          {networkDwellingTableColumns
            .map(header => (
              <TableCell
                key={header.id}
                sortColumn={header.id}
                width={header.width}
                alignmentHorizontal={header.alignment}
              >
                {header.label}
              </TableCell>
            ))}
          {/* Empty header cell to create space for the table row details button */}
          <TableCell />
        </TableRow>
        {visibleNetworkDwelling.map((networkDwellingShipment, index) => (
          <NetworkDwellingTableRow
            key={networkDwellingShipment.loadId}
            networkDwellingShipment={networkDwellingShipment}
          />
        ))}
      </Table>
    </React.Fragment>
  )
}

NetworkDwellingTable.propTypes = {
  visibleIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
  networkDwelling: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default NetworkDwellingTable
