function mapNetwork(network: string) {
  const networkMap = {
    "Mendel": "Stores",
    "UFF": "FCs",
    "Malone": "Stores"
  };
  return networkMap[network] ? networkMap[network] : "?"
};

export { mapNetwork };
