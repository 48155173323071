/**
 * A hook that that takes incoming data and applies filters to it as they are changed
 */

import { useMemo } from "react";

const useFilters = (data, filterFunction, filters = new Map()) => {

  const filteredData = useMemo(() => {
    if (data) {
      return filterFunction(data, filters)
    } else {
      return []
    };
  }, [filters, data, filterFunction])

  return filteredData;
};

export default useFilters;
