function convertUnix(unixTimestamp: number, timeZone: string, locale="en-gb") {
    if ( unixTimestamp === undefined ) {
        return ""
    }
    if (locale === "en-gb") {
        return Intl.DateTimeFormat("en-gb", {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
            timeZone: timeZone
        }).format(unixTimestamp)
    } else {
       return Intl.DateTimeFormat(locale, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
            timeZone: timeZone
        }).format(unixTimestamp)
    }
};

function convertUnixDate(unixTimestamp: number, timeZone: string) {
    if ( unixTimestamp === undefined ) {
        return ""
    }
    return Intl.DateTimeFormat("en-gb", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      timeZone: timeZone
  }).format(unixTimestamp)
};

function convertUnixDateNumeric(unixTimestamp: number, timeZone: string) {
    if ( unixTimestamp === undefined ) {
        return ""
    }
    return parseInt(Intl.DateTimeFormat("sv-SE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone: timeZone
    }).format(unixTimestamp).split("-").join(""))
};

function convertUnixToShiftDate(unixTimestamp: number, timeZone: string) {
    const hour = parseInt(Intl.DateTimeFormat("sv-SE", {
      hour: "2-digit",
      hour12: false,
      timeZone: timeZone
    }).format(unixTimestamp))

    const daysBack = hour < 6 ? 1 : 0;

    const shiftDate = Intl.DateTimeFormat("sv-SE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone: timeZone
    }).format(unixTimestamp - (daysBack * 24 * 60 * 60 * 1000))
    return shiftDate;
  };

function convertUnixToLocalHour(unixTimestamp: number, timeZone: string): number {
    return parseInt(Intl.DateTimeFormat("sv-SE", {
        hour: "2-digit",
        hour12: false,
        timeZone: timeZone
    }).format(unixTimestamp))
}


export { convertUnix, convertUnixDate, convertUnixToShiftDate, convertUnixDateNumeric, convertUnixToLocalHour };
