/**
 * A table of shipments. Rendered when the "My shipments" page view is set to
 * "table".
 */

import React from "react"
import PropTypes from "prop-types"
import Table, { TableRow, TableCell } from "@amzn/meridian/table"
import Text from "@amzn/meridian/text"
import IncomingNetworkShipmentsTableRow from "./incoming-network-shipments-table-row"

const IncomingNetworkShipmentsTable = props => {
  const { incomingNetworkShipments, incomingNetworkShipmentsTableColumns } = props

  const visibleIncomingNetworkShipments = incomingNetworkShipments.slice(
    props.visibleIndices[0],
    props.visibleIndices[1]
  )

  return (
    <React.Fragment>
      <Table
        headerRows={1}
        showDividers={true}
        rowComponents={[IncomingNetworkShipmentsTableRow, TableRow]}
        stickyHeaderRow={true}
        stickyHeaderColumn={true}
        showStripes={true}
      >
        <TableRow>
          <TableCell><Text><strong>Site Id</strong></Text></TableCell>
          <TableCell><Text><strong>Temp Zone</strong></Text></TableCell>
          {incomingNetworkShipmentsTableColumns.map(date =>
              <TableCell key={date}>{date}</TableCell>
          )}
        </TableRow>
        {visibleIncomingNetworkShipments.map((incomingNetworkShipment, index) => (
          <IncomingNetworkShipmentsTableRow
            key={incomingNetworkShipment.warehouseId}
            incomingNetworkShipment={incomingNetworkShipment}
            incomingNetworkShipmentsTableColumns={incomingNetworkShipmentsTableColumns}
          />
        ))}
      </Table>
    </React.Fragment>
  )
}

IncomingNetworkShipmentsTable.propTypes = {
  visibleIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
  incomingNetworkShipments: PropTypes.arrayOf(PropTypes.object),
  incomingNetworkShipmentsTableColumns: PropTypes.arrayOf(PropTypes.object)
}

export default IncomingNetworkShipmentsTable
