/**
 * A single row in the iol details table. Shows details for a item.
 */

import React from "react";
import { TableRow, TableCell } from "@amzn/meridian/table";
import Text from "@amzn/meridian/text";
import InstockDetailsPropType from "../../../prop-types/iol-details";

const rowShouldNotUpdate = (prevProps, nextProps) =>
  !(
    prevProps.selected !== nextProps.selected ||
    prevProps.iol !== nextProps.iol
  )

const InstockDetailsTableRow = React.memo(props => {
  const { instockDetail} = props
  return (
    <TableRow>
      { (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
        { instockDetail.alarm==="OUT_OF_STOCK" ? "OOS" : "OOS Risk" }
          </Text>
        </TableCell>
      )}
      {(
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            {instockDetail.asin}
          </Text>
        </TableCell>
      )}
      {(
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            {instockDetail.asinTitle}
          </Text>
        </TableCell>
      )}
      {(
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            {instockDetail.noos==="true"? "Y": "N"}
          </Text>
        </TableCell>
      )}
      {(
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            {instockDetail.tempZoneDetailed}
          </Text>
        </TableCell>
      )}
      {(
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            {instockDetail.quantityConfirmed}
          </Text>
        </TableCell>
      )}
      
      {(
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
          {instockDetail.onhand_inventory ? instockDetail.onhand_inventory: "0" }
          </Text>
        </TableCell>
      )}
      {(
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
          {instockDetail.demand ? instockDetail.demand.toFixed(2) : "0"}
          </Text>
        </TableCell>
      )}
      {(
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
          {instockDetail.weight ? instockDetail.weight.toFixed(2) : "0"}
          </Text>
        </TableCell>
      )}
      
    </TableRow>
  )
}, rowShouldNotUpdate)

InstockDetailsTableRow.propTypes = {
  instockDetail: InstockDetailsPropType.isRequired,
  rowComponents: TableRow.propTypes.rowComponents,
}

export default InstockDetailsTableRow
