/**
 * The action bar shown above the iol table. Includes a search field and
 * filters to help find shipments.
 */

import React from "react";
import PropTypes from "prop-types";
import { TableActionBar } from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import IOLDetailsSearchField from "./iol-details-search-field";
import IOLDetailsViewPreferences from "./iol-details-view-preferences";
import HorizontalStickyContainer from "../../app/horizontal-sticky-container";
import usePageLayoutContentRectangle from "../../../hooks/use-page-layout-content-rectangle";
import iolDetailsPropType from "../../../prop-types/iol-details";

const HorizontalStickyRow = props => (
  <HorizontalStickyContainer Component={Row} {...props} />
)


const IOLDetailsActionBar = props => {
  const Wrapper = props.stick ? HorizontalStickyRow : Row
  const { width: contentWidth = 1200 } = usePageLayoutContentRectangle()
  const mobile = contentWidth < 600

  return (
    <Wrapper widths="fill">
      <TableActionBar alignmentHorizontal="justify">
        <Row spacing="200">
          <IOLDetailsSearchField mobile={mobile} />
        </Row>
        <Row spacing="200">
          <IOLDetailsViewPreferences />

        </Row>
      </TableActionBar>
    </Wrapper>
  )
}

IOLDetailsActionBar.propTypes = {
  stick: PropTypes.bool.isRequired,
  iolDetails: PropTypes.arrayOf(iolDetailsPropType),
}

export default IOLDetailsActionBar
