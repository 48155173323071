/**
 * A table of nyr
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import NYRTableRow from "./nyr-table-row";
import nyrTableColumns from "../../../data/nyr-table-columns";

import { shipmentQuantityKeys } from "../../Constants";

const { vendorConfirmedUnits, vendorReceivedUnits } = shipmentQuantityKeys;

const NYRTable = props => {
  const { nyrData, criticalAsinEvents, region_id } = props;
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("ascending");

  const onSort = ({ sortColumn, sortDirection }) => {
    setSortDirection(sortDirection)
    setSortColumn(sortColumn)
  };
  // Make a copy of incoming shipments and sort if `sortColumn` exists
  const sortedNyrItems = [...nyrData];
  if (sortColumn) {
    sortedNyrItems.sort((a, b) => {
      if (sortColumn === "backlog") {
        if (a[vendorConfirmedUnits] - a[vendorReceivedUnits] < b[vendorConfirmedUnits]- a[vendorReceivedUnits]) {
          return sortDirection === "ascending" ? -1 : 1
        };
        if (a[vendorConfirmedUnits] - a[vendorReceivedUnits] > b[vendorConfirmedUnits] - a[vendorReceivedUnits]) {
          return sortDirection === "ascending" ? 1 : -1
        };
        return 0
      };
      if (a[sortColumn] < b[sortColumn]) {
        return sortDirection === "ascending" ? -1 : 1
      };
      if (a[sortColumn] > b[sortColumn]) {
        return sortDirection === "ascending" ? 1 : -1
      };
      return 0
    });
  };

  const visibleNyrItems = sortedNyrItems.slice(
    props.visibleIndices[0],
    props.visibleIndices[1]
  );

  return (
    <React.Fragment>
      <Table
        headerRows={1}
        showDividers={true}
        rowComponents={[NYRTableRow, TableRow]}
        stickyHeaderRow={true}
        stickyHeaderColumn={false}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={onSort}
        showStripes={true}
      >
        <TableRow>
          {nyrTableColumns
            .map(header => (
              <TableCell
                key={header.id}
                sortColumn={header.id}
                width={header.width}
                alignmentHorizontal={header.alignment}
              >
                {header.label}
              </TableCell>
            ))}
        </TableRow>
        {visibleNyrItems.map((nyrItem, index) => (
          <NYRTableRow
            key={nyrItem.asin}
            nyrItem={nyrItem}
            criticalAsinEvents={criticalAsinEvents}
            region_id={region_id}
          />
        ))}
      </Table>
    </React.Fragment>
  );
};

NYRTable.propTypes = {
  visibleIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
  nyrData: PropTypes.arrayOf(PropTypes.object).isRequired,
  criticalAsinEvents: PropTypes.object.isRequired,
  region_id: PropTypes.number.isRequired,
};

export default NYRTable;
