
const escalationTableColumns = [
  {
    id: "region_id",
    label: "Region ID",
    width: 95,
    alignment: "center",
  },
  {
    id: "source_fc",
    label: "Source",
    width: 95,
    alignment: "center",
  },
  {
    id: "destination_fc",
    label: "Destination",
    width: 95,
    alignment: "center",
  },
  {
    id: "tempzone",
    label: "Temp Profile",
    width: 95,
    alignment: "center",
  },
  {
    id: "action_issue",
    label: "Action Issue",
    width: 95,
    alignment: "center"
  },
  {
    id: "action_status",
    label: "Action Status",
    width: 95,
    alignment: "center",
  },
  {
    id: "action_owner",
    label: "Action Owner",
    width: 175,
    alignment: "center",
  },
  {
    id: "requested_by",
    label: "Requested By",
    width: 175,
    alignment: "center",
  },
  {
    id: "asin",
    label: "ASIN",
    width: 175,
    alignment: "center",
  },
  {
    id: "cpt",
    label: "CPT",
    width: 95,
    alignment: "center",
  },
  {
    id: "action_comment",
    label: "Comment",
    width: 95,
    alignment: "center",
  },
  {
    id: "root_cause",
    label: "Root Cause",
    width: 95,
    alignment: "center",
  },
  {
    id: "update_escalation_modal",
    label: "Update",
    width: 95,
    alignment: "center",
  },
]

// By default all visible table columns are set to visible (`true`)
export const defaultEscalationTableColumns = escalationTableColumns.reduce(
  (columns, column) => ({
    ...columns,
    [column.id]: true,
  }),
  {}
)

export default escalationTableColumns
