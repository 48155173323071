// /**
//  * This module exports a function that filters an array of shipments according
//  * to a Map of filters.
//  */
//

/**
 * Returns a function that checks if an object's value is in an array of options.
 * @example
 * const checkFoo = isInOptions("foo")
 * const object = { foo: "bar" }
 * checkFoo(object, ["bar", "baz"])  // returns true
 * checkFoo(object, ["baz"]) // returns false
 */

import { searchBarFilter } from "../utilities/search-bar-filter";
import defaultInstockTableColumns from "../data/instock-table-columns";
const isInOptions = key => (obj, options) => options.includes(obj[key])

// An object of filter applications where each key points to a function
// that expects `shipment` and `value` to consider
const applyFilter = {
  allFields: (instock, value) => {
    // If any field passes, return true
    const searchableFields = defaultInstockTableColumns.map(column => column.id);
    const dateKeys = [];
    return searchBarFilter(value, instock, dateKeys, searchableFields);
  },
  id: ({ location }, value) => {
    location.toLowerCase().includes(value.toLowerCase())
  },
  tempZone: isInOptions("tempZone"),
  loadType: ( location , value) => {
    // console.log(location.id)
    if (value.includes("Vendor") && value.length===1) {
        return location.id.startsWith("PO")
    }
    else if(value.includes("Transship") && value.length===1)
        return !location.id.startsWith("PO")
    else
        return location    
    
  },
  alarm: isInOptions("alarm"),
  noos: (location, value) =>{
  
    if(value[0]==="Y" && value.length===1)
      return location.noos>0
    else if(value[0]==="N" && value.length===1)
      return location.noos===0
    else 
      return location

  }
}

/**
 * This function filters an array of shipments according to a Map of filters.
 *
 * @param  Instock          Array of Instock objects (see `src/prop-types/incoming-shipment` to see shape)
 * @param  filters            Map of filter objects (see `src/hooks/use-shipments-filters`)
 * @return filteredShipments  Array of filtered shipments
 */
const filterInstock = (Instock, filters) => {
  if (filters.size === 0) return Instock
  console.log(filters)
  let filteredInstock = [...Instock]
  // Loop through and apply filter in the Map to the Instock array
  for (const filter of filters) {
    const [key, { value }] = filter
    filteredInstock = filteredInstock.filter(Instock =>
      applyFilter[key](Instock, value)
    )
  }
  console.log(filteredInstock)
  return filteredInstock
}

export default filterInstock
