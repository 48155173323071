/**
 * The date range selector for incoming shipments
 */

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Badge from "@amzn/meridian/badge";
import Button from "@amzn/meridian/button";

import useDockReceivedFilters from "../../hooks/use-dock-received-filters";

const DockReceivedSuppressionFilter = props => {
  const { totalSuppressions } = props;
  const { filters, dispatch } = useDockReceivedFilters();
  const [buttonText, setButtonText] = useState(filters.get("suppression") ? "Show Suppressions" : "Hide Suppressions");

  // This allows clearing the filter through the filter tag to change the button text
  useEffect(() =>
      setButtonText(filters.get("suppression") ? "Show Suppressions" : "Hide Suppressions"),
      [filters, setButtonText]
  )

  const onClick = useCallback(() => {
    setButtonText(filters.get("suppression") ? "Hide Suppressions": "Show Suppressions");
    if (filters.get("suppression")) {
      dispatch({ type: "REMOVE_FILTER", filter: "suppression" })
    } else {
      dispatch({
        type: "UPDATE_FILTER",
        filter: "suppression",
        value: false,
        tagLabel: `Suppressions Hidden`,
      })
    }
  }, [setButtonText, filters, dispatch]);

  return (
    <Button onClick={onClick} disabled={totalSuppressions <= 0}>
      {buttonText}
      <Badge value={totalSuppressions} type={totalSuppressions <= 0 ? "neutral" : "error"} />
    </Button>
  )
}

DockReceivedSuppressionFilter.propTypes = {
  totalSuppressions: PropTypes.number,
}

export default DockReceivedSuppressionFilter
