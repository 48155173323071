// /**
//  * This module exports a function that filters an array of shipments according
//  * to a Map of filters.
//  */
//

/**
 * Returns a function that checks if an object's value is in an array of options.
 * @example
 * const checkFoo = isInOptions("foo")
 * const object = { foo: "bar" }
 * checkFoo(object, ["bar", "baz"])  // returns true
 * checkFoo(object, ["baz"]) // returns false
 */

import { searchBarFilter } from "../utilities/search-bar-filter";
const isInOptions = key => (obj, options) => options.includes(obj[key])

// An object of filter applications where each key points to a function
// that expects `dockReceived` and `value` to consider
const applyFilter = {
  alarmCategory: isInOptions("alarmCategory"),
  allFields: (dockReceived, value) => {

    // If any field passes, return true
    const searchableFields = [
        "loadType",
        "source",
        "loadId",
        "tempZone",
        "actualArrivalParsed",
        "actualArrival",
        "dockReceivedParsed",
        "dockReceived",
        "lastDockReceiveBy",
        "firstReceiveTimeParsed",
        "firstReceiveTime",
        "confirmedUnits",
        "receivedUnits",
        "nyrnys",
        "progress",
        "dwellDays",
        "notification",
        "alarmCategory"
    ];

    const dateKeys = ["actualArrival", "dockReceived", "firstReceiveTime"];
    return searchBarFilter(value, dockReceived, dateKeys, searchableFields);
  },
}

/**
 * This function filters an array of shipments according to a Map of filters.
 *
 * @param  notifications          Array of incomingShipment objects (see `src/prop-types/dock-received` to see shape)
 * @param  filters            Map of filter objects (see `src/hooks/use-shipments-filters`)
 * @return filteredDockReceive  Array of filtered dock received shipments
 */
const filterNotifications = (notifications, filters) => {
  if (filters.size === 0) return notifications
  let filteredNotifications = [...notifications]
  // Loop through and apply filter in the Map to the dockReceived array
  for (const filter of filters) {
    const [key, { value }] = filter
    filteredNotifications = filteredNotifications.filter(notification =>
      applyFilter[key](notification, value)
    )
  }
  return filteredNotifications
}

export default filterNotifications
