/**
 * A single row in the nyr snapshot table.
 */

import React from "react";
import PropTypes from "prop-types";
import { TableRow, TableCell } from "@amzn/meridian/table";
import Text from "@amzn/meridian/text";
// import nyrSnapshotPropType from "../../prop-types/nyr-snapshot";

const CurrentNYRSnapshotTableRow = props => {
  const { currentSnapshot } = props;
  return (
    <TableRow key={currentSnapshot.tempZone}>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
            {currentSnapshot.warehouseId}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {currentSnapshot.tempZone}
        </Text>
      </TableCell>
        <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {currentSnapshot.units.toLocaleString()}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p">
          {Math.floor(currentSnapshot.dwellDays * 100) / 100}
        </Text>
      </TableCell>
    </TableRow>
  )
};

CurrentNYRSnapshotTableRow.propTypes = {
 currentSnapshot: PropTypes.object.isRequired
};

export default CurrentNYRSnapshotTableRow;
