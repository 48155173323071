function findNetworkDwellingShipments(pastShipments: Array<any>) {
  const dwellHoursThreshold = 24;
  return  {
      networkDwellingPos: pastShipments.filter(
          pastShipment => pastShipment.orderId.startsWith("PO")
              && pastShipment.reconcileDuration >= (dwellHoursThreshold * 60 * 60)
      ),
      networkDwellingTransshipments: pastShipments.filter(
          pastShipment => pastShipment.orderId.startsWith("Trans")
              && pastShipment.reconcileDuration >= (dwellHoursThreshold * 60 * 60)
      )
  }
};

export {findNetworkDwellingShipments};