import { slaShipmentProgressThreshold } from "../components/Constants";

function calculateNetworkNYR(dockReceivedData: Array<any>) {
  let greaterThan = 0;
  let lessThan = 0;
  let greaterThanPercent = 0;
  let lessThanPercent = 0;
  let palletMap = {};
  const averagePalletCubeFeet = 40;
  // unicode making it variable here for easy replacement and tracking
  const greaterThanorEqualTo = "\u{2265}"
  for (const dockReceived of dockReceivedData) {
    const warehouseId = dockReceived.warehouseId;
    const tempZone = dockReceived.tempZone;
    const uniqueKey = `${warehouseId}-${tempZone}`;
    palletMap[uniqueKey] =  palletMap[uniqueKey] ?  palletMap[uniqueKey] : {};
    const asinCube = Math.max(dockReceived.asinCube, 0);
    if(((dockReceived.lastDockReceiveTime) + (dockReceived.sla ? dockReceived.sla : 24) * 60 * 60 * 1000) - Date.now() <= 0 
    && dockReceived.progress < slaShipmentProgressThreshold){

      if (dockReceived.firstDockReceiveTime + ((dockReceived.sla + 24) * 60 * 60 * 1000) < Date.now()) {
        greaterThan += Math.max((dockReceived.confirmedUnits - dockReceived.receivedUnits), 0);
        let cube = palletMap[uniqueKey] ?
            palletMap[uniqueKey]["greaterThan"] ? palletMap[uniqueKey]["greaterThan"] : 0
            : 0;
        cube += asinCube;
        palletMap[uniqueKey]["greaterThan"] = cube;
      } else {
     lessThan += Math.max((dockReceived.confirmedUnits - dockReceived.receivedUnits), 0);
      let cube = palletMap[uniqueKey] ?
          palletMap[uniqueKey]["lessThan"] ? palletMap[uniqueKey]["lessThan"] : 0
          : 0;
      cube += asinCube;
      palletMap[uniqueKey]["lessThan"] = cube;
    }
  }
  };
  try {
    greaterThanPercent = Math.floor((greaterThan / (greaterThan + lessThan)) * 10000 ) / 100
  } catch {

  }
  try {
    lessThanPercent = Math.floor((lessThan / (greaterThan + lessThan)) * 10000 ) / 100
  } catch {

  }

  // This is because temp zones do not share pallets
  let greaterThanPallets = 0;
  let lessThanPallets = 0;
  Object.keys(palletMap).forEach(warehouseTemp => {
    Object.keys(palletMap[warehouseTemp]).forEach(condition => {
      if (condition === "greaterThan") {
        greaterThanPallets += Math.ceil(palletMap[warehouseTemp][condition] / ((12 * 100) ** 3) / averagePalletCubeFeet);
      } else {
        lessThanPallets += Math.ceil(palletMap[warehouseTemp][condition] / ((12 * 100) ** 3) / averagePalletCubeFeet);
      };
    });
  });

  let greaterThanPalletPercent = 0;
  let lessThanPalletPercent = 0;
  
  try {
    greaterThanPalletPercent = Math.floor((greaterThanPallets / (greaterThanPallets + lessThanPallets)) * 10000 ) / 100
  } catch {

  }
  try {
    lessThanPalletPercent = Math.floor((lessThanPallets / (greaterThanPallets + lessThanPallets)) * 10000 ) / 100
  } catch {

  }

  const returnData = {
    "Units":
      [
        {
         color: "Green",
         key: "Dwell < 24 hours",
         label: `Dwell (past SLA) < 24 hours ${lessThanPercent}%`,
         value: lessThan
        },
        {
         color: "Red",
         key: "Dwell > 24 hours",
         label: `Dwell (past SLA) ${greaterThanorEqualTo} 24 hours ${greaterThanPercent}%`,
         value: greaterThan
        }
      ],
    "Pallets":
      [
        {
         color: "Green",
         key: "Dwell < 24 hours",
         label: `Dwell (past SLA) < 24 hours ${lessThanPalletPercent}%`,
         value: lessThanPallets
        },
        {
         color: "Red",
         key: "Dwell > 24 hours",
         label: `Dwell (past SLA) ${greaterThanorEqualTo} 24 hours ${greaterThanPalletPercent}%`,
         value: greaterThanPallets
        }
      ]
};
  return returnData
};

export { calculateNetworkNYR };