/**
 * This screen appears if an error is thrown. See react-error-boundary for more
 * information: https://github.com/bvaughn/react-error-boundary
 */

import React from "react"
import Column from "@amzn/meridian/column"
import Text from "@amzn/meridian/text"
import Button from "@amzn/meridian/button"
import Row from "@amzn/meridian/row"
// import { ReactComponent as EmptyStateIllustration } from "../assets/marauders-map.svg"

const ErrorRecoveryScreen = () => {
  return (
    <Column
      alignmentHorizontal="center"
      alignmentVertical="center"
      spacing="600"
      spacingInset="600"
    >
      <Column spacing="300" alignmentHorizontal="center">
        {/*<EmptyStateIllustration />*/}
        <Text type="b400">Mischief Managed!</Text>
        <Text type="b400">An error has occurred in the application. Please refresh and try again. If the issue persists please contact us with the below button.</Text>
      </Column>
      <Row>
        <Button
          type="tertiary"
          size="large"
          href="https://iris-diagonalley.agl.amazon.dev/goblet-of-fire?team=AGL-IRIS"
          target="_blank"
          rel="noopener noreferrer"
        >
          Create bug report
        </Button>
      </Row>
    </Column>
  )
}

export default ErrorRecoveryScreen