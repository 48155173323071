import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Column from "@amzn/meridian/column";
import Tab, { TabGroup } from "@amzn/meridian/tab";

import NetworkTab from "../tabs/network";
import StoreFCTab from "../tabs/store-fc";

import PageLayout from "../components/app/page-layout";


const SummaryScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(location.pathname.split("/")[2]);

  const getRenderedTab = (tab: string) => {
    if (tab === "building") {
      return(<StoreFCTab />);
    }
    else if (tab === "network") {
      return(<NetworkTab />);
    }
    else {
      return (<></>)
    }
  };

  const [renderedTab, setRenderedTab] = useState(getRenderedTab(currentTab));

  // This is make the router dynamically render the tab otherwise nothing changes when the navigate function is used
  useEffect(() => {setRenderedTab(getRenderedTab(currentTab))}, [currentTab])
  return (
    <PageLayout
      loading={false}
      width={"inherit"}
      horizontallySticky={false}
      maxWidth={undefined}
      alignmentHorizontal={"start"}
    >
      <Column>
        <TabGroup
            value={currentTab}
            onChange={(value) => {
                navigate(`/summary/${value}`)
                setCurrentTab(value);
            }}
            type="classic"
        >
          <Tab value="building">Stores/FCs</Tab>
          <Tab value="network">Network</Tab>
        </TabGroup>
      </Column>
      <Column>
        {renderedTab}
      </Column>
    </PageLayout>
  )
}

export default SummaryScreen;