import PropTypes from 'prop-types';
import warehouseParamPropType from "../prop-types/warehouse-param";

function getWarehouseAttributes({warehouseId, warehouseParams}) {
  try {
    return warehouseParams.filter(warehouse => warehouse.warehouse_id === warehouseId)[0]
  }
  catch {
    return {}
  };
};

getWarehouseAttributes.propTypes = {
  warehouseId: PropTypes.string.isRequired,
  warehouseParams: PropTypes.arrayOf(warehouseParamPropType).isRequired
};

export { getWarehouseAttributes };