/**
 * The side navigation for the application.
 */

import React, { useEffect, useCallback } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import SideMenu, { SideMenuLink } from "@amzn/meridian/side-menu"
import useBreakpoint from "@amzn/meridian/use-breakpoint"
import useSideMenuOpen from "../../hooks/use-side-menu-open"

const overlayBreakpoint = "600px"

const AppSideMenu = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [open, setOpen] = useSideMenuOpen()
  const breakpointState = useBreakpoint(overlayBreakpoint)
  const mobile = breakpointState === "smaller"

  // When the browser resizes from desktop -> mobile, close the side menu
  // and vicaversa
  useEffect(() => {
    setOpen(!mobile)
  }, [mobile, setOpen])

  // On mobile, clicking on a link will close the menu
  const onClickLink = useCallback(
    href => {
      if (!location.pathname.startsWith(href)) {
        navigate(href);
      }
      if (mobile) setOpen(false)
    },
    [mobile, navigate, setOpen, location]
  )

  const onMobileClose = () => setOpen(false)

  return (
    <SideMenu
      open={open}
      type={mobile ? "overlay" : "default"}
      onClose={mobile ? onMobileClose : undefined}
    >
      <SideMenuLink
        href="/building"
        onClick={onClickLink}
        selected={location.pathname.startsWith("/building")}
      >
        FC/Store Level
      </SideMenuLink>
      <SideMenuLink
        href="/summary"
        onClick={onClickLink}
        selected={location.pathname.startsWith("/summary")}
      >
        Summary or Deep Dive
      </SideMenuLink>
    </SideMenu>
  )
}

export default AppSideMenu