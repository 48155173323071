/**
 * All "My shipment" table columns. Each column has an `id` (used as a key and for
 * sorting), `label` (the header column text), `width` (how wide the column should
 * be in pixels), and `alignment` (which side the column's content "sticks" to).
 */

const networkPODeepDiveTableColumns = [
  {
    id: "asin",
    label: "ASIN",
    width: 110,
    alignment: "start",
  },
  {
    id: "asinTitle",
    label: "Product Name",
    width: 220,
    alignment: "start",
  },
  {
    id: "expectedQuantity",
    label: "Expected Qty.",
    width: 150,
    alignment: "start",
  },
  {
    id: "received",
    label: "Received On",
    width: 150,
    alignment: "start",
  },
  {
    id: "receivedBy",
    label: "Received By",
    width: 150,
    alignment: "start",
  },
  {
    id: "receivedQuantity",
    label: "Received Qty.",
    width: 150,
    alignment: "start",
  },
  {
    id: "totalReceivedQuantity",
    label: "Total Received Qty.",
    width: 200,
    alignment: "start",
  },
]

export const defaultNetworkPODeepDiveTableColumns = networkPODeepDiveTableColumns.reduce(
  (columns, column) => ({
    ...columns,
    [column.id]: true,
  }),
  {}
)

export const getNetworkPODeepDiveTableColumnById = id =>
  networkPODeepDiveTableColumns.find(column => column.id === id)

export default networkPODeepDiveTableColumns
