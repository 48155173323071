/**
 * The action bar shown above the iol table. Includes a search field and
 * filters to help find shipments.
 */

import React from "react";
import PropTypes from "prop-types";

import { TableActionBar } from "@amzn/meridian/table";

import Button from "@amzn/meridian/button";
import Heading from "@amzn/meridian/heading";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

import IOLSearchField from "./iol-search-field";
import HorizontalStickyContainer from "../app/horizontal-sticky-container";
import usePageLayoutContentRectangle from "../../hooks/use-page-layout-content-rectangle";
import iolPropType from "../../prop-types/iol";
import CsvDownloader from 'react-csv-downloader';
import IOLTempZoneFilter from "./iol-temp-zone-filter";
import { convertUnix, convertUnixDate } from "../../utilities/convert-unix";
import { getWarehouseAttributes } from "../../utilities/get-warehouse-attributes";
import useWarehouseData from "../../hooks/use-warehouse-data";

const HorizontalStickyRow = props => (
  <HorizontalStickyContainer Component={Row} {...props} />
)



const IOLActionBar = props => {
  const { iol, warehouseId } = props
  const Wrapper = props.stick ? HorizontalStickyRow : Row
  const { width: contentWidth = 1200 } = usePageLayoutContentRectangle()
  const mobile = contentWidth < 600
  const { time_zone } = getWarehouseAttributes({ warehouseId: warehouseId, warehouseParams: useWarehouseData() });

  const findSum = () =>{
    const sumOfQty = iol.reduce((acc, obj) => acc + obj.quantity, 0);
    return sumOfQty.toLocaleString();
  }

  const createCSV = () =>{
    let csvHeader = ["location", "tempZone", "asin", "asinTitle", "cost", "costCurrency", "units"]
    const csvRows = iol.flatMap((item) => {
      const mainObjectValues = csvHeader
        .filter((key) => key !== 'details') // Exclude 'details' from main object
        .map((key) => item[key])
    
      const detailsRows = item.details
        ? Object.values(item.details).map((detail) => {
            const warehouse = warehouseId;
            const detailValues = csvHeader
              .filter((key) => key !== 'details')
              .map((key) => detail[key]);
              const receivedDate = convertUnix(detail.receivedDate, time_zone);
              const dwellVal = detail.receivedDate ? Math.floor((Date.now() - detail.receivedDate) / (60 * 60 * 1000)).toLocaleString() : null;
              const expired = detail.expirationDate ? detail.expirationDate < Date.now() / 1000 ? "Y" : "N" : "N";
            return [warehouse, ...mainObjectValues, ...detailValues, receivedDate, dwellVal, expired];
          })
        : [];
      const filteredRows = detailsRows.map(row => row.filter((value) => value !== undefined));
      const filteredRow = filteredRows.map(row => row.map(value => `"${value}"`)); // Enclose values in double quotes
      return filteredRow;
    });
   
    csvHeader = ["Warehouse Id", "Container", "Temperature Zone", "Asin", "Asin Title", "Cost", "CostCurrency", "Units", "Received Date", "Dwell Hours", "Is Expired"]
    const csvContent = [csvHeader, ...csvRows];
    return csvContent;
  }
  return (
    <Wrapper widths="fill">
      <TableActionBar alignmentHorizontal="justify">
        <Row spacing="200">
          <IOLSearchField mobile={mobile} />
          <IOLTempZoneFilter />
          <Row spacing="200">
            <Heading level={6}>Total Locations:</Heading>
            <Text>{iol.length.toLocaleString()}</Text>
          </Row>
          <Row spacing="200"></Row>
          <Row spacing="200"></Row>
          <Row spacing="200">
            <Heading level={6}>Total Qty:</Heading>
            <Text>{findSum()}</Text>
          </Row>
        </Row>
        <Row spacing="200">
          <CsvDownloader
              datas={createCSV()}
              filename={`${warehouseId}_IOL_${convertUnixDate(Date.now())}`}
              extension=".csv"
          >
              <Button>CSV</Button>
          </CsvDownloader>

        </Row>
      </TableActionBar>
    </Wrapper>
  )
}

IOLActionBar.propTypes = {
  stick: PropTypes.bool.isRequired,
  iol: PropTypes.arrayOf(iolPropType),
}

export default IOLActionBar
