/**
 * A table of inventory in odd locations (iol)
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import Table, { TableRow, TableCell } from "@amzn/meridian/table"
import IOLTableRow from "./iol-table-row"
import iolPropType from "../../prop-types/iol"
import iolTableColumns from "../../data/iol-table-columns"
import useIOLViewPreferences from "../../hooks/use-iol-view-preferences"

const IOLTable = props => {
  const { iol, warehouseId } = props
  const [sortColumn, setSortColumn] = useState("")
  const [viewPreferences] = useIOLViewPreferences()
  const [sortDirection, setSortDirection] = useState("ascending")

  const onSort = ({ sortColumn, sortDirection }) => {
    setSortDirection(sortDirection)
    setSortColumn(sortColumn)
  }

  // Make a copy of incoming shipments and sort if `sortColumn` exists
  const sortedIOL = [...iol]
  if (sortColumn) {
    sortedIOL.sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) {
        return sortDirection === "ascending" ? -1 : 1
      }
      if (a[sortColumn] > b[sortColumn]) {
        return sortDirection === "ascending" ? 1 : -1
      }
      return 0
    })
  }

  const visibleIOL = sortedIOL.slice(
    props.visibleIndices[0],
    props.visibleIndices[1]
  )

  return (
    <React.Fragment>
      <Table
        headerRows={1}
        showDividers={true}
        rowComponents={[IOLTableRow, TableRow]}
        stickyHeaderRow={true}
        stickyHeaderColumn={false}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={onSort}
        showStripes={true}
        spacing={viewPreferences.get("spacing")}
      >
        <TableRow>
          {iolTableColumns
            .map(header => (
              <TableCell
                key={header.id}
                sortColumn={header.id}
                width={header.width}
                alignmentHorizontal={header.alignment}
              >
                {header.label}
              </TableCell>
            ))}
          {/* Empty header cell to create space for the table row details button */}
          <TableCell />
        </TableRow>
        {visibleIOL.map((iol, index) => (
          <IOLTableRow
            key={iol.id}
            iol={iol}
            warehouseId={warehouseId}
          />
        ))}
      </Table>
    </React.Fragment>
  )
}

IOLTable.propTypes = {
  visibleIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
  iol: PropTypes.arrayOf(iolPropType),
  warehouseId: PropTypes.string.isRequired
}

IOLTable.defaultProps = {
  areAllSelected: false,
}

export default IOLTable
