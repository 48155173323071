/**
 * A table of shipments. Rendered when the "My shipments" page view is set to
 * "table".
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import Table, { TableRow, TableCell } from "@amzn/meridian/table"
import DockReceivedTableRow from "./dock-received-table-row"
import dockReceivedPropType from "../../prop-types/dock-received"
import dockReceivedTableColumns from "../../data/dock-received-table-columns"
import useDockReceivedViewPreferences from "../../hooks/use-dock-received-view-preferences"

const DockReceivedTable = props => {
  const { dockReceived, notifications, warehouseId, dcEscalations, getDCEscalations } = props
  const [sortColumn, setSortColumn] = useState("")
  const [viewPreferences] = useDockReceivedViewPreferences()
  const [sortDirection, setSortDirection] = useState("ascending")

  const visibleColumns = viewPreferences.get("columns")

  const onSort = ({ sortColumn, sortDirection }) => {
    setSortDirection(sortDirection)
    setSortColumn(sortColumn)
  }

  // Make a copy of dock received shipments and sort if `sortColumn` exists
  const sortedDockReceived = [...dockReceived]
  if (sortColumn) {
    if (sortColumn === "timeToSLA") {
      sortedDockReceived.sort((a, b) => {
        const x = a.lastDockReceiveTime + (a.sla ? Math.min(a.sla, 24) : 24) * 60 * 60 * 1000 ;
        const y = b.lastDockReceiveTime + (b.sla ? Math.min(b.sla, 24) : 24) * 60 * 60 * 1000 ;
        if (x>y) {
            return sortDirection === "ascending" ? -1 : 1
        }
        if (x < y) {
            return sortDirection === "ascending" ? 1 : -1
        }
        return 0
      })
    }
    else if (sortColumn === "dwellDays") {
      sortedDockReceived.sort((a, b) => {
        if ((Date.now() - a.lastDockReceiveTime) / (24 * 60 * 60 * 1000) < (Date.now() - b.lastDockReceiveTime) / (24 * 60 * 60 * 1000)) {
          return sortDirection === "ascending" ? -1 : 1
        }
        if ((Date.now() - a.lastDockReceiveTime) / (24 * 60 * 60 * 1000) > (Date.now() - b.lastDockReceiveTime) / (24 * 60 * 60 * 1000)) {
          return sortDirection === "ascending" ? 1 : -1
        }
        return 0
      })
    }
    else if (sortColumn === "progress") {
      sortedDockReceived.sort((a, b) => {
        if (a.receivedUnits / a.confirmedUnits < b.receivedUnits / b.confirmedUnits) {
          return sortDirection === "ascending" ? -1 : 1
        }
        if (a.receivedUnits / a.confirmedUnits > b.receivedUnits / b.confirmedUnits) {
          return sortDirection === "ascending" ? 1 : -1
        }
        return 0
      })
    }
    else if (sortColumn === "nyrnys") {
      sortedDockReceived.sort((a, b) => {
        if (a.confirmedUnits - a.receivedUnits < b.confirmedUnits - b.receivedUnits) {
          return sortDirection === "ascending" ? -1 : 1
        }
        if (a.confirmedUnits - a.receivedUnits > b.confirmedUnits - b.receivedUnits) {
          return sortDirection === "ascending" ? 1 : -1
        }
        return 0
      })
    }
    else {
      sortedDockReceived.sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) {
          return sortDirection === "ascending" ? -1 : 1
        }
        if (a[sortColumn] > b[sortColumn]) {
          return sortDirection === "ascending" ? 1 : -1
        }
        return 0
      })
    }
  }

  const visibleDockReceived = sortedDockReceived.slice(
    props.visibleIndices[0],
    props.visibleIndices[1]
  )

  return (
    <React.Fragment>
      <Table
        headerRows={1}
        // layout={"fixed"}
        showDividers={true}
        rowComponents={[DockReceivedTableRow, TableRow]}
        stickyHeaderRow={true}
        stickyHeaderColumn={false}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={onSort}
        showStripes={viewPreferences.get("stripes")}
        spacing={viewPreferences.get("spacing")}
      >
        <TableRow>
          {dockReceivedTableColumns
            // Only include columns that have been set to "visible"
            .filter(({ id }) => visibleColumns[id])
            .map(header => (
              <TableCell
                key={header.id}
                sortColumn={header.id}
                width={header.width}
                alignmentHorizontal={header.alignment}
              >
                {header.label}
              </TableCell>
            ))}
          {/* <TableCell alignmentHorizontal="center"><Text><strong>Action</strong></Text></TableCell> */}
        </TableRow>
        {visibleDockReceived.map((dockReceived, index) => (
          <DockReceivedTableRow
            key={dockReceived.id}
            dockReceived={dockReceived}
            notifications={notifications}
            warehouseId={warehouseId}
            dcEscalations={dcEscalations}
            getDCEscalations={getDCEscalations}
          />
        ))}
      </Table>
    </React.Fragment>
  )
}

DockReceivedTable.propTypes = {
  visibleIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
  dockReceived: PropTypes.arrayOf(dockReceivedPropType),
  notificatons: PropTypes.object,
  warehouseId: PropTypes.string.isRequired,
  dcEscalations: PropTypes.arrayOf(PropTypes.object),
  getDCEscalations: PropTypes.func,
}

DockReceivedTable.defaultProps = {
  areAllSelected: false,
}

export default DockReceivedTable
