import  { useState, useCallback, useRef } from "react";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import InstockDetailsTable from "./instock-details/instock-details-table";
import InstockDetailsTableFooter from "./instock-details/instock-details-table-footer";
import InstockDetailsActionBar from "./instock-details/instock-details-action-bar";
import usePagination from "../../hooks/use-pagination";
import PageLayout from "../app/page-layout";
import usePageLayoutContentRectangle from "../../hooks/use-page-layout-content-rectangle";

const InstockDetailsModal = props => {
  const {  warehouseId, instock, instockSnapshot } = props;
  const [open, setOpen] = useState(false);
  const onClickButton = useCallback(() => setOpen(true), []);
  const resetModalView = () => {
        setOpen(false);
    };
  const onClickFooterButton = useCallback(() => {
        resetModalView();
    }, []);


  const { width: contentWidth = 1500 } = usePageLayoutContentRectangle();
  const tableWrapperRef = useRef();
  const [resultsPerPage, setResultsPerPage] = useState(10);

  const pagination = usePagination({
    numberOfItemsPerPage: resultsPerPage,
    numberOfResults: instock?.length || 0,
    trackQueryParam: "page",
  });
  const tableWidth = undefined;
  const isSticky = contentWidth < tableWidth;

  const { visibleIndices } = pagination;

  const onChangeResultsPerPage = resultsPerPage => {
    // Update local state variable
    setResultsPerPage(resultsPerPage);
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    pagination.onChange(1);
  };

  const resultsPerPageOptions = [5, 10, 20, 50] 

  return (
    <>
      <Button
        size="small"
        minWidth="8%"
        onClick={onClickButton}
        data-testid="modalButton"
      >
      Details
      </Button>
      <Modal
        title="Asin Details"
        open={open}
        scrollContainer="viewport"
        describedById="instock-details-modal-description"
        onClose={onClickFooterButton}
        closeLabel="Close"
        bodySpacingInset="none">
        <Column>
          <PageLayout
            title={``}
            loading={!instock}
            width={null}
            horizontallySticky={isSticky}
            maxWidth={undefined}
            alignmentHorizontal={isSticky ? "start" : "center"}>
            <Column spacing="300">
              <InstockDetailsActionBar
                instockSnapshot={instockSnapshot}
              />
              <Row
                spacingInset={true ? "none 500 none none" : undefined}
                ref={tableWrapperRef}
              >
              <InstockDetailsTable
                visibleIndices={visibleIndices}
                warehouseId={warehouseId}
                instock={instock}
                />
              </Row>
              {pagination.numberOfPages > 0 ? (
                <InstockDetailsTableFooter
                  pagination={pagination}
                  stick={true}
                  resultsPerPage={resultsPerPage}
                  onChangeResultsPerPage={onChangeResultsPerPage}
                  resultsPerPageOptions={resultsPerPageOptions}
                />
              ) : null}
            </Column>
          </PageLayout>
        </Column>
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button type="secondary" onClick={onClickFooterButton}>
              Close
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default InstockDetailsModal