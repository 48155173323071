/**
 * All the states that a shipment can be in.
 */

const ocrVendors = [
  { id: "Y", label: "Y" },
  { id: "N", label: "N" }
]

export const getOcrVendorById = id => ocrVendors.find(crVendor => crVendor.id === id)
export const allOcrVendorIds = ocrVendors.map(({ id }) => id)

export default ocrVendors