/**
 * The navigation bar across the top of the application.
 */

import React, { useCallback } from "react"
import { useNavigate } from "react-router-dom"

import Masthead, {
  MastheadTitle,
  MastheadMenuButton,
} from "@amzn/meridian/masthead"
import Link from "@amzn/meridian/link"
import Text from "@amzn/meridian/text"
import Icon from "@amzn/meridian/icon"
import helpToken from "@amzn/meridian-tokens/base/icon/help"

import useSideMenuOpen from "../../hooks/use-side-menu-open"


const AppMasthead = () => {
  const navigate = useNavigate();
  const [sideMenuOpen, setSideMenuOpen] = useSideMenuOpen()
  const onClickMenuButton = useCallback(() => setSideMenuOpen(!sideMenuOpen), [
    sideMenuOpen,
    setSideMenuOpen,
  ])


  return (
    <Masthead>
      <MastheadMenuButton onClick={onClickMenuButton} />
      <MastheadTitle href="/" onClick={navigate}>
        <Text type="b500">
          IRIS Marauder's Map
        </Text>
      </MastheadTitle>
        <Link
          href="https://w.amazon.com/bin/view/GSF/SupplyChain/IRIS/Projects/MaraudersMapV2/#HUserGuide"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon tokens={helpToken} />
        </Link>
    </Masthead>
  )
}

export default AppMasthead