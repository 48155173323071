/**
 * A single row in the po deep dive table
 */

import React from "react"
import PropType from "prop-types"
import { TableRow, TableCell } from "@amzn/meridian/table"
import Text from "@amzn/meridian/text"
import { convertUnix } from "../../utilities/convert-unix"


const NetworkPODeepDiveTableRow = props => {
  const { networkPODeepDiveItem } = props
  return (
    <TableRow key={networkPODeepDiveItem.asin}>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={networkPODeepDiveItem.asin ? networkPODeepDiveItem.asin : ""}>
          {networkPODeepDiveItem.asin ? networkPODeepDiveItem.asin : ""}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={networkPODeepDiveItem.asinTitle ? networkPODeepDiveItem.asinTitle : "Unknown Title"}>
          {networkPODeepDiveItem.asinTitle ? networkPODeepDiveItem.asinTitle : "Unknown Title"}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={networkPODeepDiveItem.expectedQuantity ? networkPODeepDiveItem.expectedQuantity : ""}>
          {networkPODeepDiveItem.expectedQuantity ? networkPODeepDiveItem.expectedQuantity.toLocaleString() : 0}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={networkPODeepDiveItem.received ? convertUnix(networkPODeepDiveItem.received, "America/Los_Angeles") : "Never"}>
          {networkPODeepDiveItem.received ? convertUnix(networkPODeepDiveItem.received, "America/Los_Angeles") : "Never"}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={networkPODeepDiveItem.receivedBy ? networkPODeepDiveItem.receivedBy : ""}>
          {networkPODeepDiveItem.receivedBy ? networkPODeepDiveItem.receivedBy : ""}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={networkPODeepDiveItem.receivedQuantity ? networkPODeepDiveItem.receivedQuantity : 0}>
          {networkPODeepDiveItem.receivedQuantity ? networkPODeepDiveItem.receivedQuantity.toLocaleString() : 0}
        </Text>
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <Text tag="p" children={networkPODeepDiveItem.totalReceivedQuantity ? networkPODeepDiveItem.totalReceivedQuantity : 0}>
          {networkPODeepDiveItem.totalReceivedQuantity ? networkPODeepDiveItem.totalReceivedQuantity.toLocaleString() : 0}
        </Text>
      </TableCell>
    </TableRow>
  )
}

NetworkPODeepDiveTableRow.propTypes = {
  networkPODeepDiveItem: PropType.object.isRequired,
  rowComponents: TableRow.propTypes.rowComponents,
}

export default NetworkPODeepDiveTableRow
