import { getWarehouseAttributes } from "../utilities/get-warehouse-attributes";

// An explode data function with the raw data incoming
function parseFalconTrout(
    incomingData: Array<any>,
    warehouseData: Array<any>,
    setIncomingNetworkDeepDiveItems: any,
    setIncomingNetworkPODeepDiveSummary: any
) {

  const warehouseId = incomingData.filter(data => data.warehouseId !== null)[0].warehouseId;
  const { time_zone } = getWarehouseAttributes({ warehouseId: warehouseId, warehouseParams: warehouseData });
  const returnData: Array<any> = [];
  for (const data of incomingData) {
    if (data.recordType.includes("Item")) {
        const receiveHistory = data.wmsReceiveHistory ? JSON.parse(data.wmsReceiveHistory) : [];
        if (receiveHistory.length > 0) {
          for (const subData of receiveHistory) {
            returnData.push({
                "asin": data.asin,
                "asinTitle": data.asinTitle,
                "expectedQuantity": data.quantityExpected,
                "received": subData.notificationTimestamp,
                "receivedBy": subData.user,
                "receivedQuantity": subData.deltaQuantity,
                "totalReceivedQuantity": data.quantityReceived,
                "warehouseId": warehouseId,
                "time_zone": time_zone
            });
          };
        } else {
          returnData.push({
            "asin": data.asin,
            "asinTitle": data.asinTitle,
            "expectedQuantity": data.quantityExpected,
            "received": null,
            "receivedBy": null,
            "receivedQuantity": null,
            "totalReceivedQuantity": data.quantityReceived,
            "warehouseId": warehouseId,
            "time_zone": time_zone
          });
        };
    };
  };
  setIncomingNetworkDeepDiveItems(returnData);
  setIncomingNetworkPODeepDiveSummary(incomingData.filter(data => data.recordType.includes("Summary"))[0]);
};

export { parseFalconTrout };
