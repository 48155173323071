/**
 * SLA exceptions for shipments
 */

// exceptionTypes = one of:
//   "theme"
//   "neutral"
//   "success"
//   "warning"
//   "error"
const notifications = [
  { id: "nearSLA", label: "Near SLA", type: "warning" },
  { id: "pastSLA", label: "Past SLA", type: "error" },
]

export const getNotificationById = id =>
  notifications.find(notification => notification.id === id)
export const allNotificationIds = notifications.map(({ id }) => id)

export default notifications
