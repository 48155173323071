import React from "react"
import PropTypes from "prop-types"
import Column from "@amzn/meridian/column"
import Divider from "@amzn/meridian/divider"
import Heading from "@amzn/meridian/heading"
import Link from "@amzn/meridian/link"
import Text from "@amzn/meridian/text"
import { MDXProvider } from "@mdx-js/react"
import { useNavigate } from "react-router"

const LinkWithHistory = props => {
  const navigate = useNavigate()
  return <Link {...props} onClick={navigate(props.href)} />
}

/**
 * Map some basic HTML tags from Markdown output to the Meridian components
 * they should be rendered with.
 *
 * See: https://mdxjs.com/table-of-components/#components
 */
const meridianComponents = {
  a: LinkWithHistory,
  h1: props => <Heading {...props} level={1} />,
  h2: props => <Heading {...props} level={2} />,
  h3: props => <Heading {...props} level={3} />,
  h4: props => <Heading {...props} level={4} />,
  h5: props => <Heading {...props} level={5} />,
  h6: props => <Heading {...props} level={6} />,
  hr: Divider,
  p: Text,

  // ...and wrap the content in a Column by default!
  wrapper: Column,
}

/**
 * Context provider that automatically uses Meridian components for transformed MDX
 * wherever MDX is loaded in the Firefly application.
 *
 * For example: ### Title ### => h3 => <Heading level={3}>
 */
const MeridianMDXProvider = ({ components, children }) => {
  const mergedComponents = {
    ...meridianComponents,
    ...components,
  }
  return <MDXProvider components={mergedComponents}>{children}</MDXProvider>
}

MeridianMDXProvider.propTypes = {
  children: PropTypes.node,
  /**
   * Additional components that can be used to extend the behavior of the
   * default set of Meridian components mapped to HTML tags.
   */
  components: PropTypes.objectOf(PropTypes.elementType),
}

export default MeridianMDXProvider