/**
 * A single row in the iol details table. Shows details for a item.
 */

import React from "react";
import PropType from "prop-types";

import { TableRow, TableCell } from "@amzn/meridian/table";
import Text from "@amzn/meridian/text";


import useWarehouseData from "../../hooks/use-warehouse-data";

import { getWarehouseAttributes } from "../../utilities/get-warehouse-attributes";
import { convertUnix } from "../../utilities/convert-unix";


const NetworkDwellingTableRow = props => {
  const { networkDwellingShipment } = props;
  const warehouseParams = useWarehouseData();
  const { time_zone } = getWarehouseAttributes(networkDwellingShipment.warehouseId, warehouseParams);
  return (
    <TableRow key={networkDwellingShipment.orderId}>
      <TableCell>
        <Text tag="p">
          {networkDwellingShipment.warehouseId}
        </Text>
      </TableCell>
      <TableCell>
        <Text tag="p" truncate={true}>
          {networkDwellingShipment.orderId.substring(networkDwellingShipment.orderId.indexOf('_') + 1)}
        </Text>
      </TableCell>
      <TableCell>
        <Text tag="p">
          {networkDwellingShipment.orderId.startsWith("PO") ? networkDwellingShipment.distributorId : networkDwellingShipment.srcWarehouseId}
        </Text>
      </TableCell>
      <TableCell>
        <Text tag="p">
          {convertUnix(networkDwellingShipment.lastDockReceiveTime, time_zone)}
        </Text>
      </TableCell>
      <TableCell>
        <Text tag="p">
          {networkDwellingShipment.lastDockReceiveBy}
        </Text>
      </TableCell>
      <TableCell>
        <Text tag="p">
          {convertUnix(networkDwellingShipment.reconcileTime, time_zone)}
        </Text>
      </TableCell>
      <TableCell>
        <Text tag="p">
          {Math.floor((networkDwellingShipment.reconcileDuration / 60 / 60) * 100) / 100}
        </Text>
      </TableCell>
    </TableRow>
  )
}

NetworkDwellingTableRow.propTypes = {
  networkDwellingShipment: PropType.object.isRequired,
  rowComponents: TableRow.propTypes.rowComponents,
}

export default NetworkDwellingTableRow
