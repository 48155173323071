/**
 * The action bar shown above the network table. Includes a search field
 */

import React from "react";
import PropTypes from "prop-types";
import { TableActionBar } from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import NetworkPODefectsSearchField from "./network-po-defects-search-field";
import HorizontalStickyContainer from "../app/horizontal-sticky-container";
import usePageLayoutContentRectangle from "../../hooks/use-page-layout-content-rectangle";

const HorizontalStickyRow = props => (
  <HorizontalStickyContainer Component={Row} {...props} />
)


const NetworkPODefectsActionBar = props => {
  const Wrapper = props.stick ? HorizontalStickyRow : Row
  const { width: contentWidth = 1200 } = usePageLayoutContentRectangle()
  const mobile = contentWidth < 600

  return (
    <Wrapper widths="fill">
      <TableActionBar alignmentHorizontal="justify">
        <Row spacing="200">
          <NetworkPODefectsSearchField mobile={mobile}/>
        </Row>
        <Row spacing="200">
        </Row>
      </TableActionBar>
    </Wrapper>
  )
}

NetworkPODefectsActionBar.propTypes = {
  stick: PropTypes.bool.isRequired,
}

export default NetworkPODefectsActionBar
