import PropTypes from "prop-types"

// Disable alphabetical ordering to make it easier to scan for pairings (e.g.
// origin + destination)
/* eslint-disable react/sort-prop-types */
export default PropTypes.shape({
  receivedDate: PropTypes.number.isRequired,
  asin: PropTypes.string.isRequired,
  asinTitle: PropTypes.string.isRequired,
  cost: PropTypes.number,
  costCurrency: PropTypes.string,
  expirationDate: PropTypes.number,
  units: PropTypes.number
})
