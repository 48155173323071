function calculateCurrentNYR(dockReceived: Array<any>) {
  const weightedAverage = (nums: Array<number>, weights: Array<number>) => {
    let sum = 0;
    let weightSum = 0;
    for (const index in nums) {
      sum += (nums[index] * weights[index]);
      weightSum += weights[index];
    };
    if (weightSum === 0) {
      return 0
    }
    return sum / weightSum;
  };
  let nyrData: any = {};
  for (const data of dockReceived) {
    nyrData[data.warehouseId] = nyrData[data.warehouseId] ? nyrData[data.warehouseId] : {};

    nyrData[data.warehouseId][data.tempZone] = nyrData[data.warehouseId][data.tempZone] ?
        nyrData[data.warehouseId][data.tempZone]
        : {};

    const nyr = Math.max(
        ((data.confirmedUnits ? data.confirmedUnits : 0) - (data.receivedUnits ? data.receivedUnits : 0)),
        0
    )

    // We don't want 0 unit left shipments affecting our weights
    if (nyr <= 0) {
      continue;
    };

    // converting from cubic 100th of inches to cubic feet
    const incomingASINCube = data.asinCube ? data.asinCube / ((12 * 100) ** 3 ) : 0;

    let asinCube = nyrData[data.warehouseId][data.tempZone]["asinCube"] ?
        nyrData[data.warehouseId][data.tempZone]["asinCube"]
        : 0;

    asinCube += incomingASINCube;

    nyrData[data.warehouseId][data.tempZone]["asinCube"] = asinCube;

    let unitArray: Array<number> = nyrData[data.warehouseId][data.tempZone]["unitArray"] ?
        nyrData[data.warehouseId][data.tempZone]["unitArray"]
        : [];

    unitArray.push(Math.max(nyr, 0));

    nyrData[data.warehouseId][data.tempZone]["unitArray"] = unitArray;

    let dwellArray: Array<number> = nyrData[data.warehouseId][data.tempZone]["dwellArray"] ?
        nyrData[data.warehouseId][data.tempZone]["dwellArray"]
        : [];

    dwellArray.push(Math.floor((Date.now() - data.lastDockReceiveTime) / (24 * 60 * 60 * 1000) * 100) / 100);
    nyrData[data.warehouseId][data.tempZone]["dwellArray"] = dwellArray;

    let units: number = nyrData[data.warehouseId][data.tempZone]["totalUnits"] ?
        nyrData[data.warehouseId][data.tempZone]["totalUnits"]
        : 0;

    units += Math.max(nyr, 0);
    nyrData[data.warehouseId][data.tempZone]["totalUnits"] = units;

  };
  // This is the assumed average product cubic feet per pallet
  const averagePalletCubicFeet = 40;
  // Returning an array for easier processing
  let returnData: Array<any> = [];

  for (const warehouseId in nyrData) {
    const tempZoneData = nyrData[warehouseId];
    for (const tempZone in tempZoneData) {
      const cubicFeet = tempZoneData[tempZone].asinCube ? tempZoneData[tempZone].asinCube as number : 0;
      const dwelltimes = tempZoneData[tempZone].dwellArray ? tempZoneData[tempZone].dwellArray as Array<number> : [];
      const unitWeights = tempZoneData[tempZone].unitArray ?  tempZoneData[tempZone].unitArray as Array<number> : [];
      const totalUnits = tempZoneData[tempZone].totalUnits ? tempZoneData[tempZone].totalUnits as number : 0;
      returnData.push({
        warehouseId: warehouseId,
        tempZone: tempZone,
        totalCubicFeet: cubicFeet,
        units: totalUnits,
        estimatedPallets: Math.ceil(cubicFeet / averagePalletCubicFeet),
        dwellDays: weightedAverage(dwelltimes, unitWeights)
      });
    };
  };
  
  return returnData;
};

export {calculateCurrentNYR};