/**
 * "Network" page - view network level metrics
 */

import React, {  useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";

// API
import { getShipmentSummaries, getPastShipments, getSCIPSummaries } from "../graphql/queries";

// Reports
import IncomingNetworkShipments from "./network-reports/incoming-network-shipments";
import NetworkNYRBreakdown from "./network-reports/network-nyr-breakdown";
import NetworkDwelling from "./network-reports/network-dwelling";
import NetworkPODefects from "./network-reports/network-po-defects";
import NetworkPODeepDive from "./network-reports/network-po-deep-dive";

// Helpers
import { calculateIncomingUnits } from "../utilities/calculate-incoming-units";
import { calculateNetworkNYR } from "../utilities/calculate-network-nyr";
import { findNetworkDwellingShipments } from "../utilities/find-network-dwelling-shipments";
import { findNetworkPoDefects } from "../utilities/find-network-po-defects";
import { getStartOfDay } from "../utilities/get-start-of-day";
import { getPaginatedAPICall } from "../utilities/get-paginated-api-call";
import { parseShipments } from "../utilities/parse-shipments";
import { convertUnixDateNumeric } from "../utilities/convert-unix";

// constants
import { shipmentSummariesAPIRange } from "../components/Constants";


const NetworkScreen = props => {
  const { warehouseParams } = props;
  const warehouseIds = useMemo(() => [...new Set(warehouseParams.map(({ warehouse_id }) => (warehouse_id)))], [warehouseParams]);
  const [shipmentSummaries, setShipmentSummaries] = useState();
  const [scipSummaries, setSCIPSummaries] = useState();
  const [currentNYR, setCurrentNYR] = useState();
  const [expectedIncomingShipments, setExpectedIncomingShipments] = useState();
  const [pastShipments, setPastShipments] = useState();
  // const [networkDwellingPos, setNetworkDwellingPos] = useState();
  // const [networkDwellingTransshipments, setNetworkDwellingTransshipments] = useState();
  const daysForward = 7;
  const daysBack = -7;

  const getWarehouseShipmentSummaries = useCallback(async (warehouses) => {
    let params = {};
    for (const warehouse of warehouses) {
      const warehouseId = warehouse.warehouse_id;
      const timeZone = warehouse.time_zone;
      params[warehouseId] = {
                    warehouseId: warehouseId,
                    minAppointmentTime: getStartOfDay(timeZone, shipmentSummariesAPIRange.min),
                    maxAppointmentTime: getStartOfDay(timeZone, shipmentSummariesAPIRange.max),
                }
    };
    const data = await getPaginatedAPICall(params, getShipmentSummaries, "getShipmentSummaries")
    if (data) {
      setShipmentSummaries(data);
    }
  }, [setShipmentSummaries])

  const getSCIPSummariesData = useCallback(async (warehouses) => {
      let params = {};
      for (const warehouse of warehouses) {
        const warehouseId = warehouse.warehouse_id;
        const timeZone = warehouse.time_zone;
        params[warehouseId] = {
              warehouseId: warehouseId,
              minExpectedArrivalDate: convertUnixDateNumeric(getStartOfDay(timeZone), timeZone),
              maxExpectedArrivalDate: convertUnixDateNumeric(getStartOfDay(timeZone, daysForward), timeZone)
        };
      };
      const data = await getPaginatedAPICall(params, getSCIPSummaries, "getSCIPSummaries")
      if (data) {
        setSCIPSummaries(data);
      }
  }, [setSCIPSummaries, daysForward]);



  const getPastShipmentsData = useCallback(async (warehouses) => {
      let params = {};
      for (const warehouse of warehouses) {
        const warehouseId = warehouse.warehouse_id;
        const timeZone = warehouse.time_zone;
        params[warehouseId] = {warehouseId: warehouseId, minAppointmentTime: getStartOfDay(timeZone, daysBack)};
      };
      const data = await getPaginatedAPICall(params, getPastShipments, "getPastShipments")
      if (data) {
        setPastShipments(data);
      }
    }, [setPastShipments, daysBack]);

  useEffect(() => {
    setExpectedIncomingShipments();
    setCurrentNYR();
    setPastShipments();
    setSCIPSummaries();
    if (warehouseParams) {

      getWarehouseShipmentSummaries(warehouseParams);
      getPastShipmentsData(warehouseParams);
      getSCIPSummariesData(warehouseParams);
    };
  }, [getWarehouseShipmentSummaries, getPastShipmentsData, getSCIPSummariesData,
    setExpectedIncomingShipments, setCurrentNYR, setPastShipments, warehouseParams,
      setSCIPSummaries
  ]);

  useEffect(() => {
    if (shipmentSummaries && scipSummaries && warehouseParams) {
      if (shipmentSummaries.map(shipmentSummary => shipmentSummary.warehouseId).every(warehouseId => warehouseIds.includes(warehouseId))
          && scipSummaries.map(scipSummary => scipSummary.warehouseId).every(warehouseId => warehouseIds.includes(warehouseId))
      ) {
        const {
          parsedDockReceived
        } = parseShipments(shipmentSummaries);
        setExpectedIncomingShipments(calculateIncomingUnits(scipSummaries));
        setCurrentNYR(calculateNetworkNYR(parsedDockReceived));
      };
    };
  }, [shipmentSummaries, setExpectedIncomingShipments, setCurrentNYR, warehouseParams, warehouseIds, scipSummaries]
  );

  const { networkDwellingPos, networkDwellingTransshipments } = pastShipments ?
      findNetworkDwellingShipments(pastShipments) : { networkDwellingPos: null, networkDwellingTransshipments: null};
  const networkPODefects = pastShipments ? findNetworkPoDefects(pastShipments) : undefined;

  return (
    <Column spacing={"600"}>
      {! expectedIncomingShipments ?
        <Column alignmentHorizontal="center" spacingInset="600 none">
          <Loader type="circular" label="Loading shipments" />
        </Column>
          : <IncomingNetworkShipments
              expectedIncomingShipments={expectedIncomingShipments}
              warehouseParams={warehouseParams}
            />
      }
      {! currentNYR ?
        <Column alignmentHorizontal="center" spacingInset="600 none">
          <Loader type="circular" label="Loading shipments" />
        </Column>
          : <NetworkNYRBreakdown
              data={currentNYR}
            />
      }
      {! networkDwellingPos ?
        <Column alignmentHorizontal="center" spacingInset="600 none">
          <Loader type="circular" label="Loading shipments" />
        </Column>
          : <NetworkDwelling
              dwellingShipments={networkDwellingPos}
              shipmentsType={"PO"}
              shipment
            />
      }
      {! networkDwellingTransshipments ?
        <Column alignmentHorizontal="center" spacingInset="600 none">
          <Loader type="circular" label="Loading shipments" />
        </Column>
          : <NetworkDwelling
              dwellingShipments={networkDwellingTransshipments}
              shipmentsType={"TS"}
            />
      }
      {! networkPODefects ?
        <Column alignmentHorizontal="center" spacingInset="600 none">
          <Loader type="circular" label="Loading shipments" />
        </Column>
          : <NetworkPODefects
              incomingNetworkPODefects={networkPODefects}
            />
      }
      <NetworkPODeepDive />
    </Column>
  )
};

NetworkScreen.propTypes = {
  warehouseParams: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default NetworkScreen;