/**
 * All "IOL" table columns. Each column has an `id` (used as a key and for
 * sorting), `label` (the header column text), `width` (how wide the column should
 * be in pixels), and `alignment` (which side the column's content "sticks" to).
 */

const iolTableColumns = [
  {
    id: "location",
    label: "Location",
    width: 200,
    alignment: "center",
  },
  {
    id: "tempZone",
    label: "Temp Zone",
    width: 200,
    alignment: "center",
  },
  {
    id: "quantity",
    label: "Total Quantity",
    width: 200,
    alignment: "center",
  },
  {
    id: "dwellTimeHours",
    label: "Dwell (Hours)",
    width: 200,
    alignment: "center",
  },

]

export const defaultIOLTableColumns = iolTableColumns.reduce(
  (columns, column) => ({
    ...columns,
    [column.id]: true,
  }),
  {}
)

export const getIOLTableColumnById = id =>
  iolTableColumns.find(column => column.id === id)

export default iolTableColumns
