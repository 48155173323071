import PropTypes from "prop-types"
import iolDetailsPropType from "./iol-details"

// Disable alphabetical ordering to make it easier to scan for pairings (e.g.
// origin + destination)
/* eslint-disable react/sort-prop-types */
export default PropTypes.shape({
  location: PropTypes.string.isRequired,
  tempZone: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  details: PropTypes.arrayOf(iolDetailsPropType).isRequired
})
