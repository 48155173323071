/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "e053d4e4430548fc892d1a542a10c4d4",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "e053d4e4430548fc892d1a542a10c4d4",
            "region": "us-east-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "IBMaraudersMapRest",
            "endpoint": "https://jt0ust2lle.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://us62zb2sb5d6rcmzppeh7tp4sm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:c525a7e1-958a-487c-a30e-73e169d2b1d6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_yvJgXN67S",
    "aws_user_pools_web_client_id": "23ue9ancpmbp90ooto3bsoscbd",
    "oauth": {
        "domain": "iris-ibmm-afg-midway-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://dev-dsk-kcrich-1e-696087e8.us-east-1.amazon.com:3000/",
        "redirectSignOut": "https://dev-dsk-kcrich-1e-696087e8.us-east-1.amazon.com:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "gsf_shipment_items_2_beta",
            "region": "us-east-1"
        },
        {
            "tableName": "iris_fc_info",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "gsf-iris-dataloader",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
