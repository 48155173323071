import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

// Amplify
import { Analytics } from "aws-amplify";

// Meridian
import Loader from "@amzn/meridian/loader";
import Select, { SelectOption } from "@amzn/meridian/select";
import Row from "@amzn/meridian/row";

// components
import StoreFCScreen from "../screens/store-fc-screen";
import useSelectedOptions from "../hooks/use-selected-options";
import useWarehouseData from "../hooks/use-warehouse-data";
import escapeRegExp from "lodash/escapeRegExp"

const StoreFCTab = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState()
  const [selectedOptions, setSelectedOptions] = useSelectedOptions();
  const routeOptionKey = "/summary/building";
  const [warehouseId, setWarehouseId] = useState(params.warehouseId ?
      params.warehouseId.split("+")
      : selectedOptions[routeOptionKey] ?
          selectedOptions[routeOptionKey]
          : []);
  const [storeFCScreen, setStoreFCScreen] = useState(<></>);
  const searchRegExp = new RegExp(escapeRegExp(searchQuery), "i")

  const warehouseParams = useWarehouseData();

  // This is the navigation for the select
  const navigateWarehouse = useCallback(
    value => {
      const newWarehouseIds = value;
      const selectedParams = warehouseParams.filter(warehouse => newWarehouseIds.includes(warehouse.warehouse_id));
      setWarehouseId(newWarehouseIds);
      let locationArray = location.pathname.split("/");
      locationArray[3] = newWarehouseIds.join("+");
      const newLocation = locationArray.join("/");
      navigate(`../${newLocation}`)
      setStoreFCScreen(
        <StoreFCScreen
            warehouseParams={selectedParams}
          />
      );
    },
    [navigate, location, warehouseParams]
  );

  // This triggers when building is provided in url
  useEffect(() => {
    if (warehouseParams && warehouseId.length > 0) {
      const selectedParams = warehouseParams.filter(warehouse => warehouseId.includes(warehouse.warehouse_id));
      const newSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
      newSelectedOptions[routeOptionKey] = warehouseId;
      setSelectedOptions(newSelectedOptions);
      // Record selection
      for (const warehouse of warehouseId) {
        Analytics.record({ name: 'Summary Store FC Warehouse Id Selection', attributes: { warehouseId: warehouse } });
      };
      if (!params.warehouseId) {
          let locationArray = location.pathname.split("/");
          locationArray[3] = warehouseId.join("+");
          const newLocation = locationArray.join("/");
          navigate(`../${newLocation}`)
      };
      if (selectedParams !== undefined) {
        setStoreFCScreen(
          <StoreFCScreen
            warehouseParams={selectedParams}
          />
        );
      };
    }
    else {
        setStoreFCScreen(<></>)
      };
    // eslint-disable-next-line
  }, [warehouseId, warehouseParams, setSelectedOptions]);

  return (
    <>
      <Row>
        {warehouseParams ?
          <Select
              value={warehouseId}
              onChange={navigateWarehouse}
              id="warehouse-select"
              placeholder="Select a building"
              searchQuery={searchQuery}
              onSearch={setSearchQuery}
          >
              { 
               warehouseParams.map( ({warehouse_id}) => ((!searchQuery || searchRegExp.test(warehouse_id)) &&
               <SelectOption key={warehouse_id} label={warehouse_id} value={warehouse_id} />
              
            ))}
          </Select>
          : <Loader/>
        }
      </Row>
      {storeFCScreen}
    </>
  )
}

export default StoreFCTab;