/**
 * Renders a list of dismissible tags for filters chosen in the shipments table.
 */

import React from "react"
import PropTypes from "prop-types"
import Tag from "@amzn/meridian/tag"
import Row from "@amzn/meridian/row"
import HorizontalStickyContainer from "../app/horizontal-sticky-container"
import useInstockFilters from "../../hooks/use-instock-filters"

/**
 * This helper function converts the filters Map into a flattened array.
 *
 * // Before
 * Map(
 *   "foo" => { value: ["1", "2"], tagLabel: ["f 1", "f 2"] },
 *   "bar" => { value: "3", tagLabel: "b 3" },
 * )
 * // After
 * [
 *   { filter: "foo" value: "1", tagLabel: "f 1" },
 *   { filter: "foo" value: "2", tagLabel: "f 2" },
 *   { filter: "bar" value: "3", tagLabel: "b 3" },
 * ]
 */
const filterMapToArray = filtersMap => {
  // Create an array of filters from the Map
  const filtersArray = Array.from(filtersMap, ([filter, value]) => ({
    filter,
    ...value,
  }))
  // Flatten the array of filters
  const flattendFilters = filtersArray.reduce(
    (filters, { filter, value, tagLabel }) => {
      if (Array.isArray(value)) {
        return filters.concat(
          value.map((value, index) => ({
            value,
            filter,
            tagLabel: tagLabel[index],
          }))
        )
      } else {
        return filters.concat({ filter, value, tagLabel })
      }
    },
    []
  )
  return flattendFilters
}

const HorizontalStickyRow = props => (
  <HorizontalStickyContainer Component={Row} {...props} />
)

const FilterTags = props => {
  const { filters, dispatch } = useInstockFilters()
  if (filters.size === 0) return null
  const filtersArray = filterMapToArray(filters)
  const Wrapper = props.stick ? HorizontalStickyRow : Row
  return (
    <Wrapper Component={Row} spacing="300" wrap="down">
      {filtersArray.map(({ filter, value, tagLabel }) => (
        <Tag
          key={`${filter}-${value}`}
          onClose={() => dispatch({ type: "REMOVE_FILTER", filter, value })}
          closeLabel={`Remove "${tagLabel}" filter`}
        >
          {tagLabel}
        </Tag>
      ))}
    </Wrapper>
  )
}

FilterTags.propTypes = {
  stick: PropTypes.bool.isRequired,
}

export default FilterTags
