import React from "react";

import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Link from "@amzn/meridian/link"

const Landing = () => {
  let stage = 'prod';

  if (window.location.hostname.includes('beta')) {
    stage = 'beta';
  } else if (window.location.hostname.includes('dev-dsk')) {
    stage = 'dev';
  }

  return (
    <Column alignmentHorizontal="center">
      <Column>
        <Row alignmentHorizontal="center"><Heading level={2}>Welcome!</Heading></Row>
        <Row alignmentHorizontal="start"><Text>Please select a view in the left menu</Text></Row>
        <Row alignmentHorizontal="start"><Heading level={5}>FC/Store Level:</Heading><Text>This has a detailed view of what shipments current dock received, incoming and your sites Inventory in Odd Locations (IOL)</Text></Row>
        <Row alignmentHorizontal="start"><Heading level={5}>Summary or Deep Dive:</Heading><Text>This has various aggregated reports at the local and regional level</Text></Row>
      </Column>
      <Column>
        <Row></Row>
        <Row></Row>
        <Row></Row>
        {(stage === 'beta' || stage === 'dev') &&
          (<Row alignmentHorizontal="center">
            <Heading level={4}>** This tool link is a beta link and not meant for production use. Please use this link for production use -  <Link href="https://iris-ibmm-afg.gsf.amazon.dev/">https://iris-ibmm-afg.gsf.amazon.dev/</Link> ** </Heading>
          </Row>)}
      </Column>
    </Column>
  )
};

export default Landing;