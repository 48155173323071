import PropTypes from "prop-types"

// Disable alphabetical ordering to make it easier to scan for pairings (e.g.
// origin + destination)
/* eslint-disable react/sort-prop-types */
export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  loadType: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  loadId: PropTypes.string.isRequired,
  tempZone: PropTypes.string.isRequired,
  status: PropTypes.string,
  scheduledArrivals: PropTypes.arrayOf(PropTypes.number).isRequired,
  arrivalDatetime: PropTypes.arrayOf(PropTypes.number),
  expectedUnits: PropTypes.number.isRequired,
  expectedPallets: PropTypes.number.isRequired,
  expectedUnitSource: PropTypes.string.isRequired,
  shipmentId: PropTypes.string,
  ocrVendor: PropTypes.bool.isRequired,
  scac: PropTypes.string
})
