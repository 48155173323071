// /**
//  * This module exports a function that filters an array of poItems according
//  * to a Map of filters.
//  */
//

/**
 * Returns a function that checks if an object's value is in an array of options.
 * @example
 * const checkFoo = isInOptions("foo")
 * const object = { foo: "bar" }
 * checkFoo(object, ["bar", "baz"])  // returns true
 * checkFoo(object, ["baz"]) // returns false
 */

import { searchBarFilter } from "../utilities/search-bar-filter";
import defaultNetworkPODeepDiveTableColumns from "../data/network-po-deep-dive-table-columns";

// An object of filter applications where each key points to a function
// that expects `poItems` and `value` to consider
const applyFilter = {
  allFields: (poItems, value) => {
    // If any field passes, return true
    const searchableFields = defaultNetworkPODeepDiveTableColumns.map(column => column.id);
    const dateKeys = ["received"];
    return searchBarFilter(value, poItems, dateKeys, searchableFields);
  },
}

/**
 * This function filters an array of poItems according to a Map of filters.
 *
 * @param  poItems          Array of poItems objects (see `src/prop-types/incoming-iol` to see shape)
 * @param  filters            Map of filter objects
 * @return filteredNetworkPODefects Array of filtered poItems
 */
const filterNetworkPODeepDive = (poItems, filters) => {
  if (filters.size === 0) return poItems
  let filteredNetworkPODefects = [...poItems]
  // Loop through and apply filter in the Map to the poItems array
  for (const filter of filters) {
    const [key, { value }] = filter
    filteredNetworkPODefects = filteredNetworkPODefects.filter(poItems =>
      applyFilter[key](poItems, value)
    )
  }
  return filteredNetworkPODefects
}

export default filterNetworkPODeepDive
