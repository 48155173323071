import React, {useState} from "react";
import PropTypes from "prop-types";

import { TableRow, TableCell } from "@amzn/meridian/table";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Tooltip from "@amzn/meridian/tooltip";
import Link from "@amzn/meridian/link";
import {loadIdDisplayLength} from "../Constants";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import copyTokens from "@amzn/meridian-tokens/base/icon/copy";
import {getWarehouseAttributes} from "../../utilities/get-warehouse-attributes";
import useWarehouseData from "../../hooks/use-warehouse-data";
import {convertUnix} from "../../utilities/convert-unix";


export const ShipmentSearchResultTableRow = props => {
    const { result } = props;

    const { region_id, time_zone, wms } = getWarehouseAttributes(
        {warehouseId: result.warehouseId, warehouseParams: useWarehouseData()}
    );

    const [toasts, setToasts] = useState([]);
    // @ts-ignore
    const onOpenToast = loadId => setToasts(toasts.concat({ id: loadId, timeout: 1500 }));

    const transshipmentLinkMap = {
        1:
            {
                "AFTLite": `https://aftlite-portal.amazon.com/receive_inventory/show?transferId=${result.transferId}`,
                "PRIMS": "https://dashboard.prod.whitetree.ihm.a2z.com/IhmTransshipDashboards/index.html#/inbound"
            },
        2:{
            "AFTLite": `https://aftlite-portal-eu.amazon.com/receive_inventory/show?transferId=${result.transferId}`,
            "PRIMS": "https://dashboard.prod.whitetree.ihm.a2z.com/IhmTransshipDashboards/index.html#/inbound"
        },
        3:{
            "AFTLite": `https://aftlite-portal-nrt.amazon.co.jp/receive_inventory/show?transferId=${result.transferId}`,
            "PRIMS": "https://dashboard.prod.whitetree.ihm.a2z.com/IhmTransshipDashboards/index.html#/inbound"
        },
    };

    const vendorLinkMap = {

        1:{
            "AFTLite": `https://procurementportal-na.corp.amazon.com/bp/po?poId=${result.loadId}&tabId=summary`,
            "PRIMS": `https://dashboard.prod.whitetree.ihm.a2z.com/IhmVendorPODashboard/index.html#/vendor-pos-detail/${result.loadId}`
        },
        2:{
            "AFTLite": `https://procurementportal-eu.corp.amazon.com/bp/po?poId=${result.loadId}&tabId=summary`,
            "PRIMS": `https://dashboard.prod.whitetree.ihm.a2z.com/IhmVendorPODashboard/index.html#/vendor-pos-detail/${result.loadId}`
        },
        3:{
            "AFTLite": `https://procurementportal-fe.corp.amazon.com/bp/po?poId=${result.loadId}&tabId=summary`,
            "PRIMS": `https://dashboard.prod.whitetree.ihm.a2z.com/IhmVendorPODashboard/index.html#/vendor-pos-detail/${result.loadId}`
        },
    }

  const vendorStatusMap = {
    UnderConstruction: 'Under Construction',
    Reserved: 'Reserved',
    Submitted: 'Submitted',
    PartiallyConfirmed: 'Confirmed',
    CompletelyConfirmed: 'Confirmed',
    Complete: 'Reconciled'
  };

  const transshipStatusMap = {
    NOT_RECEIVED: 'Confirmed',
    RECEIVING: 'Confirmed',
    RECEIVED: 'Confirmed',
    NOTIFIED_SOURCE_OF_RECEIVE: 'Confirmed',
    RECONCILING: 'Confirmed',
    RECONCILED: 'Reconciled',
    REPORTED: 'Reconciled',
    ARCHIVED: 'Reconciled',
    CONFIRMED: 'Confirmed',
    CANCELLED: 'Cancelled',
    NOT_CANCELLED: 'Not Cancelled',
    PARTIALLY: 'Partially Cancelled',
    FULLY: 'Cancelled'
  };

    return (
        <TableRow key={result.id}>
            <TableCell alignmentHorizontal="center" width={"10%"}>
                <Row alignmentHorizontal="center">
                    <Tooltip
                        position="end"
                        title={result.orderId}
                        id={`${result.id}-${result.orderId}-toolTip`}
                    >
                        <Link
                            href={result.shipmentType === "PO" ? vendorLinkMap[region_id][wms] : transshipmentLinkMap[region_id][wms]}
                            target={"_blank"}
                            rel={"noopener noreferrer"}
                        >
                            <Text truncate={true}>{result.orderId.length > loadIdDisplayLength ? `${result.orderId.substring(0, loadIdDisplayLength)}...` : result.orderId}</Text>
                        </Link>
                    </Tooltip>
                    <Button type="icon" size="small" onClick={() => {
                        navigator.clipboard.writeText(result.orderId)
                        onOpenToast(result.orderId)
                    }
                    }>
                        <Icon tokens={copyTokens} />
                    </Button>
                </Row>
            </TableCell>
            <TableCell alignmentHorizontal="center">
                <Text tag="p">
                    {result.palletId}
                </Text>
            </TableCell>
            <TableCell alignmentHorizontal="center">
                <Text tag="p">
                    {result.asin}
                </Text>
            </TableCell>
            <TableCell alignmentHorizontal="center">
                {result.loadType === 'Vendor' && result.distributorName ?
                    <Tooltip position="end" title={result.distributorName}>
                        <Text tag="p">
                            {result.source}
                        </Text>
                    </Tooltip>
                    :
                    <Text tag="p">
                        {result.source}
                    </Text>
                }
            </TableCell>
            <TableCell alignmentHorizontal="center">
                <Text tag="p">
                    {result.warehouseId}
                </Text>
            </TableCell>
            <TableCell alignmentHorizontal="center">
                <Text tag="p">
                    {result.firstAppointmentTime ? convertUnix(result.firstAppointmentTime, time_zone) : ""}
                </Text>
            </TableCell>
            <TableCell alignmentHorizontal="center">
                <Text tag="p">
                    {result.arrivalDatetime ? convertUnix(result.arrivalDatetime, time_zone) : ""}
                </Text>
            </TableCell>
            <TableCell alignmentHorizontal="center">
                <Text tag="p">
                    {result.firstDockReceiveTime ? convertUnix(result.firstDockReceiveTime, time_zone) : ""}
                </Text>
            </TableCell>
            <TableCell alignmentHorizontal="center">
                <Text tag="p">
                    {result.confirmedUnits}
                </Text>
            </TableCell>
            <TableCell alignmentHorizontal="center">
                <Text tag="p">
                    {result.receivedUnits}
                </Text>
            </TableCell>
            <TableCell alignmentHorizontal="center">
                <Text tag="p">
                    {result.nyrNys}
                </Text>
            </TableCell>
            <TableCell alignmentHorizontal="center">
                <Text tag="p">
                    {
                      result.shipmentType === "PO" ?
                        vendorStatusMap[result.orderCondition] ?
                          vendorStatusMap[result.orderCondition] :
                            result.orderCondition
                        : transshipStatusMap[result.orderCondition] ?
                          transshipStatusMap[result.orderCondition] :
                            result.orderCondition
                    }
                </Text>
            </TableCell>
        </TableRow>
    )

};

ShipmentSearchResultTableRow.propTypes = {
    result: PropTypes.shape(
        {
            asin: PropTypes.string,
            orderId: PropTypes.string.isRequired,
            palletId: PropTypes.string,
            source: PropTypes.string,
            destination: PropTypes.string,
            manifestTime: PropTypes.number,
            appointmentTime: PropTypes.number,
            actualArrival: PropTypes.number,
            dockReceivedTime: PropTypes.number,
            status: PropTypes.string,
            nyr_nys: PropTypes.number,
            searchInput: PropTypes.string,
            searchId: PropTypes.string,
        }
    ).isRequired,
};