import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Heading from "@amzn/meridian/heading";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";

import usePagination from "../../hooks/use-pagination";
import useFilters from "../../hooks/use-filters";
import useNetworkDwellingPOsFilters from "../../hooks/use-network-dwelling-pos-filters";
import useNetworkDwellingTransshipmentsFilters from "../../hooks/use-network-dwelling-transshipments-filters";
import NetworkDwellingActionBar from "../../components/network-dwelling/network-dwelling-action-bar";
import NetworkDwellingTable from "../../components/network-dwelling/network-dwelling-table";
import TableFooter from "../../components/app/table-footer";
import EmptyTable from "../../components/app/empty-table";
import filterNetworkDwelling from "../../filters/filter-network-dwelling";
import FilterTags from "../../components/app/filter-tags";

import { defaultResultsPerPage, resultsPerPageOptions } from "../../components/Constants";


const NetworkDwelling = props => {
  const { dwellingShipments, shipmentsType } = props;
  const [resultsPerPage, setResultsPerPage] = useState(defaultResultsPerPage);
  const [selectedResultsPerPage, setSelectedResultsPerPage] = useState(defaultResultsPerPage);
  const filtersHook = shipmentsType === "PO" ? useNetworkDwellingPOsFilters : useNetworkDwellingTransshipmentsFilters;
  const { filters } = filtersHook();
  const networkDwelling = useFilters(dwellingShipments, filterNetworkDwelling, filters);

  const pagination = usePagination({
    numberOfItemsPerPage: resultsPerPage,
    numberOfResults: networkDwelling?.length || 0,
  });

  const onChangeResultsPerPage = resultsPerPage => {
    // Update local state variable
    setSelectedResultsPerPage(resultsPerPage)
    if (resultsPerPage === "All") {
      setResultsPerPage(networkDwelling?.length || 0)
    } else {
      setResultsPerPage(resultsPerPage)
    }
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    pagination.onChange(1)
  };

  // Set pagination back to page 1 when filters change
  useEffect(() => {
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    onChangeResultsPerPage(selectedResultsPerPage)
    // We disable the exhaustive-deps hooks rule here because including `pagination`
    // will cause the effect to be called over-and-over.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filters, dwellingShipments])

  const { visibleIndices } = pagination;

  return (
    <Column>
      <Row><Heading level={2}>{`${shipmentsType}s dwelling past 24 hours since dock receive in last 7 days`}</Heading></Row>
      <Row><NetworkDwellingActionBar shipmentsType={shipmentsType}/></Row>
      <Row><FilterTags stick={true} filtersHook={filtersHook}/></Row>
      <Row><NetworkDwellingTable networkDwelling={networkDwelling} visibleIndices={visibleIndices}/></Row>
      {networkDwelling.length === 0 ? (
        <Row><EmptyTable searching={Boolean(filters.size)} /></Row>
        ) : null
      }
      {networkDwelling && networkDwelling.length > 0 ? (
        <Row>
          <TableFooter
            pagination={pagination}
            stick={false}
            resultsPerPage={resultsPerPage}
            onChangeResultsPerPage={onChangeResultsPerPage}
            resultsPerPageOptions={resultsPerPageOptions}
            selectedResultsPerPage={selectedResultsPerPage}
          />
        </Row>
        ) : null
      }
    </Column>
  );
};

NetworkDwelling.propTypes = {
  dwellingShipments: PropTypes.arrayOf(PropTypes.object).isRequired,
  shipmentsType: PropTypes.string.isRequired
};

export default NetworkDwelling;