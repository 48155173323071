// /**
//  * This module exports a function that filters an array of poItems according
//  * to a Map of filters.
//  */
//

/**
 * Returns a function that checks if an object's value is in an array of options.
 * @example
 * const checkFoo = isInOptions("foo")
 * const object = { foo: "bar" }
 * checkFoo(object, ["bar", "baz"])  // returns true
 * checkFoo(object, ["baz"]) // returns false
 */

import { searchBarFilter } from "../utilities/search-bar-filter";
import defaultNYSTableColumns from "../data/nys-table-columns";

const isInOptions = key => (obj, options) => options.includes(obj[key])

// An object of filter applications where each key points to a function
// that expects `poItems` and `value` to consider
const applyFilter = {
  allFields: (nys, value) => {
    // If any field passes, return true
    const searchableFields = defaultNYSTableColumns.map(column => column.id);
    const dateKeys = [];
    return searchBarFilter(value, nys, dateKeys, searchableFields);
  },
  isCriticalAsin: isInOptions("isCriticalAsin"),
  asrsEligible: (item, value) => {
  
    if(value.length === 2) {
      return true;
    } 
    else {
      if(value[0]==='Y' && item.asrsEligible ===1 ){
        return true;
      }
      else if(value[0]==='N' && item.asrsEligible === 0 ){
        return true;
      }
    }
    return false
  }
}

/**
 * This function filters an array of poItems according to a Map of filters.
 *
 * @param  poItems          Array of poItems objects (see `src/prop-types/incoming-iol` to see shape)
 * @param  filters            Map of filter objects
 * @return filteredNetworkPODefects Array of filtered poItems
 */
const filterNYS = (poItems, filters) => {
  if (filters.size === 0) return poItems
  let filteredNetworkPODefects = [...poItems]
  // Loop through and apply filter in the Map to the poItems array
  for (const filter of filters) {
    const [key, { value }] = filter
    filteredNetworkPODefects = filteredNetworkPODefects.filter(poItems =>
      applyFilter[key](poItems, value)
    )
  }
  return filteredNetworkPODefects
}

export default filterNYS
