/**
 * A table of end of shift not yet received snapshots at building level
 */

import React from "react";
import PropTypes from "prop-types";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";

const EOSSnapshotTable = props => {
  const { eosSnapshots, tableHeaders, tempZones, warehouseIds } = props;
  return (
    <React.Fragment>
      <Table
        headerRows={2}
        showDividers={true}
        rowComponents={[TableRow]}
        stickyHeaderRow={true}
        stickyHeaderColumn={false}
        // sortColumn={sortColumn}
        // sortDirection={sortDirection}
        // onSort={onSort}
        showStripes={true}
        spacing={"medium"}
      >
        <TableRow>
          <TableCell rowSpan={2}>Site ID</TableCell>
          <TableCell rowSpan={2}>Temp Zone</TableCell>
          {Object.entries(tableHeaders).map(([date, times], index) => (
              <TableCell
                key={date}
                columnSpan={times.length}
              >
                {date}
              </TableCell>
            ))}
        </TableRow>
        <TableRow>
          {Object.entries(tableHeaders).map(([date, times], index) =>
            {return times.map(time => {
              return <TableCell key={date + time}>{time}:00</TableCell>
            })}
            )}
        </TableRow>
          {warehouseIds.map((warehouseId) => {
            return tempZones.map(tempZone => {
              return (
                <TableRow>
                  {(tempZone === "AMBIENT-3") ? <TableCell rowSpan={tempZone.length - 3} header={true}>{warehouseId}</TableCell> : (tempZone === "AMBIENT") ? <TableCell rowSpan={tempZone.length - 1} header={true}>{warehouseId}</TableCell>: null}
                  <TableCell header={true}>{tempZone}</TableCell>
                  {Object.entries(tableHeaders).map(([date, times], index) =>
                    {return times.map(time => {
                      const snapshot = eosSnapshots.filter(
                          eosSnapShot => eosSnapShot.warehouseId === warehouseId
                            && eosSnapShot.date === date
                            && eosSnapShot.warehouseHour === time
                      )
                      const filteredSnapshot = snapshot.length > 0 ? snapshot[0] : {};
                      const tempZoneMap = filteredSnapshot.nyrnysSnapshot ? JSON.parse(filteredSnapshot.nyrnysSnapshot) : null;
                      return <TableCell key={date + time}>{
                        tempZoneMap ? tempZoneMap[tempZone] ? tempZoneMap[tempZone].toLocaleString() : 0 : 0
                      }</TableCell>
                    })}
                  )}
                </TableRow>
              )
            })
          })}
      </Table>
    </React.Fragment>
  )
};

EOSSnapshotTable.propTypes = {
  eosSnapshots: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableHeaders: PropTypes.object.isRequired,
  tempZones: PropTypes.array.isRequired,
  warehouseIds: PropTypes.array.isRequired,
};

export default EOSSnapshotTable;
