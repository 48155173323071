import { API } from "aws-amplify";
async function getPaginatedAPICall(params: object, query: string, queryName: string) {
  let data: Array<any> = [];
  let nextTokens = {};
  let dataNotFetched = true;
  while (dataNotFetched) {
    let requests = {};
    for (const identifier in params) {
        const nextToken = nextTokens[identifier];
        const variables = params[identifier];
        variables.nextToken = nextToken;
        if (nextToken === null) {
            continue;
        }

        requests[identifier] = API.graphql({ query: query, variables: variables });
    }
    const resolvedArray = await Promise.all(Object.values(requests));
    const resolvedMap: any = {};

    Object.keys(requests).forEach((key, index) => {
      resolvedMap[key] = resolvedArray[index];
    });
    if (resolvedMap) {
      for (const identifier in requests) {
          const queryData = resolvedMap[identifier].data[queryName];
          nextTokens[identifier] = queryData.nextToken;
          if (queryData.items.length > 0) {
            data.push(queryData.items)
          };
      };
      if (Object.values(nextTokens).every(element => element === null)) {
                dataNotFetched = false
      };
    };
  };
  return data.flat();
};

export { getPaginatedAPICall };