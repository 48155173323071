/**
 * A context-based hook that allows components to consume and set drop box selections such as warehouse id and region
 */

import React, { useState, useContext, createContext } from "react";
import PropTypes from "prop-types";

const SelectedOptionsContext = createContext();

const SelectedOptionsContextProvider = props => {
  // This isn't really necessary but I'm putting it here as a reference and hopefully as a map for future dev
  // Let's keep these as routes to make things clearer
  const defaultSelectedOptions = {
    "/building": undefined,
    "/summary/building": undefined,
    // This one is different because it's url params
    "/summary/network": {
      "region": undefined,
      "country": undefined,
      "network": undefined,
      "group": undefined
    }
  };
  const state = useState(defaultSelectedOptions);

  return (
    <SelectedOptionsContext.Provider value={state}>
      {props.children}
    </SelectedOptionsContext.Provider>
  );
};

SelectedOptionsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useSelectedOptions = () => useContext(SelectedOptionsContext);

export { SelectedOptionsContextProvider };
export default useSelectedOptions;