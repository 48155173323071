
// A group by function with the raw data incoming
function groupInstock(incominginstock: Array<any>, falseFlagMappings, maxWeight) {

  let instockMap: any = {};
  if (incominginstock) {
    for (const instockItem of incominginstock) {
      const orderId = instockItem.palletId && instockItem.palletId !== "None" ? instockItem.palletId : instockItem.orderId;
      const transId = instockItem.orderId;
      const tempZone = instockItem.tempZone;
      const firstDockReceiveTime = instockItem.firstDockReceiveTime;
      const category = instockItem.oos_asin_category;
      const noos = instockItem.noos;
      
      let shipmentObject: any = instockMap[`${orderId}`] ? instockMap[`${orderId}`] : {};
      if (shipmentObject === undefined) {
        continue
      }
      let quantity = shipmentObject.quantity ? shipmentObject.quantity : 0;
      let weight = shipmentObject.weight ? shipmentObject.weight : 0;
      let oos_quantity = shipmentObject.oos_quantity ? shipmentObject.oos_quantity : 0;
      let oos_risk_quantity = shipmentObject.oos_risk_quantity ? shipmentObject.oos_risk_quantity : 0;
      let noos_quantity = shipmentObject.noos ? shipmentObject.noos : 0;
      quantity += instockItem.onhand_inventory;

      let risk_category = shipmentObject.alarm ? shipmentObject.alarm : "OUT_OF_STOCK_RISK";
     
      
      let detailObject: any = {};
      detailObject.id = instockItem.palletId && instockItem.palletId !== "None" ? instockItem.palletId : instockItem.orderId;
      detailObject.asin = instockItem.asin;
      detailObject.asinTitle=instockItem.asinTitle;
      detailObject.tempZoneDetailed = instockItem.tempZoneDetailed;
      detailObject.alarm = instockItem.alarm;
      detailObject.demand = instockItem.demand;
      detailObject.onhand_inventory = instockItem.onhand_inventory;
      detailObject.quantityConfirmed = instockItem.quantityConfirmed;
      detailObject.weight = instockItem.weight;
      detailObject.noos = instockItem.noos;

      let details = shipmentObject.details ? shipmentObject.details : [];
        
        if(detailObject.alarm === "OUT_OF_STOCK"){
          risk_category = "OUT_OF_STOCK"
          oos_quantity+=1;
          weight += detailObject.weight;
          if (noos==='true'){
            noos_quantity+=1
          }
        }
        else if (detailObject.alarm === "OUT_OF_STOCK_RISK"){
          oos_risk_quantity+=1;
        }
        details.push(detailObject);
      
      details.sort((a, b) => b.weight - a.weight);

      shipmentObject.id = `${orderId}`
      shipmentObject.orderId = orderId;
      shipmentObject.transId = transId;
      shipmentObject.tempZone = tempZone;
      shipmentObject.quantity = quantity;
      shipmentObject.alarm = risk_category;
      shipmentObject.firstDockReceiveTime = firstDockReceiveTime;
      shipmentObject.oos_quantity = oos_quantity;
      shipmentObject.oos_risk_quantity = oos_risk_quantity;
      shipmentObject.weight = weight
      shipmentObject.oos_asin_category = category;
      shipmentObject.noos = noos_quantity;
      shipmentObject.details = details;
      instockMap[`${orderId}`] = shipmentObject;
      
    }
    for (const key in instockMap) {
      if (instockMap.hasOwnProperty(key)) {
          const obj = instockMap[key];
          obj.vbi =(obj.weight/maxWeight)*10000
      }
  }
  
  }
  return  Object.entries(instockMap).map(([key, value]) => value);
}

export {groupInstock };
