/**
 * "Store/FC" page - view Building level metrics
 */

import React, { useRef, useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"
import Column from "@amzn/meridian/column"
import Divider from "@amzn/meridian/divider"
import Loader from "@amzn/meridian/loader"
import Row from "@amzn/meridian/row"
import Heading from "@amzn/meridian/heading"
import PageLayout from "../components/app/page-layout"
import EOSSnapshotTable from "../components/eos-snapshot/eos-snapshot-table"
import CurrentSnapshotTable from "../components/current-nyr-snapshot/current-nyr-snapshot-table"
import usePageLayoutContentRectangle from "../hooks/use-page-layout-content-rectangle"

// API
import { getShipmentSummaries, getNYRSnapshots } from "../graphql/queries";

// Helpers
import { getPaginatedAPICall } from "../utilities/get-paginated-api-call";
import { getWarehouseAttributes } from "../utilities/get-warehouse-attributes";
import { convertUnixDate } from "../utilities/convert-unix";
import { getStartOfDay } from "../utilities/get-start-of-day";
import { calculateCurrentNYR } from "../utilities/calculate-current-nyr";
import { parseShipments } from "../utilities/parse-shipments";


const getTableWidth = wrapperElement => {
  try {
    return wrapperElement.querySelector("table").clientWidth
  } catch (_) {
    return undefined
  }
}

const StoreFCScreen = props => {
 
  const { warehouseParams } = props
  
  const warehouseIds = warehouseParams.map(({ warehouse_id }) => (warehouse_id)).sort(function (a, b) { return a.localeCompare(b);})
  const regionIds = warehouseParams.map(({ region_id }) => (region_id));
  const [currentNYRSnapshot, setCurrentNYRSnapshot] = useState();
  const [eosSnapshots, setEOSSnapshots] = useState();
  const { width: contentWidth = 1200 } = usePageLayoutContentRectangle()
  const tableWrapperRef = useRef();
  const defaultTempZones = ["AMBIENT", "CHILLED", "FROZEN", "PRODUCE", "PROTEIN", "TOTAL"];
  const euDefaultTempZones = ["AMBIENT-3", "CHILLED-24", "FROZEN-24", "CHILLED-3", "AMBIENT-24", "TOTAL"]

  const getEOSSnapshots = useCallback(async (warehouses) => {
      let params = {};
      for (const warehouse of warehouses) {
        const warehouseId = warehouse.warehouse_id;
        const timeZone = warehouse.time_zone;
        params[warehouseId] = {warehouseId: warehouseId, minSnapshotTime: getStartOfDay(timeZone, -14)};
      };
      const data = await getPaginatedAPICall(params, getNYRSnapshots, "getNYRSnapshots")
      if (data) {
        setEOSSnapshots(data);
      }
  }, [setEOSSnapshots]);

  const getWarehouseShipmentSummaries = useCallback(async (warehouses) => {
      let params = {};
      let region_id = 0;
      for (const warehouse of warehouses) {
        const warehouseId = warehouse.warehouse_id;
        const timeZone = warehouse.time_zone;
        region_id = warehouse.region_id;
        params[warehouseId] = {
          warehouseId: warehouseId,
          minAppointmentTime: getStartOfDay(timeZone, -7),
          maxAppointmentTime: getStartOfDay(timeZone, 1)
        };
      };
      const data = await getPaginatedAPICall(params, getShipmentSummaries, "getShipmentSummaries")
      //console.log("params", params);
      if (data) {
        const { parsedDockReceived } = parseShipments(data);
        const currentNYR = calculateCurrentNYR(parsedDockReceived);
        // We're going to default in temp zones to 0 here if they don't exist
        let snapshots = [];
        let tz = []
        for (const warehouse of warehouses) {
          const warehouseId = warehouse.warehouse_id;
          if (region_id === 2)
            tz = ["AMBIENT-3", "CHILLED-24", "FROZEN-24", "CHILLED-3", "AMBIENT-24"];
          else
            tz = ["AMBIENT", "CHILLED", "FROZEN", "PRODUCE", "PROTEIN"];
          for (const tempZone of tz) {
            const defaultSnapshot = {warehouseId: warehouseId, tempZone: tempZone, dwellDays: 0, units: 0};
            const snapshot = currentNYR.filter(nyr => nyr.warehouseId === warehouseId && nyr.tempZone === tempZone);
            if (snapshot.length === 0) {
              snapshots.push(defaultSnapshot);
            } else {
              snapshots.push(snapshot[0]);
            }
          };
        };
        setCurrentNYRSnapshot(snapshots);
      };
    }, [setCurrentNYRSnapshot]);

  useEffect(() => {
    getWarehouseShipmentSummaries(warehouseParams);
    getEOSSnapshots(warehouseParams);
  }, [warehouseParams, getWarehouseShipmentSummaries, getEOSSnapshots]);

  // Will probably have to sort incoming data based on timestamp first
  let eosTableHeaders = {};
  if (eosSnapshots) {
    eosSnapshots.map(snapshot => {
      const warehouseId = snapshot.warehouseId;
      const { time_zone } = getWarehouseAttributes(warehouseId, warehouseParams);
      const snapshotDate = convertUnixDate(snapshot.snapshotTime, time_zone);
      const hour = snapshot.warehouseHour;
      snapshot.date = snapshotDate;
      eosTableHeaders[snapshotDate] ? eosTableHeaders[snapshotDate].push(hour) : eosTableHeaders[snapshotDate] = [hour]
      eosTableHeaders[snapshotDate] = [...new Set(eosTableHeaders[snapshotDate])]
      eosTableHeaders[snapshotDate].sort((a,b) => a - b);
      return null
    })
  };

  const tableWidth = getTableWidth(tableWrapperRef.current)
  const isSticky = contentWidth < tableWidth

  const getdefaultTempZones = (regionIds) =>{
    for(const id of regionIds){
      
      if(id===2)
        return euDefaultTempZones;
      else
        return defaultTempZones;
    }
  }

  return (
    <PageLayout
      title={""}
      loading={!eosSnapshots && !currentNYRSnapshot}
      width={eosSnapshots ? "inherit" : undefined}
      horizontallySticky={isSticky}
      maxWidth={isSticky ? "none" :  undefined}
      alignmentHorizontal={isSticky ? "start" : "center"}
    >
        <Column spacing="600">
          <Row
            spacingInset={isSticky ? "none 500 none none" : undefined}
            ref={tableWrapperRef}
          >
            <Column>
              <Heading level={1} type="h200">End of Shift NYR Snapshot</Heading>
              <EOSSnapshotTable
                eosSnapshots={eosSnapshots}
                tableHeaders={eosTableHeaders}
                tempZones={getdefaultTempZones(regionIds)}
                warehouseIds={warehouseIds}
              />
            </Column>
            </Row>
          <Row><Divider/></Row>
            <Row>
            <Column>
              <Heading level={1} type="h200">NYR/NYS by temp zone and weighted average dwell days</Heading>
              {currentNYRSnapshot ?
                <CurrentSnapshotTable
                  currentNYRSnapshot={currentNYRSnapshot}
                />
                : <Loader/>
              }
            </Column>
          </Row>
        </Column>
    </PageLayout>
  )
}

StoreFCScreen.propTypes = {
  warehouseParams: PropTypes.array.isRequired
}

export default StoreFCScreen