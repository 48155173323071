/**
 * A single row in the shipments table. Shows details for a single shipment.
 */

import React, { useState } from "react"
import Column from "@amzn/meridian/column"
import { TableRow, TableCell } from "@amzn/meridian/table"
import PropTypes from "prop-types"

import Button from "@amzn/meridian/button"
import Icon from "@amzn/meridian/icon";
import Link from "@amzn/meridian/link"
import Text from "@amzn/meridian/text"
import Tooltip from "@amzn/meridian/tooltip"
import chevronDownSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-down-small";
import chevronRightSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-right-small";
import copyTokens from "@amzn/meridian-tokens/base/icon/copy";

import RiverModal from "../river/river-modal";
import incomingShipmentPropType from "../../prop-types/incoming-shipment"
import useIncomingShipmentsViewPreferences from "../../hooks/use-incoming-shipments-view-preferences"
import useWarehouseData from "../../hooks/use-warehouse-data";
import { convertUnix } from "../../utilities/convert-unix"
import { getWarehouseAttributes } from "../../utilities/get-warehouse-attributes"
import Row from "@amzn/meridian/row";
import Alert from "@amzn/meridian/alert";
import Toaster from "@amzn/meridian/toaster";
import ExceptionTag from "../app/exception-tag";
import {loadIdDisplayLength, unscheduledVendorName, notYetManifestedName} from "../Constants";
import EventIcon from "../event/event-icon";

const IncomingShipmentsTableRow = props => {
  const { incomingShipment, warehouseId } = props

  const warehouseData = useWarehouseData()
  const { business_type, region_id, store_id, time_zone, wms } = getWarehouseAttributes(
      { warehouseId: warehouseId, warehouseParams: warehouseData }
  );

  const [viewPreferences] = useIncomingShipmentsViewPreferences()

  const visibleColumns = viewPreferences.get("columns")
  const [expandRow, setExpandrow] = useState(false)

  const [toasts, setToasts] = useState([]);
  const onCloseToast = id => setToasts(toasts.filter(t => t.id !== id));
  const onOpenToast = loadId => setToasts(toasts.concat({ id: loadId, timeout: 1500 }));

  const transshipmentLinkMap = {
    1:{
      "AFTLite": `https://aftlite-portal.amazon.com/receive_inventory/show?transferId=${incomingShipment.transferId}`,
      "PRIMS": "https://dashboard.prod.whitetree.ihm.a2z.com/IhmTransshipDashboards/index.html#/inbound"
    },
    2:{
      "AFTLite": `https://aftlite-portal-eu.amazon.com/receive_inventory/show?transferId=${incomingShipment.transferId}`,
      "PRIMS": "https://dashboard.prod.whitetree.ihm.a2z.com/IhmTransshipDashboards/index.html#/inbound"
    },
    3:{
      "AFTLite": `https://aftlite-portal-nrt.amazon.co.jp/receive_inventory/show?transferId=${incomingShipment.transferId}`,
      "PRIMS": "https://dashboard.prod.whitetree.ihm.a2z.com/IhmTransshipDashboards/index.html#/inbound"
    }
  };

  const vendorLinkMap = {
    1:{
    "AFTLite": `https://procurementportal-na.corp.amazon.com/bp/po?poId=${incomingShipment.loadId}&tabId=summary`,
    "PRIMS": `https://dashboard.prod.whitetree.ihm.a2z.com/IhmVendorPODashboard/index.html#/vendor-pos-detail/${incomingShipment.loadId}`
    },
    2:{
      "AFTLite": `https://procurementportal-eu.corp.amazon.com/bp/po?poId=${incomingShipment.loadId}&tabId=summary`,
      "PRIMS": `https://dashboard.prod.whitetree.ihm.a2z.com/IhmVendorPODashboard/index.html#/vendor-pos-detail/${incomingShipment.loadId}`
    },
    3:{
      "AFTLite": `https://procurementportal-fe.corp.amazon.com/bp/po?poId=${incomingShipment.transferId}&tabId=summary`,
      "PRIMS": `https://dashboard.prod.whitetree.ihm.a2z.com/IhmVendorPODashboard/index.html#/vendor-pos-detail/${incomingShipment.loadId}`
    }
  }


  const dockReceiveURLMap = {
    1:{
      "AFTLite": {
        "Vendor": `https://aftlite-portal.amazon.com/dock_receive/receive?receivable_order_id=${incomingShipment.loadId}`,
        "Transship": `https://aftlite-portal.amazon.com/dock_receive/receive?receivable_order_id=${incomingShipment.loadId}`
      },
      "PRIMS": {
        "Vendor": `https://ui.us-west-2.prod.associatetools.ihm.amazon.dev/remote/ihm-associate-tools-base/index.html?auth=midway&domain=prod&storeId=${store_id}`,
        "Transship": `https://ui.us-west-2.prod.associatetools.ihm.amazon.dev/remote/ihm-associate-tools-base/index.html?auth=midway&domain=prod&storeId=${store_id}`
      },
    },
    2:{
      "AFTLite": {
        "Vendor": `https://aftlite-portal-eu.amazon.com/dock_receive/receive?receivable_order_id=${incomingShipment.loadId}`,
        "Transship": `https://aftlite-portal-eu.amazon.com/dock_receive/receive?receivable_order_id=${incomingShipment.loadId}`
      },
      "PRIMS": {
        "Vendor": `https://ui.us-west-2.prod.associatetools.ihm.amazon.dev/remote/ihm-associate-tools-base/index.html?auth=midway&domain=prod&storeId=${store_id}`,
        "Transship": `https://ui.us-west-2.prod.associatetools.ihm.amazon.dev/remote/ihm-associate-tools-base/index.html?auth=midway&domain=prod&storeId=${store_id}`
      },
    },
    3:{
      "AFTLite": {
        "Vendor": `https://aftlite-portal-nrt.amazon.co.jp/dock_receive/receive?receivable_order_id=${incomingShipment.loadId}`,
        "Transship": `https://aftlite-portal-nrt.amazon.co.jp/dock_receive/receive?receivable_order_id=${incomingShipment.loadId}`
      },
      "PRIMS": {
        "Vendor": `https://ui.us-west-2.prod.associatetools.ihm.amazon.dev/remote/ihm-associate-tools-base/index.html?auth=midway&domain=prod&storeId=${store_id}`,
        "Transship": `https://ui.us-west-2.prod.associatetools.ihm.amazon.dev/remote/ihm-associate-tools-base/index.html?auth=midway&domain=prod&storeId=${store_id}`
      },
    }
  }

  // Map to correct worflow based on site parameters
  // TODO: See if we can plug in parameters
  const workflowMap = {
    1: {
      "Mendel": [
        {
          "title": "Failed Temp Test",
          "url": "https://river.amazon.com/SEA53/workflows?buildingType=amznmndl&q0=d4775f0b-b726-407d-94db-64e703c3a8cb&q1=d7ed5d53-1189-4238-9231-19e455217d2d&id=d7ed5d53-1189-4238-9231-19e455217d2d"
        },
        {
          "title": "Pallet Too Tall",
          "url": "https://river.amazon.com/SEA53/workflows?buildingType=amznmndl&q0=d4775f0b-b726-407d-94db-64e703c3a8cb&q1=a10a0c11-67fe-4f49-9b33-62be79d09b0d&id=a10a0c11-67fe-4f49-9b33-62be79d09b0d"
        },
        {
          "title": "Wrong Stores Pallet",
          "url": "https://river.amazon.com/SEA53/workflows?buildingType=amznmndl&q0=d4775f0b-b726-407d-94db-64e703c3a8cb&q1=c47eb9ef-af9d-442a-8d4a-dd08914b92f5&id=c47eb9ef-af9d-442a-8d4a-dd08914b92f5"
        },
        {
          "title": "Early Delivery Arrivals",
          "url": "https://river.amazon.com/SEA53/workflows?buildingType=amznmndl&q0=d4775f0b-b726-407d-94db-64e703c3a8cb&q1=ee7d411e-545e-429b-8c27-d3864d0fc0f1&id=ee7d411e-545e-429b-8c27-d3864d0fc0f1"
        },
        {
          "title": "Other",
          "url": "https://river.amazon.com/?org=amznmndl&buildingId=SEA53&q0=d4775f0b-b726-407d-94db-64e703c3a8cb"
        }
      ],
      "Malone": [
        {
          "title": "Failed Temp Test",
          "url": "https://river.amazon.com/SEA53/workflows?buildingType=amznmndl&q0=d4775f0b-b726-407d-94db-64e703c3a8cb&q1=d7ed5d53-1189-4238-9231-19e455217d2d&id=d7ed5d53-1189-4238-9231-19e455217d2d"
        },
        {
          "title": "Pallet Too Tall",
          "url": "https://river.amazon.com/SEA53/workflows?buildingType=amznmndl&q0=d4775f0b-b726-407d-94db-64e703c3a8cb&q1=a10a0c11-67fe-4f49-9b33-62be79d09b0d&id=a10a0c11-67fe-4f49-9b33-62be79d09b0d"
        },
        {
          "title": "Wrong Stores Pallet",
          "url": "https://river.amazon.com/SEA53/workflows?buildingType=amznmndl&q0=d4775f0b-b726-407d-94db-64e703c3a8cb&q1=c47eb9ef-af9d-442a-8d4a-dd08914b92f5&id=c47eb9ef-af9d-442a-8d4a-dd08914b92f5"
        },
        {
          "title": "Early Delivery Arrivals",
          "url": "https://river.amazon.com/SEA53/workflows?buildingType=amznmndl&q0=d4775f0b-b726-407d-94db-64e703c3a8cb&q1=ee7d411e-545e-429b-8c27-d3864d0fc0f1&id=ee7d411e-545e-429b-8c27-d3864d0fc0f1"
        },
        {
          "title": "Other",
          "url": "https://river.amazon.com/?org=amznmndl&buildingId=SEA53&q0=d4775f0b-b726-407d-94db-64e703c3a8cb"
        }
      ],
      "UFF": [
        {
          "title": "PO Rejections",
          "url": "https://river.amazon.com/BOS21/workflows?buildingType=UFFSCExecution&q0=2fad020b-108a-493c-b733-85b8d938956f&id=2fad020b-108a-493c-b733-85b8d938956f"
        },
        // {
        //   "title": "Full Pallet Short",
        //   "url": "
        // },
        {
          "title": "PO not in AFTLite/Receive",
          "url": "https://river.amazon.com/BOS21/workflows?buildingType=UFFSCExecution&q0=d9064ab3-7b54-4dc3-8f8d-3a530f438b39&id=d9064ab3-7b54-4dc3-8f8d-3a530f438b39"
        },
        {
          "title": "Vendor/Whole Foods Compliance",
          "url": "https://river.amazon.com/BOS21/workflows?buildingType=UFFSCExecution&q0=d167d18f-8a95-4bfc-9b87-83f15a87e7a5&id=d167d18f-8a95-4bfc-9b87-83f15a87e7a5"
        },
        {
         "title": "Other",
         "url": "https://river.amazon.com/BOS21/home?buildingType=UFFSCExecution"
        }
      ]
    },
    2: {
      "UFF": [
        {
         "title": "Other",
         "url": "https://river.amazon.com/LHR16/home?buildingType=aft_lite_fc&q0=ce9abbea-c39e-4860-bc14-0154f653554b&q1=4962b4d4-23f5-4bdc-afd3-0bbff7f750a8"
        }
      ]
    },
    3: {
      "UFF": [
        {
         "title": "Other",
         "url": "https://river.amazon.com/LHR16/home?buildingType=aft_lite_fc&q0=ce9abbea-c39e-4860-bc14-0154f653554b&q1=4962b4d4-23f5-4bdc-afd3-0bbff7f750a8"
        }
      ]
    },

  }

  const riverWorkflows = workflowMap[region_id][business_type] ? workflowMap[region_id][business_type] : [];

  return (
    <TableRow key={incomingShipment.id} rowComponents={props.rowComponents}>
      <Toaster toasts={toasts} onCloseToast={onCloseToast}>
            {toast => (
              <Alert toast={true} onClose={toast.onClose}>
                {`${toast.id} copied to clipboard`}
              </Alert>
            )}
        </Toaster>
      {visibleColumns.loadType && (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            {incomingShipment.loadType}
          </Text>
        </TableCell>
      )}
      {visibleColumns.source && (
        <TableCell alignmentHorizontal="center">
          {incomingShipment.loadType === 'Vendor' && incomingShipment.distributorName ?
              <Tooltip position="end" title={incomingShipment.distributorName}>
                <Text tag="p">
                  {incomingShipment.source}
                </Text>
              </Tooltip>
              :
              <Text tag="p">
                {incomingShipment.source}
              </Text>
          }
        </TableCell>
      )}
      {visibleColumns.loadId && (
        <TableCell alignmentHorizontal="center">
          <Column>
          {typeof incomingShipment.loadId === "string" ?
            [unscheduledVendorName, notYetManifestedName].includes(incomingShipment.loadId) ?
                <Text truncate={!expandRow}>{incomingShipment.loadId}</Text>
              :<Row alignmentHorizontal="center">
                <Tooltip
                    position="end"
                    title={incomingShipment.loadId}
                    id={`${incomingShipment.id}-${incomingShipment.loadId}-toolTip`}
                >
                  <Link
                    href={incomingShipment.loadType === "Vendor" ? vendorLinkMap[region_id][wms] : transshipmentLinkMap[region_id][wms]}
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                  >
                    <Text truncate={!expandRow}>{incomingShipment.loadId.length > loadIdDisplayLength ? `${incomingShipment.loadId.substring(0, loadIdDisplayLength)}...` : incomingShipment.loadId}</Text>
                  </Link>
                </Tooltip>
                <Button type="icon" size="small" onClick={() => {
                  navigator.clipboard.writeText(incomingShipment.loadId)
                  onOpenToast(incomingShipment.loadId)
                }}>
                  <Icon tokens={copyTokens} />
                </Button>


              </Row>

          : incomingShipment.loadId.length > 0 ?
          incomingShipment.loadId.map(loadId =>
          loadId === "Not Yet Manifested" ?
          <Text truncate={!expandRow}>{loadId}</Text>
          : <Row>
          <Tooltip
              position="end"
              title={incomingShipment.loadId}
              id={`${incomingShipment.id}-${incomingShipment.loadId}-toolTip`}
          >
            <Link
                href={(incomingShipment.loadType === "Vendor" && region_id===1) ?
                    `https://procurementportal-na.corp.amazon.com/bp/po?poId=${loadId}&tabId=summary`
                    : (incomingShipment.loadType === "Vendor" && region_id===2) ?
                        `https://procurementportal-eu.corp.amazon.com/bp/po?poId=${loadId}&tabId=summary`
                        : transshipmentLinkMap[region_id][wms]
                }
                target={"_blank"}
                rel={"noopener noreferrer"}
            >
              <Text truncate={!expandRow}>{loadId.length > loadIdDisplayLength ? `${loadId.substring(0, loadIdDisplayLength)}...` : loadId}</Text>
            </Link>
          </Tooltip>
          <Button type="icon" size="small" onClick={() => {
            navigator.clipboard.writeText(incomingShipment.loadId)
            onOpenToast(incomingShipment.loadId)
          }
          }>
            <Icon tokens={copyTokens} />
          </Button>
        </Row>
          )


          : null

          }
            { incomingShipment.criticalAsinEvents &&
                <Row alignmentHorizontal="center">
                  {incomingShipment.criticalAsinEvents.map(criticalEvent => <EventIcon event={criticalEvent.eventName} regionId={region_id}/>)}
                </Row>
            }
          {
            incomingShipment.arrivalDatetime[0] ? <ExceptionTag exception={"arrived"}/> : null
          } 
          </Column>  
        </TableCell>
      )}
      {visibleColumns.tempZone && (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            {incomingShipment.tempZone}
          </Text>
        </TableCell>
      )}
      {visibleColumns.criticalAsinEvents && (
        <TableCell alignmentHorizontal="center">
          <Column>
            {incomingShipment.criticalAsinEvents && incomingShipment.criticalAsinEvents.map(criticalEvent => <Text tag="p">{criticalEvent.eventName}</Text> )}
          </Column>
        </TableCell>
      )}
      {visibleColumns.status && (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            {incomingShipment.status}
          </Text>
        </TableCell>
      )}
      {visibleColumns.scheduledArrivals && (
        <TableCell alignmentHorizontal="center">
          <Column>
            { incomingShipment.scheduledArrivals.map(scheduledArrival =>
              scheduledArrival ?
                <Tooltip
                    position="end"
                    title={`Source: ${incomingShipment.firstAppointmentSource}`}
                    id={`${incomingShipment.id}-${incomingShipment.tempZone}-toolTip`}
                >
                  {incomingShipment.firstAppointmentSource !== "SCIP" && incomingShipment.associatedAppointments ?
                      <Link
                          href={`https://unified-portal-na.corp.amazon.com/#/appointment?searchType=IAS&searchCategory=appointment&searchIds=${Object.keys(JSON.parse(incomingShipment.associatedAppointments))[incomingShipment.scheduledArrivals.indexOf(scheduledArrival)]}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Text tag="p">{convertUnix(scheduledArrival, time_zone)}</Text>
                        </Link>
                      : <Text tag="p">{convertUnix(scheduledArrival, time_zone)}</Text>
                  }
                </Tooltip>
                : null
            )}
          </Column>
        </TableCell>
      )}
      {visibleColumns.expectedArrivalTime && (
        <TableCell alignmentHorizontal="center">
          <Column>
            { incomingShipment.expectedArrivalTime.map((arrival, index) =>
              incomingShipment.expectedArrivalTimeSource[index] ?
                <Tooltip
                  position="end"
                  title={`Source: ${incomingShipment.expectedArrivalTimeSource[index]}`}
                  id={`${incomingShipment.id}-${incomingShipment.tempZone}-expected-arrival-toolTip`}
                >
                  <Text tag="p">
                    {arrival ? convertUnix(arrival, time_zone) : null}
                  </Text>
                </Tooltip>
                  :
                <Text tag="p">
                  {arrival ? convertUnix(arrival, time_zone) : null}
                </Text>
              )
            }
          </Column>
        </TableCell>
      )}
      {visibleColumns.arrivalDatetime && (
        <TableCell alignmentHorizontal="center">
          <Column>
            { incomingShipment.arrivalDatetime.map((arrival, index) =>
              incomingShipment.arrivalDatetimeSource[index] ?
                <Tooltip
                  position="end"
                  title={`Source: ${incomingShipment.arrivalDatetimeSource[index]}`}
                  id={`${incomingShipment.id}-${incomingShipment.tempZone}-arrival-toolTip`}
                >
                  <Text tag="p">
                    {arrival ? convertUnix(arrival, time_zone) : null}
                  </Text>
                </Tooltip>
                  :
                <Text tag="p">
                  {arrival ? convertUnix(arrival, time_zone) : null}
                </Text>
              )
            }
          </Column>
        </TableCell>
      )}
      {visibleColumns.expectedUnits && (
        <TableCell alignmentHorizontal="center">
          <Tooltip
            position="end"
            title={`Source: ${incomingShipment.expectedUnitSource}`}
            id={`${incomingShipment.id}-${incomingShipment.tempZone}-expectedUnitSource-toolTip`}
          >
            <Text tag="p">{incomingShipment.expectedUnits ? incomingShipment.expectedUnits.toLocaleString() : 0}</Text>
          </Tooltip>
        </TableCell>
      )}
      {visibleColumns.expectedAsrsUnits && (
        <TableCell alignmentHorizontal="center">
            <Text tag="p">{incomingShipment.expectedAsrsUnits ? incomingShipment.expectedAsrsUnits.toLocaleString() : 0}</Text>
        </TableCell>
      )}
      {visibleColumns.expectedAsrsUnitsPercent && (
        <TableCell alignmentHorizontal="center">
            <Text tag="p">{incomingShipment.expectedAsrsUnitsPercent ? `${incomingShipment.expectedAsrsUnitsPercent.toLocaleString()}%` : `0%`}</Text>
        </TableCell>
      )}
      {visibleColumns.expectedPallets && (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">{incomingShipment.expectedPallets ? incomingShipment.expectedPallets.toLocaleString() : 0}</Text>
        </TableCell>
      )}
      {visibleColumns.shipmentId && (
        <TableCell alignmentHorizontal="center">
          <Column>
            {incomingShipment.shipmentId.map( shipmentId =>
              <Text tag="p">
                {shipmentId}
              </Text>
            )}
          </Column>
        </TableCell>
      )}
      {visibleColumns.ocrVendor && (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            {incomingShipment.ocrVendor}
          </Text>
        </TableCell>
      )}
      {visibleColumns.scac && (
        <TableCell alignmentHorizontal="center">
          <Text tag="p">
            {incomingShipment.scac}
          </Text>
        </TableCell>
      )}
      <TableCell alignmentHorizontal="center">
        <Button
          type="icon"
          size="small"
          onClick={() => {
              expandRow ? setExpandrow(false) : setExpandrow(true)
          }}
        >
          {expandRow ? (
            <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
            ) : (
              <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
            )
          }
        </Button>
        {expandRow && (
          <Column spacing="300">
            <Button
              size="small"
              minWidth="8%"
              href={dockReceiveURLMap[region_id][wms][incomingShipment.loadType]}
              rel={"noopener noreferrer"}
              target={"_blank"}
            >
              Dock Receive
            </Button>
             <RiverModal
               buttonTitle={"River"}
               data={incomingShipment}
               modalTitle={"River"}
               riverWorkflows={riverWorkflows}
             />
          </Column>
        )}
      </TableCell>
    </TableRow>
  )
}

IncomingShipmentsTableRow.propTypes = {
  incomingShipment: incomingShipmentPropType.isRequired,
  rowComponents: TableRow.propTypes.rowComponents,
  warehouseId: PropTypes.string.isRequired
}

export default IncomingShipmentsTableRow
