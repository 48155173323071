import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import iolDetailsPropType from "../../prop-types/iol-details";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import IOLDetailsTable from "./iol-details/iol-details-table";
import IOLDetailsTableFooter from "./iol-details/iol-details-table-footer";
import IOLDetailsEmpty from "./iol-details/iol-details-empty";
import IOLDetailsActionBar from "./iol-details/iol-details-action-bar";
import FilterTags from "./iol-details/filter-tags";
import usePagination from "../../hooks/use-pagination";
import useIOLDetailsFilters from "../../hooks/use-iol-details-filters";
import useIOLDetailsViewPreferences from "../../hooks/use-iol-details-view-preferences";
import PageLayout from "../app/page-layout";
import usePageLayoutContentRectangle from "../../hooks/use-page-layout-content-rectangle";
import useIOLDetails from "../../hooks/use-iol-details";

const getTableWidth = wrapperElement => {
  try {
    return wrapperElement.querySelector("table").clientWidth
  } catch (_) {
    return undefined
  }
}

const IOLDetailsModal = props => {
  const { incomingIOLDetails, location, warehouseId } = props;
  const [open, setOpen] = useState(false);
  const onClickButton = useCallback(() => setOpen(true), []);
  const resetModalView = () => {
        setOpen(false);
    };
  const onClickFooterButton = useCallback(() => {
        resetModalView();
    }, []);


  const { filters } = useIOLDetailsFilters();
  const [viewPreferences] = useIOLDetailsViewPreferences();
  const { width: contentWidth = 1200 } = usePageLayoutContentRectangle();
  const currentView = viewPreferences.get("view");
  const tableWrapperRef = useRef();
  const [resultsPerPage, setResultsPerPage] = useState(
    currentView === "table" ? 10 : 6
  );

  const iolDetails = useIOLDetails(incomingIOLDetails, filters);

  const pagination = usePagination({
    numberOfItemsPerPage: resultsPerPage,
    numberOfResults: iolDetails?.length || 0,
    trackQueryParam: "page",
  });
  const tableWidth =
    currentView === "table" ? getTableWidth(tableWrapperRef.current) : undefined;
  const isSticky = currentView === "table" && contentWidth < tableWidth;

  const { visibleIndices } = pagination;

  const onChangeResultsPerPage = resultsPerPage => {
    // Update local state variable
    setResultsPerPage(resultsPerPage);
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    pagination.onChange(1);
  };

  const resultsPerPageOptions =
    currentView === "table" ? [5, 10, 20] : [3, 6, 12];

  // Update the result per page options when the view changes
  useEffect(() => {
    // Reset results per page to the default number in respect to the view
    setResultsPerPage(currentView === "table" ? 10 : 6)
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    pagination.onChange(1)
    // We disable the exhaustive-deps hooks rule here because including `pagination`
    // will cause the effect to be called over-and-over.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [currentView])

  // Set pagination back to page 1 when filters change
  useEffect(() => {
    // Change pagination back to page 1. This avoids the weird state users can
    // find themselves in where they're on a page that no longer exists if the
    // number of pages decreases.
    pagination.onChange(1)
    // We disable the exhaustive-deps hooks rule here because including `pagination`
    // will cause the effect to be called over-and-over.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filters])


  return (
    <>
      <Button
                size="small"
                minWidth="8%"
                onClick={onClickButton}
                data-testid="modalButton"
            >
                Details
            </Button>
    <Modal
      title="IOL Container Details"
      open={open}
      scrollContainer="viewport"
      describedById="iol-details-modal-description"
      onClose={onClickFooterButton}
      closeLabel="Close"
    >
      <Column>
        <PageLayout
          title={`${location} Details`}
          loading={!iolDetails}
          width={iolDetails ? "inherit" : undefined}
          horizontallySticky={isSticky}
          maxWidth={
            isSticky ? "none" : currentView === "card" ? "1500px" : undefined
          }
          alignmentHorizontal={isSticky ? "start" : "center"}
        >
        <Column spacing="300">
          <IOLDetailsActionBar
            stick={isSticky}
            iolDetails={iolDetails}
          />
          <FilterTags stick={isSticky} />
          <Row
            spacingInset={isSticky ? "none 500 none none" : undefined}
            ref={tableWrapperRef}
          >
            <IOLDetailsTable
              iolDetails={iolDetails}
              visibleIndices={visibleIndices}
              warehouseId={warehouseId}
            />
          </Row>

          {iolDetails && iolDetails.length === 0 ? (
            <IOLDetailsEmpty searching={Boolean(filters.size)} />
          ) : null}
          {pagination.numberOfPages > 1 ? (
            <IOLDetailsTableFooter
              pagination={pagination}
              stick={isSticky}
              resultsPerPage={resultsPerPage}
              onChangeResultsPerPage={onChangeResultsPerPage}
              resultsPerPageOptions={resultsPerPageOptions}
            />
          ) : null}
        </Column>
    </PageLayout>
      </Column>
      <ModalFooter>
        <Row alignmentHorizontal="end" widths="fit">
          <Button type="secondary" onClick={onClickFooterButton}>
            Close
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  </>
  )
}

IOLDetailsModal.propTypes = {
  incomingIOLDetails: PropTypes.arrayOf(iolDetailsPropType).isRequired,
  location: PropTypes.string.isRequired,
  warehouseId: PropTypes.string.isRequired,
}

export default IOLDetailsModal