import PropTypes from "prop-types";
import React, {useState} from "react";
import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import { ShipmentSearchResultTableRow } from "./shipment-search-result-table-row";

export const ShipmentSearchResultTable = props => {
    const {result, visibleIndices} = props;

    const [sortColumn, setSortColumn] = useState("")
    const [sortDirection, setSortDirection] = useState<"ascending" | "descending">("ascending")

    const onSort = ({sortColumn, sortDirection}) => {
        setSortDirection(sortDirection)
        setSortColumn(sortColumn)
    }

    // Make a copy of incoming shipments and sort if `sortColumn` exists
    const sortedResults = [...result]
    if (sortColumn) {
        sortedResults.sort((a, b) => {
            if (a[sortColumn] < b[sortColumn]) {
                return sortDirection === "ascending" ? -1 : 1
            } else if (a[sortColumn] > b[sortColumn]) {
                return sortDirection === "ascending" ? 1 : -1
            }
            return 0
        })
    }

    const visibleResults = sortedResults.slice(
        visibleIndices[0],
        visibleIndices[1]
    )

    const shipmentSearchResultTableHeaders = {
    'Shipment Id': 'orderId',
        'Pallet Id': 'palletId',
        'ASIN': 'asin',
        'Source': 'source',
        'Destination Spoke': 'warehouseId',
        'Appointment Time': 'firstAppointmentTime',
        'Actual Arrival Time': 'arrivalDatetime',
        'Dock Received Time': 'firstDockReceivedTime',
        'Confirmed Units': 'confirmedUnits',
        'Received Units': 'receivedUnits',
        'NYR/NYS': 'nyrNys',
        'Status': 'orderCondition',
    }

    return (
        <Table
            headerRows={1}
            showDividers={true}
            rowComponents={[ShipmentSearchResultTableRow, TableRow]}
            stickyHeaderRow={true}
            stickyHeaderColumn={false}
            sortColumn={sortColumn}
            onSort={onSort}
            showStripes={true}
            sortDirection={sortDirection}
        >
            <TableRow>
                {Object.entries(shipmentSearchResultTableHeaders)
                    .map(([header, key], index) => (
                        <TableCell
                            key={index}
                            sortColumn={key}
                        >
                            {header}
                        </TableCell>
                    ))}
            </TableRow>
            {visibleResults.map((visibleResult, index) => (
                <ShipmentSearchResultTableRow
                    key={index}
                    result={visibleResult}
                />
            ))}
        </Table>
    )


};

ShipmentSearchResultTable.propTypes = {
    result: PropTypes.arrayOf(PropTypes.shape(
        {
            asin: PropTypes.string,
            orderId: PropTypes.string.isRequired,
            palletId: PropTypes.string,
            source: PropTypes.string,
            destination: PropTypes.string,
            manifestTime: PropTypes.number,
            appointmentTime: PropTypes.number,
            actualArrival: PropTypes.number,
            dockReceivedTime: PropTypes.number,
            status: PropTypes.string,
            nyr_nys: PropTypes.number,
            searchInput: PropTypes.string,
            searchId: PropTypes.string,
        }
    )).isRequired,
    visibleIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
};